import { ApiGateway } from '@pasteltech/api-gateway'
import {
  ApproveAssetBulkRequestPayload,
  ApproveAssetRequestPayload,
  AssetListRequestPayload,
  AssetReimburseBulkPayload,
  AssetReimburseWithdrawBulkPayload,
  AssetRequestPayload,
  DeleteAssetBulkRequestPayload,
  PatchAssetPayload,
  PostAssetPayload,
  ReimburseAssetRequestPayload,
  RejectAssetPayload,
  RejectBulkAssetPayload,
  TransitAssetRequestPayload,
} from '@stewards-fas/schemas'
import { z } from 'zod'

const PATH = '/assets'
const TIMEOUT_FOR_POSTING_ONE_ASSET = 6000

export function getAssetList(param?: AssetListRequestPayload) {
  return ApiGateway.primary.get(
    `${PATH}`,
    { request: z.any(), response: z.any() },
    {
      param,
    },
  )
}

export function postReimburseBulkAsset(payload: AssetReimburseBulkPayload) {
  return ApiGateway.primary.post(
    `${PATH}/reimburse/bulk`,
    { request: z.any(), response: z.any() },
    {
      body: payload,
    },
  )
}

export function postAssetReimburseWithdrawBulk(
  payload: AssetReimburseWithdrawBulkPayload,
) {
  return ApiGateway.primary.post(
    `${PATH}/withdraw-reimburse/bulk`,
    { request: z.any(), response: z.any() },
    {
      body: payload,
    },
  )
}

export function getAsset(id: string, param?: AssetRequestPayload) {
  return ApiGateway.primary.get(
    `${PATH}/${id}`,
    { request: z.any(), response: z.any() },
    {
      param,
    },
  )
}

export function postAsset(totalPieces: number, payload: PostAssetPayload) {
  return ApiGateway.primary.post(
    `${PATH}`,
    { request: z.any(), response: z.any() },
    {
      body: payload,
      config: {
        timeout: totalPieces * TIMEOUT_FOR_POSTING_ONE_ASSET,
      },
    },
  )
}

export function patchAsset(id: string, payload: PatchAssetPayload) {
  return ApiGateway.primary.patch(
    `${PATH}/${id}`,
    { request: z.any(), response: z.any() },
    {
      body: payload,
    },
  )
}

export function approveAsset(id: string, payload: ApproveAssetRequestPayload) {
  return ApiGateway.primary.post(
    `${PATH}/${id}/approve`,
    { request: z.any(), response: z.any() },
    {
      body: payload,
    },
  )
}

export function approveBulkAsset(payload: ApproveAssetBulkRequestPayload) {
  return ApiGateway.primary.post(
    `${PATH}/approve/bulk`,
    { request: z.any(), response: z.any() },
    {
      body: payload,
    },
  )
}

export function reimburseAsset(
  id: string,
  payload: ReimburseAssetRequestPayload,
) {
  return ApiGateway.primary.post(
    `${PATH}/${id}/reimburse`,
    { request: z.any(), response: z.any() },
    {
      body: payload,
    },
  )
}

export function transitAsset(id: string, payload: TransitAssetRequestPayload) {
  return ApiGateway.primary.post(
    `${PATH}/${id}/transit`,
    { request: z.any(), response: z.any() },
    {
      body: payload,
    },
  )
}

export function deleteAssetBulk(payload: DeleteAssetBulkRequestPayload) {
  return ApiGateway.primary.post(
    `${PATH}/delete/bulk`,
    { request: z.any(), response: z.any() },
    {
      body: payload,
    },
  )
}

export function rejectAsset({ id, ...payload }: RejectAssetPayload) {
  return ApiGateway.primary.post(
    `${PATH}/${id}/reject`,
    { request: z.any(), response: z.any() },
    {
      body: payload,
    },
  )
}

export function rejectBulkAsset(payload: RejectBulkAssetPayload) {
  return ApiGateway.primary.post(
    `${PATH}/reject/bulk`,
    { request: z.any(), response: z.any() },
    {
      body: payload,
    },
  )
}
