import { Typography } from '@mui/material'
import { TFuncKey, useTranslation } from '@pasteltech/i18n-react'
import { memo, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { SVGComponent } from '../../icons'
import { Role } from '../../models'

type TabBarType = 'box' | 'inline'

const Tab = styled.div<{ $isSelected: boolean; $type: TabBarType }>`
  display: flex;
  height: 64px;
  min-width: 180px;
  background-color: ${({ theme, $type }) =>
    $type === 'box' && theme.colors.white};
  align-items: center;
  justify-content: center;
  box-shadow: ${({ theme, $type }) =>
    $type === 'box' && `0px 2px 2px ${theme.colors.blackTransparents[3]}`};
  opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0.4)};
  gap: 8px;
  flex: 1;
  cursor: pointer;
  border-bottom: 2px solid
    ${({ $isSelected, $type, theme }) =>
      $isSelected && $type === 'inline'
        ? theme.colors.secondary
        : theme.colors.greys[4]};

  svg {
    fill: ${({ theme, $isSelected }) =>
      $isSelected ? theme.colors.secondary : theme.colors.greys[4]};
  }
`

const TabTitle = styled(Typography)`
  font-weight: 600;
  font-size: ${({ theme }) => theme.sizes.fontSize.heading5}px;
`

export enum AppManagementTabItem {
  search = 'search',
  add = 'add',
  writeOff = 'writeoff',
  transfer = 'transfer',
  approvalStatus = 'approvalStatus',
  systemUnitManagement = 'systemUnitManagement',
  systemUserManagement = 'systemUserManagement',
  systemAssetClassManagement = 'systemAssetClassManagement',
  systemFundingResourceManagement = 'systemFundingResourceManagement',
  systemReferenceMaterialManagement = 'systemReferenceMaterialManagement',
  referenceInformationFixedAssetGuideline = 'referenceInformationFixedAssetGuideline',
  referenceInformationAssetPurchaseFrom = 'referenceInformationAssetPurchaseFrom',
  referenceInformationPriceComparisonTable = 'referenceInformationPriceComparisonTable',
  referenceInformationAssetType = 'referenceInformationAssetType',
  referenceInformationSourcesOfFunds = 'referenceInformationSourcesOfFunds',
}

export type AppManagementTabItemType = {
  value: AppManagementTabItem
  titleKey: TFuncKey
  icon: SVGComponent
  content: any
  allowedRoles: Role[]
  type?: TabBarType
  dataKey?: string
}

type AppManagementTabProps = Omit<AppManagementTabItemType, 'allowedRoles'> & {
  isSelectedTab: boolean
  onSelect: (value: AppManagementTabItem) => void
}

export const AppManagementTab = memo<AppManagementTabProps>(
  ({ value, titleKey, icon, isSelectedTab, onSelect, type = 'box' }) => {
    const { t } = useTranslation()

    const Icon = icon

    const tabTitle = useMemo(() => t(titleKey) as string, [t, titleKey])

    const onClick = useCallback(() => onSelect(value), [onSelect, value])

    return (
      <Tab $isSelected={isSelectedTab} onClick={onClick} $type={type}>
        <Icon />
        <TabTitle>{tabTitle}</TabTitle>
      </Tab>
    )
  },
)

AppManagementTab.displayName = 'AppManagementTab'
