import { PendingIcon } from '../../../icons'
import { theme } from '../../../theme'
import {
  FormCheckboxField,
  FormReadOnlyField,
  FormSelectField,
  FormTextField,
} from '../../form-fields'
import { FieldProps, FormAction } from './tabs-form-fields'

export const BasicInfoFormFields: FieldProps[] = [
  {
    field: 'status',
    element: FormTextField,
    props: {
      backgroundColor: theme.colors.lightGreen,
      fontColor: theme.colors.darkGreen,
    },
    actions: [FormAction.edit, FormAction.view],
    readOnlyActions: [FormAction.edit],
  },
  {
    field: 'unit',
    element: FormSelectField,
    actions: [FormAction.create],
  },
  {
    field: 'assetType',
    element: FormSelectField,
    actions: [FormAction.create],
  },
  {
    field: 'year',
    element: FormSelectField,
    actions: [FormAction.create, FormAction.edit, FormAction.view],
    readOnlyActions: [FormAction.edit],
  },
  {
    field: 'assetNo',
    element: FormReadOnlyField,
    props: { icon: PendingIcon },
    actions: [FormAction.create, FormAction.edit, FormAction.view],
    readOnlyActions: [FormAction.create, FormAction.edit],
  },
  {
    field: 'name',
    element: FormTextField,
    actions: [FormAction.create, FormAction.edit, FormAction.view],
  },
  {
    field: 'brand',
    element: FormTextField,
    actions: [FormAction.create, FormAction.edit, FormAction.view],
  },
  {
    field: 'model',
    element: FormTextField,
    actions: [FormAction.create, FormAction.edit, FormAction.view],
  },
  {
    field: 'batchInput',
    element: FormCheckboxField,
    actions: [FormAction.create],
  },
  {
    field: 'totalPieces',
    props: {
      type: 'number',
    },
    actions: [FormAction.create],
    element: FormTextField,
  },
  {
    field: 'quantity',
    props: {
      type: 'number',
    },
    element: FormTextField,
    actions: [FormAction.create, FormAction.edit, FormAction.view],
  },
  {
    field: 'serialNo',
    element: FormTextField,
    actions: [FormAction.create, FormAction.edit, FormAction.view],
  },
  {
    field: 'valuedAmount',
    props: {
      type: 'number',
    },
    element: FormTextField,
    actions: [FormAction.create, FormAction.edit, FormAction.view],
  },
  {
    field: 'atUnitNotes',
    element: FormTextField,
    actions: [FormAction.create, FormAction.edit, FormAction.view],
  },
  {
    field: 'remarks',
    element: FormTextField,
    actions: [FormAction.create, FormAction.edit, FormAction.view],
  },
  {
    field: 'supervisor',
    element: FormReadOnlyField,
    actions: [FormAction.create, FormAction.edit, FormAction.view],
  },
  {
    field: 'formFiller',
    element: FormReadOnlyField,
    actions: [FormAction.edit, FormAction.view],
  },
]
