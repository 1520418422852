import { memo, useCallback } from 'react'
import {
  AppBar as MuiAppBar,
  Typography,
  Toolbar,
  IconButton,
} from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from '@pasteltech/i18n-react'
import { useQueryUserProfile } from '@stewards-fas/queries'
import { useView, ScreenSize } from '../../utilities'
import {
  LogoutIcon,
  MenuIcon,
  StewardsHeaderLogoMedium,
  StewardsHeaderLogoSmall,
  UserIcon,
} from '../../icons'
import { useLogout } from '../../services'

export const APPBAR_NORMAL_VIEW_HEIGHT = 72
export const APPBAR_MOBILE_VIEW_HEIGHT = 52

const StyledAppBar = styled(MuiAppBar)`
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  z-index: 1300;

  box-shadow: 0px 2px 2px ${({ theme }) => theme.colors.blackTransparents[0]};
`

const StyledToolbar = styled(Toolbar)<{ $view: ScreenSize }>`
  justify-content: space-between;
  min-height: ${({ $view }) =>
    $view === ScreenSize.mobile
      ? APPBAR_MOBILE_VIEW_HEIGHT
      : APPBAR_NORMAL_VIEW_HEIGHT}px;
  height: ${({ $view }) =>
    $view === ScreenSize.mobile
      ? APPBAR_MOBILE_VIEW_HEIGHT
      : APPBAR_NORMAL_VIEW_HEIGHT}px;
  margin-left: ${({ $view }) => ($view === ScreenSize.desktop ? 100 : 0)}px;
  margin-right: ${({ $view }) => ($view === ScreenSize.desktop ? 100 : 0)}px;
`

const LeftContainer = styled.div<{ $view: ScreenSize }>`
  text-align: left;
  display: flex;
  align-items: center;
  gap: ${({ theme, $view }) =>
    theme.sizes.spacings[$view === ScreenSize.mobile ? 2 : 5]}px;
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  color: ${({ theme }) => theme.colors.primary};
`

const AppBarHeader = styled(Typography)<{ $view: ScreenSize }>`
  color: ${({ theme }) => theme.colors.greys[2]};
  font-weight: bold;
  font-size: ${({ theme, $view }) =>
    $view === ScreenSize.desktop
      ? theme.sizes.fontSize.heading5
      : theme.sizes.fontSize.large}px;
`

const UserNameText = styled(Typography)`
  font-size: ${({ theme }) => theme.sizes.fontSize.normal}px;
  color: ${({ theme }) => theme.colors.primary};
`

const UserInformationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.sizes.spacings[3]}px;
`

type Props = {
  handleMenuOnClick: () => void
  isUnauthorized?: boolean
}

export const AppBar = memo<Props>(({ handleMenuOnClick, isUnauthorized }) => {
  const view = useView()
  const { t } = useTranslation()
  const logout = useLogout()

  const { data } = useQueryUserProfile()

  const logoutButtonOnClick = useCallback(() => {
    logout()
  }, [logout])

  return (
    <StyledAppBar position="fixed">
      <StyledToolbar $view={view}>
        <LeftContainer $view={view}>
          {view === ScreenSize.mobile && !isUnauthorized && (
            <IconButton color="inherit" onClick={handleMenuOnClick}>
              <MenuIcon />
            </IconButton>
          )}
          {view === ScreenSize.mobile ? (
            <StewardsHeaderLogoSmall />
          ) : (
            <StewardsHeaderLogoMedium />
          )}
          {!isUnauthorized && (
            <AppBarHeader $view={view}>{t('appbar.header')}</AppBarHeader>
          )}
        </LeftContainer>
        {(view === ScreenSize.desktop || isUnauthorized) && (
          <RightContainer>
            <UserInformationContainer>
              <UserIcon />
              <UserNameText>{data?.username ?? '-'}</UserNameText>
            </UserInformationContainer>
            <IconButton onClick={logoutButtonOnClick}>
              <LogoutIcon />
            </IconButton>
          </RightContainer>
        )}
      </StyledToolbar>
    </StyledAppBar>
  )
})

AppBar.displayName = 'AppBar'
