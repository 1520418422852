import {
  ApiFormattedError,
  getAssetTypeList,
  patchAssetType,
  postAssetType,
} from '@stewards-fas/apis'
import {
  AssetTypeListRequestPayload,
  AssetTypeListResponsePayload,
  AssetTypeResponsePayload,
  PatchAssetTypePayload,
  PostAssetTypePayload,
} from '@stewards-fas/schemas'
import {
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import { callbackNonNullable, useOptsOnSuccessInvalidate } from './helper'

const QUERY_CACHE_KEY_ROOT = 'asset-types'

function useOpts<R, E, P>(opts?: UseMutationOptions<R, E, P>) {
  return useOptsOnSuccessInvalidate([QUERY_CACHE_KEY_ROOT], opts)
}

export function useAssetTypeList(
  param?: AssetTypeListRequestPayload,
  opts?: UseQueryOptions<unknown, unknown, AssetTypeListResponsePayload>,
) {
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => getAssetTypeList(param),
    opts,
  )
}

export function usePostAssetTypeMutation(
  opts?: UseMutationOptions<
    AssetTypeResponsePayload,
    ApiFormattedError,
    PostAssetTypePayload
  >,
) {
  const options = useOpts(opts)
  return useMutation(callbackNonNullable(postAssetType), options)
}

export function usePatchAssetTypeMutation(
  opts?: UseMutationOptions<
    AssetTypeResponsePayload,
    ApiFormattedError,
    PatchAssetTypePayload
  >,
) {
  const options = useOpts(opts)
  return useMutation(callbackNonNullable(patchAssetType), options)
}
