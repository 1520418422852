import { Box } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { useFormikContext } from 'formik'
import { memo, useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  BlankImageIcon,
  ReplaceIcon,
  UploadIcon,
  WriteOffIcon,
} from '../../icons'
import { AssetFormSchemaModel } from '../../models'
import { ImageDialog } from './image-dialog'
import {
  useImageUploaderDelete,
  useImageUploaderReplace,
} from './image-uploader-hook'

const Wrapper = styled(Box)<{
  $isFileSelected: boolean
  $readonly: boolean
}>`
  border: 1px solid ${({ theme }) => theme.colors.greys[3]};
  position: relative;
  height: 100%;
  width: 100%;
  background-color: ${({ $isFileSelected, theme }) =>
    !$isFileSelected && theme.colors.greys[4]};
  ${({ $readonly, $isFileSelected, theme }) =>
    !$readonly &&
    `:hover {
    img {
      opacity: 0.5;
    }
    background-color: ${
      $isFileSelected ? theme.colors.greys[3] : theme.colors.greys[2]
    }
    };`}
`

const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const WriteOffIconWrapper = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`
const BlankImageIconWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const UploadImageIconWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`
const StyledWriteOffIcon = styled(WriteOffIcon)`
  stroke-width: 0.5;
  stroke: ${({ theme }) => theme.colors.white};
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`

const StyledReplaceIcon = styled(ReplaceIcon)`
  stroke: ${({ theme }) => theme.colors.white};
  stroke-width: 0.5;
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
  transform: scale(2);
`

const StyledUploadIcon = styled(UploadIcon)`
  stroke: ${({ theme }) => theme.colors.white};
  stroke-width: 0.5;
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
  transform: scale(2);
`
type Props = {
  name: string
  readonly?: boolean
  order: number
  handleUpload: (file: File) => Promise<string>
  creating: boolean
  currentAssetId: string
  readonlyOnClick: () => void
}

enum ImageAction {
  delete = 'delete',
  upload = 'upload',
  replace = 'replace',
}
export const ImageUploader = memo<Props>(
  ({
    name,
    readonly = false,
    order,
    handleUpload,
    creating,
    currentAssetId,
    readonlyOnClick,
  }) => {
    const [imageAction, setImageAction] = useState<ImageAction | undefined>()
    const [isHovering, setIsHovering] = useState(false)
    const { t } = useTranslation()
    const imageInputRef = useRef<HTMLInputElement>(null)
    const { values, setFieldValue } = useFormikContext<AssetFormSchemaModel>()

    const resetImageInputRef = useCallback(() => {
      if (imageInputRef.current) {
        imageInputRef.current.value = ''
      }
    }, [])

    const imageFile = useMemo(() => {
      if (creating) {
        return values.createResources?.find(
          (resource) => resource.order === order,
        )
      }
      return values.editResources?.find(
        (resource) => resource.order === order && !('isDeleted' in resource),
      )
    }, [values, order, creating])

    const { handleDelete } = useImageUploaderDelete(
      values,
      order,
      setFieldValue,
      creating,
    )
    const { handleReplace } = useImageUploaderReplace(
      values,
      order,
      setFieldValue,
      creating,
      handleUpload,
      currentAssetId,
    )

    const handleUploadIconClicked = useCallback(() => {
      imageInputRef.current?.click()
    }, [])

    const handleConfirm = useCallback(async () => {
      if (imageAction === ImageAction.delete) {
        await handleDelete()
        resetImageInputRef()
        return
      }
      const newImageFile = imageInputRef.current?.files?.[0]
      if (newImageFile) {
        await handleReplace(newImageFile)
        resetImageInputRef()
      }
    }, [handleDelete, handleReplace, imageAction, resetImageInputRef])

    const handleFileChange = useCallback(() => {
      if (imageFile) {
        setImageAction(ImageAction.replace)
        return
      }
      setImageAction(ImageAction.upload)
    }, [setImageAction, imageFile])

    const dialogTitleAndMessage = useMemo(
      () => ({
        delete: {
          message: t('imageUploader.delete.message'),
          title: t('imageUploader.delete.title'),
        },
        upload: {
          message: t('imageUploader.upload.message'),
          title: t('imageUploader.upload.title'),
        },
        replace: {
          message: t('imageUploader.replace.message'),
          title: t('imageUploader.replace.title'),
        },
      }),
      [t],
    )

    return (
      <Wrapper
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        $isFileSelected={imageFile !== undefined}
        $readonly={readonly}
        onClick={() => {
          if (readonly && imageFile) {
            readonlyOnClick()
          }
        }}
      >
        {imageFile && <ImagePreview src={imageFile.url} />}
        {!readonly && !imageFile && (
          <BlankImageIconWrapper>
            <BlankImageIcon />
          </BlankImageIconWrapper>
        )}
        {!readonly && isHovering && (
          <WriteOffIconWrapper
            onClick={() => setImageAction(ImageAction.delete)}
          >
            <StyledWriteOffIcon />
          </WriteOffIconWrapper>
        )}
        {!readonly && isHovering && (
          <UploadImageIconWrapper>
            {imageFile === undefined ? (
              <StyledUploadIcon onClick={handleUploadIconClicked} />
            ) : (
              <StyledReplaceIcon onClick={handleUploadIconClicked} />
            )}
          </UploadImageIconWrapper>
        )}
        <input
          type="file"
          name={name}
          id={name}
          onChange={handleFileChange}
          hidden
          ref={imageInputRef}
          accept=".jfif, image/*"
        />
        {imageAction !== undefined && (
          <ImageDialog
            title={dialogTitleAndMessage[imageAction].title}
            message={dialogTitleAndMessage[imageAction].message}
            onClose={() => {
              resetImageInputRef()
              setImageAction(undefined)
              setIsHovering(false)
            }}
            onConfirm={() => {
              handleConfirm()
            }}
            visible={imageAction !== undefined}
          />
        )}
      </Wrapper>
    )
  },
)
ImageUploader.displayName = 'ImageUploader'
