import { Grid } from '@mui/material'
import { TFuncKey, useTranslation } from '@pasteltech/i18n-react'
import { useOverlay } from '@pasteltech/overlay-provider'
import { useFormikContext } from 'formik'
import { memo, useCallback } from 'react'
import styled from 'styled-components'
import {
  CreateAssetFormSchema,
  CreateAssetFormSchemaModel,
} from '../../../models'
import { Dialog } from '../../dialog'
import { BaseFormLabel, BaseTextField } from '../../form-fields'

type Props = {
  onClose(): void
  numberOfBulkAsset: number
}

const Container = styled.div`
  height: 400px;
  overflow: auto;
`

export const BatchAssetModal = memo<Props>(({ onClose, numberOfBulkAsset }) => {
  const { t } = useTranslation()
  const { setFieldValue, handleSubmit, values } =
    useFormikContext<CreateAssetFormSchemaModel>()

  const { showSnackbar } = useOverlay()

  const handleSerialNoChange = useCallback(
    (index: number, value: string) => {
      setFieldValue(`assetBulk.${index}.serialNo`, value)
    },
    [setFieldValue],
  )
  const handleSelected = useCallback(
    async (val: boolean | undefined) => {
      if (val === true) {
        try {
          await CreateAssetFormSchema.validateAt('assetBulk', values)
          handleSubmit()
        } catch (err) {
          showSnackbar({
            content: t([
              (err as Error).message as TFuncKey,
              'assetDetailsPage.form.errorMessages.invalidInput',
            ]) as string,
            severity: 'error',
          })
          return
        }
      }
      onClose()
    },
    [onClose, showSnackbar, values, handleSubmit, t],
  )
  return (
    <Dialog
      dismissable
      visible
      title={t('assetCreatePage.batchAssetModal.title')}
      onSelected={handleSelected}
    >
      <Container>
        <Grid container gap="20px" py="6px">
          <Grid item width={20} />
          <Grid item flexGrow={1}>
            <BaseFormLabel>
              {t('assetCreatePage.batchAssetModal.seriesNumber')}
            </BaseFormLabel>
          </Grid>
        </Grid>
        {Array.from(Array(numberOfBulkAsset).keys()).map((it) => (
          <Grid container gap="20px" key={it} py="6px">
            <Grid item width={20} pr={1} display="flex" alignItems="center">
              {it + 1}:
            </Grid>
            <Grid item flexGrow={1}>
              <BaseTextField
                size="small"
                value={
                  values.assetBulk[it] ? values.assetBulk[it].serialNo : ''
                }
                onChange={(event) =>
                  handleSerialNoChange(it, event.target.value)
                }
              />
            </Grid>
          </Grid>
        ))}
      </Container>
    </Dialog>
  )
})

BatchAssetModal.displayName = 'BatchAssetModal'
