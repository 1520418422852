import { Navigate, RouteObject } from 'react-router-dom'
import { Role } from '../models'
import {
  AssetEditPage,
  AssetManagementAddSection,
  AssetManagementApprovalSection,
  AssetManagementPage,
  AssetManagementSearchSection,
  AssetManagementTransferSection,
  AssetManagementWriteOffSection,
  AssetTransferPreviewPage,
  AssetViewPage,
  AssetViewPageAction,
  GenerateReportPage,
  HealthCheckPage,
  LandingPage,
  ManageSystemDataPage,
  ReferenceInformationPage,
  SearchAssetsQRPage,
} from '../pages'
import { AppRoutes } from './paths'
import { ProtectedRoute } from './protected-route'

export type RouteItem = Omit<RouteObject, 'children'> & {
  accessibleRoles?: Role[]
  children?: RouteItem[]
}

export const routes: RouteItem[] = [
  {
    path: AppRoutes.healthCheck.path,
    element: <HealthCheckPage />,
  },
  {
    path: AppRoutes.login.path,
    element: <LandingPage />,
  },
  {
    path: AppRoutes.base.path,
    element: <ProtectedRoute />,
    children: [
      {
        path: AppRoutes.assetManagement.path,
        element: <AssetManagementPage />,
        accessibleRoles: AppRoutes.assetManagement.accessibleRoles,
        children: [
          {
            path: AppRoutes.assetManagement.searchSection.path,
            element: <AssetManagementSearchSection />,
            accessibleRoles:
              AppRoutes.assetManagement.searchSection.accessibleRoles,
          },
          {
            path: AppRoutes.assetManagement.createSection.path,
            element: <AssetManagementAddSection />,
            accessibleRoles:
              AppRoutes.assetManagement.createSection.accessibleRoles,
          },
          {
            path: AppRoutes.assetManagement.writeOffSection.path,
            element: <AssetManagementWriteOffSection />,
            accessibleRoles:
              AppRoutes.assetManagement.writeOffSection.accessibleRoles,
          },
          {
            path: AppRoutes.assetManagement.transferSection.path,
            element: <AssetManagementTransferSection />,
            accessibleRoles:
              AppRoutes.assetManagement.transferSection.accessibleRoles,
          },
          {
            path: AppRoutes.assetManagement.approvalSection.path,
            element: <AssetManagementApprovalSection />,
            accessibleRoles:
              AppRoutes.assetManagement.approvalSection.accessibleRoles,
          },
          // Default section for Asset Management
          {
            index: true,
            element: (
              <Navigate
                to={AppRoutes.assetManagement.searchSection.path}
                replace
              />
            ),
          },
        ],
      },
      {
        path: AppRoutes.referenceInformation.path,
        element: <ReferenceInformationPage />,
        accessibleRoles: AppRoutes.referenceInformation.accessibleRoles,
      },
      {
        path: AppRoutes.report.path,
        element: <GenerateReportPage />,
        accessibleRoles: AppRoutes.report.accessibleRoles,
      },
      {
        path: AppRoutes.systemManagement.path,
        element: <ManageSystemDataPage />,
        accessibleRoles: AppRoutes.systemManagement.accessibleRoles,
      },
      {
        path: AppRoutes.assetEdit.path,
        element: <AssetEditPage />,
        accessibleRoles: AppRoutes.assetEdit.accessibleRoles,
      },
      {
        path: AppRoutes.assetView.path,
        element: <AssetViewPage pageAction={AssetViewPageAction.view} />,
        accessibleRoles: AppRoutes.assetView.accessibleRoles,
      },
      {
        path: AppRoutes.assetApproval.path,
        element: <AssetViewPage pageAction={AssetViewPageAction.approval} />,
        accessibleRoles: AppRoutes.assetApproval.accessibleRoles,
      },
      {
        path: AppRoutes.assetTransferView.path,
        element: <AssetViewPage pageAction={AssetViewPageAction.transfer} />,
        accessibleRoles: AppRoutes.assetTransferView.accessibleRoles,
      },
      {
        path: AppRoutes.assetPreview.path,
        element: <AssetTransferPreviewPage isPreviewAsset={true} />,
        accessibleRoles: AppRoutes.assetPreview.accessibleRoles,
      },
      {
        path: AppRoutes.assetTransferPreview.path,
        element: <AssetTransferPreviewPage />,
        accessibleRoles: AppRoutes.assetTransferPreview.accessibleRoles,
      },
      {
        path: AppRoutes.searchAssets.path,
        element: <SearchAssetsQRPage />,
        accessibleRoles: AppRoutes.searchAssets.accessibleRoles,
      },

      // Default Home Page (Authorized user)
      {
        index: true,
        element: <Navigate to={AppRoutes.assetManagement.path} replace />,
      },

      // Redirect to Asset Management Page (Authorized user)
      {
        path: '*',
        element: <Navigate to={AppRoutes.assetManagement.path} replace />,
      },
    ],
  },

  // Redirect to Asset Management Page (Unauthorized user)
  {
    path: '*',
    element: <Navigate to={AppRoutes.login.path} replace />,
  },
]
