import { useTranslation } from '@pasteltech/i18n-react'
import { AssetStatus } from '@stewards-fas/schemas'
import { useMemo } from 'react'
export const useAssetStatus = (status: AssetStatus) => {
  const { t } = useTranslation()
  const translator: Record<AssetStatus, string> = useMemo(
    () => ({
      [AssetStatus.created]: t('assetDetailsPage.assetStatus.created'),
      [AssetStatus.assetUpdated]: t(
        'assetDetailsPage.assetStatus.assetUpdated',
      ),
      [AssetStatus.assetRejected]: t(
        'assetDetailsPage.assetStatus.assetRejected',
      ),
      [AssetStatus.assetApproved]: t(
        'assetDetailsPage.assetStatus.assetApproved',
      ),
      [AssetStatus.reimbursePending]: t(
        'assetDetailsPage.assetStatus.reimbursePending',
      ),
      [AssetStatus.reimburseSubmitted]: t(
        'assetDetailsPage.assetStatus.reimburseSubmitted',
      ),
      [AssetStatus.reimburseRejected]: t(
        'assetDetailsPage.assetStatus.reimburseRejected',
      ),
      [AssetStatus.reimburseApproved]: t(
        'assetDetailsPage.assetStatus.reimburseApproved',
      ),
      [AssetStatus.transitPending]: t(
        'assetDetailsPage.assetStatus.transitPending',
      ),
      [AssetStatus.transitRejected]: t(
        'assetDetailsPage.assetStatus.transitRejected',
      ),
      [AssetStatus.transitApproved]: t(
        'assetDetailsPage.assetStatus.transitApproved',
      ),
    }),
    [t],
  )

  const isCreated = useMemo(() => status === AssetStatus.created, [status])
  const isAssetUpdated = useMemo(
    () => status === AssetStatus.assetUpdated,
    [status],
  )
  const isAssetRejected = useMemo(
    () => status === AssetStatus.assetRejected,
    [status],
  )
  const isAssetApproved = useMemo(
    () => status === AssetStatus.assetApproved,
    [status],
  )
  const isReimbursePending = useMemo(
    () => status === AssetStatus.reimbursePending,
    [status],
  )
  const isReimburseSubmitted = useMemo(
    () => status === AssetStatus.reimburseSubmitted,
    [status],
  )
  const isReimburseRejected = useMemo(
    () => status === AssetStatus.reimburseRejected,
    [status],
  )
  const isReimburseApproved = useMemo(
    () => status === AssetStatus.reimburseApproved,
    [status],
  )
  const isTransitPending = useMemo(
    () => status === AssetStatus.transitPending,
    [status],
  )
  const isTransitRejected = useMemo(
    () => status === AssetStatus.transitRejected,
    [status],
  )
  const isTransitApproved = useMemo(
    () => status === AssetStatus.transitApproved,
    [status],
  )

  // should be same as server setting
  const validStatusForUpdate = useMemo(
    () => isCreated || isAssetRejected || isAssetUpdated || isAssetApproved,
    [isCreated, isAssetRejected, isAssetUpdated, isAssetApproved],
  )
  // should be same as server setting
  const validStatusForTransit = useMemo(
    () => isAssetApproved,
    [isAssetApproved],
  )
  // should be same as server setting
  const validStatusForReimburse = useMemo(
    () => isAssetApproved,
    [isAssetApproved],
  )
  const statusCN = useMemo(() => translator[status], [status, translator])

  return {
    statusCN,
    isCreated,
    isAssetApproved,
    isAssetUpdated,
    isAssetRejected,
    isReimbursePending,
    isReimburseSubmitted,
    isReimburseRejected,
    isReimburseApproved,
    isTransitPending,
    isTransitRejected,
    isTransitApproved,
    validStatusForUpdate,
    validStatusForTransit,
    validStatusForReimburse,
  }
}
