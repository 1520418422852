import { Grid } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import React, { memo, useMemo } from 'react'
import styled from 'styled-components'
import { DisconnectIcon } from '../../icons'
import { useAssetList } from '@stewards-fas/queries'
import {
  AssetResponsePayload,
  PostAssetLinksPayload,
} from '@stewards-fas/schemas'

export type Link = {
  assetNo: string
  name: string
  linkedQuantity: number | undefined
  id: string
  canUnlink: boolean
}

const Header = styled.div`
  color: ${({ theme }) => theme.colors.greys[3]};
  margin: 10px 0;
`
const Cell = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  margin: 10px 0;
`
const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`
const DisconnectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  background: linear-gradient(
    ${({ theme }) =>
      `to left,${theme.colors.berryBlue},${theme.colors.brigBlue}`}
  );
  border-radius: 5px;
  :hover {
    cursor: pointer;
  }
  stroke-width: 0.5;
  stroke: ${({ theme }) => theme.colors.white};
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`
const UNLINK_COLUMN_WIDTH = 1
const ASSETNO_COLUMN_WIDTH = 5
const NAME_COLUMN_WIDTH = 4
const QUANTITY_COLUMN_WIDTH = 2

type AssetLinksTableColumn = {
  id: string
  assetNo: string
  name: string
  quantity: number
  canUnlink: boolean
}
type Props = {
  onUnLink: (id: string) => void
  canUnlink: boolean
  childLinks: AssetResponsePayload['childAssetLinks']
  parentLinks: PostAssetLinksPayload[]
}
export const AssetLinksTable = memo<Props>(
  ({ childLinks, parentLinks, onUnLink, canUnlink }) => {
    const { t } = useTranslation()
    const { data: assetList, isLoading } = useAssetList()

    const parentlinksDisplayContent: AssetLinksTableColumn[] = useMemo(
      () =>
        parentLinks.reduce((acc: AssetLinksTableColumn[], parentLink) => {
          const assetInfo = assetList?.data.find(
            (asset) => asset.id === parentLink.childId,
          )
          if (!assetInfo) return acc
          return [
            ...acc,
            {
              id: assetInfo.id,
              assetNo: assetInfo.assetNo,
              name: assetInfo.name,
              quantity: parentLink.quantity,
              canUnlink: true,
            },
          ]
        }, []),
      [parentLinks, assetList],
    )
    const childLinksDisplayContent: AssetLinksTableColumn[] = useMemo(() => {
      return childLinks.reduce((acc: AssetLinksTableColumn[], childLink) => {
        const assetInfo = assetList?.data.find(
          (asset) => asset.id === childLink.parentId,
        )
        if (!assetInfo) return acc
        return [
          ...acc,
          {
            id: assetInfo.id,
            assetNo: assetInfo.assetNo,
            name: assetInfo.name,
            quantity: childLink.quantity,
            canUnlink: false,
          },
        ]
      }, [])
    }, [childLinks, assetList])

    const displayColumns = useMemo(
      () => [...childLinksDisplayContent, ...parentlinksDisplayContent],
      [childLinksDisplayContent, parentlinksDisplayContent],
    )
    if (isLoading || assetList === undefined) return null
    return (
      <>
        {childLinks.length + parentLinks.length > 0 ? (
          <Grid container columnSpacing={2}>
            <Grid
              item
              xs={UNLINK_COLUMN_WIDTH}
              key={`transferRecordTable.headers.unlink`}
            />
            <Grid
              item
              xs={ASSETNO_COLUMN_WIDTH}
              key={`transferRecordTable.headers.assetNo`}
            >
              <Header>{t(`assetLinksTable.headers.assetNo`)}</Header>
            </Grid>

            <Grid
              item
              xs={NAME_COLUMN_WIDTH}
              key={`transferRecordTable.headers.name`}
            >
              <Header>{t(`assetLinksTable.headers.name`)}</Header>
            </Grid>

            <Grid
              item
              xs={QUANTITY_COLUMN_WIDTH}
              key={`transferRecordTable.headers.quantity`}
            >
              <Header>{t(`assetLinksTable.headers.quantity`)}</Header>
            </Grid>

            {displayColumns.map((link) => {
              return (
                <React.Fragment key={link.id}>
                  <Grid
                    item
                    xs={UNLINK_COLUMN_WIDTH}
                    key={`transferRecordTable.cells.unlink`}
                  >
                    {canUnlink && link.canUnlink && (
                      <DisconnectWrapper
                        onClick={() => {
                          onUnLink(link.id)
                        }}
                      >
                        <DisconnectIcon />
                      </DisconnectWrapper>
                    )}
                    {canUnlink && !link.canUnlink && (
                      <TextWrapper>
                        {t(`assetLinksTable.headers.parentAsset`)}
                      </TextWrapper>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={ASSETNO_COLUMN_WIDTH}
                    key={`transferRecordTable.cells.assetNo`}
                  >
                    <Cell>{link.assetNo}</Cell>
                  </Grid>
                  <Grid
                    item
                    xs={NAME_COLUMN_WIDTH}
                    key={`transferRecordTable.cells.name`}
                  >
                    <Cell>{link.name}</Cell>
                  </Grid>
                  <Grid
                    item
                    xs={QUANTITY_COLUMN_WIDTH}
                    key={`transferRecordTable.cells.quantity`}
                  >
                    <Cell>{link.quantity}</Cell>
                  </Grid>
                </React.Fragment>
              )
            })}
          </Grid>
        ) : (
          <></>
        )}
      </>
    )
  },
)
AssetLinksTable.displayName = 'AssetLinksTable'
