import { ApiGateway } from '@pasteltech/api-gateway'
import { RoleListRequestPayload } from '@stewards-fas/schemas'
import { z } from 'zod'

const PATH = '/roles'

export function getRoleList(param?: RoleListRequestPayload) {
  return ApiGateway.primary.get(
    `${PATH}`,
    { request: z.any(), response: z.any() },
    {
      param,
    },
  )
}
