import { useTranslation } from '@pasteltech/i18n-react'
import { FundingResponsePayload, Status } from '@stewards-fas/schemas'
import { useMemo } from 'react'
import {
  CRUDTableActions,
  FieldMapping,
  InputType,
} from '../../../../components'
import { ActiveStatus } from '../../ui-components'

export function useFundingResourceFields() {
  const { t } = useTranslation()
  const mappings = useMemo(
    () => ({
      code: {
        key: 'code',
        value: (it) => it.code,
        display: (it) => it.code,
        inputType: InputType.text,
        label: t('referenceInformation.fields.fundNumber'),
        disableMode: [CRUDTableActions.edit],
      } as FieldMapping<FundingResponsePayload>, // these required fields will be checked
      name: {
        key: 'name',
        value: (it) => it.name,
        display: (i) => i.name,
        inputType: InputType.text,
        label: t('referenceInformation.fields.fundName'),
      } as FieldMapping<FundingResponsePayload>, // these required fields will be checked
      status: {
        key: 'status',
        value: (it) => it.status ?? 'active',
        display: (it) => {
          const lower = it.status.toLowerCase()
          if (['active', 'inactive'].includes(lower)) {
            return (
              <ActiveStatus
                status={lower as Status}
                activeText={t('systemDataManagement.fundingStatus.active')}
                inactiveText={t('systemDataManagement.fundingStatus.inactive')}
              />
            )
          }
          return 'undefined'
        },
        inputType: InputType.selectFundingStatus,
        label: t('systemDataManagement.fields.fundingStatus'),
      } as FieldMapping<{ status: string }>,
    }),
    [t],
  )
  return { mappings }
}
