/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, ErrorInfo, PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

type State = {
  errorThrown:
    | { error: Error; info: ErrorInfo; stack: { [key: string]: string } }
    | undefined
}

const Root = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.secondaryContainer};
  color: ${({ theme }) => theme.colors.secondary};
`

const StyledAlert = styled.div`
  max-width: 70%;
`

const AlertTitle = styled.h1``

const AlertContent = styled.h3``

const StyledCard = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryContainer};
  color: ${({ theme }) => theme.colors.primary};
`

const CardHeader = styled.div`
  padding: ${({ theme }) => theme.sizes.spacings[2]}px;
`

const CardContent = styled.div`
  padding: ${({ theme }) => theme.sizes.spacings[2]}px;
`

export class ErrorBoundary extends Component<PropsWithChildren<{}>, State> {
  constructor(props: {}) {
    super(props)
    this.state = { errorThrown: undefined }
  }

  async componentDidCatch(error: Error, info: ErrorInfo): Promise<void> {
    console.warn(error, info)
    const stack: { [key: string]: string } = {}
    try {
      const regexParan = /[^()]*(\([^()]*\))/g // regular expression to find strings inside param
      const source = info.componentStack.toString()
      let match = regexParan.exec(source)
      while (match) {
        const [wholeMatch, key] = match
        stack[key] = wholeMatch
        match = regexParan.exec(source)
      }
    } catch (err) {
      console.error('Error regex', (err as Error).toString())
    }

    this.setState({ errorThrown: { error, info, stack } })
  }

  render(): ReactNode {
    const { errorThrown } = this.state
    const { children } = this.props

    if (errorThrown != null) {
      console.warn(errorThrown)

      return (
        <Root>
          <StyledAlert>
            <AlertTitle>Something went wrong.</AlertTitle>
            <AlertContent>
              Please refresh the page and try again. If the problem persist,
              please contact the support with the following detail:
            </AlertContent>
            <StyledCard>
              <CardHeader>{errorThrown.error.message}</CardHeader>
              <CardContent>{errorThrown.error.stack}</CardContent>
              <CardContent>{JSON.stringify(errorThrown.info)}</CardContent>
              <CardContent>
                {Object.entries(errorThrown.stack).map((entry) => (
                  <div key={entry[0]}>{`${entry[0]} | ${entry[1]}`}</div>
                ))}
              </CardContent>
            </StyledCard>
          </StyledAlert>
        </Root>
      )
    }

    return children
  }
}
