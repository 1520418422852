import { UseMutationOptions, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'

export const QUERY_ROOT_KEY_USERS = 'users'
export const QUERY_ROOT_KEY_UNITS = 'units'
export const QUERY_CACHE_KEY_ASSET_REQUESTS = 'asset-requests'

export function useOptsOnSuccessInvalidate<Result, Error, Payload>(
  invalidQueryKeys: string[],
  opts?: UseMutationOptions<Result, Error, Payload>,
) {
  const client = useQueryClient()
  const optsWithInvalidate: typeof opts = useMemo(
    () => ({
      ...opts,
      onSuccess: async (...data) => {
        await Promise.allSettled(
          invalidQueryKeys.map((key) =>
            client.invalidateQueries({ queryKey: [key] }),
          ),
        )
        opts?.onSuccess?.(...data)
      },
    }),
    [opts, client, invalidQueryKeys], // TODO: set exhaustive deps rule
  )
  return optsWithInvalidate
}
