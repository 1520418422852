import {
  ApiFormattedError,
  deleteAssetLinks,
  patchAssetLinks,
  postAssetLinks,
} from '@stewards-fas/apis'
import {
  AssetLinksResponsePayload,
  DeleteAssetLinksPayload,
  PatchAssetLinksPayload,
  PostAssetLinksPayload,
} from '@stewards-fas/schemas'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { useOptsOnSuccessInvalidate } from './helper'
import { QUERY_ROOT_KEY_ASSETS } from './assets'

const QUERY_CACHE_KEY_ROOT = 'asset-links'
const EMPTY_PAYLOAD = 'empty-payload'

export function usePostAssetLinks(
  opts?: UseMutationOptions<
    AssetLinksResponsePayload,
    ApiFormattedError,
    PostAssetLinksPayload
  >,
) {
  const optsWithInvalidate = useOptsOnSuccessInvalidate(
    [QUERY_CACHE_KEY_ROOT, QUERY_ROOT_KEY_ASSETS],
    opts,
  )
  return useMutation(async (payload) => {
    const res = await postAssetLinks(payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, optsWithInvalidate)
}

export function usePatchAssetLinks(
  opts?: UseMutationOptions<
    AssetLinksResponsePayload,
    ApiFormattedError,
    PatchAssetLinksPayload & { id: string }
  >,
) {
  const optsWithInvalidate = useOptsOnSuccessInvalidate(
    [QUERY_CACHE_KEY_ROOT, QUERY_ROOT_KEY_ASSETS],
    opts,
  )
  return useMutation(async ({ id, ...payload }) => {
    const res = await patchAssetLinks(id, payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, optsWithInvalidate)
}

export function useDeleteAssetLinks(
  opts?: UseMutationOptions<
    unknown,
    ApiFormattedError,
    DeleteAssetLinksPayload
  >,
) {
  const optsWithInvalidate = useOptsOnSuccessInvalidate(
    [QUERY_CACHE_KEY_ROOT, QUERY_ROOT_KEY_ASSETS],
    opts,
  )
  return useMutation(async ({ id }) => {
    const res = await deleteAssetLinks(id)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, optsWithInvalidate)
}
