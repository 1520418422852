import {
  useAssetList,
  useAssetTypeList,
  useFundingList,
  useUnitList,
  useUserList,
} from '@stewards-fas/queries'
import { useMemo } from 'react'
export function useList() {
  const { data: unitList, isLoading: unitListIsLoading } = useUnitList({
    expand: ['SupervisorUser'],
  })
  const { data: userList, isLoading: userListIsLoading } = useUserList()
  const { data: assetList, isLoading: assetListIsLoading } = useAssetList()
  const { data: assetTypeList, isLoading: assetTypeListIsLoading } =
    useAssetTypeList()
  const { data: fundingsList, isLoading: fundingsListIsLoading } =
    useFundingList()

  const isLoading = useMemo(() => {
    return (
      unitListIsLoading ||
      userListIsLoading ||
      assetListIsLoading ||
      assetTypeListIsLoading ||
      fundingsListIsLoading
    )
  }, [
    unitListIsLoading,
    userListIsLoading,
    assetListIsLoading,
    assetTypeListIsLoading,
    fundingsListIsLoading,
  ])
  return {
    unitList: unitList?.data ?? [],
    userList: userList?.data ?? [],
    assetList: assetList?.data ?? [],
    assetTypeList: assetTypeList?.data ?? [],
    fundingsList: fundingsList?.data ?? [],
    isLoading,
  }
}
