export const common = {
  add: 'Add',
  back: 'Back',
  cancel: 'Cancel',
  change: 'Change',
  clear: 'Clear',
  clickToRetry: 'Retry',
  confirm: 'Confirm',
  continue: 'Continue',
  copiedToClipboard: {
    error:
      'Unable to access the clipboard. {{ITServiceDeskRetry}}\n({{error}})',
    success: 'The content has been copied to your clipboard',
  },
  delete: 'Delete',
  discard: 'Discard',
  download: 'Download',
  edit: 'Edit',
  empty: 'Empty',
  emptyList: 'This list is empty',
  env: 'Environment',
  errorMessage: 'System error. {{userDependantServiceDeskRetry}} \n{{error}}',
  format: {
    date: 'DD/MM/YYYY',
    datetime: 'DD/MM/YYYY HH:mm',
    kendoDateInput: 'dd/MM/yyyy',
    kendoDateTimeInput: 'dd/MM/yyyy HH:mm',
  },
  from: 'From',
  generate: 'Export Data into Excel',
  logout: 'Logout',
  next: 'Next',
  no: 'No',
  noRecordsAvailable: 'No record found',
  obsolete: 'Obsolete',
  ok: 'OK',
  post: 'Post',
  reset: 'Reset',
  retry: 'Retry',
  save: 'Save',
  search: 'Search',
  submit: 'Submit',
  submitted: 'Submitted',
  to: 'To',
  unrecognizedErrorTitle: 'Error',
  unrecognizedErrorTitleWithErrorCodes: 'Error: {{errorMessage}}',
  update: 'Update',
  version: 'Version: v{{version}}',
  view: 'View',
  yes: 'Yes',
  systemName: 'Fixed Assets Management System',
}
