import {
  AssetStatusGroup,
  ReportListRequestPayload,
} from '@stewards-fas/schemas'
import { InferType, boolean, date, number, object, string } from 'yup'
import { All_VALUE_OF_DROPDOWN, convertAllValueToNull } from '../utilities'

export enum ReportPageStateFieldValues {
  reimbursed = 'REIMBURSED',
  unreimbursed = 'UNREIMBURSED',
}
export enum TransferToOrFromUnitFieldValues {
  to = 'TO',
  from = 'FROM',
}
export const ReportGeneratorSchema = object({
  dateUntil: date().optional(),
  buyStartDate: date().optional(),
  buyEndDate: date().optional(),
  unit: string().optional(),
  assetType: string().optional(),
  amountStart: number()
    .integer('reportPage.errors.amountMustBeInteger')
    .optional(),
  amountEnd: number()
    .integer('reportPage.errors.amountMustBeInteger')
    .optional(),
  sourceOfFundsSearch: string().optional(),
  state: string().optional(),
  transferToOrFrom: string().optional(),
  buyDate: boolean().required(),
  name: boolean().required(),
  brand: boolean().required(),
  model: boolean().required(),
  placement: boolean().required(),
  quantity: boolean().required(),
  amount: boolean().required(),
  serialNumber: boolean().required(),
  provider: boolean().required(),
  invoiceNo: boolean().required(),
  warrantyProvider: boolean().required(),
  warrantyPeriod: boolean().required(),
  sourceOfFundsContent: boolean().required(),
  remarks: boolean().required(),
  writeOffDate: boolean().required(),
  writeOffReason: boolean().required(),
  writeOffCode: boolean().required(),
  transferDate: boolean().required(),
  transferToUnit: boolean().required(),
  transferFromUnit: boolean().required(),
  transferReason: boolean().required(),
  formFiller: boolean().required(),
  sectionSuperviser: boolean().required(),
  fillDate: boolean().required(),
  approvalDate: boolean().required(),
})

export type ReportGeneratorSchemaModel = InferType<typeof ReportGeneratorSchema>

export const ReportGeneratorInitialValues: ReportGeneratorSchemaModel = {
  dateUntil: undefined,
  buyStartDate: undefined,
  buyEndDate: undefined,
  unit: All_VALUE_OF_DROPDOWN,
  assetType: All_VALUE_OF_DROPDOWN,
  amountStart: undefined,
  amountEnd: undefined,
  sourceOfFundsSearch: All_VALUE_OF_DROPDOWN,
  state: ReportPageStateFieldValues.unreimbursed,
  transferToOrFrom: All_VALUE_OF_DROPDOWN,
  buyDate: true,
  name: true,
  brand: true,
  model: true,
  placement: true,
  quantity: true,
  amount: true,
  serialNumber: false,
  provider: false,
  invoiceNo: false,
  warrantyProvider: false,
  warrantyPeriod: false,
  sourceOfFundsContent: false,
  remarks: false,
  writeOffDate: false,
  writeOffReason: false,
  writeOffCode: false,
  transferDate: false,
  transferToUnit: false,
  transferFromUnit: false,
  transferReason: false,
  formFiller: false,
  sectionSuperviser: false,
  fillDate: false,
  approvalDate: false,
}

export function mapReportFormikToSearchParams(
  formikValues: ReportGeneratorSchemaModel,
): ReportListRequestPayload {
  let param = {} as Partial<ReportListRequestPayload>
  if (formikValues.transferToOrFrom === All_VALUE_OF_DROPDOWN) {
    param.unitId = convertAllValueToNull(formikValues.unit)
  }
  return {
    ...param,
    lastModifiedToDateTime: formikValues.dateUntil?.toISOString(),
    purchasedFromDateTime: formikValues.buyStartDate?.toISOString(),
    purchasedToDateTime: formikValues.buyEndDate?.toISOString(),
    assetTypeId: convertAllValueToNull(formikValues.assetType),
    valuedAmountLargerOrEqualThan: formikValues.amountStart,
    valuedAmountSmallerOrEqualThan: formikValues.amountEnd,
    fundingId: convertAllValueToNull(formikValues.sourceOfFundsSearch),
    status:
      formikValues.state === ReportPageStateFieldValues.reimbursed
        ? AssetStatusGroup.reimburse
        : AssetStatusGroup.nonReimburse,
    expand: ['AssetRequestAssets', 'AssetRequestAssets.AssetRequest'],
  }
}
