import { Typography } from '@mui/material'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from '@pasteltech/i18n-react'
import styled from 'styled-components'
import { Spinner } from '../spinner'
import { delay } from 'lodash'
import { UnauthorizedPage } from '../../pages'

const Root = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Title = styled(Typography)`
  padding: ${({ theme }) => theme.sizes.spacings[2]}px;
`

export const LoginLoading = memo(() => {
  const { t } = useTranslation()
  // when the page is loading too long, something is wrong
  // most probably, the user account does not have proper rights.
  // if user is not set in the system, spinner will not end
  // let the user logout
  const [isSthWrong, setIsSthWrong] = useState(false)
  useEffect(() => {
    delay(() => {
      setIsSthWrong(true)
    }, 1000 * 60)
  }, [])
  if (isSthWrong) {
    return <UnauthorizedPage />
  }

  return (
    <Root>
      <Title variant="h5">{t('common.loginLoading')}</Title>
      <Spinner />
    </Root>
  )
})

LoginLoading.displayName = 'LoginLoading'
