import {
  ReferenceInformationAssetGuidelineIcon,
  ReferenceInformationAssetPurchaseFromIcon,
  ReferenceInformationAssetTypeIcon,
  ReferenceInformationFundsIcon,
  ReferenceInformationPriceComparisonIcon,
} from '../../icons'
import { allPermittedRoles } from '../../models'
import {
  AppManagementTabItem,
  AppManagementTabItemType,
} from '../app-management-tab'

export const referenceInformationTabItems: AppManagementTabItemType[] = [
  {
    value: AppManagementTabItem.referenceInformationFixedAssetGuideline,
    titleKey: 'referenceInformation.tabs.fixedAssetGuideline',
    icon: ReferenceInformationAssetGuidelineIcon,
    content: <></>,
    dataKey: 'assetGuidelines',
    allowedRoles: allPermittedRoles,
  },
  {
    value: AppManagementTabItem.referenceInformationAssetPurchaseFrom,
    titleKey: 'referenceInformation.tabs.assetPurchaseFrom',
    icon: ReferenceInformationAssetPurchaseFromIcon,
    content: <></>,
    dataKey: 'priceProcurement',
    allowedRoles: allPermittedRoles,
  },
  {
    value: AppManagementTabItem.referenceInformationPriceComparisonTable,
    titleKey: 'referenceInformation.tabs.priceComparisonTable',
    icon: ReferenceInformationPriceComparisonIcon,
    content: <></>,
    dataKey: 'priceComparing',
    allowedRoles: allPermittedRoles,
  },
  {
    value: AppManagementTabItem.referenceInformationAssetType,
    titleKey: 'referenceInformation.tabs.assetClass',
    icon: ReferenceInformationAssetTypeIcon,
    content: <></>,
    dataKey: 'assetTypes',
    allowedRoles: allPermittedRoles,
  },
  {
    value: AppManagementTabItem.referenceInformationSourcesOfFunds,
    titleKey: 'referenceInformation.tabs.sourcesOfFunds',
    icon: ReferenceInformationFundsIcon,
    content: <></>,
    dataKey: 'fundings',
    allowedRoles: allPermittedRoles,
  },
]
