import { Role, allPermittedRoles } from '../models'

export const AppRoutes = Object.freeze({
  healthCheck: {
    path: '/health',
  },
  login: {
    path: '/login',
  },
  // For all page after logged-in
  base: {
    path: '/',
    accessibleRoles: allPermittedRoles,
  },
  assetManagement: {
    path: '/asset-management',
    accessibleRoles: allPermittedRoles,

    searchSection: {
      path: '/asset-management/search',
      accessibleRoles: allPermittedRoles,
    },
    createSection: {
      path: '/asset-management/create',
      accessibleRoles: allPermittedRoles,
    },
    writeOffSection: {
      path: '/asset-management/write-off/:tab?',
      basePath: '/asset-management/write-off',
      render: (tab?: string) => `/asset-management/write-off/${tab}`,
      accessibleRoles: allPermittedRoles,
    },
    transferSection: {
      path: '/asset-management/transfer',
      accessibleRoles: allPermittedRoles,
    },
    approvalSection: {
      path: '/asset-management/approval',
      accessibleRoles: [Role.supervisor, Role.systemAdministrator],
    },
  },
  referenceInformation: {
    path: '/reference-information',
    accessibleRoles: allPermittedRoles,
  },
  report: {
    path: '/report',
    accessibleRoles: allPermittedRoles,
  },
  systemManagement: {
    path: '/system-management',
    accessibleRoles: [Role.systemAdministrator],
  },
  assetEdit: {
    path: '/asset-management/:id/edit',
    render: (id: string) => `/asset-management/${id}/edit`,
    accessibleRoles: allPermittedRoles,
  },
  assetView: {
    path: '/asset-management/:id',
    render: (id: string) => `/asset-management/${id}`,
    accessibleRoles: allPermittedRoles,
  },
  assetApproval: {
    path: '/asset-management/:id/approval',
    render: (id: string) => `/asset-management/${id}/approval`,
    accessibleRoles: [Role.systemAdministrator, Role.supervisor],
  },
  assetTransferView: {
    path: '/asset-management/:id/transfer',
    render: (id: string) => `/asset-management/${id}/transfer`,
    accessibleRoles: allPermittedRoles,
  },
  assetPreview: {
    path: '/asset-management/:id/preview',
    render: (id: string) => `/asset-management/${id}/preview`,
    accessibleRoles: allPermittedRoles,
  },
  assetTransferPreview: {
    path: '/asset-management/:id/transfer-preview',
    render: (id: string) => `/asset-management/${id}/transfer-preview`,
    accessibleRoles: allPermittedRoles,
  },
  searchAssets: {
    path: '/search-assets-qr',
    accessibleRoles: allPermittedRoles,
  },
})
