import { MsalProvider } from '@azure/msal-react'
import '@fontsource/open-sans'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { OverlayProvider } from '@pasteltech/overlay-provider'
import { QueryClientProvider } from '@stewards-fas/queries'
import { memo, PropsWithChildren } from 'react'
import {
  Dialog,
  ErrorBoundary,
  LoginLoading,
  OverlaySpinner,
  Snackbar,
} from './components'
import { Content } from './content'
import { useInit } from './init'
import { SearchParamProvider } from './providers'
import { useAuthInit } from './services'
import { RootStoreProvider } from './stores'
import { theme, ThemeProvider } from './theme'

const MsalContainer = memo<PropsWithChildren<{}>>(({ children }) => {
  const msalInstance = useAuthInit()
  if (msalInstance == null) return <LoginLoading />

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
})

MsalContainer.displayName = 'MsalContainer'

const InitContainer = memo<PropsWithChildren<{}>>(({ children }) => {
  const initResult = useInit()

  if (initResult == null) return null // TODO

  return (
    <QueryClientProvider client={initResult.queryClient}>
      <RootStoreProvider value={initResult.rootStore}>
        {children}
      </RootStoreProvider>
    </QueryClientProvider>
  )
})

InitContainer.displayName = 'InitContainer'

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <InitContainer>
          <OverlayProvider
            SpinnerComponent={OverlaySpinner}
            SnackbarComponent={Snackbar}
            DialogComponent={Dialog}
          >
            <MsalContainer>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <SearchParamProvider>
                  <Content />
                </SearchParamProvider>
              </LocalizationProvider>
            </MsalContainer>
          </OverlayProvider>
        </InitContainer>
      </ErrorBoundary>
    </ThemeProvider>
  )
}
