export const assetViewPage = {
  buttons: {
    writeOff: '報銷',
    transfer: '轉移',
    edit: '修改',
    print: '登記表',
    preview: '預覽',
    previewChanges: '預覽變動',
  },
  title: '資產尋找',
  errorMessage: {
    notPossibleStatusForUpdate: '此資產無法被修改',
    notPossibleStatusForReimburse: '此資產無法被報銷',
    notPossibleStatusForTransit: '此資產無法被轉移',
    noAssetHistory: '此資產沒有之前紀錄',
  },
  previewForm: {
    titleLine2: '固定資產登記表格',
    formFillerSign: '填表人簽署',
    supervisorSign: '主管簽署',
    formFillerName: '填表人姓名',
    supervisorName: '主管人姓名',
    date: '日期',
  },
  viewChangeModal: {
    beforeUpdate: '更新前',
    afterUpdate: '更新後',
    photo: '照片{{num}}',
  },
  rejectModal: {
    title: '撤回資產更新/新增',
    success: '已成功撤回資產更新/新增',
    rejectReason: '撤回原因',
  },
}
