import { Typography } from '@mui/material'
import { AssetResponsePayload } from '@stewards-fas/schemas'
import { memo } from 'react'
import styled from 'styled-components'

interface QrcodeProps {
  imageUrl: string

  selectedRowsData: AssetResponsePayload
}

const Root = styled.div`
  display: flex;
  justify-content: center;
  width: 66mm;
  height: 34mm;
`

const Qrcode = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-item: flex-end;
  width: 40%;
`

const LabelContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-item: center;
  width: 50%;
`

const LabelName = styled(Typography)`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 14;
  font-weight: bold;
  overflow: clip;
  line-break: anywhere;
  max-height: 15mm;
`

const LabelNumber = styled(Typography)`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 14;
  font-weight: bold;
  line-break: anywhere;
  max-height: 15mm;
`

export const QrcodeAsset = memo(
  ({ imageUrl, selectedRowsData }: QrcodeProps) => {
    const searchAsset = selectedRowsData
    return (
      <div>
        <Root>
          <Qrcode>
            <img src={imageUrl} height="100px" />
          </Qrcode>
          <LabelContentContainer>
            <LabelName>{searchAsset?.name}</LabelName>
            <LabelNumber>{searchAsset?.assetNo}</LabelNumber>
          </LabelContentContainer>
        </Root>
      </div>
    )
  },
)

QrcodeAsset.displayName = 'QrcodeAsset'
