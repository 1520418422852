import { useUserList } from '@stewards-fas/queries'
import { memo, useMemo } from 'react'
import { FormikSelectField } from './formik-select-field'
import { Role } from '../../models'
import { Status } from '@stewards-fas/schemas'
import { useFormikContext } from 'formik'
import { Option } from '../../hooks'

type UserSelectionFieldPropType = {
  name: string
  multiple?: boolean
  disabled?: boolean
  filterByRoles?: Role[]
}
export const UserSelectionField = memo<UserSelectionFieldPropType>(
  ({ name, multiple = false, filterByRoles = [] }) => {
    const { data } = useUserList({ expand: ['Role'] })
    const formik = useFormikContext<any>()

    const optionList = useMemo<Option[]>(() => {
      // filter away invalid users, but if it is selected before, keep it in options
      const UserList = (data?.data ?? []).filter(
        (user) =>
          formik.initialValues?.[name]?.includes(user.id) ||
          user.status != Status.inactive,
      )
      // if filterByRoles is provided, filter by user role
      return UserList.filter(
        (user) =>
          !filterByRoles.length ||
          filterByRoles.includes(user.role?.name as Role),
      ).map((it) => ({ value: it.id, label: it.username }))
    }, [data?.data, filterByRoles, formik.initialValues, name])

    return (
      <FormikSelectField name={name} options={optionList} multiple={multiple} />
    )
  },
)
UserSelectionField.displayName = 'UserSelectionField'
