import { memo, useMemo } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import styled from 'styled-components'
import { LoginLoading } from './components'
import { useUserRole } from './hooks'
import { useRoute } from './routers'
import { env } from './utilities'

type Props = {}

const Container = styled.div`
  overscroll-behavior: none;
`

export const Content = memo<Props>(() => {
  const routeItems = useRoute()

  const { isInitialLoading } = useUserRole()

  const router = useMemo(
    () =>
      createBrowserRouter(routeItems, {
        basename: env.basePath,
      }),
    [routeItems],
  )

  if (isInitialLoading) {
    return <LoginLoading />
  }
  return (
    <Container>
      <RouterProvider router={router} />
    </Container>
  )
})

Content.displayName = 'Content'
