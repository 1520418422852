import { Box, Grid } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { useOverlay } from '@pasteltech/overlay-provider'
import { FormikProvider, useFormik } from 'formik'
import { ReactNode, memo, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Dialog } from '../dialog'
import { BaseFormikForm } from '../formik-form'
import { PageTitle } from '../page-title'
import { TableList } from '../table-list'
import { CreateButton } from './create-button'
import { FieldMapping, useMappedFields } from './helper'
import { InactivateButton } from './inactivate-button'
import { useFormikErrorHelper } from '../../hooks'
import { AnyObjectSchema } from 'yup'
import { CRUDTableActions } from './crud-table-actions'

type BasicApiData = Record<string, any> & {
  id: string
  rowVersion: string
}

type CrudTablePropType = {
  validateSchemas: Record<CRUDTableActions, AnyObjectSchema | undefined>
  tableName: string
  data: BasicApiData[]
  fieldsMapping: FieldMapping<any>[]
  isEditMode?: boolean
  filterComponent?: ReactNode
  onMultipleDelete?: (data: any[]) => Promise<string | undefined>
  onCreate?: (data: any) => Promise<string | undefined>
  onUpdate?: (data: any) => Promise<string | undefined>
}

const ActionGrid = styled(Grid)`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FilterGrid = styled(Grid)`
  width: 200px;
`

const ButtonGrid = styled(Grid)`
  width: 100px;
`

export const CrudTable = memo<CrudTablePropType>(
  ({
    validateSchemas,
    tableName,
    data,
    fieldsMapping,
    filterComponent,
    onCreate,
    onUpdate,
    onMultipleDelete,
  }) => {
    const { t } = useTranslation()
    const { showSnackbar } = useOverlay()
    const title = t('systemDataManagement.crudTable.title', { name: tableName })
    const createTitle = t('systemDataManagement.crudTable.create', {
      name: tableName,
    })
    const editTitle = t('systemDataManagement.crudTable.edit', {
      name: tableName,
    })
    const { formikErrorExtractor, setAllFieldsTouched } = useFormikErrorHelper()
    const [isShowInactiveModal, setIsShowInactiveModal] = useState(false)
    const [isShowDataForm, setIsShowDataForm] = useState(false)
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([])
    const [clickedRowId, setClickedRowId] = useState<string | undefined>(
      undefined,
    )

    const clickedRowData = useMemo(() => {
      if (clickedRowId == null) return {}
      return data.find(({ id }) => id === clickedRowId)
    }, [data, clickedRowId])

    const selectedRowData = useMemo(() => {
      return data.filter((d) => (selectedRowIds ?? []).includes(d.id))
    }, [data, selectedRowIds])

    const {
      formData,
      formFields,
      formValidateSchema,
      tableData,
      tableHeader,
      setMode,
    } = useMappedFields(fieldsMapping, data, validateSchemas, clickedRowData)

    const formik = useFormik({
      initialValues: formData,
      validationSchema: formValidateSchema,
      enableReinitialize: true,
      validateOnMount: true,
      onSubmit: () => {
        alert('Not implemented')
      },
    })
    const callAndShowResult = useCallback(
      async (
        func: undefined | ((data: any) => Promise<string | undefined>),
        values: any,
        successMessage?: string,
      ) => {
        if (!func) {
          showSnackbar({ content: 'Error: Function is not ready' })
          return
        }
        const validateErrors = formikErrorExtractor(formik.errors, 5)
        setAllFieldsTouched(formik.values, formik.setFieldTouched)
        if (validateErrors) {
          showSnackbar({ content: validateErrors, severity: 'error' })
          return
        }
        const error = await func(values)
        if (!error) {
          showSnackbar({
            content: successMessage ?? '',
          })
          setIsShowDataForm(false)
          setIsShowInactiveModal(false)
          setClickedRowId(undefined)
          return
        }

        showSnackbar({ content: 'Error:' + error })
      },
      [showSnackbar, formik, formikErrorExtractor, setAllFieldsTouched],
    )

    const onUpsert = useCallback(
      (isConfirm: boolean) => {
        if (!isConfirm) {
          setClickedRowId(undefined)
          setIsShowDataForm(false)
          return
        }
        const values: any = formik.values
        if (values?.id) {
          callAndShowResult(
            onUpdate,
            values,
            t('systemDataManagement.success', { action: editTitle }),
          )
          return
        }
        callAndShowResult(
          onCreate,
          values,
          t('systemDataManagement.success', { action: createTitle }),
        )
      },
      [
        callAndShowResult,
        formik.values,
        onCreate,
        onUpdate,
        t,
        createTitle,
        editTitle,
      ],
    )

    const onMultipleDeleteConfirm = useCallback(
      (isConfirm: boolean) => {
        if (!isConfirm) {
          setIsShowInactiveModal(false)
          return
        }
        callAndShowResult(onMultipleDelete, selectedRowData)
      },
      [callAndShowResult, onMultipleDelete, selectedRowData],
    )

    return (
      <>
        <FormikProvider value={formik}>
          <Box mt={5}>
            <PageTitle>{title}</PageTitle>

            <ActionGrid container>
              <FilterGrid item>{filterComponent}</FilterGrid>

              <Grid item>
                <Grid container gap={2}>
                  {onMultipleDelete && (
                    <ButtonGrid item>
                      <InactivateButton
                        onClick={() => {
                          setMode(CRUDTableActions.multipleDelete)
                          formik.resetForm() // resetForm to make the new validate schema take effect
                          setIsShowInactiveModal(true)
                        }}
                      />
                    </ButtonGrid>
                  )}
                  {onCreate && (
                    <ButtonGrid item>
                      <CreateButton
                        onClick={() => {
                          setClickedRowId(undefined)
                          setMode(CRUDTableActions.create)
                          setIsShowDataForm(true)
                        }}
                      />
                    </ButtonGrid>
                  )}
                </Grid>
              </Grid>
            </ActionGrid>

            <Box mt={5}>
              <TableList
                selectedRows={selectedRowIds}
                setSelectedRows={setSelectedRowIds}
                headers={tableHeader}
                rows={tableData}
                isDisableSelection={!onMultipleDelete}
                onRowClick={(row) => {
                  setClickedRowId(row.id)
                  setMode(CRUDTableActions.edit)
                  setIsShowDataForm(true)
                }}
              />
            </Box>

            <Dialog
              title={t('systemDataManagement.confirmPopup.disable.title')}
              content={t('systemDataManagement.confirmPopup.disable.content')}
              visible={selectedRowIds.length > 0 && isShowInactiveModal}
              onSelected={onMultipleDeleteConfirm}
            />

            <Dialog
              title={(formik.values as any)?.id ? editTitle : createTitle}
              visible={isShowDataForm}
              onSelected={onUpsert}
            >
              <BaseFormikForm columns={formFields} />
            </Dialog>
          </Box>
        </FormikProvider>
      </>
    )
  },
)
CrudTable.displayName = 'CrudTable'
