import { ApiGateway } from '@pasteltech/api-gateway'
import {
  FundingListRequestPayload,
  PatchFundingPayload,
  PostFundingPayload,
} from '@stewards-fas/schemas'
import { z } from 'zod'

const PATH = '/fundings'

export function getFundingList(param?: FundingListRequestPayload) {
  return ApiGateway.primary.get(
    `${PATH}`,
    { request: z.any(), response: z.any() },
    {
      param,
    },
  )
}

export function postFunding(param: PostFundingPayload) {
  return ApiGateway.primary.post(
    `${PATH}`,
    { request: z.any(), response: z.any() },
    {
      body: param,
    },
  )
}

export function patchFunding(param: PatchFundingPayload) {
  const { id, ...others } = param
  return ApiGateway.primary.patch(
    `${PATH}/${id}`,
    { request: z.any(), response: z.any() },
    {
      body: others,
    },
  )
}
