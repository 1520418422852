import { FormLabel } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useTranslation } from '@pasteltech/i18n-react'
import { AssetResponsePayload } from '@stewards-fas/schemas'
import dayjs, { Dayjs } from 'dayjs'
import { memo, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Dialog, DialogAction } from '../../../../components'
import { CloseIcon, PreviewIcon } from '../../../../icons'
import { PreviewTable, TableColumns } from './preview-table'
import { formats } from '../../../../theme'

type PreviewDialogPropType = {
  isOpen: boolean
  onDateSelected: (answer: boolean, date: Dayjs | null) => void
  previewRows: AssetResponsePayload[]
}

const SlimDatePicker = styled(DatePicker)`
  & .MuiOutlinedInput-input {
    padding: 4px 12px;
  }
`
const FlexBox = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
`

const Label = styled(FormLabel)`
  flex-basis: 150px;
`

export const PreviewDialog = memo<PreviewDialogPropType>(
  ({ isOpen, onDateSelected, previewRows }) => {
    const { t } = useTranslation()
    const tableColumns: TableColumns = {
      assetNo: t('assetManagement.writeOffSection.PreviewTableHeaders.assetNo'),
      name: t('assetManagement.writeOffSection.PreviewTableHeaders.assetName'),
      quantity: t(
        'assetManagement.writeOffSection.PreviewTableHeaders.quantity',
      ),
      purchasedDateTime: t(
        'assetManagement.writeOffSection.PreviewTableHeaders.purchaseDay',
      ),
      valuedAmount: t(
        'assetManagement.writeOffSection.PreviewTableHeaders.valuedAmount',
      ),
      reason: t(
        'assetManagement.writeOffSection.PreviewTableHeaders.writeOffReason',
      ),
    } as const

    const dateLabelText = t(
      'assetManagement.writeOffSection.modals.submissionDate',
    )

    const [previewDate, setPreviewDate] = useState<Dayjs | null>(dayjs())
    const onSelected = useCallback(
      (value: boolean) => {
        onDateSelected(value, previewDate)
      },
      [onDateSelected, previewDate],
    )
    const dialogActions = useMemo<DialogAction<boolean | string>[]>(
      () => [
        {
          text: t('common.cancel'),
          type: 'outlined',
          value: false,
          icon: <CloseIcon />,
        },
        {
          text: t('assetManagement.writeOffSection.modals.preview'),
          color: 'secondary',
          value: true,
          type: 'contained',
          icon: <PreviewIcon />,
        },
      ],
      [t],
    )

    return (
      <>
        <Dialog
          visible={isOpen}
          title={t(
            'assetManagement.writeOffSection.title.confirmWriteOffAsset',
          )}
          onSelected={onSelected}
          actions={dialogActions}
        >
          <PreviewTable rowData={previewRows} tableColumns={tableColumns} />
          <FlexBox>
            <Label about="previewDatePicker">{dateLabelText}</Label>
            <SlimDatePicker
              value={previewDate}
              format={formats.date}
              onChange={(d) => setPreviewDate(d as Dayjs)}
            />
          </FlexBox>
        </Dialog>
      </>
    )
  },
)
PreviewDialog.displayName = 'PreviewDialog'
