import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import { useOverlay } from '@pasteltech/overlay-provider'
import {
  useCreateBlobStorageContainer,
  useGetBlobDownloadUrl,
} from '@stewards-fas/queries'
import { useCallback } from 'react'

export function useBlobStorage() {
  const { showDialog } = useOverlay()

  const { mutateAsync: createBlobStorageContainer } =
    useCreateBlobStorageContainer()

  const { mutateAsync: getBlobDownloadUrl } = useGetBlobDownloadUrl()

  const handleCreateBlobStorageContainer = useCallback(async () => {
    const res = await createBlobStorageContainer()
    if (res == null) {
      showDialog({
        title: 'title',
        content: 'content',
      })
    }
    return res
  }, [createBlobStorageContainer, showDialog])

  const handleGetBlobDownloadUrl = useCallback(
    async (id: string) => {
      const res = await getBlobDownloadUrl(id)
      if (res == null) {
        showDialog({
          title: 'title',
          content: 'content',
        })
        throw new Error('Failed to upload image')
      }
      return res
    },
    [getBlobDownloadUrl, showDialog],
  )

  const handleUpload = useCallback(
    async (file: File) => {
      try {
        const fileBuffer = await file.arrayBuffer()
        const containerInfo = await handleCreateBlobStorageContainer()
        const blobSasUrl = `${containerInfo.endpoint}${containerInfo.containerName}${containerInfo.sasToken}`

        const bloblServiceClient = new BlobServiceClient(blobSasUrl)

        const containerClient = new ContainerClient(blobSasUrl)
        bloblServiceClient.getContainerClient(containerInfo.containerName)

        const blockBlobClient = containerClient.getBlockBlobClient(file.name)

        await blockBlobClient.uploadData(fileBuffer)
        const urlWithSasToken = blockBlobClient.url
        return urlWithSasToken
      } catch (error) {
        throw new Error('CORS error occurred during upload')
      }
    },
    [handleCreateBlobStorageContainer],
  )

  const getDownloadUrl = useCallback(
    async (id: string) => {
      const res = await handleGetBlobDownloadUrl(id)
      return res.url
    },
    [handleGetBlobDownloadUrl],
  )

  return { getDownloadUrl, handleUpload }
}
