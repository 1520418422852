import { getReportList } from '@stewards-fas/apis'
import {
  ReportListRequestPayload,
  ReportListResponsePayload,
} from '@stewards-fas/schemas'

import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'assets-reports'
const EMPTY_PAYLOAD = 'empty-payload'

export function useReportList(
  param?: ReportListRequestPayload,
  opts?: UseQueryOptions<unknown, unknown, ReportListResponsePayload>,
) {
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    async () => {
      const res = await getReportList(param)
      if (res == null) throw new Error(EMPTY_PAYLOAD)
      return res
    },
    opts,
  )
}
