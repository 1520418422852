import { TableCell, TableRow } from '@mui/material'
import { memo } from 'react'
import styled from 'styled-components'
import { ReportGeneratorSchemaModel } from '../../models'
import { useTranslation } from '@pasteltech/i18n-react'

const StyledTableHeadCell = styled(TableCell)`
  @media only print {
    font-size: 10px;
    page-break-inside: avoid;
    page-break-after: auto;
  }
  padding: 2px 4px;
`

type ReportTableHeaderPropType = {
  filterValue?: ReportGeneratorSchemaModel
}
export const ReportTableHeader = memo<ReportTableHeaderPropType>(
  ({ filterValue }) => {
    const { t } = useTranslation()
    return (
      <>
        <TableRow>
          <StyledTableHeadCell />
          <StyledTableHeadCell>
            {t('reportPage.content.assetNo')}
          </StyledTableHeadCell>

          {filterValue?.name && (
            <StyledTableHeadCell>
              {t('reportPage.content.name')}
            </StyledTableHeadCell>
          )}
          {filterValue?.brand && (
            <StyledTableHeadCell>
              {t('reportPage.content.brand')}
            </StyledTableHeadCell>
          )}
          {filterValue?.model && (
            <StyledTableHeadCell>
              {t('reportPage.content.model')}
            </StyledTableHeadCell>
          )}
          {filterValue?.placement && (
            <StyledTableHeadCell>
              {t('reportPage.content.placement')}
            </StyledTableHeadCell>
          )}
          {filterValue?.buyDate && (
            <StyledTableHeadCell>
              {t('reportPage.content.buyDate')}
            </StyledTableHeadCell>
          )}
          {filterValue?.quantity && (
            <StyledTableHeadCell>
              {t('reportPage.content.quantity')}
            </StyledTableHeadCell>
          )}

          {filterValue?.serialNumber && (
            <StyledTableHeadCell>
              {t('reportPage.content.serialNumber')}
            </StyledTableHeadCell>
          )}
          {filterValue?.provider && (
            <StyledTableHeadCell>
              {t('reportPage.content.provider')}
            </StyledTableHeadCell>
          )}
          {filterValue?.invoiceNo && (
            <StyledTableHeadCell>
              {t('reportPage.content.invoiceNo')}
            </StyledTableHeadCell>
          )}
          {filterValue?.warrantyProvider && (
            <StyledTableHeadCell>
              {t('reportPage.content.warrantyProvider')}
            </StyledTableHeadCell>
          )}
          {filterValue?.warrantyPeriod && (
            <StyledTableHeadCell>
              {t('reportPage.content.warrantyPeriod')}
            </StyledTableHeadCell>
          )}
          {filterValue?.sourceOfFundsContent && (
            <StyledTableHeadCell>
              {t('reportPage.content.sourceOfFunds')}
            </StyledTableHeadCell>
          )}
          {filterValue?.remarks && (
            <StyledTableHeadCell>
              {t('reportPage.content.remarks')}
            </StyledTableHeadCell>
          )}

          {filterValue?.writeOffDate && (
            <StyledTableHeadCell>
              {t('reportPage.content.writeOffDate')}
            </StyledTableHeadCell>
          )}
          {filterValue?.writeOffReason && (
            <StyledTableHeadCell>
              {t('reportPage.content.writeOffReason')}
            </StyledTableHeadCell>
          )}
          {filterValue?.writeOffCode && (
            <StyledTableHeadCell>
              {t('reportPage.content.writeOffCode')}
            </StyledTableHeadCell>
          )}
          {filterValue?.transferDate && (
            <StyledTableHeadCell>
              {t('reportPage.content.transferDate')}
            </StyledTableHeadCell>
          )}
          {filterValue?.transferToUnit && (
            <StyledTableHeadCell>
              {t('reportPage.content.transferToUnit')}
            </StyledTableHeadCell>
          )}
          {filterValue?.transferFromUnit && (
            <StyledTableHeadCell>
              {t('reportPage.content.transferFromUnit')}
            </StyledTableHeadCell>
          )}
          {filterValue?.transferReason && (
            <StyledTableHeadCell>
              {t('reportPage.content.transferReason')}
            </StyledTableHeadCell>
          )}

          {filterValue?.formFiller && (
            <StyledTableHeadCell>
              {t('reportPage.content.filler')}
            </StyledTableHeadCell>
          )}
          {filterValue?.sectionSuperviser && (
            <StyledTableHeadCell>
              {t('reportPage.content.sectionSuperviser')}
            </StyledTableHeadCell>
          )}
          {filterValue?.fillDate && (
            <StyledTableHeadCell>
              {t('reportPage.content.fillDate')}
            </StyledTableHeadCell>
          )}
          {filterValue?.approvalDate && (
            <StyledTableHeadCell>
              {t('reportPage.content.approvalDate')}
            </StyledTableHeadCell>
          )}

          {filterValue?.amount && (
            <StyledTableHeadCell>
              {t('reportPage.content.amount')}
            </StyledTableHeadCell>
          )}
        </TableRow>
      </>
    )
  },
)
ReportTableHeader.displayName = 'ReportTableHeader'
// <ReportTableHeader filterValue={filterValue} />
