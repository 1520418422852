import { z } from 'zod'
import { makeGetPayloadSchema } from '../base'

// AssetStatusLogs Schema
export const AssetStatusLogsSchema = z.object({
  id: z.string().uuid(),
  assetId: z.string().uuid(),
  originalStatus: z.string(),
  newStatus: z.string(),
  reason: z.string(),
})

// AssetStatusLogs Response
export const AssetStatusLogsResponseSchema = makeGetPayloadSchema(
  AssetStatusLogsSchema,
)
export type AssetStatusLogsResponsePayload = z.infer<
  typeof AssetStatusLogsResponseSchema
>
