import { z } from 'zod'
import { AssetFundingsSchema } from '../asset-fundings'
import { AssetListRequest, AssetRequestsSchema, AssetSchema } from '../assets'
import {
  makeGetPayloadSchema,
  makePaginatedParameterSchema,
  makePaginatedPayloadSchema,
  makeSchemaOptional,
} from '../base'
import { UserResponseSchema } from '../user-units'

const AssetRequestAssetsSchema = makeGetPayloadSchema(
  z.object({
    assetId: z.string().uuid(),
    assetRequest: makeGetPayloadSchema(AssetRequestsSchema).nullable(),
    assetRequestId: z.string().uuid(),
  }),
)

const ReportFundingSchema = makeGetPayloadSchema(AssetFundingsSchema)

const ReportResponseSchema = makeGetPayloadSchema(AssetSchema).extend({
  supervisorUser: UserResponseSchema.nullable(),
  formFillUser: UserResponseSchema.nullable(),
  assetRequestAssets: AssetRequestAssetsSchema.array().nullable(),
  latestAssetRequestAsset: AssetRequestAssetsSchema.nullable(),
  assetFundings: ReportFundingSchema.array(),
})

export type Report = z.infer<typeof ReportResponseSchema>

export type ReportFunding = z.infer<typeof ReportFundingSchema>

const ReportListResponseSchema =
  makePaginatedPayloadSchema(ReportResponseSchema)

export type ReportListResponsePayload = z.infer<typeof ReportListResponseSchema>

const reportListRequest = makeSchemaOptional(
  AssetListRequest.merge(
    z.object({
      lastModifiedToDateTime: z.string(),
      fundingId: z.string().uuid(),
    }),
  ),
)

const ReportListRequestSchema = makePaginatedParameterSchema(reportListRequest)

export type ReportListRequestPayload = z.input<typeof ReportListRequestSchema>
