import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { AssetResponsePayload } from '@stewards-fas/schemas'
import { memo } from 'react'
import styled from 'styled-components'
import { mapRowDataToPreviewRows } from '../../../../models'

type AssetKeys = keyof AssetResponsePayload

export type TableColumns = Partial<{ [key in AssetKeys]: string }>

const Scrollable = styled(TableContainer)`
  max-height: 50vh;
  overflow: auto;
`
const HeaderCell = styled(TableCell)`
  color: ${({ theme }) => theme.colors.greys[3]};
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
`
const BodyCell = styled(TableCell)`
  color: ${(props) => props.theme.colors.primary};
`

type PreviewTablePropType = {
  rowData: AssetResponsePayload[]
  tableColumns: TableColumns
}
export const PreviewTable = memo<PreviewTablePropType>(
  ({ rowData, tableColumns }) => {
    return (
      <Scrollable>
        <Table aria-label="preview write-off table">
          <TableHead>
            <TableRow>
              {Object.values(tableColumns).map((head) => (
                <HeaderCell key={`TableHeader-${head}`}>{head}</HeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {mapRowDataToPreviewRows(rowData).map((row) => (
              <TableRow key={row.id}>
                {Object.keys(tableColumns).map((column) => (
                  <BodyCell key={`${row.id}-${column}`}>
                    {`${row[column as AssetKeys]}`}
                  </BodyCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollable>
    )
  },
)
PreviewTable.displayName = 'PreviewTable'
