export const error = {
  action: {
    retry: '重試',
  },
  conflict: {
    title: '數據衝突',
    content: '你正在修改的數據已被更新。請重新整理後重試。',
  },
  errorDetail: '錯誤: {{codes}}\n追跡碼: {{traceId}}',
  retryContent: '要再試一次嗎？',
  timeout: {
    title: '連線超時',
    content: '連線超時',
  },
  cannotConnect: {
    title: '無網絡連接',
    content: '網絡未能連接。',
  },
  forbidden: {
    title: '無權限',
    content: '你没有存取權限',
  },
  unauthorized: {
    title: '未認證',
    content: '你的認證已過期。',
  },
  notFound: {
    title: '找不到',
    content: '你所存取的數據不存在。',
  },
  serverError: {
    title: '伺服器錯誤',
    content:
      '我們遇上了內部錯誤，請再試一次。如問題持續發生，請聯絡技術支援。({{error}})',
  },
  badRequest: {
    title: '錯誤請求',
    content:
      '我們遇上了請求錯誤，請再試一次。如問題持續發生，請聯絡技術支援。({{error}})',
  },
  default: {
    title: '錯誤',
    content:
      '我們遇上了錯誤，請再試一次。如問題持續發生，請聯絡技術支援。({{error}})',
    contentWithError: '$t(error.default.content) ({{error}})',
  },
}
