import { Button } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { memo } from 'react'
import { PlusIcon } from '../../icons'

type CreateButtonPropType = { onClick: () => void }
export const CreateButton = memo<CreateButtonPropType>(({ onClick }) => {
  const { t } = useTranslation()
  return (
    <>
      <Button
        startIcon={<PlusIcon />}
        variant="contained"
        fullWidth
        onClick={onClick}
      >
        {t('common.create')}
      </Button>
    </>
  )
})
CreateButton.displayName = 'CreateButton'
