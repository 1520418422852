import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { TFuncKey, useTranslation } from '@pasteltech/i18n-react'
import { AssetTypeResponsePayload, Status } from '@stewards-fas/schemas'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useUnitOptions } from '../../hooks'
import {
  BlackSearchIcon,
  ClearIcon,
  CollapseIcon,
  WhiteExpandIcon,
  WhiteSearchIcon,
} from '../../icons'
import {
  AssetReimburseStatus,
  SearchFormInitialValues,
  SearchFormSchema,
  SearchFormSchemaModel,
  Section,
} from '../../models'
import { formats } from '../../theme'
import { useDropdownAll } from '../../utilities'

const SearchBar = styled.div`
  margin-bottom: 30px;
`

const SearchBarBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.lightBlue2};
  padding: 16px;
  gap: 20px;
  flex-wrap: wrap;
`

const ClearAllBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  background-color: ${({ theme }) => theme.colors.lightBlue2};
  padding: 16px;
  flex-wrap: wrap;
`

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  flex-grow: 1;
`

const LabelContainer = styled.div``

const ClearAllLabelContainer = styled.div`
  width: 70px;
  color: ${({ theme }) => theme.colors.chestnut};
  cursor: pointer;
`

const ExpandLabelContainer = styled.div`
  width: 70px;
`

const LabelContainerUnit = styled(LabelContainer)`
  text-align: right;
`

const LabelContainerTo = styled.div`
  width: 70px;
  margin-left: 5px;
  margin-right: -60px;
`

const WideTextField = styled(TextField)`
  width: auto;
  background-color: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
`

const ExpandShortTextField = styled(TextField)`
  width: auto;
  background-color: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
`

const ExpandLongTextField = styled(TextField)`
  width: auto;
  background-color: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
`

const StyledSelect = styled(Select)`
  width: auto;
  background-color: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
`
const SearchButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.berryBlue};
  margin-left: 20px;
  margin-right: 20px;
  width: 80px;
  flex-shrink: 0;
  &:hover {
    background-color: ${({ theme }) => theme.colors.berryBlue};
  }
`

const StyledDatePicker = styled(DatePicker)`
  background-color: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
`

const StyledIconButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`

interface SearchBarProps {
  tab: Section
  onSearch: (formikInput: SearchFormSchemaModel) => void
  needState: boolean
  defaultValue?: SearchFormSchemaModel
  assetTypeList?: AssetTypeResponsePayload[]
  setcurrentUnitId?: React.Dispatch<React.SetStateAction<any>>
}

export const AssetSearchBar = memo(
  ({
    onSearch,
    tab,
    needState,
    defaultValue,
    assetTypeList,
    setcurrentUnitId,
  }: SearchBarProps) => {
    const formik = useFormik({
      initialValues: { ...SearchFormInitialValues, ...(defaultValue ?? {}) },
      validationSchema: SearchFormSchema,
      onSubmit: (values) => {
        onSearch(values)
      },
      onReset: () => {
        onSearch(SearchFormInitialValues)
      },
    })

    const { t } = useTranslation()
    const { allOption } = useDropdownAll()
    const [expanded, setExpanded] = useState(false)

    // To clear all values in collapsed field
    const handleExpand = useCallback(() => {
      if (expanded) {
        formik.setFieldValue('name', '')
        formik.setFieldValue('brand', '')
        formik.setFieldValue('model', '')
        formik.setFieldValue('serialNumber', '')
        formik.setFieldValue('provider', '')
        formik.setFieldValue('amountStart', undefined)
        formik.setFieldValue('amountEnd', undefined)
        formik.setFieldValue('state', AssetReimburseStatus.notReimbursed)
      }
      setExpanded(!expanded)
    }, [expanded, formik])

    const handleClearAll = useCallback(() => {
      formik.setValues(SearchFormInitialValues)
      setcurrentUnitId?.(SearchFormInitialValues.unit)
    }, [formik, setcurrentUnitId])

    const expandable = useMemo(() => tab === Section.search, [tab])

    const { touched, errors } = formik

    useEffect(() => {
      setcurrentUnitId?.(formik.values.unit)
    }, [formik.values.unit, setcurrentUnitId])

    const UnitOptions = useUnitOptions({ isHasOptionAll: true })
    const AssetTypeOptions = useMemo(() => {
      return [allOption].concat(
        (assetTypeList ?? [])
          .filter((atype) => atype.status.toLowerCase() !== Status.inactive)
          .map((atype) => ({
            value: atype.id,
            label: `${atype.code} ${atype.typeCN}`,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      )
    }, [assetTypeList, allOption])

    return (
      <SearchBar>
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <SearchBarBox>
            {(tab === Section.search || tab === Section.reimburse) && (
              <SearchBarContainer>
                <WideTextField
                  variant="outlined"
                  name="keyword"
                  onChange={formik.handleChange}
                  placeholder={
                    tab === Section.search
                      ? t('searchBar.labels.assetCode')
                      : t('searchBar.labels.reimburseCode')
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BlackSearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={formik.values.keyword}
                />
              </SearchBarContainer>
            )}

            <SearchBarContainer>
              <LabelContainerUnit>
                {tab === Section.transfer
                  ? t('searchBar.labels.unitFrom')
                  : t('searchBar.labels.unit')}
              </LabelContainerUnit>
              <StyledSelect
                name="unit"
                onChange={formik.handleChange}
                value={formik.values.unit}
              >
                {UnitOptions.map((it) => (
                  <MenuItem key={it.value} value={it.value}>
                    {it.label}
                  </MenuItem>
                ))}
              </StyledSelect>
            </SearchBarContainer>

            {tab === Section.search && (
              <SearchBarContainer>
                <LabelContainer>
                  {t('searchBar.labels.assetType')}
                </LabelContainer>
                <StyledSelect
                  name="assetType"
                  onChange={formik.handleChange}
                  value={formik.values.assetType}
                >
                  {AssetTypeOptions?.map((it) => (
                    <MenuItem key={it.value} value={it.value}>
                      {it.label}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </SearchBarContainer>
            )}

            {tab === Section.transfer && (
              <SearchBarContainer>
                <LabelContainer>
                  {t('searchBar.labels.transferToUnit')}
                </LabelContainer>
                <StyledSelect
                  name="toUnit"
                  onChange={formik.handleChange}
                  value={formik.values.toUnit}
                >
                  {UnitOptions.map((it) => (
                    <MenuItem key={it.value} value={it.value}>
                      {it.label}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </SearchBarContainer>
            )}

            {tab !== Section.reimburse && (
              <SearchBarContainer>
                <LabelContainer>
                  {tab === Section.transfer
                    ? t('searchBar.labels.transferDate')
                    : t('searchBar.labels.buyDate')}
                </LabelContainer>

                <StyledDatePicker
                  format={formats.date}
                  onChange={(val: unknown) =>
                    formik.setFieldValue('startDate', val as Date)
                  }
                  value={
                    formik.values.startDate
                      ? dayjs(formik.values.startDate)
                      : null
                  }
                />

                <LabelContainerTo>-</LabelContainerTo>

                <StyledDatePicker
                  format={formats.date}
                  onChange={(val: unknown) =>
                    formik.setFieldValue('endDate', val as Date)
                  }
                  value={
                    formik.values.endDate ? dayjs(formik.values.endDate) : null
                  }
                />
              </SearchBarContainer>
            )}

            <SearchButton
              variant="contained"
              onClick={() => formik.handleSubmit()}
            >
              <WhiteSearchIcon />
              {t('searchBar.labels.search')}
            </SearchButton>

            {(tab === Section.transfer || tab === Section.reimburse) && (
              <ClearAllBoxContainer>
                <IconButton type="reset">
                  <ClearIcon />
                </IconButton>
                <ClearAllLabelContainer onClick={handleClearAll}>
                  {t('searchBar.labels.clearAll')}
                </ClearAllLabelContainer>
              </ClearAllBoxContainer>
            )}

            {expandable && (
              <div>
                {expanded ? (
                  <IconButton onClick={handleExpand}>
                    <CollapseIcon />
                  </IconButton>
                ) : (
                  <StyledIconButton onClick={handleExpand}>
                    <WhiteExpandIcon />
                  </StyledIconButton>
                )}
              </div>
            )}
          </SearchBarBox>

          {expanded && (
            <div>
              <SearchBarBox>
                <SearchBarContainer>
                  <ExpandLabelContainer>
                    {t('searchBar.labels.name')}
                  </ExpandLabelContainer>
                  <ExpandShortTextField
                    variant="outlined"
                    placeholder={t('searchBar.labels.inputName')}
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </SearchBarContainer>

                <SearchBarContainer>
                  <ExpandLabelContainer>
                    {t('searchBar.labels.brand')}
                  </ExpandLabelContainer>
                  <ExpandLongTextField
                    variant="outlined"
                    placeholder={t('searchBar.labels.inputBrand')}
                    name="brand"
                    onChange={formik.handleChange}
                    value={formik.values.brand}
                  />
                </SearchBarContainer>

                <SearchBarContainer>
                  <ExpandLabelContainer>
                    {t('searchBar.labels.model')}
                  </ExpandLabelContainer>
                  <ExpandLongTextField
                    variant="outlined"
                    placeholder={t('searchBar.labels.inputModel')}
                    name="model"
                    onChange={formik.handleChange}
                    value={formik.values.model}
                  />
                </SearchBarContainer>
              </SearchBarBox>

              <SearchBarBox>
                <SearchBarContainer>
                  <ExpandLabelContainer>
                    {t('searchBar.labels.serialNumber')}
                  </ExpandLabelContainer>
                  <ExpandShortTextField
                    variant="outlined"
                    placeholder={t('searchBar.labels.inputSerialNumber')}
                    name="serialNumber"
                    onChange={formik.handleChange}
                    value={formik.values.serialNumber}
                  />
                </SearchBarContainer>

                <SearchBarContainer>
                  <ExpandLabelContainer>
                    {t('searchBar.labels.provider')}
                  </ExpandLabelContainer>
                  <ExpandLongTextField
                    variant="outlined"
                    placeholder={t('searchBar.labels.inputProvider')}
                    name="provider"
                    onChange={formik.handleChange}
                    value={formik.values.provider}
                  />
                </SearchBarContainer>

                <SearchBarContainer>
                  <ExpandLabelContainer>
                    {t('searchBar.labels.amount')}
                  </ExpandLabelContainer>
                  <WideTextField
                    variant="outlined"
                    type="number"
                    placeholder={t('searchBar.labels.inputAmount')}
                    name="amountStart"
                    onChange={formik.handleChange}
                    value={formik.values.amountStart ?? ''}
                    error={touched.amountStart && Boolean(errors.amountStart)}
                    helperText={
                      touched.amountStart && errors.amountStart
                        ? (t(errors.amountEnd as TFuncKey) as string)
                        : null
                    }
                  />
                  <LabelContainerTo>-</LabelContainerTo>
                  <WideTextField
                    variant="outlined"
                    type="number"
                    placeholder={t('searchBar.labels.inputAmount')}
                    name="amountEnd"
                    onChange={formik.handleChange}
                    value={formik.values.amountEnd ?? ''}
                    error={touched.amountEnd && Boolean(errors.amountEnd)}
                    helperText={
                      touched.amountEnd && errors.amountEnd
                        ? (t(errors.amountEnd as TFuncKey) as string)
                        : null
                    }
                  />
                </SearchBarContainer>
              </SearchBarBox>

              {needState && (
                <SearchBarBox>
                  <SearchBarContainer>
                    <ExpandLabelContainer>
                      {t('searchBar.labels.state')}
                    </ExpandLabelContainer>
                    <FormControl>
                      <RadioGroup
                        row
                        name="state"
                        onChange={formik.handleChange}
                        value={formik.values.state ?? ''}
                      >
                        {Object.values(AssetReimburseStatus).map((status) => (
                          <FormControlLabel
                            key={status}
                            value={status}
                            control={<Radio />}
                            label={t(`searchBar.labels.${status}`)}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </SearchBarContainer>
                </SearchBarBox>
              )}

              <ClearAllBoxContainer>
                <IconButton type="reset">
                  <ClearIcon />
                </IconButton>
                <ClearAllLabelContainer onClick={handleClearAll}>
                  {t('searchBar.labels.clearAll')}
                </ClearAllLabelContainer>
              </ClearAllBoxContainer>
            </div>
          )}
        </form>
      </SearchBar>
    )
  },
)

AssetSearchBar.displayName = 'AssetSearchBar'
