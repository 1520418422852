export const colors = {
  background: '#F4F4F4',
  primary: '#0D5681',
  primaryContainer: '#7691B9',
  secondary: '#8E3125',
  secondaryContainer: '#BC4749',
  darkGreen: '#469846',
  lightGreen: '#EEFAF0',
  maroonOak: '#8E3125',
  lightBrown: '#FEF5F3',
  lightBlue2: '#E7F9FF',
  chetwodeBlue: '#7691B9',
  chestnut: '#BC4749',
  lightPrimary: '#EEFBF6',
  berryBlue: '#325C79',
  brigBlue: '#4AA2BA',
  black: '#000000',
  lightBlue: '#D5EEF8',
  white: '#FFFFFF',
  blackTransparents: [
    'rgba(0, 0, 0, 0.1)',
    'rgba(0, 0, 0, 0.11)',
    'rgba(0, 0, 0, 0.13)',
    'rgba(0, 0, 0, 0.25)',
  ],
  greys: [
    '#000000',
    '#333333',
    '#4F4F4F',
    '#828282',
    '#BDBDBD',
    '#E0E0E0',
    '#F4F4F4',
  ],
}
