import { useTranslation } from '@pasteltech/i18n-react'
import { useMemo } from 'react'
import { AppManagementTabItem } from '../../../components'

export function useMasterDataText(tab: AppManagementTabItem) {
  const { t } = useTranslation()
  return useMemo(() => {
    if (tab === AppManagementTabItem.systemUnitManagement) {
      return { tableName: t('systemDataManagement.tableNames.unit') }
    }
    if (tab === AppManagementTabItem.systemUserManagement) {
      return { tableName: t('systemDataManagement.tableNames.user') }
    }
    if (tab === AppManagementTabItem.systemAssetClassManagement) {
      return { tableName: t('systemDataManagement.tableNames.assetClass') }
    }
    if (tab === AppManagementTabItem.systemFundingResourceManagement) {
      return { tableName: t('systemDataManagement.tableNames.fundingSource') }
    }
    if (tab === AppManagementTabItem.systemReferenceMaterialManagement) {
      return { tableName: t('systemDataManagement.tableNames.reference') }
    }
    return { tableName: 'Undefined' }
  }, [tab, t])
}
