import { Button, IconButton, Typography } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import {
  useAssetTypeList,
  useFundingList,
  useReportList,
  useUnitList,
} from '@stewards-fas/queries'
import {
  AssetRequestStatus,
  ReportListRequestPayload,
} from '@stewards-fas/schemas'
import { uniq } from 'lodash'
import { memo, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import * as XLSX from 'xlsx'
import {
  PageTitle,
  PrintWindow,
  ReportDisplay,
  ReportGenerator,
} from '../../components'
import { useCurrentUserUnits } from '../../hooks'
import { BackIcon, ExcelIcon, WhitePrintIcon } from '../../icons'
import {
  ReportGeneratorSchemaModel,
  TransferToOrFromUnitFieldValues,
  mapReportFormikToSearchParams,
} from '../../models'
import {
  All_VALUE_OF_DROPDOWN,
  convertToEndOfDay,
  convertToStartOfDay,
} from '../../utilities'

const Root = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.background};
  flex-direction: column;
  width: 100%;
`

const ReportDisplayRoot = styled.div`
  margin-bottom: 30px;
`

const ButtonContainer = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
`

const FunctionButtonContainer = styled.div`
  gap: 20px;
  display: flex;
`

const ExportButtonBox = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.greys[2]};
  border: solid;
  border-width: 1px;
  gap: 8px;
  padding: 12px 20px;
`

const ExportButtonContent = styled(Typography)`
  color: ${({ theme }) => theme.colors.greys[2]};
  font-weight: 600;
  line-height: 22px;
`

const PrintButtonBox = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  gap: 8px;
  padding: 12px 20px;
`

const PrintButtonContent = styled(Typography)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  line-height: 22px;
`

const ReturnButtonContainer = styled.div``

export const GenerateReportPage = memo(() => {
  const { t } = useTranslation()
  const { checkPermittedUnitId } = useCurrentUserUnits()

  const [previewing, setIsPreviewing] = useState(false)

  const [filter, setFilter] = useState<ReportGeneratorSchemaModel>()

  const [countTrue, setCountTrue] = useState(0)

  const [reportGenerationSearchParam, setSearchParams] =
    useState<ReportListRequestPayload>({})

  const searchParams = useMemo(() => {
    return {
      ...reportGenerationSearchParam,
      lastModifiedToDateTime:
        reportGenerationSearchParam.lastModifiedToDateTime != null
          ? convertToEndOfDay(
              reportGenerationSearchParam.lastModifiedToDateTime,
            )
          : undefined,
      purchasedFromDateTime:
        reportGenerationSearchParam.purchasedFromDateTime != null
          ? convertToStartOfDay(
              reportGenerationSearchParam.purchasedFromDateTime,
            )
          : undefined,
      purchasedToDateTime:
        reportGenerationSearchParam.purchasedToDateTime != null
          ? convertToEndOfDay(reportGenerationSearchParam.purchasedToDateTime)
          : undefined,
    }
  }, [reportGenerationSearchParam])

  const { data: reportList } = useReportList(searchParams)

  const { data: unitList } = useUnitList()
  const permittedUnitList = useMemo(() => {
    return (unitList?.data ?? []).filter((u) => checkPermittedUnitId(u.id))
  }, [checkPermittedUnitId, unitList?.data])

  const { data: assetTypeList } = useAssetTypeList()

  const { data: fundingList } = useFundingList()

  const [selectedUnitId, setSelectedUnitId] = useState(All_VALUE_OF_DROPDOWN)
  const [currentType, setCurrentType] = useState(All_VALUE_OF_DROPDOWN)
  const [transferToOrFrom, setTransferToOrFrom] = useState(
    All_VALUE_OF_DROPDOWN,
  )

  const normalizedAssetData = useMemo(() => {
    const normalizedData = reportList?.data ?? []
    return normalizedData
  }, [reportList?.data])

  const filterByUnitAndTransfer = useMemo(() => {
    // if transfer to or from is selected, show assets transferred to/from the unit
    // if transfer is not selected, filter by assets' unit

    const selectedUnitList = selectedUnitId
      ? permittedUnitList.filter((u) => u.id === selectedUnitId)
      : permittedUnitList
    if (
      [
        TransferToOrFromUnitFieldValues.from,
        TransferToOrFromUnitFieldValues.to,
      ].includes(transferToOrFrom as any)
    ) {
      // prefilter to speed up computation
      const transfered = normalizedAssetData.filter(
        (reportAsset) => (reportAsset.assetRequestAssets?.length ?? 0) > 0,
      )

      const inOrOutOfUnits = transfered.filter((reportAsset) => {
        const validRequest = reportAsset.assetRequestAssets?.filter(
          (req) =>
            req.assetRequest?.receiveStatus !== AssetRequestStatus.withdrew &&
            req.assetRequest?.transitStatus !== AssetRequestStatus.withdrew,
        )
        const toUnitsHistory = uniq(
          validRequest?.map((req) => req.assetRequest?.toUnitId),
        )
        const fromUnitsHistory = uniq(
          validRequest?.map((req) => req.assetRequest?.fromUnitId),
        )
        if (transferToOrFrom === TransferToOrFromUnitFieldValues.from) {
          return selectedUnitList.some((u) => fromUnitsHistory.includes(u.id))
        }
        if (transferToOrFrom === TransferToOrFromUnitFieldValues.to) {
          return selectedUnitList.some((u) => toUnitsHistory.includes(u.id))
        }
        return true
      })
      return inOrOutOfUnits
    }
    const authorizedData = normalizedAssetData.filter((asset) =>
      checkPermittedUnitId(asset.unitId),
    )
    const filteredData = authorizedData.filter((asset) =>
      selectedUnitList.some((u) => u.id === asset.unitId),
    )
    return filteredData
  }, [
    normalizedAssetData,
    checkPermittedUnitId,
    selectedUnitId,
    permittedUnitList,
    transferToOrFrom,
  ])

  const displayList = useMemo(() => {
    const selectedTypeId = currentType
      ? [currentType]
      : (assetTypeList?.data ?? []).map((aType) => aType.id)
    const filterByAssetType = filterByUnitAndTransfer.filter((asset) =>
      selectedTypeId.includes(asset.assetTypeId),
    )

    const filteredData = filterByAssetType

    return filteredData
  }, [assetTypeList, currentType, filterByUnitAndTransfer])

  const defaultFormikValue: Partial<ReportGeneratorSchemaModel> =
    useMemo(() => {
      return {
        ...filter,
        transferToOrFrom:
          transferToOrFrom != '' && transferToOrFrom != null
            ? transferToOrFrom
            : All_VALUE_OF_DROPDOWN,
      }
    }, [filter, transferToOrFrom])

  const generateReport = useCallback(
    (formikValues: ReportGeneratorSchemaModel) => {
      setSearchParams(mapReportFormikToSearchParams(formikValues))
      setIsPreviewing(true)
      setFilter(formikValues)
      setCountTrue(
        Object.values(formikValues).filter((item) => item === true).length,
      )
    },
    [],
  )
  const returnButton = useCallback(() => {
    setIsPreviewing(false)
  }, [])

  const [isPrint, setIsPrint] = useState(false)
  const closePrint = useCallback(() => {
    setIsPrint(false)
  }, [])
  const startPrint = useCallback(() => {
    setIsPrint(true)
  }, [])

  const exportExcel = useCallback(() => {
    const table = document.getElementById('Table2XLSX')
    const wb = XLSX.utils.table_to_book(table)
    XLSX.writeFile(wb, 'Report.xlsx')
  }, [])

  return (
    <Root>
      <PageTitle>{t('reportPage.pageTitle')}</PageTitle>
      {previewing ? (
        <ReportDisplayRoot>
          <ButtonContainer>
            <ReturnButtonContainer>
              <IconButton>
                <BackIcon onClick={returnButton} />
              </IconButton>
            </ReturnButtonContainer>

            <FunctionButtonContainer>
              <ExportButtonBox onClick={exportExcel}>
                <ExcelIcon />
                <ExportButtonContent>
                  {t('reportPage.button.exportExcel')}
                </ExportButtonContent>
              </ExportButtonBox>

              <PrintButtonBox variant="contained" onClick={startPrint}>
                <WhitePrintIcon />
                <PrintButtonContent>
                  {t('reportPage.button.print')}
                </PrintButtonContent>
              </PrintButtonBox>
            </FunctionButtonContainer>
          </ButtonContainer>

          <ReportDisplay
            filterValue={filter}
            filterValueNumbmer={countTrue}
            reportList={displayList}
          />
          {isPrint && (
            <PrintWindow
              onClose={closePrint}
              title={t('reportPage.titleLine2')}
            >
              <ReportDisplay
                filterValue={filter}
                filterValueNumbmer={countTrue}
                reportList={displayList}
              />
            </PrintWindow>
          )}
        </ReportDisplayRoot>
      ) : (
        <ReportGenerator
          onGenerate={generateReport}
          defaultValue={defaultFormikValue}
          needState={true}
          unitList={permittedUnitList ?? []}
          assertTypeList={assetTypeList?.data ?? []}
          fundingList={fundingList?.data ?? []}
          setUnit={setSelectedUnitId}
          setAssetType={setCurrentType}
          setTransferToOrFrom={setTransferToOrFrom}
        />
      )}
    </Root>
  )
})

GenerateReportPage.displayName = 'GenerateReportPage'
