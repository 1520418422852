import { useTranslation } from '@pasteltech/i18n-react'
import { ReferencePageResponsePayload } from '@stewards-fas/schemas'
import { useMemo } from 'react'
import {
  CRUDTableActions,
  FieldMapping,
  InputType,
} from '../../../../components'

export function useReferencePagefields() {
  const { t } = useTranslation()

  const mappings = useMemo(() => {
    const m = {
      pageName: {
        key: 'name',
        value: (i) => i.name,
        display: (i) => i.name,
        inputType: InputType.text,
        label: t('systemDataManagement.fields.pageName'),
        disableMode: [CRUDTableActions.edit, CRUDTableActions.create],
      } as FieldMapping<ReferencePageResponsePayload>,
      contents: {
        key: 'contents',
        value: (i) => i.contents,
        display: (i) => i.contents,
        inputType: InputType.textArea,
        label: t('systemDataManagement.fields.content'),
      } as FieldMapping<ReferencePageResponsePayload>,
      description: {
        key: 'description',
        value: (i) => i.description,
        display: (i) => i.description,
        inputType: InputType.text,
        label: t('systemDataManagement.fields.description'),
        disableMode: [CRUDTableActions.edit, CRUDTableActions.create],
      } as FieldMapping<ReferencePageResponsePayload>,
      type: {
        key: 'type',
        value: (i) => i.type,
        display: (i) => i.type,
        inputType: InputType.text,
        label: t('systemDataManagement.fields.dataType'),
        disableMode: [CRUDTableActions.edit, CRUDTableActions.create],
      } as FieldMapping<ReferencePageResponsePayload>,
    }

    return m
  }, [t])

  return { mappings }
}
