import { SelectChangeEvent } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { memo, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { BaseDropdownField } from '../form-fields'

const FilterStatusText = styled.div<{ active?: boolean }>`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  display: inline-block;
  color: ${({ theme, active }) =>
    !active ? theme.colors.secondary : theme.colors.darkGreen};
  background-color: ${({ theme, active }) =>
    !active ? theme.colors.lightBrown : theme.colors.lightGreen};
  padding: 2px 12px;
  border-radius: 4px;
`

interface TransferStatusDropdownProps {
  onChange?: React.Dispatch<React.SetStateAction<any>>
  notApprovedOnly: boolean
}

export const TransferStatusDropdown = memo(
  ({ onChange, notApprovedOnly }: TransferStatusDropdownProps) => {
    const { t } = useTranslation()

    const transferStatusOptions = useMemo(
      () =>
        notApprovedOnly
          ? [
              {
                value: 'PENDING',
                label: t('ApproveStatus.pending'),
              },
            ]
          : [
              {
                value: undefined as any,
                label: t('common.all'),
              },
              {
                value: 'PENDING',
                label: t('ApproveStatus.pending'),
              },
              {
                value: 'APPROVED',
                label: t('ApproveStatus.approved'),
              },
            ],
      [notApprovedOnly, t],
    )

    const handleChange = useCallback(
      (event: SelectChangeEvent<unknown>) => {
        onChange?.(event.target.value)
      },
      [onChange],
    )

    const defaultOption = useMemo(
      () => (notApprovedOnly ? 'PENDING' : undefined),
      [notApprovedOnly],
    )

    return (
      <BaseDropdownField
        dir="vertical"
        variant="outlined"
        name="transferStatus"
        onChange={handleChange}
        data={transferStatusOptions}
        value={defaultOption}
        renderValue={(value) => {
          const data = transferStatusOptions.find((it) => it.value === value)

          if (data == null) return null

          return data.value ? (
            <FilterStatusText active={data.value === 'APPROVED'}>
              {data.label}
            </FilterStatusText>
          ) : (
            data.label
          )
        }}
      />
    )
  },
)

TransferStatusDropdown.displayName = 'TransferStatusDropdown'
