import { ReactNode, useMemo, useState } from 'react'
import { FormikFieldType, InputType } from '../../formik-form'
import { CRUDTableActions } from '../crud-table-actions'
import { AnyObjectSchema } from 'yup'

/**
 * Defines a mapping from api-payload-field to multiple ui fields
 * a generic interface to standardize basic crud operations.
 * allow display data as a table (label, display)
 * allow modification of data as a form (label, inputType, value)
 * */
export type FieldMapping<T> = {
  /**
   *  must have. This key is used for formik data. Thus should match the keys of payload. If payload is nested, bypass the type checking
   * */
  key: string & keyof T
  /**
   *  for form data, skip if undefined
   * */
  value: (data: T) => string | number | boolean | undefined | string[]
  /**
   *  for table, skip if undefined
   * */
  display: undefined | ((data: T) => ReactNode)
  /**
   * for form, skip ui if label is undefined
   * */
  inputType: InputType | undefined
  /**
   * for form label;
   * if undefined, skip for table header
   * */
  label: string | undefined
  /**
   * for disabled display-only field;
   * if undefined, enabled
   * */
  disableMode?: CRUDTableActions[]
}

/**
 * For fields that reacts to the data itself
 * */
export type ComplexField<T> = FieldMapping<T> & {
  /**
   * Fields that are returned here will overwrite the normal fields.
   * */
  complexToSimple?: (data: T) => Partial<FieldMapping<T>>
}

export function mapToFormFields<T extends Object>(
  mode: CRUDTableActions,
  mappingList: ComplexField<T>[],
  data: T | undefined,
): FormikFieldType[] {
  const actualMapping = mappingList.map(
    ({ complexToSimple, ...normalFields }: any) => {
      return {
        ...normalFields,
        ...complexToSimple?.(data),
      }
    },
  ) as FieldMapping<T>[]
  const filtered = actualMapping.filter(({ inputType }) => inputType != null)
  const formFields = filtered.map((field) => {
    return {
      key: field.key,
      inputType: field?.inputType,
      label: field?.label ?? '',
      disabled: field?.disableMode?.includes(mode) ?? false,
    }
  })
  return formFields
}

export function mapToFormData<T extends Object>(
  dataRow: T,
  mappingList: FieldMapping<T>[],
) {
  return mappingList
    .filter((field) => field.value !== undefined)
    .reduce(
      (obj, field) => ({
        ...obj,
        [field.key]: field.value(dataRow),
      }),
      {},
    )
}

export function mapToTableHeader<T extends Object>(
  mappingList: FieldMapping<T>[],
) {
  return mappingList.filter((f) => !!f.label).map((f) => f.label) as string[]
}

export function mapToTableData<T>(data: T[], mappingList: FieldMapping<T>[]) {
  const displayData = data.map((row) => {
    return mappingList.reduce((obj, mapping) => {
      if (mapping.display) {
        return {
          ...obj,
          // avoid key === 'status', Table List have intrinsic logic for 'status' field // TODO: use generic table
          [mapping.key === 'id' ? mapping.key : `table_${mapping.key}`]:
            mapping.display(row),
        }
      }
      return obj
    }, {})
  })
  return displayData
}

export function useMappedFields<T extends Object>(
  mappingList: FieldMapping<T>[],
  data: T[],
  validateSchemas: Record<CRUDTableActions, AnyObjectSchema | undefined>,
  selectedData?: T,
) {
  const [mode, setMode] = useState<CRUDTableActions>(CRUDTableActions.edit)
  const tableHeader = useMemo(() => {
    return mapToTableHeader(mappingList)
  }, [mappingList])
  const tableData = useMemo(() => {
    return mapToTableData(data, mappingList)
  }, [data, mappingList])
  const formFields = useMemo(() => {
    return mapToFormFields(mode, mappingList, selectedData)
  }, [mappingList, mode, selectedData])
  const formData = useMemo(() => {
    if (!selectedData) return {}
    return mapToFormData(selectedData, mappingList)
  }, [mappingList, selectedData])
  const formValidateSchema = useMemo(
    () => validateSchemas[mode],
    [validateSchemas, mode],
  )
  return {
    tableHeader,
    tableData,
    formFields,
    formData,
    formValidateSchema,
    setMode,
  }
}
