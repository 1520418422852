import { memo, useMemo } from 'react'
import {
  AppManagementTabItem,
  CRUDTableActions,
  CrudTable,
  Option,
} from '../../components'
import {
  useMasterDataFilter,
  useMasterDataMappings,
  useMasterDataMutation,
  useMasterDataText,
  useMasterDataValidate,
} from './hooks'
import { AnyObjectSchema } from 'yup'

type Props = {
  tab: AppManagementTabItem
  data?: any[]
  options?: Record<string, Option[]>
  btnDisabled?: {
    add?: boolean
    inactivate?: boolean
  }
}

export const MasterDataCrudTable = memo<Props>(({ tab, data, btnDisabled }) => {
  const { onCreate, onMultipleDelete, onUpdate } = useMasterDataMutation(tab)
  const { fieldsMapping } = useMasterDataMappings(tab)
  const { tableName } = useMasterDataText(tab)
  const validateSchemas = useMasterDataValidate(tab)
  const { filterComponent, filterFunction } = useMasterDataFilter(tab)
  const dataArray = useMemo(() => {
    const arr = data ?? []
    return filterFunction(arr)
  }, [data, filterFunction])

  return (
    <CrudTable
      validateSchemas={
        validateSchemas as Record<CRUDTableActions, AnyObjectSchema | undefined>
      }
      data={dataArray}
      fieldsMapping={fieldsMapping}
      tableName={tableName}
      filterComponent={filterComponent}
      onCreate={!btnDisabled?.add ? onCreate : undefined}
      onMultipleDelete={!btnDisabled?.inactivate ? onMultipleDelete : undefined}
      onUpdate={onUpdate}
    />
  )
})

MasterDataCrudTable.displayName = 'SystemManagementTable'
