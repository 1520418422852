import { Typography } from '@mui/material'
import { memo } from 'react'
import styled from 'styled-components'
import { MenuItem } from '../../routers'
import { env, ScreenSize, useView } from '../../utilities'
import { APPBAR_MOBILE_VIEW_HEIGHT, APPBAR_NORMAL_VIEW_HEIGHT } from '../appbar'
import { SidebarList } from './sidebar-list'

const Root = styled.div<{ $view: ScreenSize }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  margin-top: ${({ $view }) =>
    $view === ScreenSize.mobile
      ? APPBAR_MOBILE_VIEW_HEIGHT
      : APPBAR_NORMAL_VIEW_HEIGHT}px;
`

const SidebarBottom = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  gap: 12px;
`
const VersionContainer = styled.div`
  border-style: solid;
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  border-width: 1px;
  padding: 8px 20px 8px 20px;
  display: flex;
  height: 38px;
  align-items: center;
`

const Version = styled(Typography)`
  font-weight: 600;
`

const EnvContainer = styled.div`
  padding: 8px 20px 8px 20px;
  display: inline-block;
  background: ${({ theme }) => theme.colors.lightBlue2};
  border-radius: 5px;
  display: flex;
  color: ${({ theme }) => theme.colors.primary};
  height: 38px;
  align-items: center;
`

const Env = styled(Typography)`
  font-weight: 600;
`

type Props = {
  urlRoot: string
  sidebarItems: MenuItem[]
}

export const SidebarDrawer = memo<Props>(({ urlRoot, sidebarItems }) => {
  const view = useView()
  return (
    <Root $view={view}>
      <SidebarList urlRoot={urlRoot} sidebarItems={sidebarItems} />
      <SidebarBottom>
        <VersionContainer>
          <Version>v{env.version}</Version>
        </VersionContainer>
        <EnvContainer>
          <Env>{env.environment}</Env>
        </EnvContainer>
      </SidebarBottom>
    </Root>
  )
})

SidebarDrawer.displayName = 'SidebarDrawer'
