import { TFuncKey, useTranslation } from '@pasteltech/i18n-react'
import { useUnitList } from '@stewards-fas/queries'
import { Status, UserResponsePayload } from '@stewards-fas/schemas'
import { useMemo } from 'react'
import {
  CRUDTableActions,
  ComplexField,
  FieldMapping,
  InputType,
} from '../../../../components'
import { ActiveStatus } from '../../ui-components'
import { toFirstCharLowerCase } from '../../../../utilities'
import { Role } from '../../../../models'

const MAXIMUM_UNIT_DISPLAY = 3
const ELLIPSIS = '...'

export function useUserFields() {
  const { t } = useTranslation()
  const { data: unitData } = useUnitList()

  const mappings = useMemo(() => {
    const m = {
      name: {
        key: 'username',
        value: (it) => it.username,
        display: (it) => it.username,
        inputType: InputType.text,
        label: t('systemDataManagement.fields.username'),
      } as FieldMapping<UserResponsePayload>,
      email: {
        key: 'email',
        value: (it) => it.email,
        display: (it) => it.email,
        inputType: InputType.text,
        label: t('systemDataManagement.fields.email'),
        disableMode: [CRUDTableActions.edit],
      } as FieldMapping<UserResponsePayload>,
      position: {
        key: 'position',
        value: (it) => it.position,
        display: (it) => it.position,
        inputType: InputType.text,
        label: t('systemDataManagement.fields.position'),
      } as FieldMapping<UserResponsePayload>,
      role: {
        key: 'roleId',
        value: (it) => it.roleId,
        display: (it) => {
          return t(
            `systemDataManagement.userRole.${toFirstCharLowerCase(
              it.role?.name,
            )}` as TFuncKey,
          )
        },
        inputType: InputType.selectRole,
        label: t('systemDataManagement.fields.competence'),
      } as FieldMapping<UserResponsePayload>,
      responsibleUnits: {
        key: 'userUnitsIds',
        value: (it: UserResponsePayload) =>
          it.role?.name === Role.systemAdministrator
            ? []
            : it.userUnits?.map(
                (uu) =>
                  unitData?.data.find((unit) => unit.id === uu.unitId)?.id,
              ) ?? [],
        display: (it: UserResponsePayload) => {
          const unitList = it.userUnits.map(
            (uu) => unitData?.data.find((unit) => unit.id === uu.unitId)?.code,
          )
          const isAdmin = it.role?.name === Role.systemAdministrator
          const isNA = !it.userUnits || it.userUnits.length === 0
          if (isNA || isAdmin) return 'N/A'
          if (unitList.length > MAXIMUM_UNIT_DISPLAY)
            return unitList.slice(0, MAXIMUM_UNIT_DISPLAY).join(', ') + ELLIPSIS
          return unitList.join(', ')
        },
        inputType: InputType.selectMultipleUnit,
        label: t('systemDataManagement.fields.posibileUnit'),
        complexToSimple: (it: UserResponsePayload) => {
          const isAdmin = it.role?.name === Role.systemAdministrator
          if (isAdmin) {
            return {
              disableMode: isAdmin
                ? [CRUDTableActions.create, CRUDTableActions.edit]
                : undefined,
            }
          }
          return {}
        },
      } as ComplexField<UserResponsePayload & { userUnitsIds: string[] }>, // used to map userUnits
      status: {
        key: 'status',
        value: (it) => it.status ?? 'active',
        display: (it) => {
          const lower = it.status.toLowerCase()
          if (['active', 'inactive'].includes(lower)) {
            return (
              <ActiveStatus
                status={lower as Status}
                activeText={t('systemDataManagement.userStatus.active')}
                inactiveText={t('systemDataManagement.userStatus.inactive')}
              />
            )
          }
          return 'undefined'
        },
        inputType: InputType.selectUserStatus,
        label: t('systemDataManagement.fields.userStatus'),
      } as FieldMapping<{ status: string }>,
      initUserUnits: {
        // as mapping field
        key: 'initUserUnits',
        value: (it: any) => it.userUnits,
      } as FieldMapping<any>,
    }
    return m
  }, [t, unitData?.data])
  return { mappings }
}
