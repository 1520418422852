import { Box, Grid } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import { Dialog } from '../dialog'
import { BaseFormLabel, FormTextField } from '../form-fields'

const SubTitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: ${({ theme }) => theme.colors.primary};
`

const HintText = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
`

type Props = {
  assetName: string
  visible: boolean
  onClose(): void
  onSubmit?: (val: string) => void
}

export const WriteOffModal = memo<Props>(
  ({ assetName, visible, onClose, onSubmit }) => {
    const { t } = useTranslation()

    const [reason, setReason] = useState('')

    const handleSelected = useCallback(
      (res: boolean) => {
        if (res && reason.length > 0) {
          onSubmit?.(reason)
        } else {
          onClose()
        }
      },
      [onSubmit, onClose, reason],
    )

    return (
      <Dialog
        dismissable
        title={t('assetDetailsPage.writeOffModal.title')}
        visible={visible}
        onSelected={handleSelected}
      >
        <Grid container gap="20px">
          <Grid item>
            <SubTitle>{assetName}</SubTitle>
          </Grid>
          <Grid item>
            <SubTitle>{t('assetDetailsPage.writeOffModal.popupName')}</SubTitle>
          </Grid>
        </Grid>

        <Box my={3}>
          <HintText>{t('assetDetailsPage.writeOffModal.hint')}</HintText>
        </Box>

        <Grid container gap={4}>
          <Grid item mt={1}>
            <BaseFormLabel>
              {t('assetDetailsPage.writeOffModal.label')}
            </BaseFormLabel>
          </Grid>
          <Grid item flexGrow={1}>
            <FormTextField
              dir="vertical"
              onChange={(value) => setReason(value as string)}
            />
          </Grid>
        </Grid>
      </Dialog>
    )
  },
)

WriteOffModal.displayName = 'WriteOffModal'
