import {
  AssetResponsePayload,
  PatchAssetLinksPayload,
  PatchAssetPayload,
  PostAssetLinksPayload,
} from '@stewards-fas/schemas'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormAction } from '../tabs-form-fields'
import { useDeleteAssetLinks, usePostAssetLinks } from '@stewards-fas/queries'
import { useOverlay } from '@pasteltech/overlay-provider'
import { useApiErrorHandle } from '../../../hooks'
import { useTranslation } from '@pasteltech/i18n-react'

export const useAssetLink = (
  action: FormAction,
  initParentLinks: AssetResponsePayload['parentAssetLinks'] = [],
  initChildLinks: AssetResponsePayload['childAssetLinks'] = [],
) => {
  const { mutateAsync: deleteAssetLinks } = useDeleteAssetLinks()
  const { mutateAsync: postAssetLinks } = usePostAssetLinks()
  const { showSpinner, showSnackbar, hideSpinner } = useOverlay()
  const { standardErrorHandler } = useApiErrorHandle()
  const { t } = useTranslation()

  const [oldParentLinks, setOldParentLinks] = useState(initParentLinks)

  useEffect(() => {
    if (initParentLinks.length !== oldParentLinks.length) {
      setOldParentLinks(initParentLinks)
    }
  }, [initParentLinks, oldParentLinks.length])

  const [deletedParentLinks, setDeletedParentLinks] = useState<
    (PatchAssetLinksPayload & { id: string })[]
  >([])
  const [newParentLinks, setNewParentLinks] = useState<PostAssetLinksPayload[]>(
    [],
  )

  const [oldChildLinks] = useState(initChildLinks)

  const displayParentLinks = useMemo(
    () => [...oldParentLinks, ...newParentLinks],
    [oldParentLinks, newParentLinks],
  )
  const unlinkOldLinks = useCallback(
    async (unlinkAssetId: string) => {
      const linkObj = oldParentLinks.find(
        (link) => link.childId === unlinkAssetId,
      )
      if (!linkObj) return
      const { id, rowVersion, childId, parentId, quantity } = linkObj
      if (action === FormAction.edit) {
        await deleteAssetLinks({ id })
        return
      }
      setDeletedParentLinks((prev) => [
        ...prev,
        { id, rowVersion, childId, parentId, quantity, isDeleted: true },
      ])
      setOldParentLinks((prev) =>
        prev.filter((link) => link.childId !== unlinkAssetId),
      )
    },
    [
      setDeletedParentLinks,
      setOldParentLinks,
      oldParentLinks,
      action,
      deleteAssetLinks,
    ],
  )

  const unlinkNewLinks = useCallback(
    (unlinkAssetId: string) => {
      setNewParentLinks((prev) =>
        prev.filter((link) => link.childId !== unlinkAssetId),
      )
    },
    [setNewParentLinks],
  )

  const handleUnlink = useCallback(
    async (unlinkAssetId: string) => {
      try {
        showSpinner()
        if (oldParentLinks.find((link) => link.childId === unlinkAssetId)) {
          await unlinkOldLinks(unlinkAssetId)
        } else {
          if (newParentLinks.find((link) => link.childId === unlinkAssetId)) {
            unlinkNewLinks(unlinkAssetId)
          }
        }
        showSnackbar({
          content: t('assetActions.unlink.success'),
        })
      } catch (err) {
        standardErrorHandler(err)
      } finally {
        hideSpinner()
      }
    },
    [
      oldParentLinks,
      newParentLinks,
      unlinkNewLinks,
      unlinkOldLinks,
      showSpinner,
      standardErrorHandler,
      hideSpinner,
      t,
      showSnackbar,
    ],
  )

  const handleLink = useCallback(
    async (
      parentId: string,
      asset: AssetResponsePayload,
      linkedQuantity: number,
    ) => {
      try {
        showSpinner()
        if (displayParentLinks.find((link) => link.childId === asset.id)) return
        if (action === FormAction.edit) {
          await postAssetLinks({
            childId: asset.id,
            quantity: linkedQuantity,
            parentId: parentId,
          })
        } else {
          setNewParentLinks([
            ...newParentLinks,
            { childId: asset.id, quantity: linkedQuantity, parentId: parentId },
          ])
        }
        showSnackbar({
          content: t('assetActions.link.success'),
        })
      } catch (err) {
        standardErrorHandler(err)
      } finally {
        hideSpinner()
      }
    },
    [
      displayParentLinks,
      newParentLinks,
      setNewParentLinks,
      action,
      postAssetLinks,
      hideSpinner,
      showSnackbar,
      showSpinner,
      standardErrorHandler,
      t,
    ],
  )

  const displayChildLinks = useMemo(() => oldChildLinks, [oldChildLinks])
  const linkRequest: PatchAssetPayload['parentAssetLinks'] = useMemo(
    () => [...newParentLinks, ...deletedParentLinks],
    [newParentLinks, deletedParentLinks],
  )
  const cantLinkAssetIds = useMemo(
    () => [
      ...initChildLinks.map((it) => it.parentId),
      ...initParentLinks.map((it) => it.childId),
      ...newParentLinks.map((it) => it.childId),
    ],
    [newParentLinks, initParentLinks, initChildLinks],
  )

  return {
    handleUnlink,
    handleLink,
    displayParentLinks,
    displayChildLinks,
    linkRequest,
    cantLinkAssetIds,
  }
}
