import { useTranslation } from '@pasteltech/i18n-react'
import { useOverlay } from '@pasteltech/overlay-provider'
import {
  useAssetList,
  useQueryUserProfile,
  useReimburseBulkAssetMutation,
  useReimburseWithdrawBulkMutation,
} from '@stewards-fas/queries'
import { AssetStatus } from '@stewards-fas/schemas'
import dayjs from 'dayjs'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Dialog, TableList } from '../../../../components'
import {
  useApiErrorHandle,
  useCurrentUserUnits,
  useUserRole,
} from '../../../../hooks'
import { checkIsSupervisorOrAdmin } from '../../../../models'
import { formats } from '../../../../theme'
import {
  AssetManagementWriteOffActionButtons,
  PreviewDialog,
} from '../components'
import { useWriteOffContext } from '../context'

type WriteOffTabPropType = {}
export const WriteOffItemsTab = memo<WriteOffTabPropType>(() => {
  // UI
  const { t } = useTranslation()
  const { showSnackbar } = useOverlay()
  // component state
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([])
  const [isOpenWithdrawDialog, setIsOpenWithdrawingDialog] = useState(false)
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false)
  const {
    writeOffList: selectedRows,
    setWriteOffList,
    setIsViewForm,
    setViewFormId,
    setWriteOffDate,
  } = useWriteOffContext()

  // Data Query
  const { data: userData, isLoading: isLoadingUser } = useQueryUserProfile({
    expand: ['UserUnits'],
  })
  const { role } = useUserRole()
  const userUnitId = useMemo(() => {
    return userData?.userUnits?.[0]?.unitId
  }, [userData?.userUnits])

  const isSupervisorOrAdmin = useMemo(
    () => checkIsSupervisorOrAdmin(role),
    [role],
  )
  const { standardErrorHandler } = useApiErrorHandle()
  const shouldLoadAssetList = useMemo(() => {
    return isSupervisorOrAdmin || userUnitId != null
  }, [isSupervisorOrAdmin, userUnitId])

  // component logic
  const { data: assetList, isLoading: isAssetListLoading } = useAssetList(
    {
      status: [AssetStatus.reimbursePending],
    },
    { enabled: !isLoadingUser && shouldLoadAssetList },
  )
  const { checkPermittedUnitId } = useCurrentUserUnits()
  const assetsData = useMemo(() => {
    if (!assetList?.data.length) return []
    return assetList.data.filter((asset) => checkPermittedUnitId(asset.unitId))
  }, [assetList?.data, checkPermittedUnitId])

  const EMPTY_MSG = useMemo(() => t('common.selectItemFirst'), [t])

  const tableData = useMemo(
    () =>
      assetsData.map((it) => ({
        id: it.id,
        assetNumber: it.assetNo,
        name: it.name,
        brand: it.brand,
        model: it.model,
        number: it.quantity,
        valuedAmount: it.valuedAmount,
        purchasedDateTime: dayjs(it.purchasedDateTime).format(formats.date),
        placement: it.atUnitNotes,
        reason: it.reason,
        status: it.status,
      })),
    [assetsData],
  )

  useEffect(() => {
    const rows =
      assetsData?.filter((it) => selectedRowIds.some((r) => it.id === r)) ?? []
    setWriteOffList(rows)
  }, [assetsData, selectedRowIds, setWriteOffList])

  const { mutateAsync: reimburseAssetsAsync, isLoading: reimbursing } =
    useReimburseBulkAssetMutation()

  const handleApprove = useCallback(async () => {
    if (selectedRows.length > 0) {
      try {
        // request reimburse
        await reimburseAssetsAsync(
          selectedRows.map((it) => ({
            id: it.id,
            reason: '',
            rowVersion: it.rowVersion,
          })),
        )
      } catch (error) {
        console.error('Err-id:768989 ,Error when: request reimburse', error)
        standardErrorHandler(error)
      }
    } else {
      showSnackbar({ content: EMPTY_MSG })
    }
  }, [
    reimburseAssetsAsync,
    selectedRows,
    showSnackbar,
    EMPTY_MSG,
    standardErrorHandler,
  ])

  const { mutateAsync: withdrawMutate } = useReimburseWithdrawBulkMutation()

  const handleWithdraw = useCallback(async () => {
    if (selectedRows.length > 0) {
      setIsOpenWithdrawingDialog(true)
    } else {
      showSnackbar({ content: EMPTY_MSG })
    }
  }, [EMPTY_MSG, selectedRows.length, showSnackbar])

  const confirmWithdraw = useCallback(
    async (isConfirm: boolean) => {
      if (isConfirm) {
        try {
          // withdraw reimbursement
          await withdrawMutate(selectedRows)
        } catch (error) {
          console.error('Err-id:967986 ,Error when: withdraw', error)
          standardErrorHandler(error)
        }
      }
      setIsOpenWithdrawingDialog(false)
    },
    [selectedRows, withdrawMutate, standardErrorHandler],
  )
  const handlePreview = useCallback(async () => {
    if (selectedRows.length == 0) {
      showSnackbar({ content: EMPTY_MSG })
      return
    }
    const firstUnitId = selectedRows[0].unitId
    if (selectedRows.some(({ unitId }) => unitId !== firstUnitId)) {
      showSnackbar({
        content: t('assetManagement.writeOffSection.sameUnitOnlyWarning'),
      })
      return
    }
    setIsOpenPreviewDialog(true)
  }, [EMPTY_MSG, selectedRows, showSnackbar, t])

  const confirmPreview = useCallback(
    (answer: boolean, date: dayjs.Dayjs | null) => {
      if (answer) {
        setIsViewForm(true)
        setViewFormId('')
        setWriteOffDate(date)
      }
      setIsOpenPreviewDialog(false)
    },
    [setIsViewForm, setViewFormId, setWriteOffDate],
  )

  const writeOffHeaders = useMemo(
    () => [
      t('assetManagement.writeOffSection.TableHeaders.assetNo'),
      t('assetManagement.writeOffSection.TableHeaders.name'),
      t('assetManagement.writeOffSection.TableHeaders.brand'),
      t('assetManagement.writeOffSection.TableHeaders.model'),
      t('assetManagement.writeOffSection.TableHeaders.quantity'),
      t('assetManagement.writeOffSection.TableHeaders.valuedAmount'),
      t('assetManagement.writeOffSection.TableHeaders.purchaseDay'),
      t('assetManagement.writeOffSection.TableHeaders.placement'),
      t('assetManagement.writeOffSection.TableHeaders.reason'),
      t('assetManagement.writeOffSection.TableHeaders.approveState'),
    ],
    [t],
  )

  return (
    <>
      <AssetManagementWriteOffActionButtons
        handleApprove={handleApprove}
        handleWithdraw={handleWithdraw}
        handlePreview={handlePreview}
        isDisableApprove={reimbursing}
        isDisableWithdraw={isOpenWithdrawDialog}
        isDisablePreview={false}
      />
      <TableList
        headers={writeOffHeaders}
        rows={tableData}
        selectedRows={selectedRowIds}
        setSelectedRows={setSelectedRowIds}
        isLoading={isLoadingUser || (shouldLoadAssetList && isAssetListLoading)}
      />
      <Dialog
        visible={isOpenWithdrawDialog}
        title={t('assetManagement.writeOffSection.actions.withdraw')}
        onSelected={confirmWithdraw}
      >
        {t('assetManagement.writeOffSection.modals.withdraw')}
      </Dialog>
      <PreviewDialog
        isOpen={isOpenPreviewDialog}
        previewRows={selectedRows}
        onDateSelected={confirmPreview}
      />
    </>
  )
})
WriteOffItemsTab.displayName = 'WriteOffItemsTab'
