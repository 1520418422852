import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import {
  useAssetTypeList,
  useFundingList,
  useUnitList,
  useUserList,
} from '@stewards-fas/queries'
import {
  AssetStatus,
  AssetTypeResponsePayload,
  Report,
  ReportFunding,
  UnitResponsePayload,
} from '@stewards-fas/schemas'
import dayjs from 'dayjs'
import { memo, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { StewardsHeaderLogoLarge } from '../../icons'
import { ReportGeneratorSchemaModel } from '../../models'
import { formats } from '../../theme'
import { uniq } from 'lodash'
import { compareString } from '../../utilities'
import { ReportTableHeader } from './report-table-header'

interface DisplayProps {
  onDisplay?: () => void
  filterValue?: ReportGeneratorSchemaModel
  filterValueNumbmer: number
  reportList: Report[]
}

const Root = styled(TableContainer)`
  margin-bottom: 30px;
  @media print {
    zoom: 0.666;
    @page {
      size: A4 landscape;
    }
  }
`

const Logo = styled(StewardsHeaderLogoLarge)`
  width: 90px;
  height: 90px;
  filter: blur(0);
`

const ReportContainer = styled(Table)`
  display: flex;
  padding: 0 64px;
  flex-direction: column;
  gap: 20px;
`

const ReportHeading = styled(TableRow)`
  display: flex;
  width: 100%;
`

const ReportTitle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`

const ReportTitleContent = styled(Typography)`
  display: flex;
  font-weight: 700;
  font-size: 28px;
`

const ReportContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  margin: 10px 0;
`

const TableIntro = styled(TableRow)`
  display: flex;
  gap: 10px;
  flex-direction: column;
`

const TableIntroContent = styled(Typography)``

const StyledTableCell = styled(TableCell)`
  @media only print {
    font-size: 10px;
    padding: 4px;
    page-break-inside: avoid;
    page-break-after: auto;
  }
  padding: 10px 4px;
`
type DataPerUnit = {
  assetType: AssetTypeResponsePayload
  dataPerUnitPerAssetType: Report[]
}
type DataWithUnit = {
  unit: UnitResponsePayload
  dataPerUnit: DataPerUnit[]
}
type DisplayDataType = DataWithUnit[]

export const ReportDisplay = memo(
  ({ filterValue, filterValueNumbmer, reportList }: DisplayProps) => {
    const { t } = useTranslation()
    const { data: unitData } = useUnitList()
    const { data: assetTypeData } = useAssetTypeList()
    const allUnits = unitData?.data
    const allAssetTypes = assetTypeData?.data

    const displayReportList = useMemo(() => {
      return reportList
    }, [reportList])

    const getSum = useCallback(
      (unitId?: string, assetTypeId?: string): string => {
        const filteredUnit = unitId
          ? displayReportList.filter((report) => report.unitId === unitId)
          : displayReportList
        const filteredAssetType = assetTypeId
          ? filteredUnit.filter((report) => report.assetTypeId === assetTypeId)
          : filteredUnit
        const sum = filteredAssetType.reduce(
          (acc, it) => acc + it.valuedAmount,
          0,
        )
        return sum.toFixed(2)
      },
      [displayReportList],
    )

    // get all Units appeared in displayReportList
    const displayUnitList: UnitResponsePayload[] = useMemo(() => {
      const uniqIds = uniq(displayReportList.map((r) => r.unitId))
      const uniqUnits = uniqIds
        .map((id) => allUnits?.find((u) => u?.id === id))
        .filter(Boolean) as UnitResponsePayload[]
      const sorted = uniqUnits.sort((a, b) => compareString(a?.code, b?.code))
      return sorted
    }, [displayReportList, allUnits])

    const displayData: DisplayDataType = useMemo(() => {
      return displayUnitList.map((unit) => {
        const dataAllTypes = displayReportList.filter(
          (asset) => asset.unitId === unit?.id,
        )
        const uniqTypeIds = uniq(dataAllTypes.map((d) => d.assetTypeId))
        const uniqAssetTypes = uniqTypeIds
          .map((id) => allAssetTypes?.find((aType) => aType.id === id))
          .sort((ta, tb) => compareString(ta?.code, tb?.code))
          .filter(Boolean) as AssetTypeResponsePayload[]
        const dataOfUnit: DataWithUnit = {
          unit,
          dataPerUnit: uniqAssetTypes.map((aType) => {
            const data: Report[] = displayReportList
              .filter(
                (asset) =>
                  asset.unitId === unit.id && asset.assetTypeId === aType.id,
              )
              .sort((a, b) => compareString(a.assetNo, b.assetNo))
            return {
              assetType: aType,
              dataPerUnitPerAssetType: data,
            }
          }),
        }
        return dataOfUnit
      })
    }, [allAssetTypes, displayReportList, displayUnitList])

    const { data: unitList } = useUnitList()

    const getUnit = useCallback(
      (unitId: string): string => {
        const unit = unitList?.data?.filter((it) => it.id === unitId)[0]
        return `${unit?.code} ${unit?.nameCN}`
      },
      [unitList],
    )

    const { data: userList } = useUserList()

    const getUser = useCallback(
      (userId: string): string => {
        return (
          userList?.data?.filter((it) => it.id === userId)[0]?.username ?? ''
        )
      },
      [userList],
    )

    const getUnitSupervisor = useCallback(
      (unitId: string): string => {
        return getUser(
          unitList?.data?.find((it) => it.id === unitId)?.supervisorUserId ??
            '',
        )
      },
      [getUser, unitList?.data],
    )

    const { data: fundingList } = useFundingList()

    const getFundings = useCallback(
      (fundings: ReportFunding[]): string[] => {
        const fundingIds = fundings.map((it) => it.fundingId)
        const fundingNames: string[] =
          fundingList?.data?.reduce((names, it) => {
            if (fundingIds.includes(it.id)) {
              names.push(it.name ?? '')
            }
            return names
          }, [] as string[]) ?? []
        return fundingNames
      },
      [fundingList?.data],
    )

    const REIMBURSED_RECORD = 'REIMBURSED_RECORD'
    const TRANSITION = 'TRANSITION'

    return (
      <Root>
        <ReportContainer id="Table2XLSX">
          <ReportHeading>
            <Logo />
            <ReportTitle>
              <ReportTitleContent>
                {t('reportPage.titleLine1')}
              </ReportTitleContent>
              <ReportTitleContent>
                {t('reportPage.titleLine2')}
              </ReportTitleContent>
            </ReportTitle>
          </ReportHeading>
          <TableRow />
          {displayData.map(({ unit, dataPerUnit: data }) => {
            return (
              <div key={'table-' + unit.id}>
                <TableIntro>
                  <TableIntroContent>
                    {t('reportPage.content.unit')} :
                    {`${unit.code} ${unit.nameCN}`}
                  </TableIntroContent>
                </TableIntro>
                {data.map(({ assetType, dataPerUnitPerAssetType }) => {
                  return (
                    <ReportContent key={assetType.id}>
                      <TableIntro>
                        <TableIntroContent>
                          {t('reportPage.content.type')} :{' '}
                          {assetType.typeCN ?? ''}
                        </TableIntroContent>
                      </TableIntro>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <ReportTableHeader filterValue={filterValue} />
                          </TableHead>
                          {dataPerUnitPerAssetType.map((it, i) => {
                            return (
                              <TableBody key={it?.id}>
                                <TableRow>
                                  <StyledTableCell>{i + 1}</StyledTableCell>
                                  <StyledTableCell>
                                    {it.assetNo}
                                  </StyledTableCell>
                                  {filterValue?.name && (
                                    <StyledTableCell>
                                      {it?.name}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.brand && (
                                    <StyledTableCell>
                                      {it.brand}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.model && (
                                    <StyledTableCell>
                                      {it.model}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.placement && (
                                    <StyledTableCell>
                                      {it.atUnitNotes}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.buyDate && (
                                    <StyledTableCell>
                                      {dayjs(it.purchasedDateTime).format(
                                        formats.date,
                                      )}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.quantity && (
                                    <StyledTableCell>
                                      {it.quantity}
                                    </StyledTableCell>
                                  )}

                                  {filterValue?.serialNumber && (
                                    <StyledTableCell>
                                      {it.serialNo}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.provider && (
                                    <StyledTableCell>
                                      {it.providerName}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.invoiceNo && (
                                    <StyledTableCell>
                                      {it.invoiceNo}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.warrantyProvider && (
                                    <StyledTableCell>
                                      {it.warrantyProvider}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.warrantyPeriod && (
                                    <StyledTableCell>
                                      {it.warrantyPeriod}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.sourceOfFundsContent && (
                                    <StyledTableCell>
                                      {getFundings(it.assetFundings).join(', ')}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.remarks && (
                                    <StyledTableCell>
                                      {it.remarks}
                                    </StyledTableCell>
                                  )}

                                  {filterValue?.writeOffDate && (
                                    <StyledTableCell>
                                      {it.latestAssetRequestAsset?.assetRequest
                                        ?.type === REIMBURSED_RECORD &&
                                      it.latestAssetRequestAsset?.assetRequest
                                        .reimburseStatus === 'APPROVED' ? (
                                        <div>
                                          {dayjs(
                                            it.latestAssetRequestAsset
                                              ?.assetRequest
                                              ?.lastModifiedDateTime,
                                          ).format(formats.date)}
                                        </div>
                                      ) : (
                                        <div>--</div>
                                      )}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.writeOffReason && (
                                    <StyledTableCell>
                                      {it.latestAssetRequestAsset?.assetRequest
                                        ?.type === REIMBURSED_RECORD &&
                                      it.latestAssetRequestAsset?.assetRequest
                                        .reimburseStatus === 'APPROVED' ? (
                                        <div>{it.reason}</div>
                                      ) : (
                                        <div>--</div>
                                      )}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.writeOffCode && (
                                    <StyledTableCell>
                                      {it.latestAssetRequestAsset?.assetRequest
                                        ?.type === REIMBURSED_RECORD ? (
                                        <div>
                                          {
                                            it.latestAssetRequestAsset
                                              ?.assetRequest?.reimburseNo
                                          }
                                        </div>
                                      ) : (
                                        <div>--</div>
                                      )}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.transferDate && (
                                    <StyledTableCell>
                                      {it.latestAssetRequestAsset?.assetRequest
                                        ?.type === TRANSITION ? (
                                        <div>
                                          {dayjs(
                                            it.latestAssetRequestAsset
                                              ?.assetRequest?.transitDateTime,
                                          ).format(formats.date)}
                                        </div>
                                      ) : (
                                        <div>--</div>
                                      )}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.transferToUnit && (
                                    <StyledTableCell>
                                      {it.latestAssetRequestAsset?.assetRequest
                                        ?.type === TRANSITION ? (
                                        <div>
                                          {getUnit(
                                            it.latestAssetRequestAsset
                                              ?.assetRequest?.toUnitId,
                                          )}
                                        </div>
                                      ) : (
                                        <div>--</div>
                                      )}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.transferFromUnit && (
                                    <StyledTableCell>
                                      {it.latestAssetRequestAsset?.assetRequest
                                        ?.type === TRANSITION ? (
                                        <div>
                                          {getUnit(
                                            it.latestAssetRequestAsset
                                              ?.assetRequest?.fromUnitId,
                                          )}
                                        </div>
                                      ) : (
                                        <div>--</div>
                                      )}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.transferReason && (
                                    <StyledTableCell>
                                      {it.latestAssetRequestAsset?.assetRequest
                                        ?.type === TRANSITION ? (
                                        <div>
                                          {
                                            it.latestAssetRequestAsset
                                              ?.assetRequest?.reason
                                          }
                                        </div>
                                      ) : (
                                        <div>--</div>
                                      )}
                                    </StyledTableCell>
                                  )}

                                  {filterValue?.formFiller && (
                                    <StyledTableCell>
                                      {getUser(it.formFillUserId)}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.sectionSuperviser && (
                                    <StyledTableCell>
                                      {getUnitSupervisor(it.unitId)}
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.fillDate && (
                                    <StyledTableCell>
                                      <div>
                                        {dayjs(it.createdDateTime).format(
                                          formats.date,
                                        )}
                                      </div>
                                    </StyledTableCell>
                                  )}
                                  {filterValue?.approvalDate && (
                                    <StyledTableCell>
                                      {it.status ===
                                      AssetStatus.assetApproved ? (
                                        <div>
                                          {dayjs(
                                            it.lastModifiedDateTime,
                                          ).format(formats.date)}
                                        </div>
                                      ) : (
                                        <div>--</div>
                                      )}
                                    </StyledTableCell>
                                  )}

                                  {filterValue?.amount && (
                                    <StyledTableCell>
                                      {it.valuedAmount}
                                    </StyledTableCell>
                                  )}
                                </TableRow>
                              </TableBody>
                            )
                          })}
                          {filterValue?.amount && (
                            <TableRow>
                              {Array(filterValueNumbmer + 1).fill(
                                <StyledTableCell />,
                              )}
                              <StyledTableCell>
                                {t('reportPage.content.showTotal', {
                                  total: t('reportPage.content.smallTotal'),
                                  sum: getSum(unit.id, assetType.id),
                                })}
                              </StyledTableCell>
                            </TableRow>
                          )}
                          <TableRow />
                        </Table>
                      </TableContainer>
                    </ReportContent>
                  )
                })}
              </div>
            )
          })}
          {filterValue?.amount && (
            <TableBody>
              <TableRow>
                {Array(filterValueNumbmer + 1).fill(<StyledTableCell />)}
                <StyledTableCell align="right" sx={{ width: 1 }}>
                  {t('reportPage.content.showTotal', {
                    total: t('reportPage.content.total'),
                    sum: getSum(),
                  })}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          )}
        </ReportContainer>
      </Root>
    )
  },
)

ReportDisplay.displayName = 'ReportDisplay'
