import { ApiGateway } from '@pasteltech/api-gateway'
import { PostResourcesPayload } from '@stewards-fas/schemas'
import { z } from 'zod'

const PATH = '/resources'

export function postCreateBlobStorageContainer() {
  return ApiGateway.primary.post(`${PATH}/create-container`, {
    request: z.any(),
    response: z.any(),
  })
}

export function getBlobDownloadUrl(id: string) {
  return ApiGateway.primary.get(`${PATH}/${id}/url`, {
    request: z.any(),
    response: z.any(),
  })
}

export function postResources(payload: PostResourcesPayload) {
  return ApiGateway.primary.post(
    `${PATH}`,
    { request: z.any(), response: z.any() },
    {
      body: payload,
    },
  )
}
