import { getCurrentUserProfile } from '@stewards-fas/apis'
import { withEnvironment } from '@stewards-fas/model-core'
import { Instance, flow, types } from 'mobx-state-tree'
import { UserRequestPayload, UserResponsePayload } from '../../../schemas'
import { UserSessionInfoModel } from './user-session-info.model'
import { User, UserModel } from './user.model'

export const UserProfileStoreModel = types
  .model('UserProfileStore')
  .props({
    userSessionInfo: types.optional(UserSessionInfoModel, { accessToken: '' }),
    userProfile: types.maybe(UserModel),
  })
  .extend(withEnvironment)
  .actions((self) => {
    const action = {
      setAuthToken: (accessToken: string | undefined) => {
        const session = UserSessionInfoModel.create({
          accessToken: accessToken ?? '',
        })
        self.userSessionInfo = session
      },
      getCurrentUserProfile: flow(function* getCurrentUserProfileAction(
        param?: UserRequestPayload,
      ): Generator<any, User, UserResponsePayload> {
        const payload = yield getCurrentUserProfile(param)
        return action.upsertUserProfile(payload)
      }),
      upsertUserProfile(inPayload: UserResponsePayload) {
        self.userProfile = UserModel.create({
          id: inPayload.id,
          userName: inPayload.username,
          email: inPayload.email,
          position: inPayload.position,
          roleId: inPayload.roleId,
        })
        return self.userProfile
      },
    }
    return action
  })

export type UserProfileStore = Instance<typeof UserProfileStoreModel>
