import { ApiGateway } from '@pasteltech/api-gateway'
import { ReportListRequestPayload } from '@stewards-fas/schemas'
import { z } from 'zod'

const PATH = '/assets/report'

export function getReportList(param?: ReportListRequestPayload) {
  return ApiGateway.primary.get(
    `${PATH}`,
    {
      // TODO: update request and response schema
      request: z.any(),
      response: z.any(),
    },
    {
      param,
    },
  )
}
