import { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { SVGComponent } from '../../icons'
import { BaseButton } from './base-button'
const StyledButton = styled(BaseButton)`
  border: none;
  &:hover {
    border: none;
  }
`
type Props = {
  url?: string
  icon?: SVGComponent
  text?: string
  className?: string
  onClick?: () => void
  disabled?: boolean
}
export const TransitButton = memo<Props>(
  ({ url, icon, text, className = '', onClick, disabled }) => {
    const navigate = useNavigate()
    return (
      <StyledButton
        className={className}
        icon={icon}
        onClick={() => {
          if (url) {
            navigate(url)
          } else {
            onClick?.()
          }
        }}
        paddingHorizontal={15}
        paddingVertical={5}
        text={text}
        disabled={disabled}
      />
    )
  },
)

TransitButton.displayName = 'TransitButton'
