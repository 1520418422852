import { MenuItem, Select, SelectProps } from '@mui/material'
import { useFormikContext } from 'formik'
import { memo, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { FormHelperText } from '../form-fields'
import { TFuncKey, useTranslation } from '@pasteltech/i18n-react'

export type InputOption = {
  value: string
  label: string
}

export type FormikSelectFieldPropType = {
  name: string
  options?: InputOption[]
  className?: string
} & SelectProps

const StyledSelect = styled(Select)`
  width: 100%;
`
/**
 * Map formik data to mui select, stylable
 * */
export const FormikSelectField = memo<FormikSelectFieldPropType>(
  ({ name, options, className, ...props }) => {
    const { t } = useTranslation()
    const formik = useFormikContext<any>()
    useEffect(() => {
      if (Object.keys(formik?.values).includes(name)) {
        console.error('Error <023818>: Formik Context has no field: ', name)
      }
    }, [formik?.values, name])

    const isError = useMemo(
      () => formik.touched?.[name] && Boolean(formik.errors?.[name]),
      [formik, name],
    )
    const helperText = useMemo(() => {
      if (!isError) return undefined
      return t([
        formik.errors?.[name] as TFuncKey,
        'common.invalidInput',
      ]) as string
    }, [t, isError, formik, name])

    return (
      <>
        <StyledSelect
          className={className}
          size="small"
          name={name}
          value={formik.values?.[name] ?? (props.multiple ? [] : '')}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={isError}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
          {options?.map((opt) => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </StyledSelect>
        <FormHelperText>{helperText}</FormHelperText>
      </>
    )
  },
)
FormikSelectField.displayName = 'FormikSelectField'

/**
 * Options might be exposed for futher customization
 * */
