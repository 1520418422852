import { ApiGateway } from '@pasteltech/api-gateway'
import {
  PatchAssetLinksPayload,
  PostAssetLinksPayload,
} from '@stewards-fas/schemas'
import { z } from 'zod'

const PATH = '/asset-links'

export function postAssetLinks(param?: PostAssetLinksPayload) {
  return ApiGateway.primary.post(
    `${PATH}`,
    { request: z.any(), response: z.any() },
    {
      body: param,
    },
  )
}

export function patchAssetLinks(id: string, param?: PatchAssetLinksPayload) {
  return ApiGateway.primary.patch(
    `${PATH}/${id}`,
    { request: z.any(), response: z.any() },
    {
      body: param,
    },
  )
}

export function deleteAssetLinks(id: string) {
  return ApiGateway.primary.delete(`${PATH}/${id}`, {
    request: z.any(),
    response: z.any(),
  })
}
