import { Box } from '@mui/material'
import { memo, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { BaseFormLabel } from './base-form-label'

export type BaseFormDirection = 'horizontal' | 'vertical'

export type BaseFormFieldProps = {
  label?: string
  dir?: BaseFormDirection
  templateColumns?: string
  marginTop?: number | string
  required?: boolean
}

type Props = PropsWithChildren & BaseFormFieldProps

const FieldWrapper = styled(Box)<{
  $templateColumns?: string
  $marginTop?: number | string
}>`
  display: grid;
  grid-template-columns: ${({ dir, $templateColumns }) =>
    dir === 'horizontal' ? $templateColumns ?? '1fr 1fr' : '1fr'};
  grid-template-rows: ${({ dir, $templateColumns }) =>
    dir === 'vertical' ? $templateColumns ?? '1fr 1fr' : '1fr'};
  margin-top: ${({ $marginTop }) => $marginTop ?? 20}px;
`

const StyledTextWrapper = styled(Box)`
  color: ${({ theme }) => theme.colors.chestnut};
`
export const BaseFormField = memo<Props>(
  ({
    label,
    children = null,
    dir = 'horizontal',
    templateColumns,
    marginTop,
    required = false,
  }) => {
    return (
      <FieldWrapper
        dir={dir}
        $templateColumns={templateColumns}
        $marginTop={marginTop}
      >
        {label != null && (
          <BaseFormLabel>
            {`${label}:`}
            <StyledTextWrapper>{required && '*'}</StyledTextWrapper>
          </BaseFormLabel>
        )}

        <Box>{children}</Box>
      </FieldWrapper>
    )
  },
)

BaseFormField.displayName = 'BaseFormField'
