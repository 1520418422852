import { PropsWithChildren, memo } from 'react'
import { LoginLoading } from '../components'
import { useRestoreSession } from '../services'

export const RestoreSectionContainer = memo<PropsWithChildren<{}>>(
  ({ children }) => {
    const { isRestoring, result } = useRestoreSession()

    if (isRestoring && result == null) {
      return <LoginLoading />
    }

    return <>{children}</>
  },
)
RestoreSectionContainer.displayName = 'RestoreSectionContainer'
