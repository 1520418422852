import { getRoleList } from '@stewards-fas/apis'
import {
  RoleListRequestPayload,
  RoleListResponsePayload,
} from '@stewards-fas/schemas'
import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'roles'

export function useRoleList(
  param?: RoleListRequestPayload,
  opts?: UseQueryOptions<unknown, unknown, RoleListResponsePayload>,
) {
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => getRoleList(param),
    opts,
  )
}
