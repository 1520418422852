import {
  AuthenticationResult,
  AuthError,
  InteractionRequiredAuthError,
  IPublicClientApplication,
  PopupRequest,
  SilentRequest,
} from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { ApiGateway } from '@pasteltech/api-gateway'
import { useInvalidateQueries } from '@stewards-fas/queries'
import { useCallback, useEffect, useState } from 'react'
import { env } from '../../utilities'

export const loginRequest: SilentRequest | PopupRequest = {
  scopes: [`api://${env.aadServerClientId}/web-access`],
}

export function handleLogin(
  instance: IPublicClientApplication,
  response: AuthenticationResult,
  request: SilentRequest | PopupRequest,
) {
  if (response.account == null) {
    throw new Error('response with empty account')
  }
  ApiGateway.primary.setAuthorizationHeader(`Bearer ${response.accessToken}`)
  instance.setActiveAccount(response.account)

  ApiGateway.primary.setUnauthorizedHandler({
    handler: async () => {
      try {
        const session = await instance.acquireTokenSilent(request)
        return `Bearer ${session.accessToken}`
      } catch (err) {
        // refresh fail due to refresh token expired, do interactive login
        if (err instanceof InteractionRequiredAuthError) {
          const updatedSession = await instance
            .acquireTokenPopup(request)
            .catch(async (error: AuthError) => {
              throw new Error(
                `interactive login failed (${error.errorCode}): ${error.errorMessage}`,
              )
            })
          return `Bearer ${updatedSession.accessToken}`
        }
        return null
      }
    },
    delayTimeout: true,
  })
}

export function useLogin() {
  const { instance } = useMsal()

  return useCallback(
    async (type?: 'redirect' | 'popup') => {
      const request = loginRequest

      let result: AuthenticationResult

      try {
        result = await instance.acquireTokenSilent(request)
      } catch {
        if (type !== 'popup') {
          await instance.acquireTokenRedirect(request)
          return
        }
        result = await instance.acquireTokenPopup(request)
      }
      handleLogin(instance, result, request)
    },
    [instance],
  )
}

export function useRestoreSession() {
  const { instance } = useMsal()

  const [result, setResult] = useState<boolean | undefined>(undefined)
  const [isRestoring, setIsRestoring] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      try {
        const session = await instance.acquireTokenSilent(loginRequest)
        handleLogin(instance, session, loginRequest)
        setResult(true)
      } catch (error) {
        setResult(false)
        instance.acquireTokenRedirect(loginRequest)
      } finally {
        setIsRestoring(false)
      }
    })()
  }, [instance])

  return { result, isRestoring }
}

export function useLogout() {
  const { instance } = useMsal()
  const invalidateQueries = useInvalidateQueries()

  return useCallback(() => {
    instance.logoutRedirect()
    invalidateQueries()
  }, [instance, invalidateQueries])
}
