import { ApiGateway } from '@pasteltech/api-gateway'
import {
  AssetRequestApproveBulkPayload,
  AssetRequestBulkWithdrawPayload,
  AssetRequestReimburseRecordPayload,
  AssetRequestWithdrawPayload,
  AssetRequestsListRequestPayload,
} from '@stewards-fas/schemas'
import { z } from 'zod'

const PATH = '/asset-requests'

export function getAssetRequest(
  id: string,
  param?: AssetRequestsListRequestPayload,
) {
  return ApiGateway.primary.get(
    `${PATH}/${id}`,
    {
      // TODO: update request and response schema
      request: z.any(),
      response: z.any(),
    },
    {
      param,
    },
  )
}

export function getAssetRequestList(param?: AssetRequestsListRequestPayload) {
  return ApiGateway.primary.get(
    `${PATH}`,
    {
      // TODO: update request and response schema
      request: z.any(),
      response: z.any(),
    },
    {
      param,
    },
  )
}

export function postApproveBulkAssetRequest(
  payload: AssetRequestApproveBulkPayload,
) {
  return ApiGateway.primary.post(
    `${PATH}/approve/bulk`,
    {
      // TODO: update request and response schema
      request: z.any(),
      response: z.any(),
    },
    {
      body: payload,
    },
  )
}

export function postReimburseRecordRequest(
  payload: AssetRequestReimburseRecordPayload,
) {
  return ApiGateway.primary.post(
    `${PATH}/reimburse-record`,
    {
      // TODO: update request and response schema
      request: z.any(),
      response: z.any(),
    },

    {
      body: payload,
    },
  )
}

export function withdrawAssetRequest(
  id: string,
  payload: AssetRequestWithdrawPayload,
) {
  return ApiGateway.primary.post(
    `${PATH}/${id}/withdraw`,
    {
      // TODO: update request and response schema
      request: z.any(),
      response: z.any(),
    },
    {
      body: payload,
    },
  )
}

export function withdrawBulkAssetRequest(
  payload: AssetRequestBulkWithdrawPayload,
) {
  return ApiGateway.primary.post(
    `${PATH}/withdraw/bulk`,
    {
      // TODO: update request and response schema
      request: z.any(),
      response: z.any(),
    },
    {
      body: payload,
    },
  )
}
