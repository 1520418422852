import { memo } from 'react'
import { useTranslation } from '@pasteltech/i18n-react'
import { AppBar } from '../../components/appbar'
import styled from 'styled-components'

type Props = {}
const BigMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 24px;
  font-weight: 700;
`

export const UnauthorizedPage = memo<Props>(() => {
  const { t } = useTranslation()

  return (
    <>
      <AppBar handleMenuOnClick={() => {}} isUnauthorized />
      <BigMessage>{t('error.forbidden.content')}</BigMessage>
    </>
  )
})

UnauthorizedPage.displayName = 'ProtectedRoute'
