// import { withRootStore, withUpdate } from '@stewards-fas/model-core'
import { Instance, types } from 'mobx-state-tree'

export const UserModel = types.model('UserModel').props({
  id: types.string,
  userName: types.string,
  email: types.string,
  position: types.string,
  roleId: types.string,
})
// .extend(withUpdate)
// .extend(withRootStore)
// .views((self) => ({
// }))

export type User = Instance<typeof UserModel>
