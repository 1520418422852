import {
  ApiFormattedError,
  approveAsset,
  approveBulkAsset,
  deleteAssetBulk,
  getAsset,
  getAssetList,
  patchAsset,
  postAsset,
  postAssetReimburseWithdrawBulk,
  postReimburseBulkAsset,
  reimburseAsset,
  rejectAsset,
  rejectBulkAsset,
  transitAsset,
} from '@stewards-fas/apis'
import {
  ApproveAssetBulkRequestPayload,
  ApproveAssetRequestPayload,
  ApproveAssetResponsePayload,
  AssetListRequestPayload,
  AssetListResponsePayload,
  AssetReimburseBulkPayload,
  AssetReimburseWithdrawBulkPayload,
  AssetRequestPayload,
  AssetResponsePayload,
  DeleteAssetBulkRequestPayload,
  PatchAssetPayload,
  PostAssetPayload,
  ReimburseAssetRequestPayload,
  ReimburseAssetResponsePayload,
  RejectAssetPayload,
  RejectBulkAssetPayload,
  TransitAssetRequestPayload,
  TransitAssetResponsePayload,
} from '@stewards-fas/schemas'
import {
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import { useOptsOnSuccessInvalidate } from './helper'

const QUERY_CACHE_KEY_ROOT = 'assets'
const EMPTY_PAYLOAD = 'empty-payload'

export const QUERY_ROOT_KEY_ASSETS = QUERY_CACHE_KEY_ROOT

export function useAssetList(
  param?: AssetListRequestPayload,
  opts?: UseQueryOptions<unknown, unknown, AssetListResponsePayload>,
) {
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    async () => {
      const res = await getAssetList(param)
      if (res == null) throw new Error(EMPTY_PAYLOAD)
      return res
    },
    opts,
  )
}

export function useGetAsset(
  id: string,
  param?: AssetRequestPayload,
  opts?: UseQueryOptions<AssetResponsePayload>,
) {
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, id, param] as QueryKey,
    async () => {
      const res = await getAsset(id, param)
      if (res == null) throw new Error(EMPTY_PAYLOAD)
      return res
    },
    opts,
  )
}

export function usePostAsset(
  opts?: UseMutationOptions<
    AssetResponsePayload,
    ApiFormattedError,
    PostAssetPayload & { totalPieces: number }
  >,
) {
  const optsWithInvalidate = useOptsOnSuccessInvalidate(
    [QUERY_CACHE_KEY_ROOT],
    opts,
  )
  return useMutation(async ({ totalPieces, ...payload }) => {
    const res = await postAsset(totalPieces, payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, optsWithInvalidate)
}

export function usePatchAsset(
  id: string,
  opts?: UseMutationOptions<
    AssetResponsePayload,
    ApiFormattedError,
    PatchAssetPayload
  >,
) {
  const optsWithInvalidate = useOptsOnSuccessInvalidate(
    [QUERY_CACHE_KEY_ROOT],
    opts,
  )
  return useMutation(async (payload) => {
    const res = await patchAsset(id, payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, optsWithInvalidate)
}

export function useReimburseBulkAssetMutation(
  opts?: UseMutationOptions<
    AssetResponsePayload[],
    ApiFormattedError,
    AssetReimburseBulkPayload
  >,
) {
  return useMutation(async (payload) => {
    const res = await postReimburseBulkAsset(payload)

    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, opts)
}

export function useReimburseWithdrawBulkMutation(
  opts?: UseMutationOptions<
    AssetResponsePayload[],
    ApiFormattedError,
    AssetReimburseWithdrawBulkPayload
  >,
) {
  const optsWithInvalidate = useOptsOnSuccessInvalidate(
    [QUERY_CACHE_KEY_ROOT],
    opts,
  )
  return useMutation(async (payload) => {
    const res = await postAssetReimburseWithdrawBulk(payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, optsWithInvalidate)
}

export function useApproveAsset(
  id: string,
  opts?: UseMutationOptions<
    ApproveAssetResponsePayload,
    ApiFormattedError,
    ApproveAssetRequestPayload
  >,
) {
  return useMutation(async (payload) => {
    const res = await approveAsset(id, payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, opts)
}

export function useApproveBulkAssetMutation(
  opts?: UseMutationOptions<
    AssetResponsePayload[],
    ApiFormattedError,
    ApproveAssetBulkRequestPayload
  >,
) {
  const optsWithInvalidate = useOptsOnSuccessInvalidate(
    [QUERY_CACHE_KEY_ROOT],
    opts,
  )
  return useMutation(async (payload) => {
    const res = await approveBulkAsset(payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, optsWithInvalidate)
}

export function useReimburseAsset(
  id: string,
  opts?: UseMutationOptions<
    ReimburseAssetResponsePayload,
    ApiFormattedError,
    ReimburseAssetRequestPayload
  >,
) {
  return useMutation(async (payload) => {
    const res = await reimburseAsset(id, payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, opts)
}

export function useTransitAsset(
  id: string,
  opts?: UseMutationOptions<
    TransitAssetResponsePayload,
    ApiFormattedError,
    TransitAssetRequestPayload
  >,
) {
  const optsWithInvalidate = useOptsOnSuccessInvalidate(
    [QUERY_CACHE_KEY_ROOT],
    opts,
  )
  return useMutation(async (payload) => {
    const res = await transitAsset(id, payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, optsWithInvalidate)
}

export function useDeleteAssetBulk(
  opts?: UseMutationOptions<
    AssetResponsePayload[],
    ApiFormattedError,
    DeleteAssetBulkRequestPayload
  >,
) {
  const optsWithInvalidate = useOptsOnSuccessInvalidate(
    [QUERY_CACHE_KEY_ROOT],
    opts,
  )
  return useMutation(async (payload) => {
    const res = await deleteAssetBulk(payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, optsWithInvalidate)
}

export function useRejectAsset(
  opts?: UseMutationOptions<unknown, ApiFormattedError, RejectAssetPayload>,
) {
  const optsWithInvalidate = useOptsOnSuccessInvalidate(
    [QUERY_CACHE_KEY_ROOT],
    opts,
  )
  return useMutation(async (payload) => {
    const res = await rejectAsset(payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, optsWithInvalidate)
}

export function useRejectBulkAsset(
  opts?: UseMutationOptions<unknown, ApiFormattedError, RejectBulkAssetPayload>,
) {
  const optsWithInvalidate = useOptsOnSuccessInvalidate(
    [QUERY_CACHE_KEY_ROOT],
    opts,
  )
  return useMutation(async (payload) => {
    const res = await rejectBulkAsset(payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, optsWithInvalidate)
}
