import { useMemo } from 'react'
import { FieldMapping } from '../../../../components'

export function useStandardFieldMappings() {
  const mappings = useMemo(
    () => ({
      // id is useful for most data
      id: {
        key: 'id',
        value: (it) => it.id,
        display: (it) => it.id,
        inputType: undefined,
        label: undefined,
      } as FieldMapping<{ id?: string }>, // these required fields will be checked
      rowVersion: {
        key: 'rowVersion',
        value: (it) => it.rowVersion,
        display: undefined,
        inputType: undefined,
        label: undefined,
      } as FieldMapping<{ rowVersion?: string }>,
    }),
    [],
  )
  return { mappings }
}
