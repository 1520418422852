import { memo } from 'react'
import { FormikTextAreaField } from './formik-text-area-field'
import { FormikTextField } from './formik-text-field'
import { RoleSelectionField } from './role-field'
import { UnitSelectionField } from './unit-field'
import { UserSelectionField } from './user-field'
import { Role } from '../../models'
import { useTranslation } from '@pasteltech/i18n-react'
import { StatusSelectionField } from './status-field'

export enum InputType {
  selectUser,
  selectSuperVisorAndAdmin,
  selectUnit,
  selectRole,
  selectUnitStatus,
  selectUserStatus,
  selectFundingStatus,
  selectAssetTypeStatus,
  text,
  textArea,
  selectMultipleUser,
  selectMultiSuperVisorAndAdmin,
  selectMultiFormFiller,
  selectMultipleRole,
  selectMultipleUnit,
}

type FormikFieldPropType = {
  name: string
  inputType: InputType
  disabled?: boolean
}
export const FormikField = memo<FormikFieldPropType>(
  ({ name, inputType, disabled }) => {
    const { t } = useTranslation()
    if (inputType === InputType.selectUser)
      return <UserSelectionField name={name} disabled={disabled} />
    if (inputType === InputType.selectSuperVisorAndAdmin)
      return (
        <UserSelectionField
          name={name}
          disabled={disabled}
          filterByRoles={[Role.supervisor, Role.systemAdministrator]}
        />
      )
    if (inputType === InputType.selectRole)
      return <RoleSelectionField name={name} disabled={disabled} />
    if (inputType === InputType.selectUnit)
      return <UnitSelectionField name={name} disabled={disabled} />
    if (inputType === InputType.textArea)
      return <FormikTextAreaField name={name} disabled={disabled} />
    if (inputType === InputType.selectMultipleUser)
      return <UserSelectionField multiple name={name} disabled={disabled} />
    if (inputType === InputType.selectMultipleRole)
      return <RoleSelectionField multiple name={name} disabled={disabled} />
    if (inputType === InputType.selectMultipleUnit)
      return <UnitSelectionField multiple name={name} disabled={disabled} />
    if (inputType === InputType.selectUnitStatus)
      return (
        <StatusSelectionField
          name={name}
          disabled={disabled}
          activeText={t('systemDataManagement.unitStatus.active')}
          inactiveText={t('systemDataManagement.unitStatus.inactive')}
        />
      )
    if (inputType === InputType.selectUserStatus)
      return (
        <StatusSelectionField
          name={name}
          disabled={disabled}
          activeText={t('systemDataManagement.userStatus.active')}
          inactiveText={t('systemDataManagement.userStatus.inactive')}
        />
      )
    if (inputType === InputType.selectFundingStatus)
      return (
        <StatusSelectionField
          name={name}
          disabled={disabled}
          activeText={t('systemDataManagement.fundingStatus.active')}
          inactiveText={t('systemDataManagement.fundingStatus.inactive')}
        />
      )
    if (inputType === InputType.selectAssetTypeStatus)
      return (
        <StatusSelectionField
          name={name}
          disabled={disabled}
          activeText={t('systemDataManagement.assetTypeStatus.active')}
          inactiveText={t('systemDataManagement.assetTypeStatus.inactive')}
        />
      )
    if (inputType === InputType.selectMultiSuperVisorAndAdmin)
      return (
        <UserSelectionField
          multiple
          name={name}
          disabled={disabled}
          filterByRoles={[Role.supervisor, Role.systemAdministrator]}
        />
      )
    if (inputType === InputType.selectMultiFormFiller)
      return (
        <UserSelectionField
          multiple
          name={name}
          disabled={disabled}
          filterByRoles={[Role.doer]}
        />
      )
    // default / InputType.text
    return <FormikTextField name={name} disabled={disabled} />
  },
)
FormikField.displayName = 'FormikField'
