import { z } from 'zod'
import {
  makeGetPayloadSchema,
  makePaginatedParameterSchema,
  makePaginatedPayloadSchema,
  makePatchPayloadSchema,
  makePostPayloadSchema,
} from '../base'

// Fundings Schema
export const FundingSchema = z.object({
  id: z.string().uuid(),
  code: z.string(),
  name: z.string().optional(),
  status: z.string(),
})

// Fundings Response
export const FundingResponseSchema = makeGetPayloadSchema(FundingSchema)
export type FundingResponsePayload = z.infer<typeof FundingResponseSchema>

// Fundings Request
export const FundingRequestSchema = z.object({})
export type FundingRequestPayload = z.infer<typeof FundingRequestSchema>

// Fundings Post
const PostFundingSchema = makePostPayloadSchema(FundingSchema)
export type PostFundingPayload = z.infer<typeof PostFundingSchema>

// Fundings Patch
const PatchFundingSchema = makePatchPayloadSchema(FundingSchema).extend({
  id: z.string().uuid(),
})
export type PatchFundingPayload = z.infer<typeof PatchFundingSchema>

// Fundings List Request
const FundingListRequestSchema =
  makePaginatedParameterSchema(FundingRequestSchema)
export type FundingListRequestPayload = z.input<typeof FundingListRequestSchema>

// Fundings List Reponse
const FundingListResponseSchema = makePaginatedPayloadSchema(
  FundingResponseSchema,
)
export type FundingListResponsePayload = z.infer<
  typeof FundingListResponseSchema
>
