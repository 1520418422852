export const referenceInformation = {
  tabs: {
    fixedAssetGuideline: '固定資產指引',
    assetPurchaseFrom: '資產採購表',
    priceComparisonTable: '價格比較表',
    assetClass: '資產類別',
    sourcesOfFunds: '資金來源',
  },
  fields: {
    shortName: '簡稱',
    englishName: '資產類別 (英文)',
    chineseName: '資產類別 (中文)',
    detail: '內容',

    fundNumber: '基金編號',
    fundName: '基金名稱',
  },
}
