import { z } from 'zod'
import {
  AssetFundingsResponseSchema,
  PatchAssetFundingsSchema,
  PostAssetFundingsSchema,
} from '../asset-fundings'

import {
  AssetStatusLogsResponseSchema,
  AssetStatusLogsSchema,
} from '../asset-status-logs'
import { AssetTypeResponseSchema } from '../asset-types'
import {
  makeGetPayloadSchema,
  makePaginatedParameterSchema,
  makePaginatedPayloadSchema,
  makePatchPayloadSchema,
  makePostPayloadSchema,
  makeSchemaOptional,
} from '../base'
import {
  PatchResourcesSchema,
  PostResourcesSchema,
  ResourceResponseSchema,
} from '../resources'
import { UnitResponseSchema, UserResponseSchema } from '../user-units'

export enum AssetStatus {
  created = 'CREATED',
  assetUpdated = 'ASSET_UPDATED',
  assetRejected = 'ASSET_REJECTED',
  assetApproved = 'ASSET_APPROVED',

  reimbursePending = 'REIMBURSE_PENDING',
  reimburseSubmitted = 'REIMBURSE_SUBMITTED',
  reimburseRejected = 'REIMBURSE_REJECTD',
  reimburseApproved = 'REIMBURSE_APPROVED',

  transitPending = 'TRANSIT_PENDING',
  transitApproved = 'TRANSIT_APPROVED',

  // deprecated
  transitRejected = 'TRANSIT_REJECTED',
}

export const AssetStatusDeleted = 'Deleted' as AssetStatus // This constant is not used. It is added here to avoid introducing bugs

const ReimburseList = [
  AssetStatus.reimburseApproved,
  AssetStatus.reimbursePending,
  AssetStatus.reimburseRejected,
  AssetStatus.reimburseSubmitted,
]

const NonReimburseList = [
  AssetStatus.created,
  AssetStatus.assetUpdated,
  AssetStatus.assetRejected,
  AssetStatus.assetApproved,

  AssetStatus.transitPending,
  AssetStatus.transitApproved,
]
export const AssetStatusGroup = {
  reimburse: ReimburseList,
  nonReimburse: NonReimburseList,
} as const

export const AssetStatusSchema = z.nativeEnum(AssetStatus)

// AssetLinks Schema
const AssetLinksSchema = z.object({
  id: z.string(),
  parentId: z.string().uuid(),
  childId: z.string().uuid(),
  quantity: z.number(),
})

//AssetLinks Response
const AssetLinksResponseSchema = makeGetPayloadSchema(AssetLinksSchema)
export type AssetLinksResponsePayload = z.infer<typeof AssetLinksResponseSchema>

// Asset Schema
export const AssetSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  unitId: z.string().uuid(),
  atUnitNotes: z.string().optional(),
  assetTypeId: z.string().uuid(),
  year: z.number(),
  assetNo: z.string(),
  brand: z.string(),
  model: z.string(),
  quantity: z.number(),
  serialNo: z.string().optional(),
  valuedAmount: z.number(),
  remarks: z.string().optional(),
  supervisorUserId: z.string().uuid(),
  formFillUserId: z.string().uuid(),
  purchasedDateTime: z.string(),
  providerName: z.string(),
  invoiceNo: z.string(),
  warrantyProvider: z.string().optional(),
  warrantyPeriod: z.string().optional(),
  receiptNo: z.string(),
  reason: z.string(),
  status: z.string(),
})
const AssetHistoriesSchema = AssetSchema
const AssetHistoriesResponseSchema = makeGetPayloadSchema(
  AssetHistoriesSchema,
).extend({
  unit: UnitResponseSchema.nullable(),
  assetType: AssetTypeResponseSchema.nullable(),
  formFillUser: UserResponseSchema.nullable(),
  resources: z.string(),
})
// AssetLinks Request
const AssetLinksRequestSchema = z.object({})

// AssetLinks Post
const PostAssetLinksSchema = makePostPayloadSchema(AssetLinksSchema)
export type PostAssetLinksPayload = z.input<typeof PostAssetLinksSchema>

// AssetLinks Patch
const PatchAssetLinksSchema = makePatchPayloadSchema(AssetLinksSchema).extend({
  isDeleted: z.boolean(),
})
export type PatchAssetLinksPayload = z.infer<typeof PatchAssetLinksSchema>

const DeleteAssetLinksSchema = AssetLinksSchema.pick({ id: true })
export type DeleteAssetLinksPayload = z.infer<typeof DeleteAssetLinksSchema>
// Asset Request
const AssetRequestSchema = z.object({
  expand: z.array(z.string()).optional(),
})
export type AssetRequestPayload = z.infer<typeof AssetRequestSchema>

// AssetStatusLogs Schema

// Asset Response
export const AssetResponseSchema = makeGetPayloadSchema(AssetSchema).extend({
  formFillUser: UserResponseSchema.nullable(),
  unit: UnitResponseSchema.nullable(),
  assetType: AssetTypeResponseSchema.nullable(),
  assetStatusLogs: AssetStatusLogsResponseSchema.array(),
  resources: ResourceResponseSchema.array(),
  assetFundings: AssetFundingsResponseSchema.array(),
  parentAssetLinks: AssetLinksResponseSchema.array(), // TODO fix the recursive schema (SFAS-222)
  childAssetLinks: AssetLinksResponseSchema.array(),
  assetHistories: AssetHistoriesResponseSchema.array(),
  assetRequestAssets: z
    .object({
      assetRequest: z.object({}).omit({}),
    })
    .omit({})
    .array(),
})
export type AssetResponsePayload = z.infer<typeof AssetResponseSchema>
// Asset Post
const PostAssetPayloadSchema = makePostPayloadSchema(
  AssetSchema.omit({
    assetNo: true,
    status: true,
    receiptNo: true,
    reason: true,
  }),
).extend({
  parentAssetLinks: PostAssetLinksSchema.omit({ parentId: true }).array(),
  resources: PostResourcesSchema.omit({ assetId: true }).array(),
  assetFundings: PostAssetFundingsSchema.omit({ assetId: true }).array(),
})
export type PostAssetPayload = z.infer<typeof PostAssetPayloadSchema>

// Asset Patch
const PatchAssetSchema = makePatchPayloadSchema(
  AssetSchema.omit({ assetNo: true, reason: true, status: true }),
).extend({
  parentAssetLinks: z
    .union([
      PatchAssetLinksSchema.extend({
        id: z.string().uuid(),
      }),
      PostAssetLinksSchema,
    ])
    .array(),
  resources: z.union([PatchResourcesSchema, PostResourcesSchema]).array(),
  assetFundings: z
    .union([
      PatchAssetFundingsSchema.extend({
        id: z.string().uuid(),
      }),
      PostAssetFundingsSchema,
    ])
    .array(),
})
export type PatchAssetPayload = z.infer<typeof PatchAssetSchema>

const RejectAssetSchema = AssetResponseSchema.pick({
  id: true,
  rowVersion: true,
  reason: true,
})
export type RejectAssetPayload = z.infer<typeof RejectAssetSchema>

const RejectBulkAssetSchema = RejectAssetSchema.array()
export type RejectBulkAssetPayload = z.infer<typeof RejectBulkAssetSchema>
// AssetLinks List Response
const AssetLinksListResponseSchema =
  makePaginatedPayloadSchema(AssetLinksSchema)
export type AssetLinksListResponsePayload = z.infer<
  typeof AssetLinksListResponseSchema
>

// AssetLinks List Request
const AssetLinksListRequestSchema = makePaginatedParameterSchema(
  AssetLinksRequestSchema,
)
export type AssetLinksListRequestPayload = z.input<
  typeof AssetLinksListRequestSchema
>

// AssetList Request
export const AssetListRequest = z.object({
  name: z.string(),
  unitId: z.string().uuid(),
  atUnitNotes: z.string(),
  assetTypeId: z.string().uuid(),
  yearLargerOrEqualThan: z.number(),
  yearSmallerOrEqualThan: z.number(),
  assetNo: z.string(),
  brand: z.string(),
  model: z.string(),
  quantityLargerOrEqualThan: z.number(),
  quantitySmallerOrEqualThan: z.number(),
  serialNo: z.string(),
  valuedAmountLargerOrEqualThan: z.number(),
  valuedAmountSmallerOrEqualThan: z.number(),
  remarks: z.string(),
  supervisorUserId: z.string().uuid(),
  formFillUserId: z.string().uuid(),
  purchasedFromDateTime: z.string(),
  purchasedToDateTime: z.string(),
  providerName: z.string(),
  invoiceNo: z.string(),
  warrantyProvider: z.string(),
  warrantyPeriod: z.string(),
  receiptNo: z.string(),
  reason: z.string(),
  status: z.string().array(),
})
const AssetListRequestSchema = makePaginatedParameterSchema(
  makeSchemaOptional(AssetListRequest),
)
export type AssetListRequestPayload = z.input<typeof AssetListRequestSchema>

// AssetList Response
export const AssetListResponseSchema =
  makePaginatedPayloadSchema(AssetResponseSchema)
export type AssetListResponsePayload = z.infer<typeof AssetListResponseSchema>

//AssetAction Request
const AssetActionRequestSchema = AssetResponseSchema.pick({ rowVersion: true })
export type ApproveAssetRequestPayload = z.input<
  typeof AssetActionRequestSchema
>
//Approve Asset Response
export type ApproveAssetResponsePayload = AssetResponsePayload

//Asset Reimburse Request
const AssetReimburseRequestSchema = AssetActionRequestSchema.extend({
  reason: z.string(),
})
export type ReimburseAssetRequestPayload = z.input<
  typeof AssetReimburseRequestSchema
>

//Asset Reimburse Response
export type ReimburseAssetResponsePayload = AssetResponsePayload

//Asset Transit Request
const AssetTransitRequestSchema = AssetActionRequestSchema.extend({
  transitQuantity: z.number(),
  isTransferLinkedAsset: z.boolean(),
  toUnitId: z.string().uuid(),
  reason: z.string(),
  submittedDateTime: z.string(),
})
export type TransitAssetRequestPayload = z.input<
  typeof AssetTransitRequestSchema
>

//Asset Transit Response
export type TransitAssetResponsePayload = AssetRequestsResponsePayload

//Asset Delete Bulk Request
const AssetDeleteBulkRequestSchema = AssetActionRequestSchema.extend({
  id: z.string().uuid(),
}).array()
export type DeleteAssetBulkRequestPayload = z.input<
  typeof AssetDeleteBulkRequestSchema
>

//Asset ApproveBulkRequest
const AssetApproveBulkRequestSchema = AssetActionRequestSchema.extend({
  id: z.string().uuid(),
}).array()
export type ApproveAssetBulkRequestPayload = z.input<
  typeof AssetApproveBulkRequestSchema
>

export enum AssetRequestStatus {
  pending = 'PENDING',
  approved = 'APPROVED',
  withdrew = 'WITHDREW',
}

export const AssetRequestStatusSchema = z.nativeEnum(AssetRequestStatus)

export enum AssetRequestType {
  reimbursesRecord = 'REIMBURSES_RECORD',
  transition = 'TRANSITION',
}

export const AssetRequestTypeSchema = z.nativeEnum(AssetRequestType)

// AssetRequests Schema
export const AssetRequestsSchema = z.object({
  id: z.string().uuid(),
  type: z.string(),
  transitDateTime: z.string(),
  receiveDateTime: z.string(),
  submissionDateTime: z.string(),
  transitQuantity: z.number(),
  reason: z.string(),
  fromUnitId: z.string().uuid(),
  toUnitId: z.string().uuid(),
  transitStatus: z.string(),
  receiveStatus: z.string(),
  reimburseStatus: z.string(),
  newAssetNo: z.string(),
  reimburseNo: z.string(),
  requestedByUserId: z.string().uuid(),
})

// AssetRequests Request

// AssetRequests Response
export const AssetRequestsResponseSchema = makeGetPayloadSchema(
  AssetRequestsSchema,
).extend({
  assetStatusLogs: AssetStatusLogsSchema.nullable(),
  fromUnit: UnitResponseSchema.nullable(),
  toUnit: UnitResponseSchema.nullable(),
  assetRequestAssets: z
    .object({
      asset: AssetResponseSchema,
    })
    .array(),
  requestedByUser: UserResponseSchema.nullable(),
})
export type AssetRequestsResponsePayload = z.infer<
  typeof AssetRequestsResponseSchema
>

// AssetRequests List Request
const AssetRequestsListRequest = makeSchemaOptional(
  z.object({
    expand: z.array(z.string()),
    type: z.string(),
    transitFromDateTime: z.string(),
    transitToDateTime: z.string(),
    receiveFromDateTime: z.string(),
    receiveToDateTime: z.string(),
    transitQuantityLargerOrEqualThan: z.number(),
    transitQuantitySmallerOrEqualThan: z.number(),
    reason: z.string(),
    fromUnitId: z.string().uuid(),
    toUnitId: z.string().uuid(),
    requestedByUserId: z.string().uuid(),
    transitStatus: z.string(),
    receiveStatus: z.string(),
    reimburseStatus: z.string(),
    newAssetNo: z.string(),
    reimburseNo: z.string(),
  }),
)
const AssetRequestsListRequestSchema = makePaginatedParameterSchema(
  AssetRequestsListRequest,
)
export type AssetRequestsListRequestPayload = z.input<
  typeof AssetRequestsListRequestSchema
>

// AssetRequests List Response
const AssetRequestsListResponseSchema = makePaginatedPayloadSchema(
  AssetRequestsResponseSchema,
)
export type AssetRequestListResponsePayload = z.infer<
  typeof AssetRequestsListResponseSchema
>

// AssetRecord Schema
const AssetRecordSchema = z.object({
  id: z.string().uuid(),
})

// AssetRecord Response
export const AssetRecordResponseSchema = makeGetPayloadSchema(AssetRecordSchema)
export type AssetRecordResponsePayload = z.infer<
  typeof AssetRecordResponseSchema
>

// AssetRecord Post
export const PostAssetRecordSchema = makePostPayloadSchema(AssetRecordSchema)

// AssetRequest ReimburseRecord Schema
const AssetRequestReimburseRecordSchema = z.object({
  submittedDateTime: z.string(),
  reimburseRecordItems: PostAssetRecordSchema.extend({
    id: z.string().uuid(),
    rowVersion: z.string(),
  }).array(),
})
export type AssetRequestReimburseRecordPayload = z.input<
  typeof AssetRequestReimburseRecordSchema
>

// AssetRequest Aproove Bulk Schema
const AssetRequestApproveBulkSchema = z
  .object({
    id: z.string().uuid(),
    rowVersion: z.string(),
  })
  .array()
export type AssetRequestApproveBulkPayload = z.input<
  typeof AssetRequestApproveBulkSchema
>

// AssetRequest Withdraw Schema
const AssetRequestWithdrawSchema = z.object({ rowVersion: z.string() })
export type AssetRequestWithdrawPayload = z.input<
  typeof AssetRequestWithdrawSchema
>

const AssetRequestBulkWithdrawSchema = z
  .object({
    id: z.string().uuid(),
    rowVersion: z.string(),
    reason: z.string(),
  })
  .array()
export type AssetRequestBulkWithdrawPayload = z.input<
  typeof AssetRequestBulkWithdrawSchema
>
