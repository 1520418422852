import { useCallback, useMemo, useState } from 'react'
import { EditAssetFormSchemaModel } from '../../../models'

type Props = {
  resources: EditAssetFormSchemaModel['editResources']
}
export const useResourceView = ({ resources }: Props) => {
  const [indexOfViewingResource, setIndexOfViewingResource] =
    useState<number>(0)

  const totalNumberOfResources = useMemo(() => resources.length, [resources])

  const viewingResource = useMemo(() => {
    if (indexOfViewingResource + 1 > totalNumberOfResources) {
      return null
    }
    return resources[indexOfViewingResource]
  }, [indexOfViewingResource, resources, totalNumberOfResources])

  const viewPreviousResource = useCallback(() => {
    setIndexOfViewingResource(
      (indexOfViewingResource + totalNumberOfResources - 1) %
        totalNumberOfResources,
    )
  }, [
    setIndexOfViewingResource,
    indexOfViewingResource,
    totalNumberOfResources,
  ])

  const viewNextResource = useCallback(() => {
    setIndexOfViewingResource(
      (indexOfViewingResource + 1) % totalNumberOfResources,
    )
  }, [
    setIndexOfViewingResource,
    indexOfViewingResource,
    totalNumberOfResources,
  ])

  const viewResourceByOrder = useCallback(
    (order: number) => {
      const targetResource = resources.find(
        (resource) => resource.order === order,
      )
      if (targetResource) {
        setIndexOfViewingResource(resources.indexOf(targetResource))
      }
    },
    [resources],
  )
  return {
    viewNextResource,
    viewPreviousResource,
    viewResourceByOrder,
    viewingResource,
    totalNumberOfResources,
    indexOfViewingResource,
  }
}
