import {
  usePatchAssetTypeMutation,
  usePatchFundingMutation,
  usePatchReferencePageMutation,
  usePatchUnitMutation,
  usePatchUserMutation,
  usePostAssetTypeMutation,
  usePostFundingMutation,
  usePostReferencePageMutation,
  usePostUnitMutation,
  usePostUserMutation,
} from '@stewards-fas/queries'
import {
  PostUserUnitsPayload,
  UnitResponsePayload,
  UserResponsePayload,
} from '@stewards-fas/schemas'
import { useMemo } from 'react'
import { AppManagementTabItem } from '../../../components'
import { muteAsyncFunction, updateToMultipleDelete } from './silent-function'

export function useMasterDataMutation(tab: AppManagementTabItem) {
  const { mutateAsync: postUnitMutate } = usePostUnitMutation()
  const { mutateAsync: patchUnitMutate } = usePatchUnitMutation()

  const { mutateAsync: postUserMutate } = usePostUserMutation()
  const { mutateAsync: patchUserMutate } = usePatchUserMutation()

  const { mutateAsync: postAssetType } = usePostAssetTypeMutation()
  const { mutateAsync: patchAssetType } = usePatchAssetTypeMutation()

  const { mutateAsync: postFundingMutate } = usePostFundingMutation()
  const { mutateAsync: patchFundingMutate } = usePatchFundingMutation()

  const { mutateAsync: postReferencePageMutate } =
    usePostReferencePageMutation()
  const { mutateAsync: patchReferencePageMutate } =
    usePatchReferencePageMutation()

  return useMemo(() => {
    if (tab === AppManagementTabItem.systemUnitManagement) {
      const transformFormikToPayload = (
        values: UnitResponsePayload & {
          initSupervisorList: UnitResponsePayload['userUnits']
          initFillerList: UnitResponsePayload['userUnits']
          formFillUserIds: string[]
          supervisorIds: string[]
        },
      ) => {
        const {
          initSupervisorList,
          initFillerList,
          formFillUserIds,
          supervisorIds,
          ...payload
        } = values

        // patch old user
        const deletedUserUnits = [
          ...initFillerList.map((it) => {
            return {
              ...it,
              isDeleted: !formFillUserIds.some(
                (userId) => userId === it.userId,
              ),
            }
          }),
          ...initSupervisorList.map((it) => {
            return {
              ...it,
              isDeleted: !supervisorIds.some((userId) => userId === it.userId),
            }
          }),
        ]

        const newUserUnits = [] as PostUserUnitsPayload[]

        // post new user
        formFillUserIds.forEach((userId) => {
          if (!initFillerList.find((it) => it.userId === userId))
            newUserUnits.push({
              unitId: values.id,
              userId: userId,
            })
        })

        supervisorIds.forEach((userId) => {
          if (!initSupervisorList.find((it) => it.userId === userId))
            newUserUnits.push({
              unitId: values.id,
              userId: userId,
            })
        })
        return {
          ...payload,
          userUnits: [...deletedUserUnits, ...newUserUnits],
        }
      }

      const onCreate = muteAsyncFunction(
        postUnitMutate,
        transformFormikToPayload,
      )

      const onUpdate = muteAsyncFunction(
        patchUnitMutate,
        transformFormikToPayload,
      )

      return { onCreate, onUpdate }
    }

    if (tab === AppManagementTabItem.systemUserManagement) {
      const transformUserPayload = (
        values: UserResponsePayload & {
          initUserUnits: UserResponsePayload['userUnits']
          userUnitsIds: string[]
        },
      ) => {
        const { initUserUnits, userUnitsIds, ...payload } = values

        // Assign old unit rows
        const oldUserUnits =
          initUserUnits?.map((it) => ({
            ...it,
            isDeleted: !userUnitsIds.some((id) => id === it.unitId),
          })) ?? []

        const newUserUnits = [] as PostUserUnitsPayload[]
        // Push new unit row
        userUnitsIds.forEach((id) => {
          if (!initUserUnits?.some((it) => it.unitId === id))
            newUserUnits.push({
              unitId: id,
              userId: values.id,
            })
        })

        return { ...payload, userUnits: [...oldUserUnits, ...newUserUnits] }
      }

      const onCreate = muteAsyncFunction(postUserMutate, transformUserPayload)
      const onUpdate = muteAsyncFunction(patchUserMutate, transformUserPayload)
      const onMultipleDelete = updateToMultipleDelete(onUpdate, {
        status: 'inactive',
      })
      return { onCreate, onUpdate, onMultipleDelete }
    }

    if (tab === AppManagementTabItem.systemAssetClassManagement) {
      const onCreate = muteAsyncFunction(postAssetType)
      const onUpdate = muteAsyncFunction(patchAssetType)
      return { onCreate, onUpdate }
    }
    if (tab === AppManagementTabItem.systemFundingResourceManagement) {
      const onCreate = muteAsyncFunction(postFundingMutate)
      const onUpdate = muteAsyncFunction(patchFundingMutate)
      return { onCreate, onUpdate }
    }
    if (tab === AppManagementTabItem.systemReferenceMaterialManagement) {
      const onCreate = muteAsyncFunction(postReferencePageMutate)
      const onUpdate = muteAsyncFunction(patchReferencePageMutate)
      return { onCreate, onUpdate }
    }
    return {
      onCreate: undefined,
      onUpdate: undefined,
      onMultipleDelete: undefined,
    }
  }, [
    patchAssetType,
    patchFundingMutate,
    patchReferencePageMutate,
    patchUserMutate,
    postAssetType,
    postFundingMutate,
    postReferencePageMutate,
    postUserMutate,
    patchUnitMutate,
    postUnitMutate,
    tab,
  ])
}
