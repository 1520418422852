import { useTranslation } from '@pasteltech/i18n-react'
import { useMemo } from 'react'

export const All_VALUE_OF_DROPDOWN = 'all'

export const convertAllValueToNull = (val: any) => {
  if (typeof val !== 'string' || val.length === 0) {
    return val
  }
  if (val === All_VALUE_OF_DROPDOWN) return null
  return val
}
export const useDropdownAll = () => {
  const { t } = useTranslation()
  const allOption = useMemo(
    () => ({ label: t('common.all'), value: All_VALUE_OF_DROPDOWN }),
    [t],
  )
  return { allOption }
}
