import { Button } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { useOverlay } from '@pasteltech/overlay-provider'
import { useReimburseRecordRequestMutation } from '@stewards-fas/queries'
import { Dayjs } from 'dayjs'
import { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import { PageTitle, PrintWindow } from '../../../../components'
import { useApiErrorHandle } from '../../../../hooks'
import { ApprovedIcon, BackIcon, WhitePrintIcon } from '../../../../icons'
import { WriteOffForm } from '../components'
import { WriteOffSectionTabItem, useWriteOffContext } from '../context'

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
`
const ActionContainer = styled.div`
  display: flex;
  column-gap: 20px;
`
const IconButton = styled(Button)`
  display: flex;
  column-gap: 10px;
  padding: 8px 20px;
`

const PrintButton = styled(IconButton)`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
`

type PreviewPagePropType = {}
export const WriteOffDetail = memo<PreviewPagePropType>(() => {
  // contexts
  const { t } = useTranslation()
  const { showSnackbar } = useOverlay()
  const { setIsViewForm, writeOffDate, writeOffList, setTabValue, viewFormId } =
    useWriteOffContext()

  // componenet states
  const [isPrint, setIsPrint] = useState(false)
  const closePrint = useCallback(() => {
    setIsPrint(false)
  }, [])
  const startPrint = useCallback(() => {
    setIsPrint(true)
  }, [])
  const { standardErrorHandler } = useApiErrorHandle()
  // action and logic
  const { mutateAsync: recordRequestAsync, isLoading: isSubmittingRequest } =
    useReimburseRecordRequestMutation({})
  const onSubmit = useCallback(async () => {
    try {
      // Submit Reimburse Record
      await recordRequestAsync({
        submittedDateTime: (writeOffDate as Dayjs).toISOString(),
        reimburseRecordItems: writeOffList.map(({ id, rowVersion }) => ({
          id,
          rowVersion,
        })),
      })
      showSnackbar({
        content: t('common.submitted'),
      })
      setIsViewForm(false)
      setTabValue(WriteOffSectionTabItem.reimbursementRecords)
    } catch (error) {
      console.error('Err-id:908389 ,Error when: Submit Reimburse Record', error)
      standardErrorHandler(error)
    }
  }, [
    recordRequestAsync,
    setIsViewForm,
    setTabValue,
    showSnackbar,
    t,
    writeOffDate,
    writeOffList,
    standardErrorHandler,
  ])

  return (
    <>
      <PageTitle>
        {t('assetManagement.writeOffSection.title.writeOffAsset')}
      </PageTitle>
      <ActionBar>
        <Button onClick={() => setIsViewForm(false)} variant="text">
          <BackIcon />
        </Button>
        <ActionContainer>
          {viewFormId?.length ? (
            <PrintButton variant="contained" onClick={startPrint}>
              <WhitePrintIcon />
              {t('assetTransferRecordPage.button.print')}
            </PrintButton>
          ) : (
            <IconButton
              variant="contained"
              disabled={isSubmittingRequest}
              onClick={onSubmit}
            >
              <ApprovedIcon />
              {t('common.submit')}
            </IconButton>
          )}
        </ActionContainer>
      </ActionBar>
      <WriteOffForm />
      {isPrint && (
        <PrintWindow
          onClose={closePrint}
          title={t('assetManagement.writeOffSection.previewForm.titleLine2')}
        >
          <WriteOffForm />
        </PrintWindow>
      )}
    </>
  )
})
WriteOffDetail.displayName = 'WriteOffDetail'
