import { z } from 'zod'
import {
  makeGetPayloadSchema,
  makePaginatedParameterSchema,
  makePaginatedPayloadSchema,
} from '../base'

export const RoleSchema = z.object({
  id: z.string().uuid(),
  name: z.string().optional(),
  descriptions: z.string().optional(),
})

// Role Request
export const RoleRequestSchema = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
})
// Role Response
export const RoleResponseSchema = makeGetPayloadSchema(RoleSchema)
export type RoleResponsePayload = z.infer<typeof RoleResponseSchema>

// Role List Request
export const RoleListRequestSchema =
  makePaginatedParameterSchema(RoleRequestSchema)
export type RoleListRequestPayload = z.input<typeof RoleListRequestSchema>

// Role List Response
export const RoleListResponseSchema =
  makePaginatedPayloadSchema(RoleResponseSchema)
export type RoleListResponsePayload = z.infer<typeof RoleListResponseSchema>
