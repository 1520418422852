import {
  Autocomplete,
  AutocompleteProps,
  Box,
  CircularProgress,
  TextField,
} from '@mui/material'
import { ChangeEventHandler, Fragment, memo } from 'react'
import styled from 'styled-components'
import { BaseFormField, BaseFormFieldProps } from './base-form-field'

const StyledTextField = styled(TextField)`
  width: 100%;
`

type OwnProps = {
  placeholder?: string
}

type Props = Omit<
  AutocompleteProps<any, boolean, boolean, boolean | undefined>,
  'renderInput' | 'dir'
> &
  OwnProps & {
    onTextFieldValueChanged?:
      | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined
    onStopInput?: () => void
  } & BaseFormFieldProps

export const FormAutoCompleteField = memo<Props>(
  ({
    label,
    id,
    placeholder,
    onTextFieldValueChanged,
    onStopInput,
    value,
    dir,
    loading,
    marginTop,
    ...rest
  }) => {
    return (
      <BaseFormField label={label} dir={dir} marginTop={marginTop}>
        <Autocomplete
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          size="small"
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.id}>
              {option.name}
            </Box>
          )}
          renderInput={(params) => (
            <StyledTextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
        />
      </BaseFormField>
    )
  },
)

FormAutoCompleteField.displayName = 'FormAutoCompleteField'
