import { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { BackIcon } from '../../icons'
import { BaseButton } from './base-button'

const StyledButton = styled(BaseButton)`
  border: none;
  &:hover {
    border: none;
  }
`
type Props = {
  url: string
}
export const BackButton = memo<Props>(({ url }) => {
  const navigate = useNavigate()
  return (
    <StyledButton
      icon={BackIcon}
      onClick={() => {
        navigate(url)
      }}
    />
  )
})

BackButton.displayName = 'BackButton'
