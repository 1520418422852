import { ApiError } from '@pasteltech/api-gateway'
import { ErrorDetailPayloadItem } from './error.types'
import { checkTemporary } from './utils'

export type FormattedApiError = {
  kind: ApiError['kind']
  formatted: true
  isTemporary: boolean
  traceId: string
  errors: ErrorDetailPayloadItem[]
}

export function extractApiErrorDetail(
  error: any,
): FormattedApiError | (ApiError & { formatted?: never }) | null {
  if (error instanceof ApiError) {
    const apiError = error as ApiError

    if (apiError.detail !== undefined) {
      return {
        kind: apiError.kind,
        formatted: true,
        isTemporary: checkTemporary(apiError.kind),
        traceId: apiError.message,
        errors: [apiError.detail] as any as ErrorDetailPayloadItem[],
      }
    }

    return error
  }
  return null
}

export type { ApiError }
