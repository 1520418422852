import { useQueryUser, useUnitList } from '@stewards-fas/queries'
import { memo, useMemo } from 'react'
import { FormikSelectField } from './formik-select-field'
import { Option } from '../../hooks'
import { useFormikContext } from 'formik'
import { sortBy } from 'lodash'

type UnitSelectionFieldPropType = {
  name: string
  multiple?: boolean
  disabled?: boolean
}
export const UnitSelectionField = memo<UnitSelectionFieldPropType>(
  ({ name, multiple = false, disabled = false }) => {
    const { data: unitList } = useUnitList({ status: ['active'] })
    const formik = useFormikContext<any>()
    const { id: userId } = formik.values
    const { data: user } = useQueryUser(
      userId,
      { expand: ['UserUnits.Unit'] },
      { enabled: userId !== undefined },
    )
    const optionList = useMemo(() => {
      const activeUnitList = unitList?.data ?? []
      const activeUnitOptions = activeUnitList.map((activeUnit) => ({
        label: activeUnit.code ?? '',
        value: activeUnit.id,
      }))
      if (!userId && !user) return activeUnitOptions
      const userUnits = user?.userUnits
      if (!userUnits || userUnits.length === 0) return activeUnitOptions

      const initInactiveUnitOptions = userUnits.reduce(
        (acc: Option[], userUnit) => {
          const unit = userUnit.unit
          if (!unit) return acc
          if (unit.status === 'inactive')
            return [...acc, { label: unit.code ?? '', value: unit.id }]
          return acc
        },
        [],
      )
      return [...initInactiveUnitOptions, ...activeUnitOptions]
    }, [unitList?.data, user, userId])
    const sortedList = sortBy(optionList, ['label'])
    return (
      <FormikSelectField
        name={name}
        options={sortedList}
        multiple={multiple}
        disabled={disabled}
      />
    )
  },
)
UnitSelectionField.displayName = 'UnitSelectionField'
