import { z } from 'zod'
import {
  makeGetPayloadSchema,
  makePaginatedParameterSchema,
  makePaginatedPayloadSchema,
  makePatchPayloadSchema,
  makePostPayloadSchema,
} from '../base'

// ReferencePage Schema
export const ReferencePageSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  description: z.string().optional(),
  type: z.string().optional(),
  contents: z.string().optional(),
})

// ReferencePage Request
const ReferencePageRequestSchema = z.object({})

// ReferencePage Response
const ReferencePageResponseSchema = makeGetPayloadSchema(ReferencePageSchema)
export type ReferencePageResponsePayload = z.infer<
  typeof ReferencePageResponseSchema
>

// ReferencePage Post
const PostReferencePageSchema = makePostPayloadSchema(ReferencePageSchema)
export type PostReferencePagePayload = z.infer<typeof PostReferencePageSchema>

// ReferencePage Patch
const PatchReferencePageSchema = makePatchPayloadSchema(
  ReferencePageSchema,
).extend({ id: z.string().uuid() })
export type PatchReferencePagePayload = z.infer<typeof PatchReferencePageSchema>

// ReferencePage List Request
const ReferencePageListRequestSchema = makePaginatedParameterSchema(
  ReferencePageRequestSchema,
)
export type ReferencePageListRequestPayload = z.input<
  typeof ReferencePageListRequestSchema
>

// ReferencePage List Response
const ReferencePageListResponseSchema = makePaginatedPayloadSchema(
  ReferencePageResponseSchema,
)
export type ReferencePageListResponsePayload = z.infer<
  typeof ReferencePageListResponseSchema
>
