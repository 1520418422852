export const assetDetailsPage = {
  form: {
    title: {
      create: '新增資產',
    },
    fields: {
      labels: {
        name: '名稱',
        unit: '單位',
        assetType: '資產類別',
        year: '年份',
        assetNo: '資產編號',
        brand: '牌子',
        quantity: '數量',
        serialNo: '序號',
        valuedAmount: '金額/估值',
        remarks: '注意事項',
        supervisor: '主管',
        purchasedDateTime: '購買日期',
        providerName: '供應商',
        invoiceNo: '發票號碼',
        warrantyProvider: '保養公司',
        warrantyPeriod: '保養期',
        receipt: '付款憑單編號',
        status: '資產狀態',
        lastModifiedDateTime: '最後更新日期',
        atUnitNotes: '目前放置地點',
        batchInput: '批次輸入',
        totalPieces: '總件數',
        formFiller: '填表人',
        model: '型號',
        fundings: '資金來源',
        transferRecord: '轉移記錄',
      },
      placeholders: {
        name: '名稱',
        unit: '單位',
        assetType: '資產類別',
        year: '年份',
        assetNo: '資產編號',
        brand: '牌子',
        quantity: '數量',
        serialNo: '序號',
        valuedAmount: '金額/估值',
        remarks: '注意事項',
        supervisor: '主管',
        purchasedDateTime: '購買日期',
        providerName: '供應商',
        invoiceNo: '發票號碼',
        warrantyProvider: '保養公司',
        warrantyPeriod: '保養期',
        receipt: '付款憑單編號',
        status: '資產狀態',
        lastModifiedDateTime: '最後更新日期',
        atUnitNotes: '目前放置地點',
        batchInput: '批次輸入',
        totalPieces: '總件數',
        formFiller: '填表人',
        model: '型號',
        fundings: '資金來源',
        transferRecord: '轉移記錄',
      },
    },
    errorMessages: {
      name: {
        required: '請提供資產名稱',
      },
      unit: {
        required: '請提供單位',
      },
      assetType: {
        required: '請提供資產類別',
      },
      year: {
        typeError: '年份必須是數字',
        required: '請提供年份',
        positive: '年份必須是正整數',
        integer: '年份必須是正整數',
      },
      brand: {
        required: '請提供牌子',
      },
      quantity: {
        typeError: '數量必須是數字',
        required: '請提供數量',
        min: '數量至少為1',
        max: '數量最多為10000',
        integer: '數量必須是整數',
      },
      serialNo: {
        required: '請提供序號',
      },
      model: {
        required: '請提供型號',
      },
      valuedAmount: {
        typeError: '金額/估值必須是數字',
        required: '請提供金額/估值',
        positive: '金額/估值必須是數字',
        decimal: '金額/估值必須是數字，以一仙為最小單位',
      },
      purchasedDateTime: {
        required: '請提供購買日期',
      },
      providerName: {
        required: '請提供供應商',
      },
      invoiceNo: {
        required: '請提供發票號碼',
      },
      totalPieces: {
        typeError: '總件數必須是整數',
        required: '請提供總件數',
        min: '總件數至少為2',
        max: '總件數最多為100',
        integer: '總件數必須是整數',
      },
      assetBulk: {
        emptyField: '請填寫全部',
      },
      invalidInput: '無效輸入',
    },
  },
  confirmTransferModal: {
    title: '核准轉移資產',
    words: {
      confirmRepresentative: '確認代表',
      receive: '接收',
      transfer: '轉移',
      theAssetsAreFollow: '資產如下： ',
      assetNumber: '資產編號',
      assetName: '資產名稱',
      quantity: '數量',
      purchaseDate: '購置日期',
      reasonForTransfer: '轉移原因',
    },
  },
  unLinkAssetModal: {
    title: '解除連繫',
    message: '確認解除資產連繫?',
  },
  tabs: {
    basicInfo: '基本資料',
    purchaseRecord: '購買記錄',
    transferRecord: '轉移記錄',
  },
  buttons: {
    linkAsset: '連繫資產',
    add: '新增',
    reimbursementStatus: '報銷狀態',
    addMultiple: '新增 {{ count }}',
  },
  measurementUnit: {
    asset: '項',
  },
  assetStatus: {
    notReimbursed: '未報銷',
    reimbursed: '已報銷',
    transfered: '已轉移',
    created: '未核准',
    approved: '已核准',
    assetUpdated: '已更新',
    assetRejected: '被駁回',
    assetApproved: '已核准',
    reimbursePending: '報銷批核中',
    reimburseSubmitted: '報銷已提交',
    reimburseRejected: '報銷被駁回',
    reimburseApproved: '報銷已核准',
    transitPending: '轉移批核中',
    transitRejected: '轉移被駁回',
    transitApproved: '轉移已核准',
  },
  linkAssetModal: {
    title: '連繫資產',
    label: '資產編號',
    placeholder: '輸入資產編號',
    link: '連繫',
    numberOfPieces: '件數',
    totalNumber: '總件數',
    errorMessage: {
      linkQuantity: {
        max: '連繫數量不能超過{{max}}',
        positive: '連繫數量必須是正整數',
      },
      invalidInput: '無效輸入',
    },
  },
  writeOffModal: {
    title: '報銷資產',
    popupName: '資產名稱',
    hint: '確認將以上資產加入「報銷項目」？',
    label: '報銷原因',
  },
  assetTransferModal: {
    title: '轉移資產',
    words: {
      theTotalAssetsAre: '本資產總數有',
      pieces: '件',
      pleaseSelectTheNumberOfPiecesToTransfer: '請選擇轉移件數',
      distract: '轉移',
      numberOfPieces: '件數',
      totalNumber: '總件數',
      thisAssetHas: '本資產具有',
      item: '項',
      toContractAnAssetPleaseSelect: '連繫資產，請選擇',
      disconnectAndTransferOnlyThisAsset: '解除連繫並僅轉移此資產',
      transferTogether: '一併轉移，共',
      assets: '資產',
      assetWillTransferTo: '資產將轉移至',
      reasonToTransfer: '轉移原因',
      submissionDate: '遞交日期',
      receiving: '接收單位',
    },
    errorMessages: {
      toUnitId: {
        required: '請選擇轉移單位',
      },
      submittedDateTime: {
        required: '請選擇遞交日期',
      },
      transitQuantity: {
        required: '請選擇轉移件數',
      },
    },
  },

  transferTable: {
    title: '轉移批核狀態',
    headers: {
      moveOutDate: '移出日期',
      originalUnit: '原本單位',
      outOfState: '移出狀態',
      receivingUnit: '接收單位',
      receivingStatus: '接收狀態',
      receivingDate: '接收日期',
      newAssetNumber: '新資產編號',
      name: '名稱',
      brand: '牌子',
      quantity: '數量',
      transferReason: '轉移原因',
    },
  },
  approvalTable: {
    title: '批核狀態',
    headers: {
      classification: '分類',
      assetNumber: '資產/報銷編號',
      name: '名稱',
      brand: '牌子',
      type: '型號',
      quantity: '數量',
      amount: '金額',
      dateOfPurchase: '購買日期',
      placement: '放置地點',
      isApproved: '批核狀態',
    },
  },
}
