import { useTranslation } from '@pasteltech/i18n-react'
import {
  AssetManagementIcon,
  ReferenceInformationIcon,
  ReportIcon,
  SelectedAssetManagementIcon,
  SelectedReferenceInformationIcon,
  SelectedReportIcon,
  SelectedSystemManagementIcon,
  SVGComponent,
  SystemManagementIcon,
  BlackSearchIcon,
  WhiteSearchIcon,
} from '../icons'
import { AppRoutes } from './paths'
import { Role } from '../models'

type RouteItem = {
  key: string
  path: string
  component: React.FunctionComponent
}

export type MenuItem = (
  | {
      key: string
      path?: string
      onClick?(): void
      content?: Omit<MenuItem, 'content'>[]
    }
  | (RouteItem & { onClick: undefined })
) & {
  icon: SVGComponent
  selectedIcon: SVGComponent
  accessibleRoles: Role[]
  subPath?: string[]
  isMobile?: boolean
}

export const urlRoot = '/'

export const useSidebarItems: () => MenuItem[] = () => {
  const { t } = useTranslation()
  return [
    {
      key: t('sidebar.assetManagement'),
      path: AppRoutes.assetManagement.path,
      icon: AssetManagementIcon,
      selectedIcon: SelectedAssetManagementIcon,
      accessibleRoles: AppRoutes.assetManagement.accessibleRoles,
    },
    {
      key: t('sidebar.referenceInformation'),
      path: AppRoutes.referenceInformation.path,
      icon: ReferenceInformationIcon,
      selectedIcon: SelectedReferenceInformationIcon,
      accessibleRoles: AppRoutes.referenceInformation.accessibleRoles,
    },
    {
      key: t('sidebar.report'),
      path: AppRoutes.report.path,
      icon: ReportIcon,
      selectedIcon: SelectedReportIcon,
      accessibleRoles: AppRoutes.report.accessibleRoles,
    },
    {
      key: t('sidebar.systemManagement'),
      path: AppRoutes.systemManagement.path,
      icon: SystemManagementIcon,
      selectedIcon: SelectedSystemManagementIcon,
      accessibleRoles: AppRoutes.systemManagement.accessibleRoles,
    },
    {
      key: t('sidebar.searchAssets'),
      path: AppRoutes.searchAssets.path,
      icon: BlackSearchIcon,
      selectedIcon: WhiteSearchIcon,
      accessibleRoles: AppRoutes.searchAssets.accessibleRoles,
      isMobile: true,
    },
  ]
}
