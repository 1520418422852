import { Box, Button } from '@mui/material'
import { memo } from 'react'
import styled from 'styled-components'
import { SVGComponent } from '../../icons'
const TextWrapper = styled(Box)`
  margin-left: 10px;
  display: flex;
  font-weight: bold;
`
const StyledButton = styled(Button)<{
  $paddingHorizontal: number
  $paddingVertical: number
}>`
  display: flex;
  padding: ${({ $paddingVertical, $paddingHorizontal }) =>
    `${$paddingVertical}px ${$paddingHorizontal}px`};
`
const IconWrapper = styled(Box)<{ $iconStrokeColor?: string }>`
  display: flex;
  path {
    fill: ${({ $iconStrokeColor }) =>
      $iconStrokeColor ? $iconStrokeColor : 'black'};
  }
`

type Props = {
  className?: string
  paddingHorizontal?: number
  paddingVertical?: number
  text?: string
  icon?: SVGComponent
  onClick?: () => void
  iconFillColor?: string
  iconStrokeColor?: string
  disabled?: boolean
}

export const BaseButton = memo<Props>(
  ({
    text = '',
    icon,
    onClick,
    paddingHorizontal = 15,
    paddingVertical = 10,
    className = '',
    iconFillColor,
    iconStrokeColor,
    disabled,
  }) => {
    const Icon = icon
    return (
      <StyledButton
        disableRipple
        className={className}
        variant="outlined"
        onClick={onClick ? onClick : undefined}
        $paddingHorizontal={paddingHorizontal}
        $paddingVertical={paddingVertical}
        disabled={disabled}
      >
        <IconWrapper $iconStrokeColor={iconStrokeColor}>
          {Icon && <Icon fill={iconFillColor} stroke={iconStrokeColor} />}
        </IconWrapper>
        <TextWrapper>{text}</TextWrapper>
      </StyledButton>
    )
  },
)

BaseButton.displayName = 'BaseButton'
