import { useCallback } from 'react'

export function muteAsyncFunction<T>(
  asyncMutate: (paylod: T) => any,
  transformPayload?: (payload: any, initialValues?: any) => T,
) {
  const muteFunction = async (payload: T, initialValues?: any) => {
    try {
      const transformedPayload =
        transformPayload?.(payload, initialValues) ?? payload

      await asyncMutate(transformedPayload)
      return undefined
    } catch (error) {
      console.error('Err-id:542929 ,Error when: api is called', error)
      return (error as Error).message ?? 'Error is undefined'
    }
  }
  return muteFunction
}

export function updateToMultipleDelete<T, R>(
  onUpdate: (payload: T) => R,
  invalidateStatue: Partial<T>,
) {
  return async (payloadList: T[]) => {
    const onDelete: (payload: T) => R = (payload) =>
      onUpdate({ ...payload, ...invalidateStatue })
    const allResults = (
      await Promise.allSettled(payloadList.map((payload) => onDelete(payload)))
    ).map((res) => (res as PromiseFulfilledResult<string | undefined>).value)

    const errorResults = allResults.filter((res) => res !== undefined)
    if (errorResults.length === 0) return undefined

    const error = errorResults.join(';')
    return error
  }
}

/**
 * when success, return undefined.
 * on Error, return the error message.
 * */
export function useMutateSilent<T>(
  asyncMutate: (payload: T) => Promise<any>,
): (payload: T) => Promise<string | undefined> {
  const onMutate = useCallback(
    async (payload: T) => {
      const muteFunction = muteAsyncFunction(asyncMutate)
      const result = await muteFunction(payload)
      return result
    },
    [asyncMutate],
  )
  return onMutate
}
