import { TextField } from '@mui/material'
import { useFormikContext } from 'formik'
import { memo, useMemo } from 'react'
import { BaseTextField } from '../form-fields'
import { TFuncKey, useTranslation } from '@pasteltech/i18n-react'
import { FormHelperText } from '../form-fields/form-helper-text'

type FormikTextFieldPropType = {
  name: string
  props?: React.ComponentProps<typeof TextField>
  disabled?: boolean
}
export const FormikTextField = memo<FormikTextFieldPropType>(
  ({ name, ...props }) => {
    const formik = useFormikContext<any>()
    const { t } = useTranslation()

    const isError = useMemo(
      () => formik.touched?.[name] && Boolean(formik.errors?.[name]),
      [formik, name],
    )
    const helperText = useMemo(() => {
      if (!isError) return undefined
      return t([
        formik.errors?.[name] as TFuncKey,
        'common.invalidInput',
      ]) as string
    }, [t, isError, formik, name])

    return (
      <>
        <BaseTextField
          id={name}
          name={name}
          value={formik.values?.[name]}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={isError}
          size="small"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
        <FormHelperText>{helperText}</FormHelperText>
      </>
    )
  },
)
FormikTextField.displayName = 'FormikTextField'
