import { useTranslation } from '@pasteltech/i18n-react'
import { memo, useCallback, useState, useEffect } from 'react'
import { PageTitle, PrintWindow, TransferPreviewForm } from '../../components'
import { WhitePrintIcon } from '../../icons'
import { Button } from '@mui/material'
import styled from 'styled-components'
import { BackButton } from '../../components/buttons'
import { AppRoutes } from '../../routers'
import { useParams } from 'react-router-dom'
import QRCode from 'qrcode'
import { useOverlay } from '@pasteltech/overlay-provider'

const PageContainer = styled.div`
  width: 100%;
`

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
`

const ActionContainer = styled.div`
  display: flex;
  column-gap: 20px;
`

const IconButton = styled(Button)`
  display: flex;
  column-gap: 10px;
  padding: 8px 20px;
`

const PrintButton = styled(IconButton)`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
`

type AssetTransferPreviewPagePropType = {
  isPreviewAsset?: boolean
}

/**
 * @param isPreviewAsset show the asset record
 * */
export const AssetTransferPreviewPage = memo<AssetTransferPreviewPagePropType>(
  ({ isPreviewAsset }) => {
    const { t } = useTranslation()
    const { id } = useParams()
    const { showDialog } = useOverlay()
    const [QRCodeURL, setQRCodeURL] = useState<string>()
    const generateQR = useCallback(
      async (assetId: string) => {
        try {
          const url = await QRCode.toDataURL(
            window.location.protocol +
              window.location.host +
              AppRoutes.assetView.render(assetId),
          )
          setQRCodeURL(url)
        } catch (err) {
          await showDialog({
            title: t('error.default.title'),
            content: t('error.default.content', {
              error: (err as Error).message,
            }),
          })
        }
      },
      [t, showDialog],
    )
    const [isPrint, setIsPrint] = useState(false)
    const closePrint = useCallback(() => {
      setIsPrint(false)
    }, [])
    const startPrint = useCallback(() => {
      setIsPrint(true)
    }, [])

    useEffect(() => {
      if (id) generateQR(id)
    }, [id, generateQR])
    return (
      <PageContainer>
        <PageTitle>
          {t(
            isPreviewAsset
              ? 'assetViewPage.buttons.print'
              : 'assetTransferRecordPage.title.transferRecord',
          )}
        </PageTitle>
        <ActionBar>
          <BackButton
            url={
              isPreviewAsset
                ? AppRoutes.assetView.render(id ?? '')
                : AppRoutes.assetTransferView.render(id ?? '')
            }
          />
          <ActionContainer>
            <PrintButton variant="contained" onClick={startPrint}>
              <WhitePrintIcon />
              {t('assetTransferRecordPage.button.print')}
            </PrintButton>
          </ActionContainer>
        </ActionBar>
        <TransferPreviewForm
          isPreviewAsset={isPreviewAsset}
          QRCodeURL={QRCodeURL}
        />
        {isPrint && (
          <PrintWindow
            onClose={closePrint}
            title={
              isPreviewAsset
                ? t('assetViewPage.previewForm.titleLine2')
                : t('assetTransferRecordPage.previewForm.titleLine2')
            }
          >
            <TransferPreviewForm
              isPreviewAsset={isPreviewAsset}
              QRCodeURL={QRCodeURL}
            />
          </PrintWindow>
        )}
      </PageContainer>
    )
  },
)

AssetTransferPreviewPage.displayName = 'AssetTransferPreviewPage'
