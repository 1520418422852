import {
  FormDateField,
  FormSelectField,
  FormTextField,
} from '../../form-fields'
import { FieldProps, FormAction } from './tabs-form-fields'

export const PurchaseRecordFormFields: FieldProps[] = [
  {
    field: 'purchasedDateTime',
    element: FormDateField,
    actions: [FormAction.create, FormAction.edit, FormAction.view],
  },
  {
    field: 'providerName',
    element: FormTextField,
    actions: [FormAction.create, FormAction.edit, FormAction.view],
  },
  {
    field: 'invoiceNo',
    element: FormTextField,
    actions: [FormAction.create, FormAction.edit, FormAction.view],
  },
  {
    field: 'warrantyProvider',
    element: FormTextField,
    actions: [FormAction.create, FormAction.edit, FormAction.view],
  },
  {
    field: 'warrantyPeriod',
    element: FormTextField,
    actions: [FormAction.create, FormAction.edit, FormAction.view],
  },
  {
    field: 'fundings',
    element: FormSelectField,
    actions: [FormAction.create, FormAction.edit, FormAction.view],
    readOnlyActions: [FormAction.view],
  },
]
