export const systemDataManagement = {
  crudTable: {
    title: '{{name}}管理',
    create: '新增{{name}}',
    edit: '編輯{{name}}',
  },
  tableNames: {
    unit: '單位',
    user: '使用者',
    assetClass: '資產類別',
    fundingSource: '資金來源',
    reference: '參考資料',
  },
  fields: {
    assetType: {
      typeCN: '資產類別(中文)',
      typeEN: '資產類別(英文)',
    },
    unit: {
      nameCN: '單位名稱(中文)',
      nameEN: '單位名稱(英文)',
    },
    name: '名稱',
    unitCode: '單位編號',
    accountingAnalyst: '會計分析',
    unitStatus: '單位狀況',
    userStatus: '用戶狀態',
    fundingStatus: '資金狀況',
    assetTypeStatus: '類別狀況',
    filler: '填表人',
    beInChargeOf: '主管',

    sectionSuperviser: '單位主管',

    username: '使用者名稱',
    email: '電郵',
    position: '職位',
    competence: '權限',
    posibileUnit: '負責單位',

    pageName: '頁面名稱',
    description: '描述',
    dataType: '數據類型',
    content: '數據內容',
    referenceContent: '資料內容',
  },
  fieldsValidateError: {
    funding: {
      code: {
        required: '請提供基金編號',
      },
      name: {
        required: '請提供基金名稱',
      },
      status: { required: '請提供資金狀態' },
    },
    assetType: {
      code: {
        required: '請提供簡稱',
      },
      typeCN: {
        required: '請提供資產類別(中文)',
      },
      typeEN: {
        required: '請提供資產類別(英文)',
      },
      contents: {
        required: '請提供內容',
      },
      status: { required: '請提供資產類別狀態' },
    },
    unit: {
      code: { required: '請提供單位編號' },
      nameCN: { required: '請提供單位名稱(中文)' },
      nameEN: { required: '請提供單位名稱(英文)' },
      status: { required: '請提供單位狀況' },
      supervisorUserId: { required: '請提供單位主管' },
      unitUserId: { required: '請提供主管' },
    },
    user: {
      username: { required: '請提供使用者名稱' },
      email: { required: '請提供電郵' },
      position: { required: '請提供職位' },
      roleId: { required: '請提供權限' },
      status: { required: '請提供用戶狀態' },
    },
  },
  unitStatus: {
    active: '營運單位',
    inactive: '非營運單位',
    all: '全部單位',
  },
  userStatus: {
    active: '有效用戶',
    inactive: '無效用戶',
    all: '全部用戶',
  },
  fundingStatus: {
    active: '有效資金',
    inactive: '無效資金',
    all: '全部資金',
  },
  assetTypeStatus: {
    active: '有效類別',
    inactive: '無效類別',
    all: '全部類別',
  },
  userRole: {
    supervisor: '主管',
    systemAdministrator: '系統管理員',
    doer: '填表人',
    'testing Role': '測試', // TODO remove this when testing Role is not needed (SFAS-293)
  },
  confirmPopup: {
    disable: {
      title: '停用項目',
      content: '確認停用項目?',
    },
    addNew: {
      unitManagementTitle: '現有單位管理',
      userManagementTitle: '使用者內容',
      createReferenceMaterialTitle: '參考資料頁面內容',
      editReferenceMaterialTitle: '新增頁面內容',
    },
  },
  success: '已成功{{action}}',
}
