import {
  useAssetTypeList,
  useFundingList,
  useReferencePageList,
} from '@stewards-fas/queries'
import { useTranslation } from '@pasteltech/i18n-react'
import { memo, useState, useMemo } from 'react'
import styled from 'styled-components'
import ReactHtmlParser from 'react-html-parser'
import {
  AppManagementTab,
  AppManagementTabItem,
  AppManagementTabItemType,
  FormAction,
  referenceInformationTabItems,
  SystemManagementTable,
} from '../../components'

const Container = styled.div`
  width: 100%;
  padding: 20px 0px;
`

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;
`

const HtmlContainer = styled.div`
  padding-top: 20px;
`

export const ReferenceInformationPage = memo(() => {
  const [selectedTab, setSelectedTab] = useState<AppManagementTabItemType>(
    referenceInformationTabItems[0],
  )

  const { t } = useTranslation()

  const { data: assetTypes } = useAssetTypeList()

  const { data: fundings } = useFundingList()

  const { data: referencePages } = useReferencePageList()

  const data = useMemo<{ [key: string]: any }>(
    () => ({
      assetTypes:
        assetTypes?.data.filter((it) => it.status.toLowerCase() === 'active') ??
        [],
      fundings:
        fundings?.data.filter((it) => it.status.toLowerCase() === 'active') ??
        [],
      assetGuidelines:
        referencePages?.data.find(
          (it: any) => it.description == 'Fixed-Assets-Guidelines',
        ) ?? '',
      priceProcurement:
        referencePages?.data.find(
          (it: any) => it.description == 'Asset-Procurement',
        ) ?? '',
      priceComparing:
        referencePages?.data.find(
          (it: any) => it.description == 'Price-Comparing',
        ) ?? '',
    }),
    [assetTypes, fundings, referencePages],
  )

  return (
    <Container>
      <TabsContainer>
        {referenceInformationTabItems.map((item) => (
          <AppManagementTab
            key={item.value}
            value={item.value}
            titleKey={item.titleKey}
            icon={item.icon}
            content={item.content}
            isSelectedTab={selectedTab.value === item.value}
            onSelect={(value) =>
              setSelectedTab(
                referenceInformationTabItems.find(
                  (tab) => tab.value === value,
                )!,
              )
            }
            type="inline"
          />
        ))}
      </TabsContainer>

      {[
        AppManagementTabItem.referenceInformationFixedAssetGuideline,
        AppManagementTabItem.referenceInformationAssetPurchaseFrom,
        AppManagementTabItem.referenceInformationPriceComparisonTable,
      ].includes(selectedTab.value) ? (
        <HtmlContainer>
          {ReactHtmlParser(
            selectedTab?.dataKey ? data[selectedTab.dataKey].contents : '',
          )}
        </HtmlContainer>
      ) : (
        <SystemManagementTable
          key={selectedTab.value}
          title={t(selectedTab.titleKey) as string}
          tab={selectedTab.value}
          mode={FormAction.view}
          data={selectedTab?.dataKey ? data[selectedTab.dataKey] : []}
        />
      )}
    </Container>
  )
})

ReferenceInformationPage.displayName = 'ReferenceInformationPage'
