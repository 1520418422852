import {
  ApiFormattedError,
  getUnit,
  getUnitList,
  patchUnit,
  postUnit,
} from '@stewards-fas/apis'
import {
  PatchUnitPayload,
  PostUnitPayload,
  UnitListRequestPayload,
  UnitListResponsePayload,
  UnitResponsePayload,
} from '@stewards-fas/schemas'
import {
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import {
  QUERY_ROOT_KEY_UNITS,
  QUERY_ROOT_KEY_USERS,
  useOptsOnSuccessInvalidate,
} from './helper'

const EMPTY_PAYLOAD = 'empty-payload'

function useOpts<R, E, P>(opts?: UseMutationOptions<R, E, P>) {
  return useOptsOnSuccessInvalidate(
    [QUERY_ROOT_KEY_UNITS, QUERY_ROOT_KEY_USERS],
    opts,
  )
}

export function useUnitList(
  param?: UnitListRequestPayload,
  opts?: UseQueryOptions<unknown, unknown, UnitListResponsePayload>,
) {
  return useQuery(
    [QUERY_ROOT_KEY_UNITS, param] as QueryKey,
    () => getUnitList(param),
    opts,
  )
}

export function useQueryUnit(
  id: string,
  param?: UnitListRequestPayload,
  opts?: UseQueryOptions<unknown, unknown, UnitResponsePayload>,
) {
  return useQuery(
    [QUERY_ROOT_KEY_UNITS, param] as QueryKey,
    () => getUnit(id, param),
    opts,
  )
}

export function usePostUnitMutation(
  opts?: UseMutationOptions<
    UnitResponsePayload,
    ApiFormattedError,
    PostUnitPayload
  >,
) {
  const options = useOpts(opts)
  return useMutation(async (payload) => {
    const res = await postUnit(payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, options)
}

export function usePatchUnitMutation(
  opts?: UseMutationOptions<
    UnitResponsePayload,
    ApiFormattedError,
    PatchUnitPayload
  >,
) {
  const options = useOpts(opts)
  return useMutation(async (payload) => {
    const res = await patchUnit(payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, options)
}
