type CompareOptions = { isPutFalsyLast?: boolean } | undefined

type Stringy = string | null | undefined

/**
 * Basically localeCompare, but with added options
 * */
export function compareString(
  a: Stringy,
  b: Stringy,
  options?: CompareOptions,
) {
  if (!options?.isPutFalsyLast) {
    return (a ?? '').localeCompare(b ?? '')
  }
  // Put falsy to the last
  if (a && b) {
    return a.localeCompare(b)
  }
  if (a) return 1
  if (b) return -1
  return 0
}
