import {
  useAssetRequestList,
  useGetAsset,
  useGetBlobDownloadUrlForResourcesList,
  useUnitList,
} from '@stewards-fas/queries'
import { memo, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Spinner } from '../../components'
import { mapAssetDataToFormik, mapTransferRecordToTable } from '../../models'
import { AssetEditPageContent } from './content'
import { AssetRequestStatus } from '@stewards-fas/schemas'
import { useTranslation } from '@pasteltech/i18n-react'

export const AssetEditPage = memo(() => {
  const { id } = useParams()
  const { t } = useTranslation()
  const { data: unitList, isLoading: unitListIsLoading } = useUnitList()

  const { data: assetData, isLoading: assetIsLoading } = useGetAsset(id ?? '', {
    expand: [
      'AssetType',
      'FormFillUser',
      'Unit',
      'ParentAssetLinks',
      'ParentAssetLinks.Child',
      'ChildAssetLinks',
      'ChildAssetLinks.Parent',
      'Resources',
      'AssetFundings',
      'AssetFundings.Funding',
    ],
  })
  const { data: assetTransferRequests } = useAssetRequestList({
    type: 'TRANSITION',
    expand: ['AssetRequestAssets.Asset', 'FromUnit', 'ToUnit'],
  })

  const transferRecord = assetTransferRequests?.data
    .filter((it) => it.assetRequestAssets[0].asset.id === id)
    .filter(
      (record) =>
        record.transitStatus != AssetRequestStatus.withdrew &&
        record.receiveStatus != AssetRequestStatus.withdrew,
    )
  const {
    data: recourseWithValidUrl,
    isLoading: recourseWithValidUrlIsLoading,
  } = useGetBlobDownloadUrlForResourcesList(assetData?.resources ?? [], {
    enabled: assetData != null,
  })
  const isLoading = useMemo(
    () => assetIsLoading || unitListIsLoading || recourseWithValidUrlIsLoading,
    [assetIsLoading, unitListIsLoading, recourseWithValidUrlIsLoading],
  )

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        assetData &&
        unitList &&
        recourseWithValidUrl !== null && (
          <AssetEditPageContent
            formData={mapAssetDataToFormik({
              ...assetData,
              resources: recourseWithValidUrl ?? [],
            })}
            assetData={assetData}
            transferRecord={mapTransferRecordToTable(
              transferRecord ?? [],
              t(`transferRecordTable.pendingReceive`),
            )}
          />
        )
      )}
    </>
  )
})
AssetEditPage.displayName = 'AssetEditPage'
