import { useTranslation } from '@pasteltech/i18n-react'
import { useMemo } from 'react'
import { AppManagementTabItem } from '../app-management-tab'
import { sortBy } from 'lodash'

export type SystemDataColumn = {
  key: string
  header: string

  type?: string
  options?: Option[]
  multiple?: boolean
}

export type Option = {
  label: string
  value: string
}

export enum ReferencePageKeys {
  'Fixed-Assets-Guidelines',
  'Asset-Procurement',
  'Price-Comparing',
}

export const useSystemDataTable = (
  activeTab: AppManagementTabItem,
  data?: any[],
  options?: Record<string, Option[]>,
) => {
  const rows = useMemo<{ [key: string]: any }[]>(() => {
    switch (activeTab) {
      // Units
      case AppManagementTabItem.systemUnitManagement:
        return (
          data?.map((it) => ({
            id: it.id,
            originalUnit: it.code,
            name: it.name,
            accountingAnalyst: it.accounting,
            isApproved: it.status == 'ACTIVE',
            filter: it.formFillUser?.name ?? 'N/A',
            sectionSuperviser: it.supervisorUser?.name ?? 'N/A',
            userUnits: it.unitUser?.name ?? 'N/A',
          })) ?? []
        )

      // Users
      case AppManagementTabItem.systemUserManagement:
        return (
          data?.map((it) => ({
            id: it.id,
            username: it.username,
            email: it.email,
            department: it.position,
            role: it.role?.name ?? 'User',
            supervisorUserUnits: 'CAO',
            posibileUnit: 'TAC,YTW',
          })) ?? []
        )

      case AppManagementTabItem.systemReferenceMaterialManagement:
        return (
          data
            ?.filter((it) => it.description in ReferencePageKeys)
            .map((it) => ({
              id: it.id,
              name: it.name,
              description: it.description,
              type: it.type,
              contents: it.contents,
            })) ?? []
        )

      // Asset types
      case AppManagementTabItem.systemAssetClassManagement:
      case AppManagementTabItem.referenceInformationAssetType:
        return sortBy(
          data?.map((it) => ({
            id: it.id,
            code: it.code,
            typeEN: it.typeEN,
            typeCN: it.typeCN,
            contents: it.contents,
          })) ?? [],
          ['code'],
        )

      // Fundings
      case AppManagementTabItem.systemFundingResourceManagement:
      case AppManagementTabItem.referenceInformationSourcesOfFunds:
        return sortBy(
          data?.map((it) => ({
            id: it.id,
            code: it.code,
            name: it.name,
          })) ?? [],
          ['code'],
        )

      default:
        return []
    }
  }, [activeTab, data])

  const { t } = useTranslation()

  const columns: SystemDataColumn[] = useMemo(() => {
    switch (activeTab) {
      case AppManagementTabItem.systemUnitManagement:
        return [
          {
            key: 'originalUnit',
            header: t('systemDataManagement.fields.unitCode'),
          },
          {
            key: 'chineseName',
            header: t('systemDataManagement.fields.unit.nameCN'),
          },
          {
            key: 'accountingAnalyst',
            header: t('systemDataManagement.fields.accountingAnalyst'),
          },
          {
            key: 'unitStatus',
            header: t('systemDataManagement.fields.unitStatus'),
          },
          {
            key: 'filter',
            header: t('systemDataManagement.fields.filler'),
          },
          {
            key: 'supervisorUserUnits',
            header: t('systemDataManagement.fields.sectionSuperviser'),
          },
          {
            key: 'userUnits',
            header: t('systemDataManagement.fields.beInChargeOf'),
          },
        ]

      case AppManagementTabItem.systemUserManagement:
        return [
          {
            key: 'username',
            header: t('systemDataManagement.fields.username'),
          },
          { key: 'email', header: t('systemDataManagement.fields.email') },
          {
            key: 'department',
            header: t(['systemDataManagement.fields.position']),
          },
          {
            key: 'role',
            header: t('systemDataManagement.fields.competence'),
            type: 'SELECT',
            options: options?.roles,
          },
          {
            key: 'sectionSuperviser',
            header: t('systemDataManagement.fields.sectionSuperviser'),
            type: 'SELECT',
            options: options?.units,
          },
          {
            key: 'posibileUnit',
            header: t('systemDataManagement.fields.posibileUnit'),
            type: 'SELECT',
            options: options?.units ?? [],
            multiple: true,
          },
        ]

      case AppManagementTabItem.systemReferenceMaterialManagement:
        return [
          {
            key: 'pageName',
            header: t('systemDataManagement.fields.pageName'),
          },
          {
            key: 'description',
            header: t('systemDataManagement.fields.description'),
          },
          {
            key: 'dataType',
            header: t('systemDataManagement.fields.dataType'),
          },
          {
            key: 'content',
            header: t('systemDataManagement.fields.content'),
            type: 'TEXT_AREA',
          },
        ]

      case AppManagementTabItem.systemAssetClassManagement:
      case AppManagementTabItem.referenceInformationAssetType:
        return [
          {
            key: 'shortName',
            header: t('referenceInformation.fields.shortName'),
          },
          {
            key: 'englishName',
            header: t('referenceInformation.fields.englishName'),
          },
          {
            key: 'chineseName',
            header: t('referenceInformation.fields.chineseName'),
          },
          {
            key: 'detail',
            header: t('referenceInformation.fields.detail'),
          },
        ]

      case AppManagementTabItem.systemFundingResourceManagement:
      case AppManagementTabItem.referenceInformationSourcesOfFunds:
        return [
          {
            key: 'fundNumber',
            header: t('referenceInformation.fields.fundNumber'),
          },
          {
            key: 'fundName',
            header: t('referenceInformation.fields.fundName'),
          },
        ]

      default:
        return []
    }
  }, [activeTab, t, options])

  return {
    rows,
    columns,
    options,
  }
}
