import { QueryKey, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

// TODO: need to refactor useOptsWithInvalidate to this file

export function useInvalidateQueries(queryKey?: QueryKey) {
  const client = useQueryClient()

  return useCallback(async () => {
    await client.invalidateQueries({ queryKey })
  }, [])
}
