import { Tab, Tabs } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import dayjs, { Dayjs } from 'dayjs'
import { memo, useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { PageTitle } from '../../../components'
import { PaperMenuIcon, WriteOffIcon } from '../../../icons'
import { AppRoutes } from '../../../routers'
import {
  WriteOffAssetData,
  WriteOffContext,
  WriteOffSectionTabItem,
} from './context'
import {
  WriteOffDetail,
  WriteOffItemsTab,
  WriteOffRequestRecordsTab,
} from './page-content'

const Container = styled.div`
  max-height: 800px;
`

const StyledTabs = styled(Tabs)`
  margin-bottom: 20px;
`
const StyledTab = styled(Tab)`
  display: flex;
  flex-direction: row;
  align-items: center;
  & .MuiTab-iconWrapper {
    margin: 0;
  }
  column-gap: 10px;
`

export const AssetManagementWriteOffSection = memo(() => {
  const navigate = useNavigate()

  const { tab: defaultTabParam } = useParams()
  const defaultTabValue = useMemo(() => {
    if (
      [
        WriteOffSectionTabItem.reimbursementItems,
        WriteOffSectionTabItem.reimbursementRecords,
      ].some((tabType) => tabType === defaultTabParam)
    )
      return defaultTabParam as WriteOffSectionTabItem
    return WriteOffSectionTabItem.reimbursementItems
  }, [defaultTabParam])

  const [tabValue, setTabValue] =
    useState<WriteOffSectionTabItem>(defaultTabValue)
  const [writeOffList, setWriteOffList] = useState([] as WriteOffAssetData)
  const [writeOffDate, setWriteOffDate] = useState<Dayjs | null>(dayjs())
  const [isViewForm, setIsViewForm] = useState(false)
  const [viewFormId, setViewFormId] = useState('')

  const onTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: WriteOffSectionTabItem) => {
      setTabValue(newValue)
      navigate(AppRoutes.assetManagement.writeOffSection.render(newValue))
    },
    [navigate],
  )

  const { t } = useTranslation()
  const contextValue = useMemo(() => {
    return {
      writeOffList,
      setWriteOffList,
      writeOffDate,
      setWriteOffDate,
      isViewForm,
      setIsViewForm,
      tabValue,
      setTabValue,
      viewFormId,
      setViewFormId,
    }
  }, [writeOffList, writeOffDate, isViewForm, tabValue, viewFormId])

  return (
    <WriteOffContext.Provider value={contextValue}>
      <Container>
        {isViewForm ? (
          <WriteOffDetail />
        ) : (
          <>
            <PageTitle>
              {t('assetManagement.writeOffSection.title.writeOffAsset')}
            </PageTitle>

            <StyledTabs
              variant="fullWidth"
              value={tabValue}
              onChange={onTabChange}
            >
              <StyledTab
                icon={<WriteOffIcon />}
                label={t('assetManagement.writeOffSection.tabs.items')}
                id={WriteOffSectionTabItem.reimbursementItems}
                value={WriteOffSectionTabItem.reimbursementItems}
              />
              <StyledTab
                icon={<PaperMenuIcon />}
                label={t('assetManagement.writeOffSection.tabs.records')}
                id={WriteOffSectionTabItem.reimbursementRecords}
                value={WriteOffSectionTabItem.reimbursementRecords}
              />
            </StyledTabs>

            {tabValue === WriteOffSectionTabItem.reimbursementItems && (
              <WriteOffItemsTab />
            )}
            {tabValue === WriteOffSectionTabItem.reimbursementRecords && (
              <WriteOffRequestRecordsTab />
            )}
          </>
        )}
      </Container>
    </WriteOffContext.Provider>
  )
})

AssetManagementWriteOffSection.displayName = 'AssetManagementWriteOffSection'
