import { useTranslation } from '@pasteltech/i18n-react'
import { Status, UnitResponsePayload } from '@stewards-fas/schemas'
import { useMemo } from 'react'
import {
  CRUDTableActions,
  FieldMapping,
  InputType,
} from '../../../../components'
import { ActiveStatus } from '../../ui-components'
import { Role } from '../../../../models'

const MAXIMUM_USER_DISPLAY = 3
const ELLIPSIS = '...'

export function useUnitFields() {
  const { t } = useTranslation()
  const mappings = useMemo(
    () => ({
      nameCN: {
        key: 'nameCN',
        value: (it) => it.nameCN,
        display: (it) => it.nameCN,
        inputType: InputType.text,
        label: t('systemDataManagement.fields.unit.nameCN'),
      } as FieldMapping<{ nameCN: string }>,
      nameEN: {
        key: 'nameEN',
        value: (it) => it.nameEN,
        display: (it) => it.nameEN,
        inputType: InputType.text,
        label: t('systemDataManagement.fields.unit.nameEN'),
      } as FieldMapping<{ nameEN: string }>,
      code: {
        key: 'code',
        value: (it) => it.code,
        display: (it) => it.code,
        inputType: InputType.text,
        label: t('systemDataManagement.fields.unitCode'),
        disableMode: [CRUDTableActions.edit],
      } as FieldMapping<{ code?: string }>,
      accounting: {
        key: 'accounting',
        value: (it) => it.accounting,
        display: (it) => it.accounting,
        inputType: InputType.text,
        label: t('systemDataManagement.fields.accountingAnalyst'),
      } as FieldMapping<{ accounting?: string }>,
      filler: {
        key: 'formFillUserIds',
        value: (it: UnitResponsePayload) => {
          if (!it.userUnits) return []
          return it.userUnits.reduce((acc: string[], cur) => {
            if (!cur.user?.role) return acc
            if (cur.user?.role.name !== Role.doer) return acc
            return [...acc, cur.user.id]
          }, [])
        },
        display: (it: UnitResponsePayload) => {
          const doerList = it.userUnits.reduce((acc: string[], cur) => {
            if (!cur.user?.role) return acc
            if (cur.user?.role.name !== Role.doer) return acc
            return [...acc, cur.user.username]
          }, [])
          if (doerList.length > MAXIMUM_USER_DISPLAY)
            return doerList.slice(0, MAXIMUM_USER_DISPLAY).join(', ') + ELLIPSIS
          if (doerList.length > 0) return doerList.join(', ')
          return 'N/A'
        },
        inputType: InputType.selectMultiFormFiller,
        label: t('systemDataManagement.fields.filler'),
      } as FieldMapping<any>, // used for mapping userUnits
      status: {
        key: 'status',
        value: (it) => it.status ?? 'active',
        display: (it) => {
          const lower = it.status.toLowerCase()
          if (['active', 'inactive'].includes(lower)) {
            return <ActiveStatus status={lower as Status} />
          }
          return 'undefined'
        },
        inputType: InputType.selectUnitStatus,
        label: t('systemDataManagement.fields.unitStatus'),
      } as FieldMapping<{ status: string }>,
      sectionSuperviser: {
        key: 'supervisorUserId',
        value: (it) => it.supervisorUserId,
        display: (it) => it.supervisorUser?.username ?? 'N/A',
        inputType: InputType.selectSuperVisorAndAdmin,
        label: t('systemDataManagement.fields.sectionSuperviser'),
      } as FieldMapping<UnitResponsePayload>,
      supervisorList: {
        key: 'supervisorIds',
        value: (it: UnitResponsePayload) => {
          if (!it.userUnits) return []
          return it.userUnits.reduce((acc: string[], cur) => {
            if (!cur.user?.role) return acc
            if (cur.user?.role.name !== Role.supervisor) return acc
            return [...acc, cur.user.id]
          }, [])
        },
        display: (it: UnitResponsePayload) => {
          const supervisorList = it.userUnits.reduce((acc: string[], cur) => {
            if (!cur.user?.role) return acc
            if (cur.user?.role.name !== Role.supervisor) return acc
            return [...acc, cur.user.username]
          }, [])
          if (supervisorList.length > MAXIMUM_USER_DISPLAY)
            return (
              supervisorList.slice(0, MAXIMUM_USER_DISPLAY).join(', ') +
              ELLIPSIS
            )
          if (supervisorList.length > 0) return supervisorList.join(', ')
          return 'N/A'
        },
        inputType: InputType.selectMultiSuperVisorAndAdmin,
        label: t('systemDataManagement.fields.beInChargeOf'),
      } as FieldMapping<any>, // used for mapping userUnits
      rowVersion: {
        key: 'rowVersion',
        value: (it) => it.rowVersion,
        display: undefined,
        inputType: undefined,
        label: undefined,
      } as FieldMapping<{ rowVersion?: string }>,
      initSupervisorList: {
        key: 'initSupervisorList',
        value: (it: UnitResponsePayload) => {
          if (!it.userUnits) return []
          const supervisorList = it.userUnits.filter((userUnit) => {
            if (!userUnit.user?.role) return false
            if (userUnit.user.role.name === Role.supervisor) return true
            return false
          })
          return supervisorList as any
        },
      } as FieldMapping<any>, // used for mapping
      initFillerList: {
        key: 'initFillerList',
        value: (it: UnitResponsePayload) => {
          if (!it.userUnits) return []
          const fillerList = it.userUnits.filter((userUnit) => {
            if (!userUnit.user?.role) return false
            if (userUnit.user.role.name === Role.doer) return true
            return false
          })
          return fillerList as any
        },
      } as FieldMapping<any>, // used for mapping
    }),
    [t],
  )
  return { mappings }
}
