import { TFuncKey, useTranslation } from '@pasteltech/i18n-react'
import { FormikErrors } from 'formik'

export const useFormikErrorHelper = () => {
  const { t } = useTranslation()

  const formikErrorExtractor = (
    errors: FormikErrors<any>,
    maximumNumberOfError: number,
    omitFields?: string[],
  ): string => {
    const errorMessage: string[] = Object.entries(errors).reduce(
      (acc, [key, value]) => {
        if (omitFields?.includes(key)) return acc
        if (acc.length === maximumNumberOfError) return [...acc, '...']
        if (acc.length > maximumNumberOfError) return acc
        return [
          ...acc,
          t([
            value as TFuncKey,
            'assetDetailsPage.form.errorMessages.invalidInput',
          ]) as string,
        ]
      },
      [] as string[],
    )
    return errorMessage.join('\n')
  }
  const setAllFieldsTouched = (
    values: Record<string, any>,
    setFieldTouch: (
      field: string,
      isTouched?: boolean | undefined,
      shouldValidate?: boolean | undefined,
    ) => void,
    omitFields?: string[],
  ): void => {
    Object.keys(values).forEach((key) => {
      if (omitFields?.includes(key)) return
      setFieldTouch(key)
    })
  }
  return { formikErrorExtractor, setAllFieldsTouched }
}
