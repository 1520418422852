import { AssetRequestsListRequestPayload } from '@stewards-fas/schemas'
import { PropsWithChildren, memo, useMemo, useState } from 'react'
import {
  AssetManagementSearchParam,
  SearchParamContext,
} from './search-param-context'

type Props = PropsWithChildren

export const SearchParamProvider = memo<Props>(({ children }) => {
  const [
    isAssetManagementSearchButtonClicked,
    setIsAssetManagementSearchButtonClicked,
  ] = useState(false)

  const [assetManagementSearchParam, setAssetManagementSearchParam] =
    useState<AssetManagementSearchParam>({})
  const [
    writeOffAssetManagementSearchParam,
    setWriteOffAssetManagementSearchParam,
  ] = useState<AssetRequestsListRequestPayload>({})

  const context = useMemo(
    () => ({
      isAssetManagementSearchButtonClicked,
      setIsAssetManagementSearchButtonClicked,
      assetManagementSearchParam,
      setAssetManagementSearchParam,
      writeOffAssetManagementSearchParam,
      setWriteOffAssetManagementSearchParam,
    }),
    [
      assetManagementSearchParam,
      isAssetManagementSearchButtonClicked,
      writeOffAssetManagementSearchParam,
    ],
  )

  return (
    <SearchParamContext.Provider value={context}>
      {children}
    </SearchParamContext.Provider>
  )
})

SearchParamProvider.displayName = 'SearchParamProvider'
