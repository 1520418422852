import {
  AssetRequestStatus,
  AssetRequestsResponsePayload,
  AssetResponsePayload,
  PatchAssetPayload,
  PostAssetPayload,
} from '@stewards-fas/schemas'
import dayjs from 'dayjs'
import {
  InferType,
  array,
  boolean,
  date,
  mixed,
  number,
  object,
  string,
} from 'yup'
import { TableProps } from '../components'
import { formats } from '../theme'
import { convertToStartOfDay, makeFieldNullish } from '../utilities'
const ERROR_TRANSLATION_PREFIX = 'assetDetailsPage.form.errorMessages'

const assetDetailsFormCommonSchema = object({
  // required fields
  name: string().required(`${ERROR_TRANSLATION_PREFIX}.name.required`),
  unit: string().required(`${ERROR_TRANSLATION_PREFIX}.unit.required`),
  assetType: string().required(
    `${ERROR_TRANSLATION_PREFIX}.assetType.required`,
  ),
  year: number()
    .typeError(`${ERROR_TRANSLATION_PREFIX}.year.typeError`)
    .required(`${ERROR_TRANSLATION_PREFIX}.year.required`)
    .positive(`${ERROR_TRANSLATION_PREFIX}.year.positive`)
    .integer(`${ERROR_TRANSLATION_PREFIX}.year.integer`),
  brand: string().required(`${ERROR_TRANSLATION_PREFIX}.brand.required`),
  model: string().required(`${ERROR_TRANSLATION_PREFIX}.model.required`),
  valuedAmount: number()
    .typeError(`${ERROR_TRANSLATION_PREFIX}.valuedAmount.typeError`)
    .required(`${ERROR_TRANSLATION_PREFIX}.valuedAmount.required`)
    .positive(`${ERROR_TRANSLATION_PREFIX}.valuedAmount.positive`)
    .test(
      'two decimal only',
      `${ERROR_TRANSLATION_PREFIX}.valuedAmount.decimal`,
      (val: any) => {
        let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/
        if (val != undefined) {
          return patternTwoDigisAfterComma.test(val)
        }
        return true
      },
    ),
  supervisor: string().required(),
  purchasedDateTime: date().required(
    `${ERROR_TRANSLATION_PREFIX}.purchasedDateTime.required`,
  ),
  providerName: string().required(
    `${ERROR_TRANSLATION_PREFIX}.providerName.required`,
  ),
  invoiceNo: string().required(
    `${ERROR_TRANSLATION_PREFIX}.invoiceNo.required`,
  ),

  // nullish fields
  atUnitNotes: makeFieldNullish(string()),
  assetNo: makeFieldNullish(string()),
  serialNo: makeFieldNullish(string()),
  remarks: makeFieldNullish(string()),
  warrantyProvider: makeFieldNullish(string()),
  warrantyPeriod: makeFieldNullish(string()),
  receipt: makeFieldNullish(string()),
  formFiller: makeFieldNullish(string()),

  transferRecord: mixed().optional(),
  fundings: string().optional(),
})

export const CreateAssetFormSchema = assetDetailsFormCommonSchema.concat(
  object({
    batchInput: boolean().optional(),
    quantity: number()
      .typeError(`${ERROR_TRANSLATION_PREFIX}.quantity.typeError`)
      .when('batchInput', ([batchInput], schema) => {
        if (batchInput) {
          return schema.optional()
        }
        return schema
          .required(`${ERROR_TRANSLATION_PREFIX}.quantity.required`)
          .integer(`${ERROR_TRANSLATION_PREFIX}.quantity.integer`)
          .min(1, `${ERROR_TRANSLATION_PREFIX}.quantity.min`)
          .max(10000, `${ERROR_TRANSLATION_PREFIX}.quantity.max`)
      }),
    totalPieces: number()
      .typeError(`${ERROR_TRANSLATION_PREFIX}.totalPieces.typeError`)
      .required(`${ERROR_TRANSLATION_PREFIX}.totalPieces.required`)
      .when('batchInput', ([batchInput], schema) => {
        if (batchInput) {
          return schema
            .min(2, `${ERROR_TRANSLATION_PREFIX}.totalPieces.min`)
            .max(100, `${ERROR_TRANSLATION_PREFIX}.totalPieces.max`)
            .integer(`${ERROR_TRANSLATION_PREFIX}.totalPieces.integer`)
        }
        return schema.optional()
      }),
    assetBulk: array()
      .of(
        object().shape({
          serialNo: string().required(
            `${ERROR_TRANSLATION_PREFIX}.serialNo.required`,
          ),
        }),
      )
      .required()
      .when('batchInput', ([batchInput], schema) => {
        if (batchInput) {
          return schema.test(
            'check-empty-field',
            `${ERROR_TRANSLATION_PREFIX}.assetBulk.emptyField`,
            function (value) {
              const totalPieces = this.parent.totalPieces
              if (totalPieces !== undefined && value !== undefined) {
                return value.length == totalPieces
              }
              return true
            },
          )
        }
        return schema.optional()
      }),
    createResources: array()
      .of(
        object().shape({
          mime: string().required(),
          filename: string().required(),
          url: string().required(),
          order: number().integer().required(),
        }),
      )
      .required(),
  }),
)

export const EditAssetFormSchema = assetDetailsFormCommonSchema.concat(
  object({
    quantity: number()
      .typeError(`${ERROR_TRANSLATION_PREFIX}.quantity.typeError`)
      .required(`${ERROR_TRANSLATION_PREFIX}.quantity.required`)
      .integer(`${ERROR_TRANSLATION_PREFIX}.quantity.integer`)
      .min(1, `${ERROR_TRANSLATION_PREFIX}.quantity.min`)
      .max(10000, `${ERROR_TRANSLATION_PREFIX}.quantity.max`),
    rowVersion: string().required(),
    status: string().optional(),
    lastModifiedDateTime: date().optional(),
    editResources: array()
      .of(
        object().shape({
          mime: string().required(),
          filename: string().required(),
          url: string().required(),
          order: number().integer().required(),
          id: string().optional(),
          rowVersion: string().optional(),
          isDeleted: boolean().optional(),
          assetId: string().required(),
        }),
      )
      .required(),
  }),
)
export const AssetFormSchema = CreateAssetFormSchema.concat(EditAssetFormSchema)
export type CreateAssetFormSchemaModel = InferType<typeof CreateAssetFormSchema>
export type EditAssetFormSchemaModel = InferType<typeof EditAssetFormSchema>

export type AssetFormSchemaModel = CreateAssetFormSchemaModel &
  EditAssetFormSchemaModel

export const CreateAssetFormInitialValues: CreateAssetFormSchemaModel = {
  unit: '',
  assetType: '',
  year: undefined as any, // forcly set empty by default
  assetNo: '',
  name: '',
  brand: '',
  model: '',
  batchInput: false,
  quantity: undefined,
  serialNo: '',
  valuedAmount: undefined as any, // forcly set empty by default
  remarks: '',
  supervisor: '',
  purchasedDateTime: undefined as any, // forcly set empty by default
  providerName: '',
  invoiceNo: '',
  warrantyProvider: '',
  warrantyPeriod: '',
  atUnitNotes: '',
  formFiller: undefined,
  totalPieces: undefined as any, // forcly set empty by default
  assetBulk: [],
  createResources: [],
  fundings: undefined,
}

export type AssetFormikData = Omit<
  CreateAssetFormSchemaModel & EditAssetFormSchemaModel,
  | 'numberOfPiecesPerTotalPieces'
  | 'batchInput'
  | 'numberOfPieces'
  | 'totalPieces'
>

export const mapAssetDataToFormik = (
  asset: AssetResponsePayload,
): Omit<AssetFormikData, 'createResources' | 'assetBulk'> => {
  return {
    editResources: asset.resources,
    status: asset.status,
    assetNo: asset.assetNo,
    name: asset.name,
    brand: asset.brand,
    model: asset.model,
    quantity: asset.quantity,
    serialNo: asset.serialNo,
    valuedAmount: asset.valuedAmount,
    remarks: asset.remarks,
    purchasedDateTime: dayjs(asset.purchasedDateTime).toDate(),
    providerName: asset.providerName,
    invoiceNo: asset.invoiceNo,
    warrantyProvider: asset.warrantyProvider,
    warrantyPeriod: asset.warrantyPeriod,
    lastModifiedDateTime: dayjs(asset.lastModifiedDateTime).toDate(),
    transferRecord: '',
    fundings:
      asset.assetFundings.length > 0 ? asset.assetFundings[0].fundingId : '',
    receipt: asset.receiptNo ?? undefined,
    year: asset.year,
    rowVersion: asset.rowVersion,
    supervisor: asset.unit?.supervisorUserId ?? '',
    formFiller: asset.formFillUserId,
    unit: asset.unitId,
    assetType: asset.assetType?.id ?? '',
    atUnitNotes: asset.atUnitNotes,
  }
}

function falsyToNull<T>(val: T): T | null {
  return val ? val : null
}
export const mapFormikFundingsToPostPayloadFundings = (
  fundings: string[],
): PostAssetPayload['assetFundings'] => {
  return fundings.map((fundingId) => ({ fundingId }))
}

export const mapFormikToPostAssetPayload = (
  formikInputs: CreateAssetFormSchemaModel,
): Omit<PostAssetPayload, 'parentAssetLinks'> => {
  return {
    name: formikInputs.name,
    year: formikInputs.year,
    unitId: formikInputs.unit,
    assetTypeId: formikInputs.assetType,
    brand: formikInputs.brand,
    model: formikInputs.model,
    quantity: formikInputs.quantity as number,
    valuedAmount: formikInputs.valuedAmount,
    purchasedDateTime: convertToStartOfDay(
      formikInputs.purchasedDateTime.toISOString(),
    ),
    providerName: formikInputs.providerName,
    invoiceNo: formikInputs.invoiceNo,
    supervisorUserId: formikInputs.supervisor,
    formFillUserId: formikInputs.formFiller ?? '',
    resources: formikInputs.createResources,
    assetFundings: formikInputs.fundings
      ? [{ fundingId: formikInputs.fundings }]
      : [],
    atUnitNotes: falsyToNull(formikInputs.atUnitNotes),
    serialNo: falsyToNull(formikInputs.serialNo),
    remarks: falsyToNull(formikInputs.remarks),
    warrantyProvider: falsyToNull(formikInputs.warrantyProvider),
    warrantyPeriod: falsyToNull(formikInputs.warrantyPeriod),
  }
}

export const mapFormikFundingsToPatchPayloadFundings = ({
  currentAssetId,
  oldFundings,
  formikFundings,
}: {
  currentAssetId: string
  oldFundings: AssetResponsePayload['assetFundings']
  formikFundings: string | undefined
}): PatchAssetPayload['assetFundings'] => {
  const request = [] as PatchAssetPayload['assetFundings']

  oldFundings.forEach((oldFunding) => {
    request.push({
      id: oldFunding.id,
      assetId: oldFunding.assetId,
      isDeleted: true,
      fundingId: oldFunding.fundingId,
      rowVersion: oldFunding.rowVersion,
    })
  })

  if (formikFundings) {
    request.push({
      assetId: currentAssetId,
      fundingId: formikFundings,
    })
  }
  return request
}

export const mapFormikToPatchAssetPayload = (
  originData: AssetResponsePayload,
  formikInputs: EditAssetFormSchemaModel,
): Omit<PatchAssetPayload, 'parentAssetLinks'> => {
  return {
    name: formikInputs.name,
    unitId: formikInputs.unit,
    assetTypeId: formikInputs.assetType,
    year: formikInputs.year,
    brand: formikInputs.brand,
    model: formikInputs.model,
    quantity: formikInputs.quantity,
    valuedAmount: formikInputs.valuedAmount,
    supervisorUserId: formikInputs.supervisor ?? '',
    formFillUserId: formikInputs.formFiller ?? '',
    purchasedDateTime: convertToStartOfDay(
      formikInputs.purchasedDateTime.toISOString(),
    ),
    providerName: formikInputs.providerName,
    invoiceNo: formikInputs.invoiceNo,
    receiptNo: formikInputs.receipt ?? '',
    rowVersion: formikInputs.rowVersion,
    resources: formikInputs.editResources,
    assetFundings: mapFormikFundingsToPatchPayloadFundings({
      currentAssetId: originData.id,
      oldFundings: originData.assetFundings,
      formikFundings: formikInputs.fundings,
    }),
    atUnitNotes: falsyToNull(formikInputs.atUnitNotes),
    serialNo: falsyToNull(formikInputs.serialNo),
    remarks: falsyToNull(formikInputs.remarks),
    warrantyProvider: falsyToNull(formikInputs.warrantyProvider),
    warrantyPeriod: falsyToNull(formikInputs.warrantyPeriod),
  }
}

export const mapTransferRecordToTable = (
  transferRecord: AssetRequestsResponsePayload[],
  pendingText: string,
): TableProps => {
  return transferRecord.map((it) => {
    const receptionDate =
      it.receiveStatus === AssetRequestStatus.approved
        ? dayjs(it.receiveDateTime ?? it.lastModifiedDateTime).format(
            formats.date,
          )
        : pendingText

    const departureDate =
      it.transitStatus === AssetRequestStatus.approved
        ? dayjs(it.transitDateTime ?? it.createdDateTime).format(formats.date)
        : pendingText

    return {
      originalUnit: it.fromUnit?.nameCN ?? '',
      departureDate,
      transferUnit: it.toUnit?.nameCN ?? '',
      receptionDate,
    }
  })
}
