import { memo } from 'react'
import {
  FormAutoCompleteField,
  FormSelectField,
  FormTextField,
} from '../form-fields'
import { SystemDataColumn } from './use-system-data-table'

type Props = {
  columns: SystemDataColumn[]
  initialData?: any
}

export const DataFormModalContent = memo<Props>(({ columns, initialData }) => {
  return (
    <div>
      {columns.map((it) => {
        if (it.type === 'SELECT') {
          return it.multiple ? (
            <FormAutoCompleteField
              key={it.key}
              label={it.header}
              options={
                it.options?.map((opIt) => ({
                  value: opIt.value,
                  label: opIt.label,
                })) ?? []
              }
              value={initialData?.[it.key]}
              multiple={it.multiple}
            />
          ) : (
            <FormSelectField
              key={it.key}
              label={it.header}
              name={it.key}
              templateColumns="1fr 2fr"
              options={it.options}
              value={initialData?.[it.key]}
            />
          )
        }

        return (
          <FormTextField
            key={it.key}
            label={it.header}
            name={it.key}
            templateColumns="1fr 2fr"
            defaultValue={initialData?.[it.key]}
            multiline={it.type === 'TEXT_AREA'}
            rows={it.type === 'TEXT_AREA' ? 10 : undefined}
          />
        )
      })}
    </div>
  )
})

DataFormModalContent.displayName = 'DataFormModalContent'
