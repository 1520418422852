import { useTranslation } from '@pasteltech/i18n-react'
import { useOverlay } from '@pasteltech/overlay-provider'
import { usePostAsset, useQueryUserProfile } from '@stewards-fas/queries'
import { AssetResponsePayload, PostAssetPayload } from '@stewards-fas/schemas'
import { FormikProvider, useFormik } from 'formik'
import { memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  AssetDetailsSection,
  FormAction,
  PageTitle,
} from '../../../../components'
import { useApiErrorHandle } from '../../../../hooks'
import {
  CreateAssetFormInitialValues,
  CreateAssetFormSchema,
  CreateAssetFormSchemaModel,
  mapFormikToPostAssetPayload,
} from '../../../../models'
import { AppRoutes } from '../../../../routers'
import { useAssetLink } from '../../../../components/asset-details-section/hooks'

export const AssetManagementAddSectionContent = memo(() => {
  const { t } = useTranslation()
  const { showSnackbar, showSpinner, hideSpinner } = useOverlay()
  const navigate = useNavigate()
  const { mutateAsync: postAssetAsync, isLoading: isPosting } = usePostAsset({})
  const { data: currentUser } = useQueryUserProfile()
  const { standardErrorHandler } = useApiErrorHandle()
  const {
    handleUnlink,
    handleLink,
    displayParentLinks,
    displayChildLinks,
    linkRequest,
    cantLinkAssetIds,
  } = useAssetLink(FormAction.create)

  const handlePosting = useCallback(
    async ({
      payload,
      onSuccess,
    }: {
      payload: PostAssetPayload
      onSuccess?: (res: AssetResponsePayload) => void
    }) => {
      try {
        showSpinner()
        const res = await postAssetAsync({ ...payload, totalPieces: 1 })
        onSuccess?.(res)
      } catch (err) {
        standardErrorHandler(err)
      } finally {
        hideSpinner()
      }
    },
    [postAssetAsync, standardErrorHandler, showSpinner, hideSpinner],
  )

  const handleBulkPosting = useCallback(
    async ({
      values,
      onSuccess,
    }: {
      values: CreateAssetFormSchemaModel
      onSuccess?: () => void
    }) => {
      try {
        showSpinner()
        await Promise.all(
          values.assetBulk?.map((it) =>
            postAssetAsync({
              ...mapFormikToPostAssetPayload({
                ...values,
                quantity: 1,
                serialNo: it.serialNo,
                formFiller: currentUser?.id ?? '',
              }),
              totalPieces: values.totalPieces,
              parentAssetLinks: linkRequest,
            }),
          ),
        )
        onSuccess?.()
      } catch (err) {
        standardErrorHandler(err)
      } finally {
        hideSpinner()
      }
    },
    [
      linkRequest,
      currentUser?.id,
      showSpinner,
      hideSpinner,
      standardErrorHandler,
      postAssetAsync,
    ],
  )

  const formik = useFormik({
    initialValues: CreateAssetFormInitialValues,
    validationSchema: CreateAssetFormSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (values, { resetForm }) => {
      if (isPosting) return
      if (values.batchInput) {
        handleBulkPosting({
          values,
          onSuccess: () => {
            showSnackbar({
              content: t('assetActions.create.success'),
            })
            resetForm()
          },
        })
      } else {
        handlePosting({
          payload: {
            ...mapFormikToPostAssetPayload({
              ...values,
              formFiller: currentUser?.id ?? '',
            }),
            parentAssetLinks: linkRequest,
          },
          onSuccess: (res: AssetResponsePayload) => {
            showSnackbar({
              content: t('assetActions.create.success'),
            })
            navigate(AppRoutes.assetView.render(res.id))
          },
        })
      }
    },
  })

  return (
    <FormikProvider value={formik}>
      <PageTitle>{t('assetCreatePage.title')}</PageTitle>
      <AssetDetailsSection
        action={FormAction.create}
        handleUnlink={handleUnlink}
        handleLink={handleLink}
        displayParentLinks={displayParentLinks}
        displayChildLinks={displayChildLinks}
        cantLinkAssetIds={cantLinkAssetIds}
      />
    </FormikProvider>
  )
})

AssetManagementAddSectionContent.displayName =
  'AssetManagementAddSectionContent'
