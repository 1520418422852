import { Button, Grid } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { useOverlay } from '@pasteltech/overlay-provider'
import {
  useApproveBulkAssetMutation,
  useAssetList,
  useRejectBulkAsset,
} from '@stewards-fas/queries'
import { AssetStatus } from '@stewards-fas/schemas'
import { FormikProvider, useFormik } from 'formik'
import { memo, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  Dialog,
  FormCheckboxField,
  FormTextField,
  GenericTable,
  PageTitle,
  Spinner,
  TableList,
} from '../../../components'
import { useApiErrorHandle, useCurrentUserUnits } from '../../../hooks'
import { ApprovedIcon, WithdrawIcon } from '../../../icons'
import { mapApprovalAssetListToTableRows } from '../../../models'
import { AppRoutes } from '../../../routers'

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
const ApproveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  margin-bottom: 30px;
  column-gap: 20px;
  margin-left: auto;
`
const RejectTable = styled(GenericTable)`
  overflow: auto;
  max-height: 30vh;
  border: 1px solid ${({ theme }) => theme.colors.greys[5]};
`
export const AssetManagementApprovalSection = memo(() => {
  // global
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { showSnackbar, showSpinner, hideSpinner } = useOverlay()
  const { standardErrorHandler } = useApiErrorHandle()
  const { checkPermittedUnitId } = useCurrentUserUnits()

  // constants
  const EMPTY_MSG = useMemo(() => t('common.selectItemFirst'), [t])
  const headers = useMemo(
    () => [
      t('assetManagement.approvalSection.TableHeaders.category'),
      t('assetManagement.searchSection.TableHeaders.assetCode'),
      t('assetManagement.searchSection.TableHeaders.name'),
      t('assetManagement.searchSection.TableHeaders.brand'),
      t('assetManagement.searchSection.TableHeaders.model'),
      t('assetManagement.searchSection.TableHeaders.number'),
      t('assetManagement.searchSection.TableHeaders.amount'),
      t('assetManagement.searchSection.TableHeaders.buyDate'),
      t('assetManagement.searchSection.TableHeaders.placement'),
      t('assetManagement.searchSection.TableHeaders.approveState'),
    ],
    [t],
  )

  const formik = useFormik({
    initialValues: {
      transferStatus: '',
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2))
    },
  })

  // quries
  const { data: assets } = useAssetList({
    status: [AssetStatus.created, AssetStatus.assetUpdated],
  })

  // ui states
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [approvePopUpOpen, setApprovePopUpOpen] = useState<boolean>(false)
  const [rejectPopUpOpen, setRejectPopUpOpen] = useState<boolean>(false)
  const [createChecked, setCreateChecked] = useState<boolean>(false)
  const [updateChecked, setUpdateChecked] = useState<boolean>(false)
  const [rejectReason, setRejectReason] = useState<string>('')

  const selectedRowsData = useMemo(
    () =>
      assets?.data
        ? assets.data?.filter((it) => selectedRows.some((r) => r === it.id))
        : [],
    [assets?.data, selectedRows],
  )
  const displayList = useMemo(() => {
    const filteredList = (assets?.data ?? [])
      .filter((it) => checkPermittedUnitId(it.unitId))
      .filter((it) => {
        if (createChecked === updateChecked) {
          return true
        }
        if (createChecked) {
          return it.status === AssetStatus.created
        }
        if (updateChecked) {
          return it.status === AssetStatus.assetUpdated
        }
        return true
      })
    return mapApprovalAssetListToTableRows(filteredList, {
      create: t('common.create'),
      edit: t('assetViewPage.buttons.edit'),
    })
  }, [assets?.data, checkPermittedUnitId, createChecked, t, updateChecked])

  const rejectPopUpTableColumn = useMemo(
    () => ({
      assetNo: t('assetManagement.searchSection.TableHeaders.assetCode'),
      name: t('assetManagement.searchSection.TableHeaders.name'),
      quantity: t('assetManagement.searchSection.TableHeaders.number'),
      valuedAmount: t('assetManagement.searchSection.TableHeaders.amount'),
    }),
    [t],
  )

  // ui handler
  const handleApproveButton = useCallback(() => {
    if (selectedRows.length === 0) {
      showSnackbar({ content: EMPTY_MSG, severity: 'error' })
    } else {
      setApprovePopUpOpen(true)
    }
  }, [selectedRows, showSnackbar, setApprovePopUpOpen, EMPTY_MSG])

  const handleRejectButton = useCallback(() => {
    if (selectedRows.length === 0) {
      showSnackbar({ content: EMPTY_MSG, severity: 'error' })
    } else {
      setRejectPopUpOpen(true)
    }
  }, [selectedRows, showSnackbar, setRejectPopUpOpen, EMPTY_MSG])

  const { mutateAsync: approveAssetBulkAsync, isLoading: approving } =
    useApproveBulkAssetMutation()

  const handleApprove = useCallback(async () => {
    if (selectedRowsData && selectedRowsData?.length > 0) {
      try {
        showSpinner()
        await approveAssetBulkAsync(
          selectedRowsData.map((it) => ({
            id: it.id,
            rowVersion: it.rowVersion,
          })),
        )
        showSnackbar({ content: t('assetActions.approval.success') })
        setSelectedRows([])
      } catch (error) {
        standardErrorHandler(error)
      } finally {
        hideSpinner()
      }
    }
  }, [
    approveAssetBulkAsync,
    selectedRowsData,
    standardErrorHandler,
    showSpinner,
    hideSpinner,
    showSnackbar,
    setSelectedRows,
    t,
  ])

  const { mutateAsync: rejectAssetBulkAsync, isLoading: rejecting } =
    useRejectBulkAsset()

  const handleReject = useCallback(async () => {
    if (selectedRowsData && selectedRowsData?.length > 0) {
      try {
        showSpinner()
        await rejectAssetBulkAsync(
          selectedRowsData.map((it) => ({
            id: it.id,
            rowVersion: it.rowVersion,
            reason: rejectReason,
          })),
        )
        showSnackbar({ content: t('assetViewPage.rejectModal.success') })
        setSelectedRows([])
      } catch (error) {
        standardErrorHandler(error)
      } finally {
        hideSpinner()
      }
    }
  }, [
    rejectAssetBulkAsync,
    selectedRowsData,
    standardErrorHandler,
    rejectReason,
    showSpinner,
    hideSpinner,
    showSnackbar,
    setSelectedRows,
    t,
  ])

  const handleApprovePopUpSelected = useCallback(
    (confirmed: boolean) => {
      if (confirmed) {
        handleApprove()
      }
      if (approvePopUpOpen) {
        setApprovePopUpOpen(false)
      }
    },
    [approvePopUpOpen, handleApprove],
  )

  const handleRejectPopUpSelected = useCallback(
    (confirmed: boolean) => {
      if (confirmed) {
        handleReject()
      }
      if (rejectPopUpOpen) {
        setRejectPopUpOpen(false)
      }
      setRejectReason('')
    },
    [rejectPopUpOpen, handleReject],
  )

  return (
    <FormikProvider value={formik}>
      <PageTitle>{t('assetDetailsPage.approvalTable.title')}</PageTitle>
      <Grid container mb={3}>
        <Grid item>
          <Grid container gap={3}>
            <Grid item gap={3}>
              <Grid container>
                <FormCheckboxField
                  label={t('common.create')}
                  name="createdAsset"
                  marginTop={0}
                  /* TODO applying now signle checked option */
                  onChange={setCreateChecked}
                  checked={createChecked}
                />
                <FormCheckboxField
                  label={t('assetViewPage.buttons.edit')}
                  name="updatedAsset"
                  marginTop={0}
                  /* TODO applying now signle checked option */
                  onChange={setUpdateChecked}
                  checked={updateChecked}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <ApproveButtonContainer>
          <Button
            startIcon={<WithdrawIcon />}
            variant="contained"
            color="secondary"
            onClick={handleRejectButton}
            disabled={rejecting}
          >
            {t('common.reject')}
          </Button>
          <Button
            startIcon={<ApprovedIcon />}
            variant="contained"
            color="primary"
            onClick={handleApproveButton}
            disabled={approving}
          >
            {t('common.checkAndApprove')}
          </Button>
        </ApproveButtonContainer>
      </Grid>

      <Dialog
        visible={approvePopUpOpen}
        title={t('assetManagement.approvalSection.popUp.approveItem')}
        content={t('assetManagement.approvalSection.popUp.confirmApproveItem')}
        dismissable
        onSelected={handleApprovePopUpSelected}
      />
      <Dialog
        visible={rejectPopUpOpen}
        title={t('assetManagement.approvalSection.popUp.rejectItem')}
        dismissable
        onSelected={handleRejectPopUpSelected}
      >
        <RejectTable
          rowData={selectedRowsData}
          rowIdKey="id"
          tableColumns={rejectPopUpTableColumn}
        />
        {t('assetManagement.approvalSection.popUp.confirmRejectItem')}
        <FormTextField
          label={t('assetManagement.approvalSection.popUp.rejectReason')}
          value={rejectReason}
          onChange={(val) => {
            setRejectReason(val as string)
          }}
          templateColumns="1fr 7fr"
        />
      </Dialog>
      {assets != null ? (
        <TableList
          headers={headers}
          rows={displayList}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          onRowClick={(row) => navigate(AppRoutes.assetApproval.render(row.id))}
        />
      ) : (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
    </FormikProvider>
  )
})

AssetManagementApprovalSection.displayName = 'AssetManagementApprovalSection'
