import { ApiGateway } from '@pasteltech/api-gateway'
import {
  PatchUserPayload,
  PostUserPayload,
  UserListRequestPayload,
  UserRequestPayload,
} from '@stewards-fas/schemas'
import { z } from 'zod'

const PATH = '/users'

export function getCurrentUserProfile(param?: UserRequestPayload) {
  return ApiGateway.primary.get(
    `${PATH}/me`,
    { request: z.any(), response: z.any() },
    {
      param,
    },
  )
}

export function getUser(id: string, param?: UserRequestPayload) {
  return ApiGateway.primary.get(
    `${PATH}/${id}`,
    { request: z.any(), response: z.any() },
    {
      param,
    },
  )
}

export function getUserList(param?: UserListRequestPayload) {
  return ApiGateway.primary.get(
    `${PATH}`,
    { request: z.any(), response: z.any() },
    {
      param,
    },
  )
}

export function postUser(param: PostUserPayload) {
  return ApiGateway.primary.post(
    `${PATH}`,
    { request: z.any(), response: z.any() },
    {
      body: param,
    },
  )
}

export function patchUser(param: PatchUserPayload) {
  const { id, ...others } = param
  return ApiGateway.primary.patch(
    `${PATH}/${id}`,
    { request: z.any(), response: z.any() },
    {
      body: others,
    },
  )
}
