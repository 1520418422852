import { Box, Button, Grid } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { FormikProvider, useFormik } from 'formik'
import { memo, useMemo, useState } from 'react'
import styled from 'styled-components'
import { PlusIcon, SuspendIcon } from '../../icons'
import { AppManagementTabItem } from '../app-management-tab'
import { FormAction } from '../asset-details-section'
import { Dialog } from '../dialog'
import { BaseDropdownField } from '../form-fields'
import { PageTitle } from '../page-title'
import { TableList } from '../table-list'

import { DataFormModalContent } from './data-form-modal'
import { Option, useSystemDataTable } from './use-system-data-table'

const OutlinedButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  height: 40px;
`

type Props = {
  title: string
  tab: AppManagementTabItem
  mode?: FormAction
  data?: any[]
  options?: Record<string, Option[]>
  btnDisabled?: {
    add?: boolean
    inactivate?: boolean
  }
}

export const SystemManagementTable = memo<Props>(
  ({ title, tab, mode = FormAction.edit, data, options, btnDisabled }) => {
    const [showInactiveConfirmModal, setShowInactiveConfirmModal] =
      useState(false)
    const [showDataFormModal, setShowDataFormModal] = useState(false)

    const [selectedRows, setSelectedRows] = useState<string[]>([])

    const [initFormData, setInitFormData] = useState<any>()

    const { columns, rows } = useSystemDataTable(tab, data, options)

    const { t } = useTranslation()

    const formModalTitle = useMemo(() => {
      switch (tab) {
        case AppManagementTabItem.systemUnitManagement:
          return t(
            'systemDataManagement.confirmPopup.addNew.unitManagementTitle',
          )

        case AppManagementTabItem.systemUserManagement:
          return t(
            'systemDataManagement.confirmPopup.addNew.userManagementTitle',
          )

        case AppManagementTabItem.systemReferenceMaterialManagement:
          return initFormData != null
            ? t(
                'systemDataManagement.confirmPopup.addNew.createReferenceMaterialTitle',
              )
            : t(
                'systemDataManagement.confirmPopup.addNew.editReferenceMaterialTitle',
              )

        default:
          return 'Error: case not defined'
      }
    }, [initFormData, t, tab])

    const formik = useFormik({
      initialValues: {},
      onSubmit: () => {
        //
      },
    })

    return (
      <FormikProvider value={formik}>
        <Box mt={5}>
          <PageTitle>{title}</PageTitle>

          {mode === FormAction.edit && (
            <Grid
              container
              mt="20px"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item width={200}>
                {tab === AppManagementTabItem.systemUnitManagement && (
                  <BaseDropdownField
                    data={[
                      {
                        value: '1',
                        label: '現有單位',
                      },
                    ]}
                  />
                )}
              </Grid>

              <Grid item>
                <Grid container gap={2}>
                  {btnDisabled?.inactivate && (
                    <Grid item width={100}>
                      <OutlinedButton
                        startIcon={<SuspendIcon />}
                        variant="outlined"
                        fullWidth
                        onClick={() => setShowInactiveConfirmModal(true)}
                      >
                        {t('common.disabled')}
                      </OutlinedButton>
                    </Grid>
                  )}

                  {btnDisabled?.add && (
                    <Grid item width={100}>
                      <Button
                        startIcon={<PlusIcon />}
                        variant="contained"
                        fullWidth
                        onClick={() => setShowDataFormModal(true)}
                      >
                        {t('common.create')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}

          <Box mt={5}>
            <TableList
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              headers={columns.map((it) => it.header)}
              rows={rows}
              onRowClick={
                mode === FormAction.edit
                  ? (row) => {
                      setInitFormData(row)
                      setShowDataFormModal(true)
                    }
                  : undefined
              }
              isDisableSelection={true}
            />
          </Box>

          <Dialog
            title={t('systemDataManagement.confirmPopup.disable.title')}
            content={t('systemDataManagement.confirmPopup.disable.content')}
            visible={selectedRows.length > 0 && showInactiveConfirmModal}
            onSelected={() => setShowInactiveConfirmModal(false)}
          />

          <Dialog
            title={formModalTitle}
            visible={showDataFormModal}
            onSelected={() => {
              setShowDataFormModal(false)
              setInitFormData(undefined)
            }}
          >
            <DataFormModalContent
              columns={columns}
              initialData={initFormData}
            />
          </Dialog>
        </Box>
      </FormikProvider>
    )
  },
)

SystemManagementTable.displayName = 'SystemManagementTable'
