import { ApiGateway } from '@pasteltech/api-gateway'
import {
  PatchReferencePagePayload,
  PostReferencePagePayload,
  ReferencePageListRequestPayload,
} from '@stewards-fas/schemas'
import { z } from 'zod'

const PATH = '/reference-pages'

export function getReferencePageList(param?: ReferencePageListRequestPayload) {
  return ApiGateway.primary.get(
    `${PATH}`,
    { request: z.any(), response: z.any() },
    {
      param,
    },
  )
}

export function postReferencePage(param: PostReferencePagePayload) {
  return ApiGateway.primary.post(
    `${PATH}`,
    { request: z.any(), response: z.any() },
    {
      body: param,
    },
  )
}

export function patchReferencePage(param: PatchReferencePagePayload) {
  const { id, ...others } = param
  return ApiGateway.primary.patch(
    `${PATH}/${id}`,
    { request: z.any(), response: z.any() },
    {
      body: others,
    },
  )
}
