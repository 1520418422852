import { TextField, TextFieldProps } from '@mui/material'
import { memo } from 'react'
import styled from 'styled-components'

const StyledTextField = styled(TextField)<{ $readOnly?: boolean }>`
  width: 100%;
  & fieldset {
    border: ${({ $readOnly }) => $readOnly && 'none'};
  }
`

export type BaseTextFieldProps = TextFieldProps & {
  readOnly?: boolean
}

export const BaseTextField = memo<BaseTextFieldProps>((props) => {
  const { readOnly, ...rest } = props
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledTextField {...rest} $readOnly={readOnly} />
})

BaseTextField.displayName = 'BaseTextField'
