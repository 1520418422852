import { memo, PropsWithChildren, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useUserRole } from '../hooks'
import { urlRoot, useSidebarItems } from '../routers'
import { ScreenSize, useView } from '../utilities'
import {
  AppBar,
  APPBAR_MOBILE_VIEW_HEIGHT,
  APPBAR_NORMAL_VIEW_HEIGHT,
} from './appbar'
import { drawerWidth, SideBar } from './sidebar'
import { Spinner } from './spinner'

type Props = PropsWithChildren<{}>

const Root = styled.main`
  height: 100%;
`

const ContentRoot = styled.div<{ $view: ScreenSize }>`
  margin-left: ${({ $view }) =>
    $view === ScreenSize.mobile ? 0 : drawerWidth}px;
  margin-right: 0px;
  height: 100%;
  display: flex;
`

const Container = styled.div<{ $view: ScreenSize }>`
  margin-top: ${({ $view }) =>
    $view === ScreenSize.mobile
      ? APPBAR_MOBILE_VIEW_HEIGHT
      : APPBAR_NORMAL_VIEW_HEIGHT}px;
  max-height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ $view }) =>
    $view === ScreenSize.mobile ? '10px' : '20px 100px 45px 32px'};
`

export const BasePage = memo<Props>(({ children }) => {
  const view = useView()
  const { role, isLoading } = useUserRole()

  const [open, setOpen] = useState(false)
  const toggleSidebar = useCallback(() => setOpen(!open), [open])

  const sidebarItems = useSidebarItems()

  const permittedSidebarItems = useMemo(() => {
    return sidebarItems.filter((it) => it.accessibleRoles.includes(role))
  }, [sidebarItems, role])

  if (isLoading) {
    return (
      <Root>
        <Spinner />
      </Root>
    )
  }

  return (
    <Root>
      <AppBar handleMenuOnClick={toggleSidebar} />
      <SideBar
        open={open}
        toggleSidebar={toggleSidebar}
        sidebarItems={permittedSidebarItems}
        urlRoot={urlRoot}
      />
      <ContentRoot $view={view}>
        <Container $view={view}>{children}</Container>
      </ContentRoot>
    </Root>
  )
})

BasePage.displayName = 'BasePage'
