export const reportPage = {
  pageTitle: '報告表',
  titleLine1: '香港神託會',
  titleLine2: '固定資產記錄總表',
  labels: {
    dateUntil: '日期至',
    buyDate: '購買日期',
    unit: '單位',
    assetType: '資產類別',
    sourceOfFunds: '資金來源',
    inputSourceOfFunds: '輸入來源',
    state: '狀態',
    unreimbursed: '未報銷',
    reimbursed: '已報銷',
    transfered: '已轉移',
    transferStatus: '轉移狀態',
    name: '名稱',
    brand: '牌子',
    model: '型號',
    serialNumber: '序號',
    provider: '供應商',
    placement: '放置地點',
    quantity: '數量',
    amount: '金額/估值',
    inputAmount: '估值',
    invoiceNo: '發票號碼',
    warrantyProvider: '保養公司',
    warrantyPeriod: '保養期',
    remarks: '注意事項',
    writeOffDate: '報銷日期',
    writeOffdReason: '報銷原因',
    writeOffCode: '報銷編號',
    transferDate: '轉移日期',
    transferToUnit: '接收單位',
    transferFromUnit: '移出單位',
    transferReason: '轉移原因',
    filler: '填表人',
    sectionSuperviser: '單位主管',
    fillDate: '新資產提交日',
    approvalDate: '新資產核准日',
    clearAll: '全部清除',
    generate: '生成報告',
  },
  button: {
    exportPDF: '匯出文件',
    exportExcel: '匯出試算表',
    print: '列印',
  },
  content: {
    unit: '單位',
    type: '類別',
    assetNo: '資產編號',
    name: '名稱',
    brand: '牌子',
    model: '型號',
    placement: '放置地點',
    buyDate: '購置日期',
    quantity: '數量',
    amount: '金額',
    serialNumber: '序號',
    provider: '供應商',
    invoiceNo: '發票號碼',
    warrantyProvider: '保養公司',
    warrantyPeriod: '保養期',
    sourceOfFunds: '資金來源',
    remarks: '注意事項',
    writeOffDate: '報銷日期',
    writeOffReason: '報銷原因',
    writeOffCode: '報銷編號',
    transferDate: '轉移日期',
    transferToUnit: '接收單位',
    transferFromUnit: '移出單位',
    transferReason: '轉移原因',
    filler: '填表人',
    sectionSuperviser: '單位主管',
    fillDate: '新資產提交日',
    approvalDate: '新資產核准日',
    smallTotal: '小計',
    total: '總計',
    showTotal: '{{total}}: {{sum}}',
  },
  errors: {
    amountMustBeInteger: '金額必須是整數',
  },
}
