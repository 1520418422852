import { z } from 'zod'

const AssetReimburseSchema = z.object({
  id: z.string(),
  reason: z.string(),
  rowVersion: z.string(),
})

export type AssetReimbursePayload = z.input<typeof AssetReimburseSchema>

const AssetReimburseBulkSchema = AssetReimburseSchema.array()

export type AssetReimburseBulkPayload = z.input<typeof AssetReimburseBulkSchema>

const AssetReimburseWithdrawSchema = z.object({
  id: z.string(),
  rowVersion: z.string(),
})

const AssetReimburseWithdrawBulkSchema = AssetReimburseWithdrawSchema.array()
export type AssetReimburseWithdrawBulkPayload = z.input<
  typeof AssetReimburseWithdrawBulkSchema
>
