import {
  AssetTypeResponsePayload,
  FundingResponsePayload,
  ReferencePageResponsePayload,
  UnitResponsePayload,
  UserResponsePayload,
} from '@stewards-fas/schemas'
import { useMemo } from 'react'
import {
  AppManagementTabItem,
  ComplexField,
  FieldMapping,
} from '../../../components'
import {
  useAssetTypeFields,
  useFundingResourceFields,
  useReferencePagefields,
  useStandardFieldMappings,
  useUnitFields,
  useUserFields,
} from './field-hooks'

export const useMasterDataMappings = (activeTab: AppManagementTabItem) => {
  const { mappings: standard } = useStandardFieldMappings()
  const { mappings: unitFields } = useUnitFields()
  const { mappings: userFields } = useUserFields()
  const { mappings: assetTypeFields } = useAssetTypeFields()
  const { mappings: fundingFields } = useFundingResourceFields()
  const { mappings: referenceFields } = useReferencePagefields()

  const dataMappings = useMemo(() => {
    if (activeTab === AppManagementTabItem.systemUnitManagement) {
      const fieldsMapping: FieldMapping<UnitResponsePayload>[] = [
        standard.id,
        standard.rowVersion,
        unitFields.nameCN,
        unitFields.nameEN,
        unitFields.code,
        unitFields.accounting,
        unitFields.filler,
        unitFields.status,
        unitFields.sectionSuperviser,
        unitFields.supervisorList,
        unitFields.rowVersion,
        unitFields.initFillerList,
        unitFields.initSupervisorList,
      ]
      return { fieldsMapping }
    }
    if (activeTab === AppManagementTabItem.systemUserManagement) {
      const fieldsMapping: ComplexField<
        UserResponsePayload & { userUnitsIds: string[] }
      >[] = [
        standard.id,
        standard.rowVersion,
        userFields.name,
        userFields.email,
        userFields.position,
        userFields.role,
        userFields.responsibleUnits,
        userFields.status,
        userFields.initUserUnits, // as mapping field
      ]
      return { fieldsMapping }
    }
    if (
      activeTab === AppManagementTabItem.referenceInformationAssetType ||
      activeTab === AppManagementTabItem.systemAssetClassManagement
    ) {
      const fieldsMapping: FieldMapping<AssetTypeResponsePayload>[] = [
        standard.id,
        standard.rowVersion,
        assetTypeFields.code,
        assetTypeFields.chineseName,
        assetTypeFields.englishName,
        assetTypeFields.content,
        assetTypeFields.status,
      ]
      return { fieldsMapping }
    }
    if (activeTab === AppManagementTabItem.systemFundingResourceManagement) {
      const fieldsMapping: FieldMapping<FundingResponsePayload>[] = [
        standard.id,
        standard.rowVersion,
        fundingFields.code,
        fundingFields.name,
        fundingFields.status,
      ]

      return { fieldsMapping }
    }
    if (activeTab === AppManagementTabItem.systemReferenceMaterialManagement) {
      const fieldsMapping: FieldMapping<ReferencePageResponsePayload>[] = [
        standard.id,
        standard.rowVersion,
        referenceFields.pageName,
        referenceFields.description,
        referenceFields.type,
        referenceFields.contents,
      ]

      return { fieldsMapping }
    }
    return { fieldsMapping: [] }
  }, [
    activeTab,
    standard,
    unitFields,
    userFields,
    assetTypeFields,
    fundingFields,
    referenceFields,
  ])
  return dataMappings
}
