import {
  AssetListRequestPayload,
  AssetRequestsListRequestPayload,
} from '@stewards-fas/schemas'
import { Dispatch, SetStateAction, createContext } from 'react'

export type AssetManagementSearchParam = AssetListRequestPayload & {
  isReimbursed?: boolean
}

type Context = {
  isAssetManagementSearchButtonClicked: boolean
  setIsAssetManagementSearchButtonClicked: Dispatch<SetStateAction<boolean>>
  assetManagementSearchParam: AssetManagementSearchParam
  setAssetManagementSearchParam: Dispatch<
    SetStateAction<AssetManagementSearchParam>
  >
  writeOffAssetManagementSearchParam: AssetRequestsListRequestPayload
  setWriteOffAssetManagementSearchParam: Dispatch<
    SetStateAction<AssetRequestsListRequestPayload>
  >
}

export const SearchParamContext = createContext<Context>(
  // ? force parsing
  undefined as unknown as Context,
)
