export const imageUploader = {
  upload: {
    title: '上傳照片',
    message: '確認上傳照片？',
  },
  delete: {
    title: '刪除照片',
    message: '確認刪除照片？',
  },
  replace: {
    title: '更換照片',
    message: '刪除並上傳新照片？',
  },
}
