import { memo, useMemo } from 'react'
import { Select, MenuItem } from '@mui/material'
import styled from 'styled-components'

export enum StatusFilterOption {
  all = 'ALL',
  active = 'ACTIVE',
  inactive = 'INACTIVE',
}

type StatusFilterPropType = {
  setValue: (value: StatusFilterOption) => void
  allText: string
  activeText: string
  inactiveText: string
}

const StyledSelect = styled(Select)`
  width: 100%;
  background: white;
`

export const StatusFilter = memo<StatusFilterPropType>(
  ({ setValue, allText, activeText, inactiveText }) => {
    const options = useMemo(
      () => [
        {
          value: StatusFilterOption.all,
          label: allText,
        },
        {
          value: StatusFilterOption.active,
          label: activeText,
        },
        {
          value: StatusFilterOption.inactive,
          label: inactiveText,
        },
      ],
      [allText, activeText, inactiveText],
    )
    return (
      <StyledSelect
        size="small"
        onChange={(e) => {
          setValue(e.target.value as StatusFilterOption)
        }}
        defaultValue={StatusFilterOption.active}
      >
        {options?.map((opt) => (
          <MenuItem key={opt.value} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </StyledSelect>
    )
  },
)
StatusFilter.displayName = 'StatusFilter'
