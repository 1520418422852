import { Button } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { useOverlay } from '@pasteltech/overlay-provider'
import {
  useApproveBulkAssetRequestMutation,
  useAssetRequestList,
  useAssetTypeList,
  useWithdrawBulkAssetRequestMutation,
} from '@stewards-fas/queries'
import { AssetRequestBulkWithdrawPayload } from '@stewards-fas/schemas'
import { memo, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  AssetSearchBar,
  Dialog,
  FormTextField,
  GenericTable,
  TableList,
} from '../../../../components'
import {
  useApiErrorHandle,
  useCurrentUserUnits,
  useUserRole,
} from '../../../../hooks'
import { ApproveIcon, WithdrawIcon } from '../../../../icons'
import { SearchFormSchemaModel, Section } from '../../../../models'
import { useSearchParamContext } from '../../../../providers'
import { All_VALUE_OF_DROPDOWN, compareString } from '../../../../utilities'
import { ApproveDialog } from '../components'
import { useWriteOffContext } from '../context'
const Container = styled.div`
  max-height: 800px;
`

const ApproveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
`

const ApproveButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.maroonOak};
  &:hover {
    background-color: ${({ theme }) => theme.colors.maroonOak};
  }
  margin-left: 20px;
  margin-bottom: 30px;
  width: 90px;
  font-weight: bold;
`

const WithdrawButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.chestnut};
  &:hover {
    background-color: ${({ theme }) => theme.colors.chestnut};
  }
  column-gap: 10px;
  font-weight: bold;
  margin-left: 20px;
  margin-bottom: 30px;
  width: 90px;
`
const StyledApproveIcon = styled(ApproveIcon)`
  margin-right: 10px;
`
const PopUpTable = styled(GenericTable)``
export const WriteOffRequestRecordsTab = memo(() => {
  const { t } = useTranslation()
  const { showSnackbar, showSpinner, hideSpinner } = useOverlay()
  const { setIsViewForm, setViewFormId } = useWriteOffContext()
  const { standardErrorHandler } = useApiErrorHandle()

  // states
  const {
    writeOffAssetManagementSearchParam,
    setWriteOffAssetManagementSearchParam: setSearchParams,
  } = useSearchParamContext()
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([])
  const [isOpenApproveDialog, setIsOpenApproveDialog] = useState(false)
  const [isOpenWithdrawDialog, setIsOpenWithdrawingDialog] = useState(false)
  const [rejectReason, setRejectReason] = useState<string>('')
  // queries
  const { data: assetTypeList } = useAssetTypeList()
  const { isAdmin, isSupervisor } = useUserRole()

  const currentUnitId = useMemo(() => {
    return writeOffAssetManagementSearchParam.toUnitId
  }, [writeOffAssetManagementSearchParam.toUnitId])

  const setCurrentUnitId = useCallback(
    (unitId: string) => {
      setSearchParams({
        ...writeOffAssetManagementSearchParam,
        toUnitId: unitId,
      })
    },
    [setSearchParams, writeOffAssetManagementSearchParam],
  )

  const searchParams = useMemo(() => {
    const { toUnitId, ...params } = writeOffAssetManagementSearchParam
    return params
  }, [writeOffAssetManagementSearchParam])

  const { data: assetRequestData, isLoading: isLoadingRequestList } =
    useAssetRequestList({
      ...searchParams,
      type: 'REIMBURSED_RECORD',
      expand: ['AssetRequestAssets.Asset.Unit'],
    })

  // display logic
  const EMPTY_MSG = useMemo(() => t('common.selectItemFirst'), [t])

  const isSupervisorOrAdmin = useMemo(
    () => isAdmin || isSupervisor,
    [isAdmin, isSupervisor],
  )

  const { checkPermittedUnitId } = useCurrentUserUnits()
  const assetRequestList = useMemo(() => {
    return (
      assetRequestData?.data.filter((it) => {
        if (it.reimburseStatus === 'WITHDREW') return false
        if (
          it.assetRequestAssets.every(
            (asset) => asset.asset.unit?.status === 'inactive',
          )
        )
          return false
        if (!checkPermittedUnitId(it.assetRequestAssets[0]?.asset?.unitId))
          return false
        return true
      }) ?? []
    )
  }, [assetRequestData?.data, checkPermittedUnitId])

  const filteredByUnitList = useMemo(() => {
    return assetRequestList.filter((it) => {
      if (currentUnitId === All_VALUE_OF_DROPDOWN) return true
      return it.assetRequestAssets[0]?.asset?.unitId === currentUnitId
    })
  }, [assetRequestList, currentUnitId])

  const selectedRows = useMemo(() => {
    return assetRequestList.filter((req) =>
      selectedRowIds.some((id) => id === req.id),
    )
  }, [assetRequestList, selectedRowIds])

  const tableDisplayRows = useMemo(() => {
    const rowDisplayFields = filteredByUnitList
      .map(({ id, reimburseNo, assetRequestAssets, reimburseStatus }) => ({
        id,
        reimburseNo,
        count: assetRequestAssets.length,
        status: reimburseStatus,
      }))
      .sort((a, b) => {
        const [aUnit, , anum] = a.reimburseNo.split('-')
        const [bUnit, , bnum] = b.reimburseNo.split('-')
        return compareString(aUnit, bUnit) * 10 + compareString(bnum, anum)
      })
      .sort((a, b) => {
        return -compareString(a.status, b.status)
      })
    return rowDisplayFields
  }, [filteredByUnitList])

  // componenet actions
  const { mutateAsync: mutateAsyncApprove, isLoading: isApiApproving } =
    useApproveBulkAssetRequestMutation()

  const { mutateAsync: mutateAsyncWithdraw, isLoading: isApiWithdrawing } =
    useWithdrawBulkAssetRequestMutation()

  const defaultSearchFormikValue: SearchFormSchemaModel = useMemo(() => {
    return {
      keyword: writeOffAssetManagementSearchParam.reimburseNo,
      unit:
        writeOffAssetManagementSearchParam.toUnitId ?? All_VALUE_OF_DROPDOWN,
    }
  }, [writeOffAssetManagementSearchParam])

  const handleSearch = useCallback(
    (formikValues: SearchFormSchemaModel) => {
      setSearchParams({ reimburseNo: formikValues.keyword })
    },
    [setSearchParams],
  )

  const handleApprove = useCallback(async () => {
    if (selectedRows.length > 0) {
      setIsOpenApproveDialog(true)
    } else {
      showSnackbar({ content: EMPTY_MSG })
    }
  }, [EMPTY_MSG, selectedRows.length, showSnackbar])

  const handleWithdraw = useCallback(async () => {
    if (selectedRows.length === 0) {
      showSnackbar({ content: EMPTY_MSG, severity: 'error' })
      return
    }
    if (selectedRows.some((row) => row.reimburseStatus === 'APPROVED')) {
      showSnackbar({
        content: t('assetManagement.writeOffSection.notApprovedRequestOnly'),
        severity: 'error',
      })
      return
    }
    setIsOpenWithdrawingDialog(true)
  }, [EMPTY_MSG, selectedRows, showSnackbar, t])

  const onConfirmWithdraw = useCallback(
    async (isConfirm: boolean) => {
      try {
        showSpinner()
        if (!isConfirm) return
        const payloads: AssetRequestBulkWithdrawPayload = selectedRows.map(
          (row) => ({
            rowVersion: row.rowVersion,
            id: row.id,
            reason: rejectReason,
          }),
        )
        await mutateAsyncWithdraw(payloads)
        showSnackbar({
          content: t('assetManagement.writeOffSection.actions.withdrawSuccess'),
        })
      } catch (error) {
        standardErrorHandler(error)
      } finally {
        setIsOpenWithdrawingDialog(false)
        hideSpinner()
      }
    },
    [
      selectedRows,
      setIsOpenWithdrawingDialog,
      rejectReason,
      standardErrorHandler,
      mutateAsyncWithdraw,
      showSpinner,
      hideSpinner,
      t,
      showSnackbar,
    ],
  )
  const onConfirmApprove = useCallback(
    async (isConfirm: boolean) => {
      try {
        showSpinner()
        if (!isConfirm) return
        await mutateAsyncApprove(
          selectedRows.map(({ id, rowVersion }) => ({ id, rowVersion })),
        )
        showSnackbar({
          content: t('assetManagement.writeOffSection.actions.writeoffSuccess'),
        })
      } catch (error) {
        standardErrorHandler(error)
      } finally {
        setIsOpenApproveDialog(false)
        hideSpinner()
      }
    },
    [
      mutateAsyncApprove,
      selectedRows,
      showSnackbar,
      t,
      standardErrorHandler,
      showSpinner,
      hideSpinner,
    ],
  )

  const writeOffHeaders = useMemo(
    () => [
      t('assetManagement.writeOffSection.TableHeaders.writeOffCode'),
      t('assetManagement.writeOffSection.TableHeaders.assetNumber'),
      t('assetManagement.writeOffSection.TableHeaders.approveState'),
    ],
    [t],
  )

  const withdrawPopUpTableHeader = useMemo(
    () => ({
      reimburseNo: t(
        'assetManagement.writeOffSection.TableHeaders.writeOffCode',
      ),
    }),
    [t],
  )
  return (
    <Container>
      <AssetSearchBar
        onSearch={handleSearch}
        tab={Section.reimburse}
        needState={false}
        defaultValue={defaultSearchFormikValue}
        assetTypeList={assetTypeList?.data}
        setcurrentUnitId={setCurrentUnitId}
      />
      <ApproveButtonContainer>
        {isSupervisorOrAdmin && (
          <WithdrawButton
            variant="contained"
            onClick={handleWithdraw}
            disabled={isApiWithdrawing}
          >
            <WithdrawIcon />
            {t('assetManagement.writeOffSection.actions.withdraw')}
          </WithdrawButton>
        )}

        {isSupervisorOrAdmin && (
          <ApproveButton
            variant="contained"
            onClick={handleApprove}
            disabled={isApiApproving}
          >
            <StyledApproveIcon />
            {t('assetManagement.writeOffSection.actions.approve')}
          </ApproveButton>
        )}
      </ApproveButtonContainer>

      <TableList
        headers={writeOffHeaders}
        rows={tableDisplayRows}
        selectedRows={selectedRowIds}
        setSelectedRows={setSelectedRowIds}
        isLoading={isLoadingRequestList}
        isDisableSelection={!isSupervisorOrAdmin}
        onRowClick={(row) => {
          setIsViewForm(true)
          setViewFormId(row.id)
        }}
      />

      <ApproveDialog
        isOpen={isOpenApproveDialog}
        rows={selectedRows}
        onSelected={onConfirmApprove}
      />
      <Dialog
        visible={isOpenWithdrawDialog}
        title={t('assetManagement.writeOffSection.actions.withdraw')}
        onSelected={onConfirmWithdraw}
      >
        <PopUpTable
          rowData={selectedRows}
          rowIdKey="id"
          tableColumns={withdrawPopUpTableHeader}
        />
        <FormTextField
          label={t('assetManagement.writeOffSection.actions.withdrawReason')}
          value={rejectReason}
          onChange={(val) => {
            setRejectReason(val as string)
          }}
        />
      </Dialog>
    </Container>
  )
})

WriteOffRequestRecordsTab.displayName = 'WriteOffRequestRecordsTab'
