import { withEnvironment } from '@stewards-fas/model-core'
import { UserProfileStoreModel } from '@stewards-fas/stores'
import { Instance, SnapshotOut, types } from 'mobx-state-tree'

export const RootStoreModel = types
  .model('RootStore')
  .props({
    // TODO: Fix this type error
    // @ts-ignore
    userProfileStore: types.optional(UserProfileStoreModel, {}),
  })
  .extend(withEnvironment)

export type RootStore = Instance<typeof RootStoreModel>
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>
