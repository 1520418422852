import { QueryClient, QueryClientConfig } from '@tanstack/react-query'

export function initReactQuery(opts?: QueryClientConfig) {
  return new QueryClient({
    ...opts,
    defaultOptions: {
      ...opts?.defaultOptions,
      queries: {
        ...opts?.defaultOptions?.queries,
        structuralSharing: false,
      },
    },
  })
}

export { QueryClientProvider } from '@tanstack/react-query'
export type { QueryClient } from '@tanstack/react-query'
