import { colors } from './colors'

export const theme = { colors }
export * from './formats'
export * from './icons'
export * from './theme-provider'
export * from './colors'
type AppColorTheme = (typeof theme)['colors']

declare module '@pasteltech/theme-provider' {
  interface ColorTheme extends AppColorTheme {}
}
