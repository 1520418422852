import {
  ThemeProvider as MuiThemeProvider,
  THEME_ID,
} from '@mui/material/styles'
import { ThemeProvider as PLThemeProvider } from '@pasteltech/theme-provider'
import { PropsWithChildren, memo } from 'react'
import { useMuiTheme } from './use-mui-theme'
import { colors } from './colors'

export type FasThemeColors = typeof colors
export type FasTheme = { colors: FasThemeColors }

type Props = PropsWithChildren<{ theme: FasTheme }>

export const ThemeProvider = memo<Props>(({ theme, children }) => {
  const muiTheme = useMuiTheme(theme)

  return (
    <PLThemeProvider initialTheme={theme as any}>
      <MuiThemeProvider theme={{ [THEME_ID]: muiTheme }}>
        {children}
      </MuiThemeProvider>
    </PLThemeProvider>
  )
})

ThemeProvider.displayName = 'ThemeProvider'
