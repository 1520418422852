import { useOverlay } from '@pasteltech/overlay-provider'
import { FormikErrors } from 'formik'
import { useCallback } from 'react'
import { useApiErrorHandle } from '../../hooks'
import { AssetFormSchemaModel } from '../../models'

export const useImageUploaderDelete = (
  values: AssetFormSchemaModel,
  order: number,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<AssetFormSchemaModel>>,
  creating: boolean,
) => {
  const handleDelete = useCallback(async () => {
    if (creating) {
      const resourcesData = values.createResources
      await setFieldValue(
        'createResources',
        resourcesData.filter((resource) => resource.order !== order),
      )
      return
    }
    const resourcesData = values.editResources
    const resourceObj = resourcesData.find(
      (resource) => resource.order === order,
    )
    //edit resource delete for edit asset
    if (resourceObj?.id) {
      await setFieldValue(
        'editResources',
        resourcesData.map((resource) => {
          if (resource.order !== order) {
            return resource
          }
          return { ...resource, isDeleted: true }
        }),
      )
    }
    //new resource delete for edit asset
    else {
      await setFieldValue(
        'editResources',
        resourcesData.filter((resource) => resource.order !== order),
      )
    }
  }, [values, order, setFieldValue, creating])

  return { handleDelete }
}

export const useImageUploaderReplace = (
  values: AssetFormSchemaModel,
  order: number,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<AssetFormSchemaModel>>,
  creating: boolean,
  handleUpload: (file: File) => Promise<string>,
  currentAssetId: string,
) => {
  const { showSpinner, hideSpinner } = useOverlay()
  const { standardErrorHandler } = useApiErrorHandle()
  const handleReplace = useCallback(
    async (newImageFile: File) => {
      try {
        showSpinner()
        const url = await handleUpload(newImageFile)
        const mime = newImageFile.type
        const filename = newImageFile.name

        //new resource replace for create asset
        if (creating) {
          const resourcesData = values.createResources
          const filteredResourcesData = resourcesData.filter(
            (resource) => resource.order !== order,
          )
          await setFieldValue('createResources', [
            ...filteredResourcesData,
            { mime, filename, url, order },
          ])
          return
        }
        const resourcesData = values.editResources
        const replaceObj = resourcesData.find(
          (resource) => resource.order === order && !('isDeleted' in resource),
        )
        //edit resource replace for edit asset
        if (replaceObj?.id) {
          const deletedResources = resourcesData.map((resource) => {
            if (resource.order !== order) {
              return resource
            }
            return { ...resource, isDeleted: true }
          })
          await setFieldValue('editResources', [
            ...deletedResources,
            { mime, filename, url, order, assetId: currentAssetId },
          ])
        }
        //new resource replace for edit asset
        else {
          const filteredResourcesData = resourcesData.filter(
            (resource) => resource.order !== order,
          )
          await setFieldValue('editResources', [
            ...filteredResourcesData,
            { mime, filename, url, order, assetId: currentAssetId },
          ])
        }
      } catch (err) {
        standardErrorHandler(err)
      } finally {
        hideSpinner()
      }
    },
    [
      setFieldValue,
      handleUpload,
      order,
      values,
      creating,
      currentAssetId,
      showSpinner,
      hideSpinner,
      standardErrorHandler,
    ],
  )

  return { handleReplace }
}
