import { useAssetTypeList, useUnitList } from '@stewards-fas/queries'
import { memo } from 'react'
import { Spinner } from '../../../components'

import { AssetManagementAddSectionContent } from './content'

export const AssetManagementAddSection = memo(() => {
  const { data: unitList, isLoading: unitListIsLoading } = useUnitList()
  const { data: assetTypeList, isLoading: assetTypeListIsLoading } =
    useAssetTypeList()

  const isLoading = unitListIsLoading || assetTypeListIsLoading
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        unitList && assetTypeList && <AssetManagementAddSectionContent />
      )}
    </>
  )
})

AssetManagementAddSection.displayName = 'AssetManagementAddSection'
