import {
  SystemAssetClassManagementIcon,
  SystemFundingResourceManagementIcon,
  SystemReferenceMaterialManagement,
  SystemUnitManagementIcon,
  SystemUserManagementIcon,
} from '../../icons'
import { Role } from '../../models'
import {
  AppManagementTabItem,
  AppManagementTabItemType,
} from '../app-management-tab'

export const systemDataManagementTabItems: AppManagementTabItemType[] = [
  {
    value: AppManagementTabItem.systemUnitManagement,
    titleKey: 'assetManagement.tab.systemUnitManagement',
    icon: SystemUnitManagementIcon,
    content: <></>,
    dataKey: 'units',
    allowedRoles: [Role.systemAdministrator],
  },
  {
    value: AppManagementTabItem.systemUserManagement,
    titleKey: 'assetManagement.tab.systemUserManagement',
    icon: SystemUserManagementIcon,
    content: <></>,
    dataKey: 'users',
    allowedRoles: [Role.systemAdministrator],
  },
  {
    value: AppManagementTabItem.systemAssetClassManagement,
    titleKey: 'assetManagement.tab.systemAssetClassManagement',
    icon: SystemAssetClassManagementIcon,
    content: <></>,
    dataKey: 'assetTypes',
    allowedRoles: [Role.systemAdministrator],
  },
  {
    value: AppManagementTabItem.systemFundingResourceManagement,
    titleKey: 'assetManagement.tab.systemFundingResourceManagement',
    icon: SystemFundingResourceManagementIcon,
    content: <></>,
    dataKey: 'fundings',
    allowedRoles: [Role.systemAdministrator],
  },
  {
    value: AppManagementTabItem.systemReferenceMaterialManagement,
    titleKey: 'assetManagement.tab.systemReferenceMaterialManagement',
    icon: SystemReferenceMaterialManagement,
    content: <></>,
    dataKey: 'refMaterials',
    allowedRoles: [Role.systemAdministrator],
  },
]
