export function convertToStartOfDay(dateTimeString: string) {
  const startDate = new Date(dateTimeString)
  startDate.setDate(startDate.getDate() + 1)
  startDate.setUTCHours(0, 0, 0, 0)
  return startDate.toISOString()
}

export function convertToEndOfDay(dateTimeString: string) {
  const endDate = new Date(dateTimeString)
  endDate.setDate(endDate.getDate() + 1)
  endDate.setUTCHours(23, 59, 59, 999)
  return endDate.toISOString()
}
