import { AssetFormSchemaModel } from '../../../models'
import {
  FormCheckboxFieldProp,
  FormDateFieldProps,
  FormReadOnlyFieldProps,
  FormSelectFieldProps,
  FormTextFieldProps,
} from '../../form-fields'

export enum FormAction {
  create,
  edit,
  view,
  approval,
}

export type FieldLabel = keyof Omit<
  AssetFormSchemaModel,
  | 'rowVersion'
  | 'reason'
  | 'viewChildAssetLinks'
  | 'editParentAssetLinks'
  | 'createParentAssetLinks'
  | 'assetBulk'
  | 'editResources'
  | 'createResources'
>

export type FieldProps = {
  element: React.ComponentType<any>
  actions: FormAction[]
  readOnlyActions?: FormAction[]
  field: FieldLabel
  props?: Omit<
    FormCheckboxFieldProp &
      FormDateFieldProps &
      FormSelectFieldProps &
      FormReadOnlyFieldProps &
      FormTextFieldProps,
    'name' | 'label' | 'options'
  >
}
