import { Typography } from '@mui/material'
import styled from 'styled-components'

export const FieldContainer = styled.div<{ $noBgColor?: boolean }>`
  width: 150px;
  font-weight: bold;
  font-size: 1.2rem;
  background: ${({ theme, $noBgColor }) =>
    $noBgColor ? 'initial' : theme.colors.greys[5]};
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  display: flex;
  align-items: center;
  padding: 5px 8px;
`

export const FieldValue = styled(Typography)`
  padding: 5px 8px;
  font-size: 1.2rem;
`
