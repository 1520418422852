import {
  ApiFormattedError,
  getCurrentUserProfile,
  getUser,
  getUserList,
  patchUser,
  postUser,
} from '@stewards-fas/apis'
import {
  PatchUserPayload,
  PostUserPayload,
  UserListResponsePayload,
  UserRequestPayload,
  UserResponsePayload,
} from '@stewards-fas/schemas'
import {
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import {
  QUERY_ROOT_KEY_UNITS,
  QUERY_ROOT_KEY_USERS,
  callbackNonNullable,
  useOptsOnSuccessInvalidate,
} from './helper'

function useOpts<R, E, P>(opts?: UseMutationOptions<R, E, P>) {
  return useOptsOnSuccessInvalidate(
    [QUERY_ROOT_KEY_USERS, QUERY_ROOT_KEY_UNITS],
    opts,
  )
}

export function useQueryUserProfile(
  param?: UserRequestPayload,
  opts?: UseQueryOptions<unknown, unknown, UserResponsePayload>,
) {
  return useQuery(
    [QUERY_ROOT_KEY_USERS, 'me', param] as QueryKey,
    () => getCurrentUserProfile(param),
    opts,
  )
}

export function useQueryUser(
  id: string,
  param?: UserRequestPayload,
  opts?: UseQueryOptions<unknown, unknown, UserResponsePayload>,
) {
  return useQuery(
    [QUERY_ROOT_KEY_USERS, id, param] as QueryKey,
    () => getUser(id, param),
    opts,
  )
}

export function useUserList(
  param?: UserRequestPayload,
  opts?: UseQueryOptions<unknown, unknown, UserListResponsePayload>,
) {
  return useQuery(
    [QUERY_ROOT_KEY_USERS, param] as QueryKey,
    () => getUserList(param),
    opts,
  )
}

export function usePostUserMutation(
  opts?: UseMutationOptions<
    UserResponsePayload,
    ApiFormattedError,
    PostUserPayload
  >,
) {
  const options = useOpts(opts)
  return useMutation(callbackNonNullable(postUser), options)
}

export function usePatchUserMutation(
  opts?: UseMutationOptions<
    UserResponsePayload,
    ApiFormattedError,
    PatchUserPayload
  >,
) {
  const options = useOpts(opts)
  return useMutation(callbackNonNullable(patchUser), options)
}
