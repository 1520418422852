import { useTranslation } from '@pasteltech/i18n-react'
import { AssetRequestsResponsePayload } from '@stewards-fas/schemas'
import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { Dialog, GenericColumns, GenericTable } from '../../../../components'

type ApproveDialogPropType = {
  isOpen: boolean
  onSelected: (answer: boolean) => void
  rows: AssetRequestsResponsePayload[]
}

const StyledTable = styled(GenericTable)``

export const ApproveDialog = memo<ApproveDialogPropType>(
  ({ isOpen, onSelected, rows }) => {
    const { t } = useTranslation()
    const displayData = useMemo(
      () =>
        rows.map(({ reimburseNo, assetRequestAssets }) => ({
          reimburseNo,
          count: assetRequestAssets.length,
        })),
      [rows],
    )

    const tableColumns = useMemo(() => {
      const columns: GenericColumns<(typeof displayData)[0]> = {
        reimburseNo: t('assetManagement.writeOffSection.TableHeaders.assetNo'),
        count: t('assetManagement.writeOffSection.TableHeaders.assetNumber'),
      } as const
      return columns
    }, [t])

    return (
      <>
        <Dialog
          visible={isOpen}
          title={t('assetManagement.writeOffSection.modals.confirmApprove')}
          onSelected={onSelected}
        >
          <StyledTable
            rowData={displayData}
            tableColumns={tableColumns}
            rowIdKey="id"
          />
        </Dialog>
      </>
    )
  },
)
ApproveDialog.displayName = 'ApproveDialog'
