import { z } from 'zod'
import {
  makeGetPayloadSchema,
  makePaginatedParameterSchema,
  makePaginatedPayloadSchema,
  makePatchPayloadSchema,
  makePostPayloadSchema,
} from '../base'

export const AssetTypeSchema = z.object({
  id: z.string().uuid(),
  code: z.string(),
  typeCN: z.string().optional(),
  typeEN: z.string().optional(),
  status: z.string(),
  contents: z.string().optional(),
})

// AssetTypes Request
const AssetTypeRequestSchema = z.object({})

// AssetTypes Response
export const AssetTypeResponseSchema = makeGetPayloadSchema(AssetTypeSchema)
export type AssetTypeResponsePayload = z.infer<typeof AssetTypeResponseSchema>

// AssetTypes Post
const PostAssetTypeSchema = makePostPayloadSchema(AssetTypeSchema)
export type PostAssetTypePayload = z.infer<typeof PostAssetTypeSchema>

// AssetTypes Patch
const PatchAssetTypeSchema = makePatchPayloadSchema(AssetTypeSchema).extend({
  id: z.string().uuid(),
})
export type PatchAssetTypePayload = z.infer<typeof PatchAssetTypeSchema>

// AssetTypes List Request
const AssetTypeListRequestSchema = makePaginatedParameterSchema(
  AssetTypeRequestSchema,
)
export type AssetTypeListRequestPayload = z.input<
  typeof AssetTypeListRequestSchema
>

// AssetTypesList Response
const AssetTypeListResponseSchema = makePaginatedPayloadSchema(
  AssetTypeResponseSchema,
)
export type AssetTypeListResponsePayload = z.infer<
  typeof AssetTypeListResponseSchema
>
