import { Box, Button, Dialog } from '@mui/material'
import { memo } from 'react'
import styled from 'styled-components'
import { BackIcon, CloseIcon, NextIcon } from '../../../icons'
import { EditAssetFormSchemaModel } from '../../../models'

type Props = {
  onClose(): void
  nextButtonOnClick(): void
  backButtonOnClick(): void
  resource: EditAssetFormSchemaModel['editResources'][0] | null
  totalNumberOfResources: number
  indexOfViewingResource: number
}

const TopBar = styled(Box)`
  height: 5vh;
  display: flex;
  justify-content: flex-end;
  color: white;
`

const BottomBar = styled(Box)`
  height: 5vh;
  display: flex;
`
const Wrapper = styled(Box)`
  height: 70vh;
  background: ${({ theme }) => theme.colors.greys[1]};
  overflow: hidden;
`
const StyledCloseIcon = styled(CloseIcon)`
  stroke-width: 0;
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
  object-fit: scale-down;
`

const StyledBackIcon = styled(BackIcon)`
  stroke: ${({ theme }) => theme.colors.white};
  stroke-width: 0.5;
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
  object-fit: scale-down;
`
const StyledNextIcon = styled(NextIcon)`
  stroke: ${({ theme }) => theme.colors.white};
  stroke-width: 0.5;
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
  object-fit: scale-down;
`
const Body = styled(Box)`
  height: 60vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: 75%;
  object-fit: contain;
`
const StyledCloseButton = styled(Button)``

const LeftButton = styled(Button)``
const RightButton = styled(Button)`
  margin-left: auto;
`

const Indicator = styled(Box)`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  width: 100%;
`
export const ViewImageModal = memo<Props>(
  ({
    onClose,
    resource,
    nextButtonOnClick,
    backButtonOnClick,
    totalNumberOfResources,
    indexOfViewingResource,
  }) => {
    return (
      <Dialog open onClose={onClose} fullWidth maxWidth="md">
        <Wrapper>
          <TopBar>
            <StyledCloseButton
              startIcon={<StyledCloseIcon />}
              onClick={onClose}
            />
          </TopBar>
          <Body>{resource && <ImagePreview src={resource.url} />}</Body>

          <BottomBar>
            <LeftButton
              startIcon={<StyledBackIcon />}
              onClick={backButtonOnClick}
            />
            <Indicator>
              <p>
                {indexOfViewingResource + 1}/{totalNumberOfResources}
              </p>
            </Indicator>
            <RightButton
              endIcon={<StyledNextIcon />}
              onClick={nextButtonOnClick}
            />
          </BottomBar>
        </Wrapper>
      </Dialog>
    )
  },
)

ViewImageModal.displayName = 'ViewImageModal'
