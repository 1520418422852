import { z } from 'zod'
import {
  makeGetPayloadSchema,
  makePaginatedParameterSchema,
  makePaginatedPayloadSchema,
  makePatchPayloadSchema,
  makePostPayloadSchema,
} from '../base'
import { toZod } from 'tozod'
import { RoleResponsePayload, RoleResponseSchema } from '../roles'

const UserUnitsSchemaObject = {
  id: z.string().uuid(),
  unitId: z.string(),
  userId: z.string(),
}
const UserUnitsSchema = z.object(UserUnitsSchemaObject)
type UserUnit = z.infer<typeof UserUnitsSchema> & {
  unit: Unit | null
  user: User | null
}
const UserUnitsTempSchema: toZod<UserUnit> = z.late.object(() => ({
  ...UserUnitsSchemaObject,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  unit: UnitResponseSchema.nullable(),
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  user: UserResponseSchema.nullable(),
})) // used to resolve recursive schema

// User Units Response
export const UserUnitsResponseSchema = makeGetPayloadSchema(UserUnitsTempSchema)
export type UserUnitsResponsePayload = z.infer<typeof UserUnitsResponseSchema>

// User Units Patch
export const PatchUserUnitsSchema = makePatchPayloadSchema(
  UserUnitsSchema,
).extend({
  isDeleted: z.boolean(),
  id: z.string().uuid(),
})
export type PatchUserUnitsPayload = z.infer<typeof PatchUserUnitsSchema>

// User Units Post
export const PostUserUnitsSchema = makePostPayloadSchema(UserUnitsSchema)
export type PostUserUnitsPayload = z.infer<typeof PostUserUnitsSchema>
// User Schema
const UserSchemaObject = {
  id: z.string().uuid(),
  username: z.string(),
  email: z.string(),
  position: z.string(),
  roleId: z.string(),
  status: z.string(),
}
export const UserSchema = z.object(UserSchemaObject)
type User = z.infer<typeof UserSchema> & {
  userUnits: Omit<UserUnit, 'user'>[]
  role: RoleResponsePayload | null
}
const UserTempSchema: toZod<User> = z.late.object(() => ({
  ...UserSchemaObject,
  userUnits: UserUnitsResponseSchema.omit({ user: true }).array(),
  role: RoleResponseSchema.nullable(),
}))
// User Response

export const UserResponseSchema = makeGetPayloadSchema(UserTempSchema)
export type UserResponsePayload = z.infer<typeof UserResponseSchema>

// User Post
const PostUserSchema = makePostPayloadSchema(UserSchema)
export type PostUserPayload = z.infer<typeof PostUserSchema>

// User Patch
const PatchUserSchema = makePatchPayloadSchema(UserSchema).extend({
  id: z.string().uuid(),
})
export type PatchUserPayload = z.infer<typeof PatchUserSchema>

// User Request
export const UserRequestSchema = z.object({
  expand: z.array(z.string()).optional(),
})
export type UserRequestPayload = z.input<typeof UserRequestSchema>

// User List Response

export const UserListResponseSchema =
  makePaginatedPayloadSchema(UserResponseSchema)
export type UserListResponsePayload = z.infer<typeof UserListResponseSchema>

// User List Request
export const UserListRequestSchema =
  makePaginatedParameterSchema(UserRequestSchema)
export type UserListRequestPayload = z.input<typeof UserListRequestSchema>

// Unit Schema
const UnitSchemaObject = {
  id: z.string().uuid(),
  code: z.string().optional(),
  nameCN: z.string(),
  nameEN: z.string(),
  accounting: z.string(),
  status: z.string(),
  supervisorUserId: z.string().uuid().optional(),
}
export const UnitSchema = z.object(UnitSchemaObject).extend({})

type Unit = z.infer<typeof UnitSchema> & {
  userUnits: Omit<UserUnit, 'unit'>[]
}
const UnitTempSchema: toZod<Unit> = z.late.object(() => ({
  ...UnitSchemaObject,
  userUnits: UserUnitsResponseSchema.omit({ unit: true }).array(),
})) // used to resolve recursive schema
// Unit Request
export const UnitRequestSchema = z.object({
  status: z.string().array().optional(),
})
export type UnitRequestPayload = z.input<typeof UnitRequestSchema>

// Unit Response
export const UnitResponseSchema = makeGetPayloadSchema(
  UnitTempSchema.extend({
    supervisorUser: UserResponseSchema.nullable(),
  }),
)
export type UnitResponsePayload = z.infer<typeof UnitResponseSchema>

// Unit Post
export const PostUnitSchema = makePostPayloadSchema(UnitSchema).extend({
  userUnits: PostUserUnitsSchema.array(),
})
export type PostUnitPayload = z.infer<typeof PostUnitSchema>

// Unit Patch
export const PatchUnitSchema = makePatchPayloadSchema(UnitSchema).extend({
  id: z.string().uuid(),
  userUnits: z.union([PatchUserUnitsSchema, PostUserUnitsSchema]).array(),
})
export type PatchUnitPayload = z.infer<typeof PatchUnitSchema>

// Unit List Request
export const UnitListRequestSchema =
  makePaginatedParameterSchema(UnitRequestSchema)
export type UnitListRequestPayload = z.input<typeof UnitListRequestSchema>

// Unit List Response
export const UnitListResponseSchema =
  makePaginatedPayloadSchema(UnitResponseSchema)
export type UnitListResponsePayload = z.infer<typeof UnitListResponseSchema>
