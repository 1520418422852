import { Checkbox } from '@mui/material'
import { memo } from 'react'
import styled from 'styled-components'
import { BaseFormField } from './base-form-field'
const StyledCheckbox = styled(Checkbox)`
  color: ${({ theme }) => theme.colors.greys[2]};
  &.Mui-checked {
    color: ${({ theme }) => theme.colors.secondary};
  }
  padding-left: 0;
`
export type FormCheckboxFieldProp = {
  label: string
  name: string
  marginTop?: string | number
  onChange?: (val: boolean) => void
  checked?: boolean
  required?: boolean
}
export const FormCheckboxField = memo<FormCheckboxFieldProp>(
  ({ label, name, marginTop, onChange, checked, required = false }) => {
    return (
      <BaseFormField label={label} marginTop={marginTop} required={required}>
        <StyledCheckbox
          name={name}
          disableRipple
          size="medium"
          onChange={(e) => onChange?.(e.target.checked)}
          checked={checked}
        />
      </BaseFormField>
    )
  },
)

FormCheckboxField.displayName = 'FormCheckboxField'
