import { memo } from 'react'
import { BaseFormField } from '../form-fields'
import { FormikField, InputType } from './formik-field'

export type FormikFieldType = {
  key: string
  inputType: InputType | undefined
  label: string
  disabled?: boolean
}

type Props = {
  columns: FormikFieldType[]
}

export const BaseFormikForm = memo<Props>(({ columns }) => {
  return (
    <div>
      {columns.map((it) => {
        const { key, inputType, disabled } = it
        return (
          <BaseFormField key={key} label={it.label} templateColumns="1fr 2fr">
            <FormikField
              name={key}
              inputType={inputType ?? InputType.text}
              disabled={disabled}
            />
          </BaseFormField>
        )
      })}
    </div>
  )
})
BaseFormikForm.displayName = 'DataFormModalContent'
