import { CircularProgress } from '@mui/material'
import { memo } from 'react'
import styled from 'styled-components'
const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`
export const Spinner = memo(() => {
  return (
    <Root>
      <CircularProgress />
    </Root>
  )
})

Spinner.displayName = 'Spinner'
