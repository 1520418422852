import {
  AssetListRequestPayload,
  AssetRequestsResponsePayload,
  AssetRequestStatus,
  AssetResponsePayload,
  AssetStatus,
  AssetStatusGroup,
} from '@stewards-fas/schemas'
import dayjs from 'dayjs'
import { date, InferType, mixed, number, object, string } from 'yup'
import { formats } from '../theme'
import { All_VALUE_OF_DROPDOWN, convertAllValueToNull } from '../utilities'
import { compareString } from '../utilities/compare-string'

export enum AssetReimburseStatus {
  reimbursed = 'REIMBURSED', // all status prefix with REIMBURSE
  notReimbursed = 'NOT_REIMBURSED', // all status not prefix with REIMBURSE
}

export enum Section {
  search = 'search',
  reimburse = 'reimburse',
  transfer = 'transfer',
}

export const SearchFormSchema = object({
  keyword: string().optional(),
  unit: string().optional(),
  toUnit: string().optional(),
  assetType: string().optional(),
  startDate: date().optional(),
  endDate: date().optional(),
  name: string().optional(),
  brand: string().optional(),
  model: string().optional(),
  serialNumber: string().optional(),
  provider: string().optional(),
  amountStart: number()
    .integer('searchBar.errors.amountMustBeInteger')
    .optional(),
  amountEnd: number()
    .integer('searchBar.errors.amountMustBeInteger')
    .optional(),
  state: mixed<AssetReimburseStatus>()
    .oneOf(Object.values(AssetReimburseStatus))
    .optional(),
})

export type SearchFormSchemaModel = InferType<typeof SearchFormSchema>

export const SearchFormInitialValues: SearchFormSchemaModel = {
  keyword: '',
  unit: All_VALUE_OF_DROPDOWN,
  toUnit: All_VALUE_OF_DROPDOWN,
  assetType: All_VALUE_OF_DROPDOWN,
  startDate: undefined,
  endDate: undefined,
  name: '',
  brand: '',
  model: '',
  serialNumber: '',
  provider: '',
  amountStart: undefined,
  amountEnd: undefined,
  state: AssetReimburseStatus.notReimbursed,
}

type AssetRequestListTableRow = {
  id: string
  type: string
  assetNo: string // `newAssetNo` or `reimburseNo`
  name: string
  brand: string
  model: string
  quantity: number | string
  valuedAmount: number | string
  requestDateTime: string
  atUnitNotes: string
  status: string
}

export const mapAssetRequestListToTableRows = (
  assetList: AssetRequestsResponsePayload[],
): AssetRequestListTableRow[] =>
  assetList.map<AssetRequestListTableRow>((it) => {
    const isTransitAsset = !it.reimburseStatus
    const asset =
      it.assetRequestAssets?.length > 0
        ? it.assetRequestAssets[0].asset
        : {
            name: '',
            model: '',
            brand: '',
            valuedAmount: 0,
          }
    const requestDateTime = isTransitAsset
      ? it.transitStatus != AssetRequestStatus.approved
        ? it.transitDateTime
        : it.receiveDateTime
      : it.submissionDateTime

    return {
      id: it.id,
      type: it.type,
      assetNo: it.newAssetNo ?? it.reimburseNo ?? 'N/A',
      name: isTransitAsset ? asset.name : 'N/A',
      brand: isTransitAsset ? asset.brand : 'N/A',
      model: isTransitAsset ? asset.model : 'N/A',
      quantity: isTransitAsset ? it.transitQuantity : 'N/A',
      valuedAmount: isTransitAsset ? asset.valuedAmount : 'N/A',
      requestDateTime: dayjs(requestDateTime).format(formats.date),
      atUnitNotes: isTransitAsset ? it.toUnit?.code ?? it.toUnitId : 'N/A',
      status: isTransitAsset
        ? it.transitStatus != AssetRequestStatus.approved
          ? it.transitStatus
          : it.receiveStatus
        : it.reimburseStatus,
    }
  })

type SearchAssetListTableRow = {
  id: string
  assetNo: string
  name: string
  brand: string
  model: string
  quantity: number
  valuedAmount: number
  purchasedDateTime: string
  atUnitNotes: string | undefined
  status: string
}

type ApprovalAssetListTableRow = {
  id: string
  action: string
  assetNo: string
  name: string
  brand: string
  model: string
  quantity: number
  valuedAmount: number
  purchasedDateTime: string
  atUnitNotes: string | undefined
  status: string
}

export const mapSearchAssetListToTableRows = (
  assetList: AssetResponsePayload[],
): SearchAssetListTableRow[] => {
  // sort data by unit code, asset type, asset no
  const sorted = assetList
    .sort((a, b) => {
      return compareString(a.assetNo, b.assetNo, { isPutFalsyLast: true })
    })
    .sort((a, b) => {
      return compareString(a.assetType?.code, b.assetType?.code, {
        isPutFalsyLast: true,
      })
    })
    .sort((a, b) => {
      return compareString(a.unit?.code, b.unit?.code, { isPutFalsyLast: true })
    })

  return sorted.map<SearchAssetListTableRow>((it) => ({
    id: it.id,
    assetNo: it.assetNo,
    name: it.name,
    brand: it.brand,
    model: it.model,
    quantity: it.quantity,
    valuedAmount: it.valuedAmount,
    purchasedDateTime: dayjs(it.purchasedDateTime).format(formats.date),
    atUnitNotes: it.atUnitNotes,
    status: it.status,
  }))
}

type WriteOffAssetListTableRow = {
  id: string
  assetNo: string
  quantity: number
  atUnitNotes: string
  status: string
}

export const mapWriteOffAssetListToTableRows = (
  assetList: AssetResponsePayload[],
): WriteOffAssetListTableRow[] =>
  assetList.map<WriteOffAssetListTableRow>((it) => ({
    id: it.id,
    assetNo: it.assetNo,
    quantity: it.quantity,
    atUnitNotes: it.atUnitNotes ?? '',
    status: it.status,
  }))

type AssetTransferRequestListTableRow = {
  id: string
  transitDateTime: string
  fromUnit: string
  transitStatus: string
  toUnit: string
  receiveStatus: string
  receiveDateTime: string
  newAssetNo: string
  name: string
  brand: string
  transitQuantity: number
  reason: string
}

export const mapAssetTransferRequestListToTableRows = (
  assetRequestList: AssetRequestsResponsePayload[],
): AssetTransferRequestListTableRow[] =>
  assetRequestList.map<AssetTransferRequestListTableRow>((it) => ({
    id: it.id,
    assetId: it.assetRequestAssets[0].asset.id,
    transitDateTime: dayjs(it.transitDateTime).format(formats.date),
    fromUnit: `${it.fromUnit?.code} ${it.fromUnit?.nameCN}`,
    transitStatus: it.transitStatus,
    toUnit: `${it.toUnit?.code} ${it.toUnit?.nameCN}`,
    receiveStatus: it.receiveStatus,
    receiveDateTime: it.receiveDateTime
      ? dayjs(it.receiveDateTime).format(formats.date)
      : '',
    newAssetNo: it.newAssetNo,
    name: it.assetRequestAssets[0].asset.name,
    brand: it.assetRequestAssets[0].asset.brand,
    transitQuantity: it.transitQuantity,
    reason: it.reason,
  }))

export function mapFormikToSearchParams(formikValues: SearchFormSchemaModel) {
  return {
    assetNo: formikValues.keyword,
    purchasedFromDateTime: formikValues.startDate?.toISOString(),
    purchasedToDateTime: formikValues.endDate?.toISOString(),
    name: formikValues.name,
    brand: formikValues.brand,
    model: formikValues.model,
    serialNo: formikValues.serialNumber,
    providerName: formikValues.provider,
    valuedAmountLargerOrEqualThan: formikValues.amountStart,
    valuedAmountSmallerOrEqualThan: formikValues.amountEnd,
    unitId: convertAllValueToNull(formikValues.unit),
    assetTypeId: convertAllValueToNull(formikValues.assetType),
    status:
      formikValues.state &&
      (formikValues.state === AssetReimburseStatus.reimbursed
        ? AssetStatusGroup.reimburse
        : AssetStatusGroup.nonReimburse),
  }
}

export function mapAssetSearchParamsToFormik(
  value: AssetListRequestPayload,
): SearchFormSchemaModel {
  return {
    keyword: value.assetNo,
    startDate:
      value.purchasedFromDateTime != null
        ? new Date(value.purchasedFromDateTime)
        : undefined,
    endDate:
      value.purchasedToDateTime != null
        ? new Date(value.purchasedToDateTime)
        : undefined,
    name: value.name,
    brand: value.brand,
    model: value.model,
    serialNumber: value.serialNo,
    provider: value.providerName,
    amountStart: value.valuedAmountLargerOrEqualThan,
    amountEnd: value.valuedAmountSmallerOrEqualThan,
    unit: value.unitId ?? All_VALUE_OF_DROPDOWN,
    assetType: value.assetTypeId ?? All_VALUE_OF_DROPDOWN,
    state: AssetStatusGroup.reimburse.every((reimburseStatus) =>
      value.status?.includes(reimburseStatus),
    )
      ? AssetReimburseStatus.reimbursed
      : AssetReimburseStatus.notReimbursed,
  }
}

export const mapApprovalAssetListToTableRows = (
  assetList: AssetResponsePayload[],
  displayString: { create: string; edit: string },
): ApprovalAssetListTableRow[] =>
  assetList.map<ApprovalAssetListTableRow>((it) => ({
    id: it.id,
    action:
      it.status === AssetStatus.created
        ? displayString.create
        : displayString.edit,
    assetNo: it.assetNo,
    name: it.name,
    brand: it.brand,
    model: it.model,
    quantity: it.quantity,
    valuedAmount: it.valuedAmount,
    purchasedDateTime: dayjs(it.purchasedDateTime).format(formats.date),
    atUnitNotes: it.atUnitNotes,
    status: it.status,
  }))
