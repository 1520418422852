import { z } from 'zod'
import {
  makeGetPayloadSchema,
  makePatchPayloadSchema,
  makePostPayloadSchema,
} from '../base'
import { FundingResponseSchema } from '../fundings'

// Asset Fundings Schema
export const AssetFundingsSchema = z.object({
  id: z.string().uuid(),
  assetId: z.string().uuid(),
  fundingId: z.string().uuid(),
})

// Asset Fundings Request

// Asset Fundings Response
export const AssetFundingsResponseSchema = makeGetPayloadSchema(
  AssetFundingsSchema,
).extend({
  funding: FundingResponseSchema.nullable(),
})
export type AssetFundingsResponsePayload = z.infer<typeof AssetFundingsSchema>

// Asset Fundings Patch
export const PatchAssetFundingsSchema = makePatchPayloadSchema(
  AssetFundingsSchema,
).extend({ isDeleted: z.boolean() })
export type PatchAssetFundingsPayload = z.infer<typeof PatchAssetFundingsSchema>

// Asset Fundings Post
export const PostAssetFundingsSchema =
  makePostPayloadSchema(AssetFundingsSchema)
export type PostAssetFundingsPayload = z.infer<typeof PostAssetFundingsSchema>
