import { TFuncKey, useTranslation } from '@pasteltech/i18n-react'
import {
  AssetStatus,
  AssetTypeResponsePayload,
  FundingResponsePayload,
  UnitResponsePayload,
  UserResponsePayload,
} from '@stewards-fas/schemas'
import { useFormikContext } from 'formik'
import { useCallback, useMemo } from 'react'
import { Option, useAssetStatus, useDropdownOptions } from '../../../hooks'
import {
  AssetFormSchema,
  AssetFormSchemaModel,
  CreateAssetFormSchemaModel,
} from '../../../models'
import {
  FormCheckboxField,
  FormDateField,
  FormMultiSelectField,
  FormReadOnlyField,
  FormSelectField,
  FormTextField,
} from '../../form-fields'
import { TableProps, TransferRecordTable } from '../../transfer-record-table'
import { FieldLabel, FieldProps, FormAction } from '../tabs-form-fields'
import { useParams } from 'react-router-dom'
import { useGetAsset } from '@stewards-fas/queries'

type Props = {
  action: FormAction
  unitList: UnitResponsePayload[]
  userList: UserResponsePayload[]
  assetTypeList: AssetTypeResponsePayload[]
  fundingsList: FundingResponsePayload[]
  fields?: FieldProps[]
  transferRecord?: TableProps
}
const emptyHolder = '- - -'
export function useFormFields({
  action,
  unitList,
  userList,
  assetTypeList,
  fundingsList,
  fields,
  transferRecord,
}: Props) {
  const { t } = useTranslation()
  const { values, setFieldValue, setFieldTouched, errors, touched } =
    useFormikContext<AssetFormSchemaModel>()
  const { id: currentAssetId } = useParams()
  const { data: asset } = useGetAsset(
    currentAssetId ?? '',
    { expand: ['AssetFundings.Funding'] },
    { enabled: currentAssetId !== undefined },
  )
  const { statusCN } = useAssetStatus(values.status as AssetStatus)
  const { assetTypeOptions, unitOptions, yearOptions, fundingOptions } =
    useDropdownOptions({
      assetTypeList,
      unitList,
      fundingsList,
    })

  const getSelectOptions = useCallback(
    (field: FieldLabel) => {
      if (field === 'unit') {
        return unitOptions
      }
      if (field === 'assetType') {
        return assetTypeOptions
      }
      if (field === 'year') {
        return yearOptions
      }
      if (field === 'fundings') {
        if (!asset) {
          return fundingOptions
        }
        const initInactiveFunding = asset.assetFundings.reduce(
          (acc: Option[], assetFunding) => {
            if (!assetFunding) return acc
            const funding = assetFunding.funding
            if (funding && funding.status === 'inactive')
              return [...acc, { value: funding.id, label: funding.code }]
            return acc
          },
          [],
        )
        return [...initInactiveFunding, ...fundingOptions]
      }
      return []
    },
    [unitOptions, assetTypeOptions, yearOptions, fundingOptions, asset],
  )
  const getDefaultValue = useCallback(
    (field: FieldLabel) => {
      if (field === 'unit') {
        return (
          unitList.find((unit) => unit.id === values.unit)?.code ?? emptyHolder
        )
      }
      if (field === 'supervisor') {
        return (
          userList.find((user) => user.id === values.supervisor)?.username ??
          emptyHolder
        )
      }
      if (field === 'formFiller') {
        return (
          userList.find((user) => user.id === values.formFiller)?.username ??
          emptyHolder
        )
      }
      if (field === 'fundings') {
        return (
          fundingsList.find((funding) => funding.id === values.fundings)
            ?.code ?? emptyHolder
        )
      }
      if (field === 'status') {
        return statusCN
      }
      return (values[field] as string) ?? emptyHolder
    },
    [values, unitList, userList, fundingsList, statusCN],
  )

  const UnitOnChange = useCallback(
    (val: string) => {
      const selectedUnit = unitList.find((unit) => unit.id === val)
      setFieldValue('supervisor', selectedUnit?.supervisorUserId ?? undefined)
    },
    [setFieldValue, unitList],
  )

  const fieldsDetails = useMemo(() => {
    return fields
      ? fields
          .filter(({ actions }) => actions.includes(action))
          .map(({ field, props, element, readOnlyActions }) => {
            if (element === TransferRecordTable) {
              return transferRecord ? (
                <TransferRecordTable records={transferRecord} />
              ) : null
            }

            if (
              field === 'totalPieces' &&
              (values as CreateAssetFormSchemaModel)?.batchInput !== true
            ) {
              return null
            }

            if (
              (field === 'quantity' || field === 'serialNo') &&
              (values as CreateAssetFormSchemaModel)?.batchInput === true
            ) {
              return null
            }
            const placeholderIcon =
              action === FormAction.create ? props?.icon : undefined
            const name = field
            const label = t(`assetDetailsPage.form.fields.labels.${field}`)
            const placeholder = t(
              `assetDetailsPage.form.fields.placeholders.${field}`,
            )
            if (
              action === FormAction.view ||
              (readOnlyActions?.includes(action) ?? false) ||
              element === FormReadOnlyField
            ) {
              return (
                <FormReadOnlyField
                  key={label}
                  {...props}
                  icon={placeholderIcon}
                  label={label}
                  defaultValue={getDefaultValue(field)}
                />
              )
            }
            const common = {
              label: label,
              name: name,
              error: Boolean(errors[name]) && Boolean(touched[name]),

              helperText: t([
                String(errors[name]) as TFuncKey,
                'assetDetailsPage.form.errorMessages.invalidInput',
              ]) as string,

              required:
                // @ts-ignore
                AssetFormSchema.describe().fields[name]?.optional === false,
              ...props,
            }
            if (element === FormTextField) {
              return (
                <FormTextField
                  key={label}
                  {...common}
                  placeholder={placeholder}
                  onChange={async (val) => {
                    setFieldTouched(name, true, true)
                    await setFieldValue(name, val)
                    if (field === 'totalPieces') {
                      setFieldValue('assetBulk', [])
                    }
                  }}
                  value={(values[name] as string) ?? ''}
                />
              )
            }
            if (element === FormCheckboxField) {
              return (
                <FormCheckboxField
                  key={label}
                  {...common}
                  checked={values[name] as boolean}
                  onChange={async (val) => {
                    setFieldTouched(name, true, true)
                    await setFieldValue(name, val)
                  }}
                />
              )
            }
            if (element === FormDateField) {
              return (
                <FormDateField
                  key={label}
                  {...common}
                  value={values[name] as Date}
                  onChange={async (val) => {
                    setFieldTouched(name, true, true)
                    await setFieldValue(name, val)
                  }}
                />
              )
            }
            if (element === FormMultiSelectField) {
              return (
                <FormMultiSelectField
                  key={label}
                  {...common}
                  options={getSelectOptions(field)}
                  onChange={async (val) => {
                    setFieldTouched(name, true, true)
                    await setFieldValue(name, val)
                  }}
                  value={values[name] as string[]}
                />
              )
            }
            if (element === FormSelectField) {
              return (
                <FormSelectField
                  key={label}
                  {...common}
                  options={getSelectOptions(field)}
                  onChange={async (val) => {
                    setFieldTouched(name, true, true)
                    await setFieldValue(name, val)
                    if (field === 'unit') {
                      UnitOnChange(val as string)
                    }
                  }}
                  value={values[name] as string}
                  defaultValue={
                    getDefaultValue(name) === emptyHolder
                      ? ''
                      : getDefaultValue(name)
                  }
                />
              )
            }
            return null
          })
      : null
  }, [
    fields,
    action,
    t,
    values,
    transferRecord,
    setFieldValue,
    getDefaultValue,
    UnitOnChange,
    errors,
    setFieldTouched,
    touched,
    getSelectOptions,
  ])
  return fieldsDetails
}
