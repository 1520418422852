import { MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material'
import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { Option } from '../../hooks'

const StyledSelect = styled(Select)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`

export type BaseDropdownFieldProps = SelectProps & {
  data?: Option[]
  label?: string
  name?: string
  value?: string
  onChange?: (event: SelectChangeEvent<unknown>) => void
}

export const BaseDropdownField = memo<BaseDropdownFieldProps>(
  ({ label, data = [], name, value, onChange, placeholder, ...rest }) => {
    const renderValue = useCallback(
      (internalValue: any) =>
        // eslint-disable-next-line no-nested-ternary
        internalValue?.length
          ? Array.isArray(internalValue)
            ? internalValue.join(', ')
            : internalValue
          : placeholder,
      [placeholder],
    )

    return (
      <StyledSelect
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        size="small"
        name={name}
        value={value}
        displayEmpty={true}
        renderValue={rest.renderValue ?? renderValue}
        onChange={onChange}
      >
        {data.map((datum) => (
          <MenuItem key={datum.value ?? 'undefined'} value={datum.value}>
            {datum.label}
          </MenuItem>
        ))}
      </StyledSelect>
    )
  },
)

BaseDropdownField.displayName = 'BaseDropdownField'
