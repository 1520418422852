import { memo, useCallback, useMemo } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  AppManagementTab,
  AppManagementTabItem,
  Spinner,
} from '../../components'
import { useUserRole } from '../../hooks'
import { AppRoutes } from '../../routers'
import { assetManagementTabItems } from './asset-management-tab'
import { WriteOffSectionTabItem } from './asset-management-write-off-section'

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  gap: 20px;
  overflow-x: auto;
`

const TabItemPath: Partial<{
  [key in AppManagementTabItem]: { basePath: string; path: string }
}> = {
  [AppManagementTabItem.search]: {
    basePath: AppRoutes.assetManagement.searchSection.path,
    path: AppRoutes.assetManagement.searchSection.path,
  },
  [AppManagementTabItem.add]: {
    basePath: AppRoutes.assetManagement.createSection.path,
    path: AppRoutes.assetManagement.createSection.path,
  },
  [AppManagementTabItem.writeOff]: {
    basePath: AppRoutes.assetManagement.writeOffSection.basePath,
    path: AppRoutes.assetManagement.writeOffSection.render(
      WriteOffSectionTabItem.reimbursementItems,
    ),
  },
  [AppManagementTabItem.transfer]: {
    basePath: AppRoutes.assetManagement.transferSection.path,
    path: AppRoutes.assetManagement.transferSection.path,
  },
  [AppManagementTabItem.approvalStatus]: {
    basePath: AppRoutes.assetManagement.approvalSection.path,
    path: AppRoutes.assetManagement.approvalSection.path,
  },
} as const

export const AssetManagementPage = memo(() => {
  const navigate = useNavigate()
  const location = useLocation()

  const { role, isLoading } = useUserRole()

  const permittedAssetManagementTabItems = useMemo(() => {
    return assetManagementTabItems.filter((it) =>
      it.allowedRoles.includes(role),
    )
  }, [role])

  const onSelectTab = useCallback(
    (value: AppManagementTabItem) => {
      const tabItem = TabItemPath[value]
      if (tabItem?.path) navigate(tabItem.path)
      return
    },
    [navigate],
  )

  const currentTab = useMemo(() => {
    const { pathname } = location
    const Path2TabItem: any = Object.entries(TabItemPath).reduce(
      (acc, item) => ({
        ...acc,
        [item[1].basePath]: item[0],
      }),
      {},
    )
    const pathKey =
      Object.keys(Path2TabItem).filter((basePath) => {
        return pathname.includes(basePath)
      })?.[0] ?? ''

    return Path2TabItem[pathKey] as unknown as AppManagementTabItem
  }, [location])

  return (
    <Root>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <TabsContainer>
            {permittedAssetManagementTabItems.map((item) => (
              <AppManagementTab
                key={item.value}
                value={item.value}
                titleKey={item.titleKey}
                icon={item.icon}
                content={item.content}
                type={item.type}
                isSelectedTab={currentTab === item.value}
                onSelect={onSelectTab}
              />
            ))}
          </TabsContainer>
          <Outlet />
        </>
      )}
    </Root>
  )
})

AssetManagementPage.displayName = 'AssetManagementPage'
