import { object, string } from 'yup'

const ERROR_TRANSLATION_PREFIX = 'systemDataManagement.fieldsValidateError'

export const FundingFormSchema = object({
  code: string().required(`${ERROR_TRANSLATION_PREFIX}.funding.code.required`),
  name: string().required(`${ERROR_TRANSLATION_PREFIX}.funding.name.required`),
  status: string().required(
    `${ERROR_TRANSLATION_PREFIX}.funding.status.required`,
  ),
})

export const AssetTypeFormSchema = object({
  code: string().required(
    `${ERROR_TRANSLATION_PREFIX}.assetType.code.required`,
  ),
  typeCN: string().required(
    `${ERROR_TRANSLATION_PREFIX}.assetType.typeCN.required`,
  ),
  typeEN: string().required(
    `${ERROR_TRANSLATION_PREFIX}.assetType.typeEN.required`,
  ),
  contents: string().required(
    `${ERROR_TRANSLATION_PREFIX}.assetType.contents.required`,
  ),
  status: string().required(
    `${ERROR_TRANSLATION_PREFIX}.assetType.status.required`,
  ),
})

export const UnitFormSchema = object({
  code: string().required(`${ERROR_TRANSLATION_PREFIX}.unit.code.required`),
  nameCN: string().required(`${ERROR_TRANSLATION_PREFIX}.unit.nameCN.required`),
  nameEN: string().required(`${ERROR_TRANSLATION_PREFIX}.unit.nameEN.required`),
  status: string().required(`${ERROR_TRANSLATION_PREFIX}.unit.status.required`),
  supervisorUserId: string().required(
    `${ERROR_TRANSLATION_PREFIX}.unit.supervisorUserId.required`,
  ),
})

export const UserFormSchema = object({
  username: string().required(
    `${ERROR_TRANSLATION_PREFIX}.user.username.required`,
  ),
  email: string().required(`${ERROR_TRANSLATION_PREFIX}.user.email.required`),
  position: string().required(
    `${ERROR_TRANSLATION_PREFIX}.user.position.required`,
  ),
  roleId: string().required(`${ERROR_TRANSLATION_PREFIX}.user.roleId.required`),
  status: string().required(`${ERROR_TRANSLATION_PREFIX}.user.status.required`),
})
