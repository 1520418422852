import { useQueryUserProfile, useUnitList } from '@stewards-fas/queries'
import { useUserRole } from './use-user-role'
import { useCallback, useMemo } from 'react'

/**
 * Logics related to user's units
 * */
export function useCurrentUserUnits() {
  const { data: currentUser } = useQueryUserProfile({ expand: ['UserUnits'] })
  const { data: allUnits } = useUnitList()
  const { isAdmin } = useUserRole()

  // computed values
  const allUnitIds = useMemo(() => {
    return (allUnits?.data ?? []).map((u) => u.id)
  }, [allUnits?.data])
  const currentUserUnitIds = useMemo(() => {
    return isAdmin
      ? // admin can manage all units
        allUnitIds
      : currentUser?.userUnits.map((uu) => uu.unitId) ?? []
  }, [allUnitIds, currentUser?.userUnits, isAdmin])

  /**
   * Check whether the unit is managed by the user. Always return true for admin
   * */
  const checkPermittedUnitId = useCallback(
    (unitId: string) => isAdmin || currentUserUnitIds.includes(unitId),
    [currentUserUnitIds, isAdmin],
  )

  return { currentUserUnitIds, checkPermittedUnitId }
}
