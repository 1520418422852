import { useTranslation } from '@pasteltech/i18n-react'
import { AssetResponsePayload } from '@stewards-fas/schemas'
import dayjs from 'dayjs'
import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { formats } from '../../../../theme'
import { GenericColumns, GenericTable } from '../../../../components'
const StyledTable = styled(GenericTable)`
  & .MuiTableCell-root {
    font-size: 18px;
    padding: 6px;
  }
`

type RecordListTablePropType = {
  rowData: AssetResponsePayload[]
}
export const RecordListTable = memo<RecordListTablePropType>(({ rowData }) => {
  const { t } = useTranslation()

  const displayData = useMemo(() => {
    return rowData.map((row, rowCount) => {
      return {
        ...row,
        rowCount: rowCount + 1,
        location: row.atUnitNotes,
        purchasedDate: dayjs(row.purchasedDateTime)?.format(formats.date),
      }
    })
  }, [rowData])
  const tableColumns = useMemo(() => {
    // assign to a typed constant to enforce type checking
    const columns: GenericColumns<(typeof displayData)[0]> = {
      rowCount: '',
      assetNo: t('assetManagement.writeOffSection.TableHeaders.assetNo'),
      name: t('assetManagement.writeOffSection.TableHeaders.name'),
      brand: t('assetManagement.writeOffSection.TableHeaders.brand'),
      model: t('assetManagement.writeOffSection.TableHeaders.model'),
      location: t('assetManagement.writeOffSection.TableHeaders.atUnitNotes'),
      purchasedDate: t(
        'assetManagement.writeOffSection.TableHeaders.purchasedDateTime',
      ),
      quantity: t('assetManagement.writeOffSection.TableHeaders.quantity'),
      reason: t('assetManagement.writeOffSection.TableHeaders.reason'),
    }
    return columns
  }, [t])

  return (
    <>
      <StyledTable
        rowData={displayData}
        tableColumns={tableColumns}
        rowIdKey="id"
      />
    </>
  )
})
RecordListTable.displayName = 'RecordListTable'
