import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import {
  useGetAsset,
  useGetBlobDownloadUrlForResourcesList,
} from '@stewards-fas/queries'
import {
  AssetRequestStatus,
  AssetRequestsResponsePayload,
  AssetStatus,
  UnitResponsePayload,
} from '@stewards-fas/schemas'
import dayjs from 'dayjs'
import { memo, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { StewardsHeaderLogoLarge } from '../../icons'
import { colors, formats } from '../../theme'
import { SignatureBox } from './signature-box'

const RecordListContainer = styled.div`
  padding: 60px;
  display: flex;
  flex-direction: column;
  @media print {
    zoom: 0.666;
  }
`

const RecordListHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 1rem;
`

const Logo = styled(StewardsHeaderLogoLarge)<{ $noImage?: boolean }>`
  width: 90px;
  height: 90px;
  visibility: ${({ $noImage }) => ($noImage ? 'hidden' : 'visible')};
  filter: blur(0);
`

const TitleContainer = styled.div`
  margin: 0 auto;
`

const TitleLine = styled.div`
  font-size: 1.75rem;
`

const RecordMeta = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.black};
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.greys[4]};
  padding-bottom: 1rem;
`

const TransitMetaContainer = styled.div`
  margin: 2rem 0;
`

const AssetNoContainer = styled.div`
  margin-left: auto;
  width: 40%;
  display: flex;
`

const RecordMetaLine = styled.div`
  display: flex;
`

const TransitRecord = styled(TableContainer)`
  & th,
  & td {
    font-size: 20px;
  }
`

const InfoContainer = styled.div<{ $isBorderBottom?: boolean }>`
  display: flex;
  align-items: center;
  border-bottom: ${({ $isBorderBottom }) => ($isBorderBottom ? '2' : '0')}px
    solid ${({ theme }) => theme.colors.greys[4]};
`

const FieldContainer = styled.div<{ $noBgColor?: boolean }>`
  width: 150px;
  font-weight: bold;
  font-size: 1.2rem;
  background: ${({ theme, $noBgColor }) =>
    $noBgColor ? 'initial' : theme.colors.greys[5]};
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  display: flex;
  align-items: center;
  padding: 5px 8px;
`

const FieldValue = styled(Typography)`
  padding: 5px 8px;
  font-size: 1.2rem;
`

const FormContentContainer = styled.div`
  display: flex;
`

const FieldsColumn = styled.div`
  flex-basis: 60%;
`

const ImagesColumn = styled.div`
  flex-basis: 40%;
  max-height: 50vh;
  margin-left: 3rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const ImageContainer = styled.div`
  flex: 1;
  flex-shrink: 1;
  height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: contain;
    height: 100%;
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
`
const QRCodeContainer = styled.div`
  width: 200px;
  height: 200px;
  img {
    object-fit: contain;
    width: 150%;
    max-height: 100%;
  }
`
type TransferPreviewFormPropType = {
  isPreviewAsset?: boolean
  QRCodeURL?: string
}

export const TransferPreviewForm = memo<TransferPreviewFormPropType>(
  ({ isPreviewAsset, QRCodeURL }) => {
    const { t } = useTranslation()
    const { id } = useParams()

    const headers = useMemo(
      () => [
        t(`assetTransferRecordPage.table.transitDateTime`),
        t(`assetTransferRecordPage.table.transitQuantity`),
        t(`assetTransferRecordPage.table.fromUnit`),
        t(`assetTransferRecordPage.table.toUnit`),
        t(`assetTransferRecordPage.table.reason`),
      ],
      [t],
    )

    const { data: asset } = useGetAsset(id ?? '', {
      expand: [
        'AssetType',
        'FormFillUser',
        'Unit',
        'Unit.SupervisorUser',
        'ParentAssetLinks',
        'ParentAssetLinks.Child',
        'AssetRequestAssets',
        'AssetRequestAssets.AssetRequest',
        'AssetRequestAssets.AssetRequest.RequestedByUser',
        'AssetRequestAssets.AssetRequest.FromUnit',
        'AssetRequestAssets.AssetRequest.ToUnit',
        'Resources',
        'AssetFundings',
        'AssetFundings.Funding',
        'AssetStatusLogs',
      ],
    })

    const validAssetRequests = useMemo(() => {
      // Return Asset Requests that are not yet withdrawn
      return (asset?.assetRequestAssets ?? []).filter((req) => {
        const real = req.assetRequest as AssetRequestsResponsePayload
        if (real.transitStatus === AssetRequestStatus.withdrew) return false
        if (real.receiveStatus === AssetRequestStatus.withdrew) return false
        return true
      })
    }, [asset?.assetRequestAssets])

    const firstAssetRequest = useMemo(() => {
      return validAssetRequests[0]?.assetRequest as AssetRequestsResponsePayload
    }, [validAssetRequests])

    const latestAssetRequestApprovalDate = useMemo(() => {
      const latestAssetApprovalDate = validAssetRequests
        .map((validRequest) => {
          const assetRequest =
            validRequest.assetRequest as AssetRequestsResponsePayload
          return assetRequest.receiveDateTime
        })
        .sort()
        .pop()
      return (
        latestAssetApprovalDate &&
        dayjs(latestAssetApprovalDate).format(formats.date)
      )
    }, [validAssetRequests])

    const { data: resourceWithValidUrl } =
      useGetBlobDownloadUrlForResourcesList(asset?.resources ?? [], {
        enabled: asset !== null,
      })

    const approvedDate = useMemo(() => {
      if (!asset?.assetStatusLogs || asset.status !== AssetStatus.assetApproved)
        return undefined
      const lastestApprovedDate = asset.assetStatusLogs
        .filter(
          (log) =>
            ![AssetStatus.assetUpdated].includes(log.newStatus as AssetStatus),
        )
        .map((log) => log.createdDateTime)
        .sort()
        .pop()
      return (
        lastestApprovedDate && dayjs(lastestApprovedDate).format(formats.date)
      )
    }, [asset])

    const assetUpdateTime = useMemo(() => {
      if (!asset?.assetStatusLogs) return undefined
      const lastestUpdatedDate = asset.assetStatusLogs
        .filter(
          (log) =>
            ![AssetStatus.assetUpdated, AssetStatus.created].includes(
              log.newStatus as AssetStatus,
            ),
        )
        .map((log) => log.createdDateTime)
        .sort()
        .pop()
      return (
        lastestUpdatedDate && dayjs(lastestUpdatedDate).format(formats.date)
      )
    }, [asset])

    const formFillerName = isPreviewAsset
      ? asset?.formFillUser?.username
      : firstAssetRequest?.requestedByUser?.username
    const supervisorName = asset?.unit?.supervisorUser?.username
    const formFillDate = isPreviewAsset
      ? assetUpdateTime
      : dayjs(firstAssetRequest?.createdDateTime).format(formats.date)
    const approvalDate = isPreviewAsset
      ? approvedDate
      : latestAssetRequestApprovalDate

    return (
      <Typography>
        <RecordListContainer>
          <RecordListHeader>
            <Logo />
            <TitleContainer>
              <TitleLine>
                {t('assetTransferRecordPage.previewForm.titleLine1')}
              </TitleLine>
              {isPreviewAsset ? (
                <TitleLine>
                  {t('assetViewPage.previewForm.titleLine2')}
                </TitleLine>
              ) : (
                <TitleLine>
                  {t('assetTransferRecordPage.previewForm.titleLine2')}
                </TitleLine>
              )}
            </TitleContainer>
            <Logo $noImage={true} />
            <QRCodeContainer>
              {QRCodeURL && isPreviewAsset && <img src={QRCodeURL} />}
            </QRCodeContainer>
          </RecordListHeader>
          <RecordMeta>
            <RecordMetaLine>
              <InfoContainer>
                <FieldContainer $noBgColor={true}>
                  {t('assetTransferRecordPage.previewForm.unit')}:
                </FieldContainer>
                <FieldValue>{asset?.unit?.nameCN}</FieldValue>
              </InfoContainer>
              <AssetNoContainer>
                <FieldContainer $noBgColor={true}>
                  {t('assetTransferRecordPage.previewForm.assetNo')}:
                </FieldContainer>
                <FieldValue>{asset?.assetNo}</FieldValue>
              </AssetNoContainer>
            </RecordMetaLine>
            <InfoContainer>
              <FieldContainer $noBgColor={true}>
                {t('assetTransferRecordPage.previewForm.assetType')}:
              </FieldContainer>
              <FieldValue>{asset?.assetType?.typeCN}</FieldValue>
            </InfoContainer>
          </RecordMeta>
          <FormContentContainer>
            <FieldsColumn>
              {!isPreviewAsset && (
                <TransitMetaContainer>
                  <InfoContainer $isBorderBottom={true}>
                    <FieldContainer>
                      {t('assetTransferRecordPage.previewForm.transferer')}
                    </FieldContainer>
                    <FieldValue>
                      {firstAssetRequest?.requestedByUser?.username}
                    </FieldValue>
                  </InfoContainer>

                  <InfoContainer>
                    <FieldContainer>
                      {t(
                        'assetTransferRecordPage.previewForm.transferDateTime',
                      )}
                    </FieldContainer>
                    <FieldValue>
                      {dayjs(firstAssetRequest?.transitDateTime).format(
                        formats.date,
                      )}
                    </FieldValue>
                  </InfoContainer>
                </TransitMetaContainer>
              )}

              <InfoContainer $isBorderBottom={true}>
                <FieldContainer>
                  {t('assetTransferRecordPage.previewForm.purchasedDateTime')}
                </FieldContainer>
                <FieldValue>
                  {dayjs(asset?.purchasedDateTime).format(formats.date)}
                </FieldValue>
              </InfoContainer>

              <InfoContainer $isBorderBottom={true}>
                <FieldContainer>
                  {t('assetTransferRecordPage.previewForm.brand')}
                </FieldContainer>
                <FieldValue>{asset?.brand}</FieldValue>
              </InfoContainer>

              <InfoContainer $isBorderBottom={true}>
                <FieldContainer>
                  {t('assetTransferRecordPage.previewForm.name')}
                </FieldContainer>
                <FieldValue>{asset?.name}</FieldValue>
              </InfoContainer>

              <InfoContainer $isBorderBottom={true}>
                <FieldContainer>
                  {t('assetTransferRecordPage.previewForm.model')}
                </FieldContainer>
                <FieldValue>{asset?.model}</FieldValue>
              </InfoContainer>

              <InfoContainer $isBorderBottom={true}>
                <FieldContainer>
                  {t('assetTransferRecordPage.previewForm.serialNo')}
                </FieldContainer>
                <FieldValue>{asset?.serialNo}</FieldValue>
              </InfoContainer>

              <InfoContainer $isBorderBottom={true}>
                <FieldContainer>
                  {t('assetTransferRecordPage.previewForm.quantity')}
                </FieldContainer>
                <FieldValue>{asset?.quantity}</FieldValue>
              </InfoContainer>

              <InfoContainer $isBorderBottom={true}>
                <FieldContainer>
                  {t('assetTransferRecordPage.previewForm.valuedAmount')}
                </FieldContainer>
                <FieldValue>{asset?.valuedAmount}</FieldValue>
              </InfoContainer>

              <InfoContainer $isBorderBottom={true}>
                <FieldContainer>
                  {t('assetTransferRecordPage.previewForm.providerName')}
                </FieldContainer>
                <FieldValue>{asset?.providerName}</FieldValue>
              </InfoContainer>

              <InfoContainer $isBorderBottom={true}>
                <FieldContainer>
                  {t('assetTransferRecordPage.previewForm.invoiceNo')}
                </FieldContainer>
                <FieldValue>{asset?.invoiceNo}</FieldValue>
              </InfoContainer>

              <InfoContainer $isBorderBottom={true}>
                <FieldContainer>
                  {t('assetTransferRecordPage.previewForm.warrantyProvider')}
                </FieldContainer>
                <FieldValue>{asset?.warrantyProvider}</FieldValue>
              </InfoContainer>

              <InfoContainer $isBorderBottom={true}>
                <FieldContainer>
                  {t('assetTransferRecordPage.previewForm.warrantyPeriod')}
                </FieldContainer>
                <FieldValue>{asset?.warrantyPeriod}</FieldValue>
              </InfoContainer>

              <InfoContainer $isBorderBottom={true}>
                <FieldContainer>
                  {t('assetTransferRecordPage.previewForm.atUnit')}
                </FieldContainer>
                <FieldValue>{asset?.atUnitNotes}</FieldValue>
              </InfoContainer>

              <InfoContainer $isBorderBottom={true}>
                <FieldContainer>
                  {t('assetTransferRecordPage.previewForm.sourceOfFunds')}
                </FieldContainer>
                <FieldValue>
                  {asset?.assetFundings.map((it) => (
                    <Typography key={it.funding?.name}>
                      {it.funding?.name}{' '}
                    </Typography>
                  ))}
                </FieldValue>
              </InfoContainer>

              <InfoContainer>
                <FieldContainer>
                  {t('assetTransferRecordPage.previewForm.remarks')}
                </FieldContainer>
                <FieldValue>{asset?.remarks}</FieldValue>
              </InfoContainer>
            </FieldsColumn>
            <ImagesColumn>
              {resourceWithValidUrl?.map((image, idx) => (
                <ImageContainer key={`image-${idx}`}>
                  <img src={image.url} alt={image.filename} />
                </ImageContainer>
              ))}
            </ImagesColumn>
          </FormContentContainer>
          {!isPreviewAsset && (
            <TransitMetaContainer>
              <TransitRecord>
                <Table>
                  <TableHead
                    sx={{
                      background: colors.greys[5],
                      borderBottom: `2px solid ${colors.greys[4]}`,
                    }}
                  >
                    <TableRow>
                      {headers.map((header) => (
                        <TableCell key={header} sx={{ fontWeight: 'bold' }}>
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {validAssetRequests?.map((row, idx) => (
                      <TableRow key={`tr-${idx}`}>
                        {[
                          'transitDateTime',
                          'transitQuantity',
                          'fromUnit',
                          'toUnit',
                          'reason',
                        ].map((property) => {
                          const assetRequest =
                            row.assetRequest as AssetRequestsResponsePayload
                          const value =
                            assetRequest[property as keyof typeof assetRequest]

                          return (
                            <TableCell key={property} sx={{ border: 0 }}>
                              {
                                (property === 'transitDateTime'
                                  ? dayjs(value as string).format(formats.date)
                                  : ['fromUnit', 'toUnit'].includes(property)
                                  ? (value as UnitResponsePayload).nameCN
                                  : value) as string
                              }
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TransitRecord>
            </TransitMetaContainer>
          )}
          <SignatureBox
            formFillerName={formFillerName}
            supervisorName={supervisorName}
            formFillDate={formFillDate}
            approvalDate={approvalDate}
          />
        </RecordListContainer>
      </Typography>
    )
  },
)

TransferPreviewForm.displayName = 'TransferPreviewForm'
