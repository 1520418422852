import { AssetListResponsePayload } from '@stewards-fas/schemas'
import { Dayjs } from 'dayjs'
import { createContext, useContext } from 'react'
import { WriteOffSectionTabItem } from './tab-values'
export type WriteOffAssetData = AssetListResponsePayload['data']
type ContextType = {
  writeOffList: WriteOffAssetData
  setWriteOffList: (selected: WriteOffAssetData) => void
  writeOffDate: Dayjs | null
  setWriteOffDate: (day: Dayjs | null) => void
  isViewForm: boolean
  setIsViewForm: React.Dispatch<React.SetStateAction<boolean>>
  viewFormId: string
  setViewFormId: React.Dispatch<React.SetStateAction<string>>
  tabValue: WriteOffSectionTabItem
  setTabValue: (tab: WriteOffSectionTabItem) => void
}

export const WriteOffContext = createContext<ContextType>({} as any)
export const useWriteOffContext = () => useContext(WriteOffContext)
