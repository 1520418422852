const EMPTY_PAYLOAD = 'empty-payload'

/**
 * call the function and return result. But throw if result == null
 * */
export function callbackNonNullable<Param, Result>(
  func: (p: Param) => Promise<Result>,
): (p: Param) => Promise<NonNullable<Result>> {
  return async (payload: Param) => {
    const res = await func(payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }
}
