import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { memo } from 'react'
import styled from 'styled-components'
import { formats } from '../../theme'
import { BaseFormDirection, BaseFormField } from './base-form-field'
import { FormHelperText } from './form-helper-text'
export type FormDateFieldProps = {
  label?: string
  name?: string
  placeholder?: string
  dir?: BaseFormDirection
  size?: 'small' | 'medium'
  onChange?: (val: Date) => void
  value?: Date
  error?: boolean
  helperText?: string
  required?: boolean
}
const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`

export const FormDateField = memo<FormDateFieldProps>(
  ({
    label,
    dir,
    size = 'small',
    onChange,
    value,
    error = false,
    helperText = '',
    required = false,
  }) => {
    return (
      <BaseFormField label={label} dir={dir} required={required}>
        <StyledDatePicker
          slotProps={{ textField: { size, error: error } }}
          format={formats.date}
          value={value ? dayjs(value) : undefined}
          onChange={(val) => {
            onChange?.(val as Date)
          }}
        />
        <FormHelperText>{error && helperText}</FormHelperText>
      </BaseFormField>
    )
  },
)

FormDateField.displayName = 'FormDateField'
