import { memo } from 'react'
import { BaseFormField, BaseFormFieldProps } from './base-form-field'
import { BaseTextField, BaseTextFieldProps } from './base-text-field'
import { FormHelperText } from './form-helper-text'
export type FormTextFieldProps = BaseTextFieldProps &
  BaseFormFieldProps & {
    name?: string
    placeholder?: string
    defaultValue?: string | number
    value?: string | number
    size?: 'small' | 'medium'
    onChange?: (val: string) => void
    type?: 'string' | 'number'
    error?: boolean
    helperText?: string
  }

export const FormTextField = memo<FormTextFieldProps>(
  ({
    label,
    placeholder,
    dir = 'horizontal',
    name = '',
    size = 'small',
    templateColumns,
    marginTop,
    onChange,
    value,
    type = 'string',
    error = false,
    helperText = '',
    required,
    ...rest
  }) => {
    return (
      <BaseFormField
        label={label}
        dir={dir}
        templateColumns={templateColumns}
        marginTop={marginTop}
        required={required}
      >
        <BaseTextField
          {...rest}
          error={error}
          type={type}
          placeholder={placeholder}
          value={value}
          size={size}
          name={name}
          onChange={(e) => {
            onChange?.(e.target.value)
          }}
          required
        />
        <FormHelperText>{error && helperText}</FormHelperText>
      </BaseFormField>
    )
  },
)

FormTextField.displayName = 'FormTextField'
