import { useMemo } from 'react'
import { AppManagementTabItem, CRUDTableActions } from '../../../components'
import {
  AssetTypeFormSchema,
  FundingFormSchema,
  UnitFormSchema,
  UserFormSchema,
} from '../../../models'

export const useMasterDataValidate = (tab: AppManagementTabItem) => {
  return useMemo(() => {
    if (tab === AppManagementTabItem.systemUnitManagement) {
      return {
        [CRUDTableActions.create]: UnitFormSchema,
        [CRUDTableActions.edit]: UnitFormSchema,
        [CRUDTableActions.multipleDelete]: undefined,
      }
    }

    if (tab === AppManagementTabItem.systemUserManagement) {
      return {
        [CRUDTableActions.create]: UserFormSchema,
        [CRUDTableActions.edit]: UserFormSchema,
        [CRUDTableActions.multipleDelete]: undefined,
      }
    }

    if (tab === AppManagementTabItem.systemAssetClassManagement) {
      return {
        [CRUDTableActions.create]: AssetTypeFormSchema,
        [CRUDTableActions.edit]: AssetTypeFormSchema,
        [CRUDTableActions.multipleDelete]: undefined,
      }
    }

    if (tab === AppManagementTabItem.systemFundingResourceManagement) {
      return {
        [CRUDTableActions.create]: FundingFormSchema,
        [CRUDTableActions.edit]: FundingFormSchema,
        [CRUDTableActions.multipleDelete]: undefined,
      }
    }
    return {
      [CRUDTableActions.create]: undefined,
      [CRUDTableActions.edit]: undefined,
      [CRUDTableActions.multipleDelete]: undefined,
    }
  }, [tab])
}
