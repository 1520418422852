import { useQuery } from '@tanstack/react-query'
import { ApiGateway } from '@stewards-fas/apis'
import { z } from 'zod'

const QUERY_CACHE_KEY = 'health-check'

export const useServerHealthCheck = () =>
  useQuery([QUERY_CACHE_KEY, 'server'], async () => {
    const res = await ApiGateway.primary.get('/health', {
      request: z.any(),
      response: z.any(),
    })
    return res
  })
