import { Grid } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { useOverlay } from '@pasteltech/overlay-provider'
import { usePatchAsset, useQueryUserProfile } from '@stewards-fas/queries'
import {
  AssetResponsePayload,
  AssetStatus,
  PatchAssetPayload,
} from '@stewards-fas/schemas'
import { FormikProvider, useFormik } from 'formik'
import { memo, useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  AssetDetailsSection,
  FormAction,
  PageTitle,
  TableProps,
} from '../../../components'
import { BackButton, TransitButton } from '../../../components/buttons'
import {
  useApiErrorHandle,
  useAssetStatus,
  useFormikErrorHelper,
} from '../../../hooks'
import { SaveIcon } from '../../../icons'
import {
  EditAssetFormSchema,
  EditAssetFormSchemaModel,
  mapFormikToPatchAssetPayload,
} from '../../../models'
import { AppRoutes } from '../../../routers'

import { useAssetLink } from '../../../components/asset-details-section/hooks'
const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const SaveButton = styled(TransitButton)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`
type Props = {
  formData: EditAssetFormSchemaModel
  transferRecord?: TableProps
  assetData: AssetResponsePayload
}
export const AssetEditPageContent = memo<Props>(
  ({ formData, transferRecord, assetData }) => {
    const navigate = useNavigate()
    const { id } = useParams()
    const currentAssetID = useMemo(() => id ?? '', [id])
    const { t } = useTranslation()
    const { formikErrorExtractor } = useFormikErrorHelper()
    const { showSnackbar } = useOverlay()
    const { mutateAsync: patchAssetAsync, isLoading: patching } =
      usePatchAsset(currentAssetID)
    const { validStatusForUpdate } = useAssetStatus(
      assetData.status as AssetStatus,
    )
    useEffect(() => {
      if (!validStatusForUpdate) {
        showSnackbar({
          content: t('assetViewPage.errorMessage.notPossibleStatusForUpdate'),
          severity: 'error',
        })
        navigate(AppRoutes.assetView.render(currentAssetID))
      }
    }, [showSnackbar, navigate, t, currentAssetID, validStatusForUpdate])
    const { data: currentUser } = useQueryUserProfile()
    const { standardErrorHandler } = useApiErrorHandle()
    const handlePatching = useCallback(
      async (payload: PatchAssetPayload) => {
        try {
          await patchAssetAsync(payload)
          showSnackbar({
            content: t('assetActions.edit.success'),
          })
          navigate(AppRoutes.assetView.render(currentAssetID))
        } catch (err) {
          standardErrorHandler(err, {
            errorMappings: {
              E0761: {
                content: t('error.conflict.content'),
                title: t('error.conflict.title'),
              },
            },
          })
        }
      },
      [
        patchAssetAsync,
        showSnackbar,
        navigate,
        currentAssetID,
        t,
        standardErrorHandler,
      ],
    )
    const {
      handleUnlink,
      handleLink,
      displayParentLinks,
      displayChildLinks,
      linkRequest,
      cantLinkAssetIds,
    } = useAssetLink(
      FormAction.edit,
      assetData.parentAssetLinks,
      assetData.childAssetLinks,
    )

    const formik = useFormik({
      initialValues: formData,
      validationSchema: EditAssetFormSchema,
      enableReinitialize: true,
      validateOnMount: true,
      onSubmit: (values) => {
        if (patching) return
        handlePatching({
          ...mapFormikToPatchAssetPayload(assetData, {
            ...values,
            formFiller: currentUser?.id ?? '',
          }),
          parentAssetLinks: linkRequest,
        })
      },
    })
    const handleSaveButtonOnClick = useCallback(() => {
      const errorMessage = formikErrorExtractor(formik.errors, 5)
      if (errorMessage) {
        showSnackbar({ content: errorMessage, severity: 'error' })
        return
      }
      formik.handleSubmit()
    }, [showSnackbar, formik, formikErrorExtractor])
    return (
      <FormikProvider value={formik}>
        <Root>
          <PageTitle>{t('assetEditPage.title')}</PageTitle>
          <Grid container>
            <Grid item mr="auto">
              <BackButton url={AppRoutes.assetView.render(currentAssetID)} />
            </Grid>
            <Grid item ml="auto">
              <SaveButton
                icon={SaveIcon}
                text={t('assetEditPage.buttons.save')}
                onClick={handleSaveButtonOnClick}
                disabled={!formik.dirty}
              />
            </Grid>
          </Grid>
          <AssetDetailsSection
            action={FormAction.edit}
            transferRecord={transferRecord}
            handleUnlink={handleUnlink}
            handleLink={handleLink}
            displayParentLinks={displayParentLinks}
            displayChildLinks={displayChildLinks}
            cantLinkAssetIds={cantLinkAssetIds}
          />
        </Root>
      </FormikProvider>
    )
  },
)
AssetEditPageContent.displayName = 'AssetEditPageContent'
