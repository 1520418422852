import { Button, Grid } from '@mui/material'
import { TFuncKey, useTranslation } from '@pasteltech/i18n-react'
import { useOverlay } from '@pasteltech/overlay-provider'
import { useAssetList } from '@stewards-fas/queries'
import {
  AssetResponsePayload,
  AssetStatus,
  AssetStatusGroup,
} from '@stewards-fas/schemas'
import { memo, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { ApprovedIcon, CloseIcon, SearchIcon } from '../../../icons'
import { Dialog, DialogAction } from '../../dialog'
import {
  BaseFormLabel,
  BaseTextField,
  FormAutoCompleteField,
} from '../../form-fields'
import { useCurrentUserUnits } from '../../../hooks'
import { compareString } from '../../../utilities/compare-string'
const StyledButton = styled(Button)`
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 4px;
  padding: 0px;
`
const Text = styled.span<{ $primary?: boolean }>`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  ${({ theme, $primary }) => ({
    color: $primary ? theme.colors.primary : undefined,
  })}
`
const Container = styled.div`
  min-height: 200px;
`

type Props = {
  onClose(): void
  onSubmit: (asset: AssetResponsePayload, linkedQuantity: number) => void
  cantLinkAssetIds: string[]
}
type AssetOption = {
  asset: AssetResponsePayload
  name: string
}

export const LinkAssetModal = memo<Props>(
  ({ onClose, onSubmit, cantLinkAssetIds }) => {
    const [selectedAsset, setSelectedAsset] = useState<AssetResponsePayload>()
    const [linkQuantity, setLinkQuantity] = useState<number>()
    const { data: assetList, isLoading } = useAssetList()

    const maximumLinkQuantity = useMemo(
      () =>
        selectedAsset
          ? selectedAsset.quantity -
            selectedAsset?.childAssetLinks.reduce(
              (acc, childAssetLink) => acc + childAssetLink.quantity,
              0,
            )
          : 0,
      [selectedAsset],
    )

    const { checkPermittedUnitId } = useCurrentUserUnits()
    const assetOptions = useMemo(() => {
      if (!assetList?.data) return []

      const validAssets = assetList.data.filter((asset) => {
        return (
          !cantLinkAssetIds.includes(asset.id) &&
          checkPermittedUnitId(asset.unitId) &&
          AssetStatusGroup.nonReimburse.includes(asset.status as AssetStatus)
        )
      })
      const options = validAssets
        .map((asset) => ({
          asset: asset,
          name: `${asset.name}-${asset.assetNo}-${asset.brand}-${asset.model}`,
        }))
        .sort((a, b) => compareString(a.name, b.name))
      return options
    }, [assetList?.data, checkPermittedUnitId, cantLinkAssetIds])

    const { t } = useTranslation()
    const { showSnackbar } = useOverlay()

    const validate = useCallback(
      (quantity: number) => {
        if (quantity <= 0)
          throw Error(
            'assetDetailsPage.linkAssetModal.errorMessage.linkQuantity.positive',
          )
        if (quantity > maximumLinkQuantity)
          throw Error(
            'assetDetailsPage.linkAssetModal.errorMessage.linkQuantity.max',
          )
      },
      [maximumLinkQuantity],
    )
    const handleSelected = useCallback(
      (val: boolean | undefined) => {
        try {
          if (val !== true) {
            onClose()
            return
          }
          validate(linkQuantity as number)
          onSubmit(
            selectedAsset as AssetResponsePayload,
            linkQuantity as number,
          )
          onClose()
        } catch (err) {
          showSnackbar({
            content: t(
              [
                (err as Error).message as TFuncKey,
                'assetDetailsPage.linkAssetModal.errorMessage.invalidInput',
              ],
              { max: maximumLinkQuantity },
            ) as string,
            severity: 'error',
          })
        }
      },
      [
        onSubmit,
        onClose,
        showSnackbar,
        selectedAsset,
        linkQuantity,
        maximumLinkQuantity,
        validate,
        t,
      ],
    )
    const handleAutoCompleteOnChange = useCallback(
      (option: AssetOption | undefined) => {
        if (option) {
          setSelectedAsset(option.asset)
          return
        }
        setSelectedAsset(undefined)
      },
      [setSelectedAsset],
    )
    const actionsOpts: DialogAction<boolean>[] = useMemo(() => {
      const opts: DialogAction<boolean>[] = [
        {
          text: t('common.cancel'),
          type: 'outlined',
          value: false,
          icon: <CloseIcon />,
        },
      ]

      if (!(selectedAsset == null || linkQuantity === undefined)) {
        opts.push({
          text: t('common.confirm'),
          color: 'secondary',
          value: true,
          type: 'contained',
          icon: <ApprovedIcon />,
        })
      }
      return opts
    }, [selectedAsset, linkQuantity, t])
    return (
      <Dialog
        dismissable
        visible
        title={t('assetDetailsPage.linkAssetModal.title')}
        actions={actionsOpts}
        onSelected={handleSelected}
      >
        <Container>
          <BaseFormLabel>
            {t('assetDetailsPage.linkAssetModal.label')}
          </BaseFormLabel>
          <Grid container gap={1} mt={3}>
            <Grid item flexGrow={1}>
              <FormAutoCompleteField
                placeholder={t('assetDetailsPage.linkAssetModal.placeholder')}
                dir="vertical"
                loading={isLoading}
                options={assetOptions}
                onChange={(...params) => handleAutoCompleteOnChange(params[1])}
                marginTop={0}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Grid>
            <Grid item flexShrink={0}>
              <StyledButton variant="contained">
                <SearchIcon />
              </StyledButton>
            </Grid>
          </Grid>
          <Grid container gap={1} mt={3}>
            <Grid container gap="20px" alignItems="center" mt={2}>
              <Grid item pr={3}>
                <BaseFormLabel>
                  {t('assetDetailsPage.linkAssetModal.link')}
                </BaseFormLabel>
              </Grid>
              <Grid item>
                <Grid container gap={1} alignItems="center">
                  <Grid item width={88}>
                    <>
                      <BaseTextField
                        type="number"
                        size="small"
                        value={linkQuantity}
                        onChange={(e) => {
                          setLinkQuantity(+e.target.value)
                        }}
                      />
                    </>
                  </Grid>
                  <Grid item>
                    <BaseFormLabel>
                      {t('assetDetailsPage.linkAssetModal.numberOfPieces')}
                    </BaseFormLabel>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Text $primary>/</Text>
              </Grid>
              <Grid item>
                <Text $primary>{maximumLinkQuantity}</Text>
              </Grid>
              <Grid item pl={4}>
                <BaseFormLabel>
                  {t('assetDetailsPage.linkAssetModal.totalNumber')}
                </BaseFormLabel>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    )
  },
)

LinkAssetModal.displayName = 'LinkAssetModal'
