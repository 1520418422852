import { Box, Grid } from '@mui/material'
import { TFuncKey, useTranslation } from '@pasteltech/i18n-react'
import {
  TransitAssetRequestPayload,
  UnitResponsePayload,
} from '@stewards-fas/schemas'
import { useFormik } from 'formik'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Option } from '../../hooks'
import { ApprovedIcon, CloseIcon } from '../../icons'
import {
  AssetTransferFormSchema,
  getAssetTransferFormInitValue,
  mapFormikDataToTransitAssetRequestPayload,
} from '../../models'
import { Dialog } from '../dialog'
import {
  BaseFormLabel,
  BaseTextField,
  FormDateField,
  FormRadioGroupField,
  FormSelectField,
  FormTextField,
  RadioGroupOption,
} from '../form-fields'
import { compareString } from '../../utilities'

const Text = styled.span<{ $primary?: boolean }>`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  ${({ theme, $primary }) => ({
    color: $primary ? theme.colors.primary : undefined,
  })}
`

type Props = {
  visible: boolean
  onClose(): void
  multiple?: boolean
  onSubmit: (payload: Omit<TransitAssetRequestPayload, 'rowVersion'>) => void
  asset: {
    assetNo: string
    name: string
    quantity: number
    assetLinks: any[]
    atUnitNotes: string
  }
  units?: UnitResponsePayload[]
}
enum AssetTransferModalStep {
  ONE = 'ONE',
  TWO = 'TWO',
}
export const AssetTransferModal = memo<Props>(
  ({ visible, onClose, multiple, onSubmit, asset, units = [] }) => {
    const { t } = useTranslation()
    const formik = useFormik({
      initialValues: getAssetTransferFormInitValue(asset.quantity),
      validationSchema: AssetTransferFormSchema,
      onSubmit: (values) => {
        onSubmit(mapFormikDataToTransitAssetRequestPayload(values))
        formik.resetForm()
      },
    })

    const [step, setStep] = useState<AssetTransferModalStep>(
      AssetTransferModalStep.ONE,
    )

    useEffect(() => {
      //reset to step one when close
      if (!visible) {
        setStep(AssetTransferModalStep.ONE)
      }
      //if no assetlink and quantity 1, skip step one
      if (asset.assetLinks.length === 0 && asset.quantity === 1) {
        setStep(AssetTransferModalStep.TWO)
      }
    }, [visible, asset])

    const handleChangeSelect = useCallback(
      (val: boolean) => {
        if (!val) {
          formik.resetForm()
          onClose()
          return
        }
        if (step === AssetTransferModalStep.ONE) {
          setStep(AssetTransferModalStep.TWO)
        } else {
          // Submit
          formik.handleSubmit()
        }
      },
      [onClose, step, formik],
    )

    const unitOptions: Option[] = useMemo(() => {
      return units
        .filter((u) => u.status.toLowerCase() !== 'inactive')
        .map((unit) => ({
          value: unit.id,
          label: unit.code as string,
        }))
        .sort((a, b) => compareString(a.label, b.label))
    }, [units])

    const options: RadioGroupOption[] = useMemo(
      () => [
        {
          label: (
            <Grid container gap="20px">
              <Grid item>
                <Text>
                  {t(
                    'assetDetailsPage.assetTransferModal.words.disconnectAndTransferOnlyThisAsset',
                  )}
                </Text>
              </Grid>
              <Grid item>
                {/* Asset code */}
                <Text $primary>{asset.assetNo}</Text>
              </Grid>
              <Grid item>
                {/* Asset name */}
                <Text $primary>{asset.name}</Text>
              </Grid>
            </Grid>
          ),
          value: '0',
        },
      ],
      [t, asset],
    )
    const Contents = useMemo(
      () => ({
        ONE: (
          <>
            {
              <Box mb={2}>
                <Grid container gap="20px">
                  <Grid item>
                    <Text>
                      {t(
                        'assetDetailsPage.assetTransferModal.words.theTotalAssetsAre',
                      )}
                    </Text>
                  </Grid>
                  <Grid item>
                    <Text $primary>{asset.quantity}</Text>
                  </Grid>
                  <Grid item>
                    <Text>
                      {t('assetDetailsPage.assetTransferModal.words.pieces')}
                    </Text>
                  </Grid>
                  {multiple && (
                    <Grid item>
                      <Text>
                        ，
                        {t(
                          'assetDetailsPage.assetTransferModal.words.pleaseSelectTheNumberOfPiecesToTransfer',
                        )}
                      </Text>
                    </Grid>
                  )}
                </Grid>
                <Grid container gap="20px" alignItems="center" mt={2}>
                  <Grid item pr={3}>
                    <BaseFormLabel>
                      {t('assetDetailsPage.assetTransferModal.words.distract')}
                    </BaseFormLabel>
                  </Grid>
                  <Grid item>
                    <Grid container gap={1} alignItems="center">
                      <Grid item width={88}>
                        <BaseTextField
                          disabled={!multiple}
                          type="number"
                          size="small"
                          onChange={(event) =>
                            formik.setFieldValue(
                              'transitQuantity',
                              event.target.value,
                            )
                          }
                          value={formik.values.transitQuantity}
                        />
                      </Grid>
                      <Grid item>
                        <BaseFormLabel>
                          {t(
                            'assetDetailsPage.assetTransferModal.words.numberOfPieces',
                          )}
                        </BaseFormLabel>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Text $primary>/</Text>
                  </Grid>
                  <Grid item>
                    <Text $primary>{asset.quantity}</Text>
                  </Grid>
                  <Grid item pl={4}>
                    <BaseFormLabel>
                      {t(
                        'assetDetailsPage.assetTransferModal.words.totalNumber',
                      )}
                    </BaseFormLabel>
                  </Grid>
                </Grid>
              </Box>
            }

            {asset.assetLinks.length > 0 && (
              <>
                <Grid container gap="20px">
                  <Grid item>
                    <Text>
                      {t(
                        'assetDetailsPage.assetTransferModal.words.thisAssetHas',
                      )}
                    </Text>
                  </Grid>
                  <Grid item>
                    <Text $primary>{asset.assetLinks.length}</Text>
                  </Grid>
                  <Grid item>
                    <Text>
                      {t('assetDetailsPage.assetTransferModal.words.item')}
                    </Text>
                  </Grid>
                  <Grid item>
                    <Text>
                      {t(
                        'assetDetailsPage.assetTransferModal.words.toContractAnAssetPleaseSelect',
                      )}
                    </Text>
                  </Grid>
                </Grid>

                <Box mt={2}>
                  <FormRadioGroupField
                    options={options}
                    onChange={(event) =>
                      formik.setFieldValue(
                        'isTransferLinkedAsset',
                        event.target.value === '1',
                      )
                    }
                  />
                </Box>
              </>
            )}
          </>
        ),
        TWO: (
          <>
            <Grid container gap={1}>
              <Grid item pt="18px" width={135}>
                <Text>
                  {t(
                    'assetDetailsPage.assetTransferModal.words.assetWillTransferTo',
                  )}
                </Text>
              </Grid>
              <Grid item width={142}>
                <FormSelectField
                  error={
                    Boolean(formik.errors.toUnitId) && formik.touched.toUnitId
                  }
                  helperText={t(formik.errors.toUnitId as TFuncKey) as string}
                  options={unitOptions}
                  dir="vertical"
                  placeholder={t(
                    'assetDetailsPage.assetTransferModal.words.receiving',
                  )}
                  value={formik.values.toUnitId}
                  onChange={(val) => {
                    formik.setFieldTouched('toUnitId')
                    formik.setFieldValue('toUnitId', val)
                  }}
                />
              </Grid>
            </Grid>
            <Grid container gap={1}>
              <Grid item pt="18px" width={135}>
                <BaseFormLabel>
                  {t(
                    'assetDetailsPage.assetTransferModal.words.reasonToTransfer',
                  )}
                </BaseFormLabel>
              </Grid>
              <Grid item flexGrow={1}>
                <FormTextField
                  dir="vertical"
                  onChange={(value) =>
                    formik.setFieldValue('reason', value as string)
                  }
                />
              </Grid>
            </Grid>
            <Grid container gap={1}>
              <Grid item pt="18px" width={135}>
                <BaseFormLabel>
                  {t(
                    'assetDetailsPage.assetTransferModal.words.submissionDate',
                  )}
                </BaseFormLabel>
              </Grid>
              <Grid item flexGrow={1}>
                <FormDateField
                  helperText={
                    t(formik.errors.submittedDateTime as TFuncKey) as string
                  }
                  error={
                    Boolean(formik.errors.submittedDateTime) &&
                    Boolean(formik.touched.submittedDateTime)
                  }
                  dir="vertical"
                  value={formik.values.submittedDateTime}
                  onChange={(day) => {
                    formik.setFieldTouched('toUnitId')
                    formik.setFieldValue('submittedDateTime', day)
                  }}
                />
              </Grid>
            </Grid>
          </>
        ),
      }),
      [
        asset.assetLinks.length,
        asset.quantity,
        formik,
        multiple,
        options,
        t,
        unitOptions,
      ],
    )
    return (
      <Dialog
        dismissable
        title={t('assetDetailsPage.assetTransferModal.title')}
        visible={visible}
        onSelected={handleChangeSelect}
        actions={[
          {
            text: t('common.cancel'),
            type: 'outlined',
            value: false,
            icon: <CloseIcon />,
          },
          ...(step === AssetTransferModalStep.ONE &&
          (formik.values.isTransferLinkedAsset !== undefined ||
            asset.assetLinks.length === 0)
            ? [
                {
                  text: t('common.nextStep'),
                  color: 'secondary',
                  value: true,
                  type: 'contained',
                  icon: <ApprovedIcon />,
                } as any,
              ]
            : []),
          ...(step === AssetTransferModalStep.TWO
            ? [
                {
                  text: t('common.confirm'),
                  color: 'secondary',
                  value: true,
                  type: 'contained',
                  icon: <ApprovedIcon />,
                } as any,
              ]
            : []),
        ]}
      >
        {Contents[step]}
      </Dialog>
    )
  },
)

AssetTransferModal.displayName = 'AssetTransferModal'
