export const searchBar = {
  labels: {
    assetCode: '資產編號',
    reimburseCode: '報銷編號',
    unit: '單位',
    unitFrom: '由單位',
    assetType: '資產類別',
    buyDate: '購買日期',
    transferDate: '轉移日期',
    transferToUnit: '轉移到單位',
    search: '搜尋',
    name: '名稱',
    brand: '牌子',
    model: '型號',
    serialNumber: '序號',
    provider: '供應商',
    amount: '金額/估值',
    state: '狀態',
    NOT_REIMBURSED: '未報銷',
    REIMBURSED: '已報銷',
    clearAll: '全部清除',
    inputName: '輸入名稱',
    inputBrand: '輸入牌子',
    inputModel: '輸入型號',
    inputSerialNumber: '輸入序號',
    inputProvider: '輸入供應商',
    inputAmount: '輸入金額/估值',
  },
  assetType: {
    tablet: '平板電腦',
    phone: '電話',
    computer: '電腦',
    tv: '電視機',
  },
  errors: {
    amountMustBeInteger: '金額必須是整數',
  },
}
