import { initI18n } from '@pasteltech/i18n-react'
import { configureApi } from '@stewards-fas/apis'
import { languagePack } from '@stewards-fas/language-pack'
import { initReactQuery, QueryClient } from '@stewards-fas/queries'
import { env } from '../utilities'
import { RootStore, RootStoreModel } from '../stores'

const DEFAULT_LANGUAGE = 'zh-Hant'
export type InitResult = { rootStore: RootStore; queryClient: QueryClient }

export async function init(): Promise<InitResult> {
  configureApi({
    baseUrl: env.apiBaseUrl,
    timeout: +env.apiTimeout,
  })

  const queryClient = initReactQuery()
  const rootStore = RootStoreModel.create({})

  await initI18n(
    {
      [DEFAULT_LANGUAGE]: languagePack[DEFAULT_LANGUAGE],
      ['en']: languagePack.en,
    },
    DEFAULT_LANGUAGE,
  )

  return { rootStore, queryClient }
}

type Resources = (typeof languagePack)['zh-Hant']

declare module '@pasteltech/i18n-react' {
  interface AppResources extends Resources {}
}
