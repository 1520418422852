import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { Typography, Alert, CircularProgress } from '@mui/material'
import { env } from '../../utilities'
import { useServerHealthCheck } from '@stewards-fas/queries'

const Root = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: column;
  padding: 20px 100px;
  gap: 10px;
  min-height: 100vh;
`
const HealthCheckTittle = styled.div`
  display: flex;
`
const HealthCheckTittleText = styled(Typography)`
  display: flex;
  font-size: 40px;
`
const VersionDisplay = styled.div`
  display: flex;
  gap: 30px;
`
const VersionDisplayBox = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  border: solid;
  border-color: ${({ theme }) => theme.colors.greys[4]};
  padding: 20px;
  width: 50%;
  border-width: 1px;
`
const VersionDisplayTittle = styled(Typography)`
  display: flex;
  font-size: 20px;
`
const VersionDisplayContent = styled(Typography)`
  display: flex;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.greys[2]};
`
const Table = styled.div`
  display: flex;
  flex-direction: column;
`
const TableTittle = styled(Typography)`
  display: flex;
  font-size: 30px;
`
const TableBox = styled.div`
  display: flex;
  flex-direction: column;
`
const TableRow = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  border: solid;
  border-color: ${({ theme }) => theme.colors.greys[4]};
  padding: 20px;
  border-width: 1px;
`
const TableContent = styled(Typography)`
  display: inline;
  width: 50%;
  font-size: 14px;
  word-break: break-all;
`

export const HealthCheckPage = memo(() => {
  const {
    data: serverHealthCheckData,
    error: serverHealthCheckError,
    isFetching: isFetchingServerHealthCheck,
  } = useServerHealthCheck()

  const healthCheckResult = useMemo(
    () =>
      serverHealthCheckError != null ? (
        <Alert variant="filled" severity="error">
          {(serverHealthCheckError as Error).toString()}
        </Alert>
      ) : (
        <Alert severity="success">
          Keep a live, API version {serverHealthCheckData?.version}
        </Alert>
      ),
    [serverHealthCheckData?.version, serverHealthCheckError],
  )

  return (
    <Root>
      <HealthCheckTittle>
        <HealthCheckTittleText>Health Check</HealthCheckTittleText>
      </HealthCheckTittle>
      <VersionDisplay>
        <VersionDisplayBox>
          <VersionDisplayTittle>Web</VersionDisplayTittle>
          <VersionDisplayContent>
            {env.version}/{env.environment}
          </VersionDisplayContent>
        </VersionDisplayBox>
        <VersionDisplayBox>
          <VersionDisplayTittle>Server</VersionDisplayTittle>
          <VersionDisplayContent>
            {isFetchingServerHealthCheck ? (
              <CircularProgress />
            ) : (
              healthCheckResult
            )}
          </VersionDisplayContent>
        </VersionDisplayBox>
      </VersionDisplay>
      <Table>
        <TableTittle>Web Environment</TableTittle>
        <TableBox>
          {Object.entries(env)
            .filter((entry) => !entry[0].includes('Key'))
            .map((entry) => (
              <TableRow key={entry[0]}>
                <TableContent>{entry[0]}</TableContent>
                <TableContent>{entry[1]}</TableContent>
              </TableRow>
            ))}
        </TableBox>
      </Table>
    </Root>
  )
})

HealthCheckPage.displayName = 'HealthCheckPage'
