import { PublicClientApplication } from '@azure/msal-browser'
import { useEffect, useRef, useState } from 'react'
import { handleLogin, loginRequest } from './login'
import { msalConfig } from './msal-config'

export function useAuthInit() {
  const [msalInstance, setMsalInstance] = useState<
    PublicClientApplication | undefined
  >(undefined)

  const didInitRef = useRef(false)

  useEffect(() => {
    ;(async () => {
      if (didInitRef.current) return
      didInitRef.current = true

      const instance = new PublicClientApplication(msalConfig)

      // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/FAQ.md#i-dont-understand-the-redirect-flow-how-does-the-handleredirectpromise-function-work
      // this should be called before any other MSAL method being called
      try {
        const loginResult = await instance.handleRedirectPromise()
        if (loginResult != null) {
          handleLogin(instance, loginResult, loginRequest)
        }
      } catch (error) {
        console.error(error)
        // FIXME add error handling
        // await standardErrorHandler(error, {
        //   defaultTitle: t('app:auth.loginFailed.title'),
        // })
      }

      setMsalInstance(instance)
    })()
  }, [])

  return msalInstance
}
