import { Box, Button, Grid } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { useOverlay } from '@pasteltech/overlay-provider'
import {
  AssetRequestStatus,
  AssetRequestsResponsePayload,
} from '@stewards-fas/schemas'
import dayjs from 'dayjs'
import { uniqBy } from 'lodash'
import { memo, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useCurrentUserUnits } from '../../../hooks'
import { ApproveIcon } from '../../../icons'
import { isAllEqual } from '../../../utilities'
import { Dialog } from '../../dialog'

const Text = styled.span<{ $primary?: boolean; $isLabel?: boolean }>`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  ${({ theme, $primary, $isLabel }) => ({
    // eslint-disable-next-line no-nested-ternary
    color: $primary
      ? theme.colors.primary
      : $isLabel
      ? theme.colors.greys[3]
      : undefined,
  })}
`

const Container = styled(Box)`
  max-height: 240px;
  overflow: auto;
`

const ConfirmTitle = styled(Grid)`
  gap: 20px;
  margin-top: 10px;
`

const AssetInfoTable = styled(Grid)`
  gap: 2px;
  padding: 10px 0;
`

const AssetInfoCode = styled(Grid)`
  width: 140px;
`

const AssetInfoName = styled(Grid)`
  width: 140px;
  text-align: center;
`

const AssetInfoQunantiy = styled(Grid)`
  width: 55px;
`

const AssetInfoPurchaseDay = styled(Grid)`
  width: 120px;
`

const AssetInfoReason = styled(Grid)`
  width: 120px;
`
const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
  }
  width: 90px;
  column-gap: 10px;
  font-weight: bold;
`
type Props = {
  withControlledButton?: boolean
  handleApprove?: () => Promise<void>
  isDisableApprove?: boolean
  onSubmit?: () => void
  selectedRowsData: AssetRequestsResponsePayload[]
}

export const AdminConfirmTransferAssetButtonModal = memo<Props>(
  ({
    withControlledButton,
    handleApprove,
    isDisableApprove,
    selectedRowsData,
  }) => {
    // global
    const { t } = useTranslation()
    const { showSnackbar } = useOverlay()
    const { checkPermittedUnitId } = useCurrentUserUnits()

    // local states
    const [isShowModal, setIsShowModal] = useState(false)

    const handleApproveOnClick = useCallback(() => {
      // show error for no data
      const isNoData = selectedRowsData.length === 0
      if (isNoData) {
        showSnackbar({
          content: t('common.selectItemFirst'),
          severity: 'error',
        })
        return
      }
      // show error for already approved data
      const isSomeApproved = selectedRowsData.some((row) => {
        const isApproved = isAllEqual(
          row.transitStatus,
          row.receiveStatus,
          AssetRequestStatus.approved,
        )
        return isApproved
      })
      if (isSomeApproved) {
        showSnackbar({
          content: t('assetActions.transitApprove.error'),
          severity: 'error',
        })
        return
      }
      const isSomeNotPermitted = selectedRowsData.some((row) => {
        if (row.transitStatus != AssetRequestStatus.approved) {
          return !checkPermittedUnitId(row.fromUnitId)
        }
        if (row.receiveStatus != AssetRequestStatus.approved) {
          return !checkPermittedUnitId(row.toUnitId)
        }
        return false
      })
      if (isSomeNotPermitted) {
        showSnackbar({
          content: t('assetActions.transitApprove.notPermittedError'),
          severity: 'error',
        })
        return
      }

      setIsShowModal(true)
    }, [checkPermittedUnitId, selectedRowsData, showSnackbar, t])

    const handleSelected = useCallback(
      (confirmed: boolean) => {
        if (confirmed) {
          handleApprove?.()
        }

        if (isShowModal) {
          setIsShowModal(false)
        }
      },
      [isShowModal, handleApprove],
    )

    const todoActions = useMemo(() => {
      const actions = selectedRowsData.map<{
        name: string
        status: 'transfer' | 'receive'
      }>((item) => {
        const status = item.transitStatus === 'PENDING' ? 'transfer' : 'receive'
        const name =
          item.transitStatus === 'PENDING'
            ? item.fromUnit?.code
            : item.toUnit?.code
        return { name: name ?? '', status }
      })
      const uniqueActions = uniqBy(
        actions,
        ({ name, status }) => name + '|' + status,
      )
      return uniqueActions
    }, [selectedRowsData])

    return (
      <>
        {withControlledButton && (
          <StyledButton
            startIcon={<ApproveIcon />}
            onClick={handleApproveOnClick}
            disabled={isDisableApprove}
          >
            {t('common.checkAndApprove')}
          </StyledButton>
        )}
        <Dialog
          dismissable
          visible={isShowModal}
          title={t('assetDetailsPage.confirmTransferModal.title')}
          onSelected={handleSelected}
        >
          <ConfirmTitle container>
            <Grid item>
              <Text>
                {t(
                  'assetDetailsPage.confirmTransferModal.words.confirmRepresentative',
                )}
              </Text>
            </Grid>
            {todoActions?.map((it) => {
              return (
                <div key={it.name}>
                  <Grid item>
                    <Text $primary>
                      {it.name}
                      {it.status === 'transfer'
                        ? t(
                            'assetDetailsPage.confirmTransferModal.words.transfer',
                          )
                        : t(
                            'assetDetailsPage.confirmTransferModal.words.receive',
                          )}
                    </Text>
                  </Grid>
                </div>
              )
            })}
            <Grid item>
              <Text>
                {t(
                  'assetDetailsPage.confirmTransferModal.words.theAssetsAreFollow',
                )}
              </Text>
            </Grid>
          </ConfirmTitle>
          <AssetInfoTable container>
            <AssetInfoCode item>
              <Text $isLabel>
                {t('assetDetailsPage.confirmTransferModal.words.assetNumber')}
              </Text>
            </AssetInfoCode>
            <AssetInfoName item>
              <Text $isLabel>
                {t('assetDetailsPage.confirmTransferModal.words.assetName')}
              </Text>
            </AssetInfoName>
            <AssetInfoQunantiy item>
              <Text $isLabel>
                {t('assetDetailsPage.confirmTransferModal.words.quantity')}
              </Text>
            </AssetInfoQunantiy>
            <AssetInfoPurchaseDay item>
              <Text $isLabel>
                {t('assetDetailsPage.confirmTransferModal.words.purchaseDate')}
              </Text>
            </AssetInfoPurchaseDay>
            <AssetInfoReason item>
              <Text $isLabel>
                {t(
                  'assetDetailsPage.confirmTransferModal.words.reasonForTransfer',
                )}
              </Text>
            </AssetInfoReason>
          </AssetInfoTable>
          <Container>
            {selectedRowsData.map((it) => {
              const asset = it.assetRequestAssets[0].asset
              return (
                <AssetInfoTable container gap={2} py="10px" key={it.id}>
                  <AssetInfoCode item>
                    <Text $primary>{asset.assetNo}</Text>
                  </AssetInfoCode>
                  <AssetInfoName item>
                    <Text $primary>{asset.name}</Text>
                  </AssetInfoName>
                  <AssetInfoQunantiy item>
                    <Text $primary>{it.transitQuantity}</Text>
                  </AssetInfoQunantiy>
                  <AssetInfoPurchaseDay item>
                    <Text $primary>
                      {dayjs(asset.purchasedDateTime).format(
                        t('common.format.date'),
                      )}
                    </Text>
                  </AssetInfoPurchaseDay>
                  <AssetInfoReason item>
                    <Text $primary>{it.reason}</Text>
                  </AssetInfoReason>
                </AssetInfoTable>
              )
            })}
          </Container>
        </Dialog>
      </>
    )
  },
)

AdminConfirmTransferAssetButtonModal.displayName =
  'AdminConfirmTransferAssetModal'
