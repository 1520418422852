import { useTranslation } from '@pasteltech/i18n-react'
import { memo, useCallback } from 'react'
import { Dialog } from '../../dialog'

type Prop = {
  onClose(): void
  onSubmit: () => void
}
export const UnlinkAssetModel = memo<Prop>(({ onClose, onSubmit }) => {
  const handleSelected = useCallback(
    (val: boolean) => {
      if (val) {
        onSubmit()
      } else {
        onClose()
      }
    },
    [onSubmit, onClose],
  )
  const { t } = useTranslation()
  return (
    <Dialog
      dismissable
      visible
      title={t('assetDetailsPage.unLinkAssetModal.title')}
      onSelected={handleSelected}
    >
      {t('assetDetailsPage.unLinkAssetModal.message')}
    </Dialog>
  )
})

UnlinkAssetModel.displayName = 'UnlinkAssetModel'
