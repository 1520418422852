import { memo, useMemo } from 'react'
import { CssBaseline, Drawer, SwipeableDrawer, Box } from '@mui/material'
import styled from 'styled-components'
import { useView, ScreenSize } from '../../utilities'
import { MenuItem } from '../../routers'
import { SidebarDrawer } from './sidebar-drawer'

export const drawerWidth = 292

const Root = styled.div`
  display: flex;
`

const Container = styled.div`
  width: ${drawerWidth}px;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  height: 100%;
`

const FixWidthDrawer = styled(Drawer)`
  width: ${drawerWidth}px;
  &.MuiDrawer-root.MuiDrawer-docked
    .MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
    border-right: 0px;
    box-shadow: 0px 0px 2px ${({ theme }) => theme.colors.blackTransparents[1]},
      0px 0px 8px ${({ theme }) => theme.colors.blackTransparents[2]};
  }
`

const StyledBox = styled(Box)`
  width: 300px;
  height: 100vh;
`

type Props = {
  open: boolean
  toggleSidebar: () => void
  urlRoot: string
  sidebarItems: MenuItem[]
}

export const SideBar = memo<Props>(
  ({ open, toggleSidebar, urlRoot, sidebarItems }) => {
    const view = useView()

    const desktopSidebarItems = useMemo(
      () => sidebarItems.filter((it) => !it.isMobile),
      [sidebarItems],
    )
    const mobileSidebarItems = useMemo(
      () => sidebarItems.filter((it) => it.isMobile),
      [sidebarItems],
    )

    return (
      <Root>
        <CssBaseline />
        {view === ScreenSize.desktop && (
          <FixWidthDrawer variant="permanent">
            <Container>
              <SidebarDrawer
                urlRoot={urlRoot}
                sidebarItems={desktopSidebarItems}
              />
            </Container>
          </FixWidthDrawer>
        )}
        {view === ScreenSize.mobile && (
          <SwipeableDrawer
            anchor="left"
            open={open}
            onClose={toggleSidebar}
            onOpen={toggleSidebar}
          >
            <StyledBox onClick={toggleSidebar}>
              <SidebarDrawer
                urlRoot={urlRoot}
                sidebarItems={mobileSidebarItems}
              />
            </StyledBox>
          </SwipeableDrawer>
        )}
      </Root>
    )
  },
)

SideBar.displayName = 'SideBar'
