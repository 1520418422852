//! Assumed backend must use the following user role as role name in database
export enum Role {
  doer = 'Doer',
  supervisor = 'Supervisor',
  systemAdministrator = 'SystemAdministrator',
  anonymous = 'Anonymous',
}

export const checkIsSupervisorOrAdmin = (role: Role) =>
  role === Role.supervisor || role === Role.systemAdministrator

export const allRoles = Object.values(Role)

export const allPermittedRoles = allRoles.filter((it) => it !== Role.anonymous)
