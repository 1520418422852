import { useCallback, useState, useMemo, useEffect, memo } from 'react'
import styled from 'styled-components'
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Collapse,
} from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { icons } from '../../theme'
import { MenuItem } from '../../routers'
import { Icon } from '../icon'

const Root = styled(List)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  padding-top: 160px;
`

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.white};
  width: 28px;
  height: 28px;
`

const StyledText = styled(ListItemText)<{ $selected: boolean }>`
  color: ${({ theme, $selected }) =>
    $selected ? theme.colors.white : theme.colors.greys[1]};
  font-size: ${({ theme }) => theme.sizes.fontSize.heading5}px;
  font-weight: 600;
  max-width: 150px;
  .MuiTypography-body1 {
    font-size: ${({ theme }) => theme.sizes.fontSize.medium}px;
  }
`

const StyledItemLink = styled(Link)`
  min-height: 38px;
  padding-left: 30px;
  padding-right: 30px;
`

const StyledSubitemLink = styled(Link)`
  height: ${({ theme }) => theme.sizes.spacings[6]}px;
`

const ItemIcon = styled.div`
  display: flex;
`

const ItemContainer = styled.div`
  .ListItem {
    color: ${({ theme }) => theme.colors.greys[1]};
    gap: 10px;
  }
  .ListItem.Mui-selected {
    background-color: ${({ theme }) => theme.colors.secondaryContainer};
  }
`

type Props = {
  urlRoot: string
  sidebarItems: MenuItem[]
}

export const SidebarList = memo<Props>(({ urlRoot, sidebarItems }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const currentMenuItem = useMemo(() => {
    const result = /^\/(v2\/)?[^/]*/.exec(location.pathname)
    const path = result
      ? result[0].split('/').slice(-1)[0]
      : location.pathname.split('/')[1]
    const root = urlRoot.concat(path)

    return sidebarItems.find(
      (obj) => obj.path === root || obj.subPath?.includes(root),
    )?.key
  }, [location, sidebarItems, urlRoot])

  const [clickedMenuItem, setClickedMenuItem] = useState<string | undefined>(
    currentMenuItem,
  )

  useEffect(() => {
    setClickedMenuItem(currentMenuItem)
  }, [currentMenuItem])

  const isSubitemClicked = useCallback(
    (key: string): boolean => {
      const subItems =
        sidebarItems.find((obj) => obj.key === key) === undefined
          ? []
          : (
              sidebarItems.find((obj) => obj.key === key) as {
                content?: Omit<MenuItem, 'content'>[]
              }
            ).content ?? []
      return subItems.length !== 0
        ? subItems.some((i) => i.key === clickedMenuItem)
        : false
    },
    [clickedMenuItem, sidebarItems],
  )

  const isExpendedMenuItem = useCallback(
    (key: string) => {
      const hasSubItem = sidebarItems.some(
        (item) =>
          item.key === key &&
          (item as { content?: Omit<MenuItem, 'content'>[] }).content !==
            undefined,
      )
      return (key === clickedMenuItem && hasSubItem) || isSubitemClicked(key)
    },
    [clickedMenuItem, isSubitemClicked, sidebarItems],
  )

  const handleMenuItemOnClick = useCallback(
    (key: string): void => {
      setClickedMenuItem(isExpendedMenuItem(key) ? undefined : key)
    },
    [isExpendedMenuItem],
  )

  return (
    <Root>
      {sidebarItems.map((item) => {
        const subItem =
          (item as { content?: Omit<MenuItem, 'content'>[] }).content ?? []
        const selected =
          clickedMenuItem === item.key ||
          (subItem.length !== 0
            ? subItem.some((i) => i.key === clickedMenuItem)
            : false)
        const { icon: NormalIcon, selectedIcon: SelectedIcon } = item
        return (
          <ItemContainer key={item.key}>
            <ListItemButton
              component={StyledItemLink}
              to={item.path ?? window.location.pathname}
              onClick={() => {
                if (subItem.length === 0) {
                  navigate(item.path ?? window.location.pathname)
                }
                handleMenuItemOnClick(item.key)
              }}
              selected={selected}
              className="ListItem"
            >
              <ItemIcon>
                {selected ? <SelectedIcon /> : <NormalIcon />}
              </ItemIcon>
              <StyledText primary={item.key} $selected={selected} />
              {subItem.length !== 0 &&
                (clickedMenuItem === item.key ||
                subItem.some((i) => i.key === clickedMenuItem) ? (
                  <StyledIcon {...icons.rightArrow} />
                ) : (
                  <StyledIcon {...icons.downArrow} />
                ))}
            </ListItemButton>
            <Collapse
              in={
                clickedMenuItem === item.key ||
                (subItem.length !== 0 &&
                  subItem.some((i) => i.key === clickedMenuItem))
              }
              timeout="auto"
              unmountOnExit
            >
              {subItem.map((sItem) => (
                <List key={sItem.key}>
                  <ListItemButton
                    component={StyledSubitemLink}
                    to={sItem.path ?? '/'}
                    onClick={() => {
                      navigate(sItem.path ?? '/')
                      handleMenuItemOnClick(sItem.key)
                    }}
                    selected={clickedMenuItem === sItem.key}
                    divider
                  >
                    <ListItemIcon>
                      <StyledIcon {...icons.home} />
                    </ListItemIcon>
                    <ListItemText primary={sItem.key} />
                  </ListItemButton>
                </List>
              ))}
            </Collapse>
          </ItemContainer>
        )
      })}
    </Root>
  )
})

SidebarList.displayName = 'SidebarList'
