import { TransitAssetRequestPayload } from '@stewards-fas/schemas'
import { InferType, boolean, date, number, object, string } from 'yup'

const ERROR_TRANSLATION_PREFIX =
  'assetDetailsPage.assetTransferModal.errorMessages'
export const AssetTransferFormSchema = object({
  transitQuantity: number().required(
    `${ERROR_TRANSLATION_PREFIX}.transitQuantity.required`,
  ),
  isTransferLinkedAsset: boolean(),
  toUnitId: string().required(`${ERROR_TRANSLATION_PREFIX}.toUnitId.required`),
  reason: string(),
  submittedDateTime: date().required(
    `${ERROR_TRANSLATION_PREFIX}.submittedDateTime.required`,
  ),
})
export type AssetTransferFormModel = InferType<typeof AssetTransferFormSchema>

export const getAssetTransferFormInitValue = (
  quantity: number,
): AssetTransferFormModel => ({
  transitQuantity: quantity,
  isTransferLinkedAsset: undefined,
  toUnitId: '',
  reason: '',
  submittedDateTime: undefined as any,
})

export const mapFormikDataToTransitAssetRequestPayload = (
  formikInputs: AssetTransferFormModel,
): Omit<TransitAssetRequestPayload, 'rowVersion'> => ({
  transitQuantity: formikInputs.transitQuantity,
  isTransferLinkedAsset: formikInputs.isTransferLinkedAsset ?? false, //default false
  toUnitId: formikInputs.toUnitId,
  reason: formikInputs.reason ?? '',
  submittedDateTime: formikInputs.submittedDateTime?.toISOString(),
})
