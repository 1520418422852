import {
  useAssetTypeList,
  useFundingList,
  useReferencePageList,
  useRoleList,
  useUnitList,
  useUserList,
} from '@stewards-fas/queries'
import { memo, useState, useMemo } from 'react'
import styled from 'styled-components'
import {
  AppManagementTab,
  AppManagementTabItem,
  AppManagementTabItemType,
  Option,
  systemDataManagementTabItems,
} from '../../components'
import { MasterDataCrudTable } from './master-data-crud-table'
import { sortBy } from 'lodash'

const Container = styled.div`
  width: 100%;
  padding: 20px 0px;
`

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;
`

export const ManageSystemDataPage = memo(() => {
  const [selectedTab, setSelectedTab] = useState<AppManagementTabItemType>(
    systemDataManagementTabItems[0],
  )

  const { data: users } = useUserList({
    expand: ['Role', 'UserUnits'],
  })

  const { data: units } = useUnitList({
    expand: ['SupervisorUser', 'UserUnits.User.Role'],
  })

  const { data: roles } = useRoleList()

  const { data: assetTypes } = useAssetTypeList()

  const { data: fundings } = useFundingList()

  const { data: referencePages } = useReferencePageList()

  const TableData = useMemo<Record<string, any[]>>(
    () => ({
      units: units?.data ?? [],
      users: users?.data ?? [],
      assetTypes: assetTypes?.data ?? [],
      fundings: fundings?.data ?? [],
      refMaterials: referencePages?.data ?? [],
    }),
    [units, users, assetTypes, fundings, referencePages],
  )
  const displayData = useMemo(
    () =>
      sortBy(
        selectedTab?.dataKey ? TableData[selectedTab.dataKey] : [],
        'code',
        'username',
        'nameEN',
        'nameCN',
      ),
    [TableData, selectedTab.dataKey],
  )

  const options = useMemo<Record<string, Option[]>>(
    () => ({
      units:
        units?.data.map((it) => ({ value: it.id, label: it.code ?? '' })) ?? [],
      users:
        users?.data.map((it) => ({ value: it.id, label: it.username })) ?? [],
      roles:
        roles?.data.map((it) => ({ value: it.id, label: it.name ?? '' })) ?? [],
    }),
    [units, users, roles],
  )

  const btnDisabled = useMemo(
    () => ({
      add:
        selectedTab.value ===
        AppManagementTabItem.systemReferenceMaterialManagement,
      inactivate: true,
    }),
    [selectedTab],
  )

  return (
    <Container>
      <TabsContainer>
        {systemDataManagementTabItems.map((item) => (
          <AppManagementTab
            key={item.value}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...item}
            isSelectedTab={selectedTab.value === item.value}
            onSelect={(value) =>
              setSelectedTab(
                systemDataManagementTabItems.find(
                  (tab) => tab.value === value,
                ) ?? systemDataManagementTabItems[0],
              )
            }
            type="inline"
          />
        ))}
      </TabsContainer>

      <MasterDataCrudTable
        key={selectedTab.value}
        tab={selectedTab.value}
        data={displayData}
        options={options}
        btnDisabled={btnDisabled}
      />
    </Container>
  )
})

ManageSystemDataPage.displayName = 'ManageSystemDataPage'
