import { useRoleList } from '@stewards-fas/queries'
import { memo, useMemo } from 'react'
import { FormikSelectField } from './formik-select-field'
import { TFuncKey, useTranslation } from '@pasteltech/i18n-react'
import { toFirstCharLowerCase } from '../../utilities'

type RoleSelectionFieldPropType = {
  name: string
  multiple?: boolean
  disabled?: boolean
}
export const RoleSelectionField = memo<RoleSelectionFieldPropType>(
  ({ name, multiple = false }) => {
    const { data } = useRoleList()
    const { t } = useTranslation()
    const optionList = useMemo(() => {
      const RoleList = data?.data ?? []
      return RoleList?.map((it) => ({
        value: it.id,
        label: t(
          `systemDataManagement.userRole.${toFirstCharLowerCase(
            it.name,
          )}` as TFuncKey,
        ) as string,
      }))
    }, [data?.data, t])
    return (
      <FormikSelectField name={name} options={optionList} multiple={multiple} />
    )
  },
)
RoleSelectionField.displayName = 'RoleSelectionField'
