import { assetDetailsPage } from './asset-details-page'

export const assetManagement = {
  tab: {
    search: '搜尋',
    add: '新增',
    writeOff: '報銷',
    transfer: '轉移',
    approvalStatus: '批核狀態',
    systemUnitManagement: '單位管理',
    systemUserManagement: '使用者管理',
    systemAssetClassManagement: '資產類別管理',
    systemFundingResourceManagement: '資金來源管理',
    systemReferenceMaterialManagement: '參考資料',
  },

  searchSection: {
    TableHeaders: {
      assetCode: '資產編號',
      name: '名稱',
      brand: '牌子',
      model: '型號',
      number: '數量',
      amount: '金額',
      buyDate: '購買日期',
      placement: '放置地點',
      approveState: '批核狀態',
    },
    assetList: '資產清單',
    button: {
      produceLabel: '產生標籤',
      assetLabel: '資產標籤',
    },
  },

  writeOffSection: {
    writeOffCode: '報銷編號',
    title: {
      writeOffAsset: '報銷資產',
      writeOffRecordList: '報銷資產記錄表',
      confirmWriteOffAsset: '確認報銷資產',
    },
    sameUnitOnlyWarning: '請選擇相同單位的固定資產',
    notApprovedRequestOnly: '已核准申請無法被撤回',
    tabs: {
      items: '報銷項目',
      records: '報銷記錄',
    },
    TableHeaders: {
      ...assetDetailsPage.form.fields.labels,
      writeOffCode: '報銷編號',
      name: '名稱',
      brand: '牌子',
      model: '型號',
      assetNumber: '數量',
      valuedAmount: '金額',
      purchaseDay: '購買日期',
      placement: '放置地點',
      reason: '報銷原因',
      approveState: '報銷編號狀態',
    },
    PreviewTableHeaders: {
      assetNo: '資產編號',
      assetName: '資產名稱',
      quantity: '數量',
      purchaseDay: '購置日期',
      valuedAmount: '金額/估值',
      writeOffReason: '報銷原因',
    },
    actions: {
      approve: '核准',
      withdraw: '撤回',
      preview: '報銷預覽',
      writeoffSuccess: '報銷已核准',
      withdrawReason: '撤回原因',
      withdrawSuccess: '已撤回報銷申請',
    },
    modals: {
      withdraw: '確定撤回？',
      submissionDate: '遞交日期',
      preview: '預覽',
      confirmApprove: '確定核准報銷',
    },
    previewForm: {
      titleLine1: '香港神託會',
      titleLine2: '固定資產報銷一覽記錄表',
      unit: '單位',
      applicantSignature: '填表人簽署',
      supervisorSignature: '主管簽署',
      applicationDate: '申請日期',
      approvedDate: '核准日期',
      export: '匯出文件',
      submit: '提交',
    },
  },

  approvalSection: {
    TableHeaders: {
      category: '分類',
    },
    popUp: {
      approveItem: '核准項目',
      confirmApproveItem: '確認核准項目？',
      rejectItem: '撤回項目',
      confirmRejectItem: '確認撤回以上項目？',
      rejectReason: '撤回原因',
    },
  },

  transitSection: {
    withdrawPopUp: {
      title: '撤回轉移資產',
      confirmMessage: '確定撤回以上轉移資產請求?',
      reason: '撤回原因',
    },
  },
  pagination: {
    from: '第',
    to: '到',
    item: '項',
  },
}
