export const assetActions = {
  create: {
    success: '已申請新增資產',
  },
  edit: {
    success: '已申請修改資產',
  },
  transit: {
    success: '已申請轉移資產',
  },
  approval: {
    success: '已成功核准資產',
  },
  reimburse: {
    success: '已加入報銷項目',
  },
  transitApprove: {
    success: '已成功核准資產轉移',
    error: '請選擇未接受或未移出的轉移請求',
    notPermittedError: '請選擇負責單位的轉移請求',
  },
  link: {
    success: '已連繫資產',
  },
  unlink: {
    success: '已取消資產連繫',
  },
  withdrawTransit: {
    success: '已撤回轉移請求',
    error: '請選擇未接受或未移出的轉移請求',
  },
}
