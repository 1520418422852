import { getEnv } from 'mobx-state-tree'
import { IEnvironment } from './environment'

export const withEnvironment = (self: any) => ({
  views: {
    get environment() {
      return getEnv<IEnvironment>(self)
    },
  },
})
