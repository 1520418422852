import dayjs from 'dayjs'
import { memo } from 'react'
import styled from 'styled-components'
import { SVGComponent } from '../../icons'
import { formats } from '../../theme'
import { BaseFormDirection, BaseFormField } from './base-form-field'

const MarginProvider = styled.div`
  margin: 10px;
`

export type FormReadOnlyFieldProps = {
  label?: string
  name?: string
  dir?: BaseFormDirection
  backgroundColor?: string
  fontColor?: string
  defaultValue?: string | Date
  icon?: SVGComponent
}

const StyledSpan = styled.span<{
  $backgroundColor: string
  $fontColor: string
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $fontColor, theme }) =>
    $fontColor ? $fontColor : theme.colors.primary};
  padding: 0 5px;
`
export const FormReadOnlyField = memo<FormReadOnlyFieldProps>(
  ({
    label,
    dir = 'horizontal',
    backgroundColor = 'white',
    fontColor = '',
    defaultValue = '',
    icon,
  }) => {
    const Icon = icon
    return (
      <BaseFormField label={label} dir={dir}>
        <MarginProvider dir={dir} />
        {Icon && <Icon />}
        <StyledSpan $backgroundColor={backgroundColor} $fontColor={fontColor}>
          {defaultValue instanceof Date
            ? dayjs(defaultValue).format(formats.date)
            : defaultValue}
        </StyledSpan>
      </BaseFormField>
    )
  },
)

FormReadOnlyField.displayName = 'FormReadOnlyField'
