import { FormHelperText as MuiFormHelperText } from '@mui/material'
import { PropsWithChildren, memo } from 'react'
import styled from 'styled-components'
const StyledFormHelperText = styled(MuiFormHelperText)`
  color: ${({ theme }) => theme.colors.error};
`
export const FormHelperText = memo<PropsWithChildren>(({ children }) => {
  return <StyledFormHelperText>{children}</StyledFormHelperText>
})
FormHelperText.displayName = 'FormHelperText'
