import { memo, useMemo } from 'react'
import { FormikSelectField } from './formik-select-field'

type StatusSelectionFieldPropType = {
  name: string
  multiple?: boolean
  disabled?: boolean
  activeText: string
  inactiveText: string
}
export const StatusSelectionField = memo<StatusSelectionFieldPropType>(
  ({ name, multiple = false, activeText, inactiveText }) => {
    const optionList = useMemo(() => {
      return [
        {
          value: 'active',
          label: activeText,
        },
        {
          value: 'inactive',
          label: inactiveText,
        },
      ]
    }, [activeText, inactiveText])
    return (
      <FormikSelectField name={name} options={optionList} multiple={multiple} />
    )
  },
)
StatusSelectionField.displayName = 'StatusSelectionField'
