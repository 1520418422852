import { useCallback, useMemo, useState } from 'react'
import { AppManagementTabItem } from '../../../components'
import { StatusFilter, StatusFilterOption } from '../components'
import {
  AssetTypeResponsePayload,
  FundingResponsePayload,
  UnitResponsePayload,
  UserResponsePayload,
} from '@stewards-fas/schemas'
import { useTranslation } from '@pasteltech/i18n-react'

export const useMasterDataFilter = (activeTab: AppManagementTabItem) => {
  const [unitFilterOption, setUnitFilterOption] = useState(
    StatusFilterOption.active,
  )
  const [userFilterOption, setUserFilterOption] = useState(
    StatusFilterOption.active,
  )
  const [fundingFilterOption, setFundingFilterOption] = useState(
    StatusFilterOption.active,
  )
  const [assetTypeFilterOption, setAssetTypeFilterOption] = useState(
    StatusFilterOption.active,
  )

  const { t } = useTranslation()
  const filterComponent = useMemo(() => {
    if (activeTab === AppManagementTabItem.systemUnitManagement)
      return (
        <StatusFilter
          setValue={setUnitFilterOption}
          allText={t('systemDataManagement.unitStatus.all')}
          activeText={t('systemDataManagement.unitStatus.active')}
          inactiveText={t('systemDataManagement.unitStatus.inactive')}
        />
      )
    if (activeTab === AppManagementTabItem.systemUserManagement)
      return (
        <StatusFilter
          setValue={setUserFilterOption}
          allText={t('systemDataManagement.userStatus.all')}
          activeText={t('systemDataManagement.userStatus.active')}
          inactiveText={t('systemDataManagement.userStatus.inactive')}
        />
      )
    if (activeTab === AppManagementTabItem.systemAssetClassManagement)
      return (
        <StatusFilter
          setValue={setAssetTypeFilterOption}
          allText={t('systemDataManagement.assetTypeStatus.all')}
          activeText={t('systemDataManagement.assetTypeStatus.active')}
          inactiveText={t('systemDataManagement.assetTypeStatus.inactive')}
        />
      )
    if (activeTab === AppManagementTabItem.systemFundingResourceManagement)
      return (
        <StatusFilter
          setValue={setFundingFilterOption}
          allText={t('systemDataManagement.fundingStatus.all')}
          activeText={t('systemDataManagement.fundingStatus.active')}
          inactiveText={t('systemDataManagement.fundingStatus.inactive')}
        />
      )
    return undefined
  }, [activeTab, setUnitFilterOption, setUserFilterOption, t])

  const defaultFilterFunction = useCallback((data: any) => data, [])
  const filterFunction = useMemo(() => {
    if (activeTab === AppManagementTabItem.systemUnitManagement)
      return (units: UnitResponsePayload[]) =>
        unitFilterOption === StatusFilterOption.all
          ? units
          : units.filter(
              (unit) =>
                unit.status.toLowerCase() === unitFilterOption.toLowerCase(),
            )
    if (activeTab === AppManagementTabItem.systemUserManagement)
      return (users: UserResponsePayload[]) =>
        userFilterOption === StatusFilterOption.all
          ? users
          : users.filter(
              (user) =>
                user.status.toLowerCase() === userFilterOption.toLowerCase(),
            )
    if (activeTab === AppManagementTabItem.systemAssetClassManagement)
      return (assetTypes: AssetTypeResponsePayload[]) =>
        assetTypeFilterOption === StatusFilterOption.all
          ? assetTypes
          : assetTypes.filter(
              (assetType) =>
                assetType.status.toLowerCase() ===
                assetTypeFilterOption.toLowerCase(),
            )

    if (activeTab === AppManagementTabItem.systemFundingResourceManagement)
      return (fundings: FundingResponsePayload[]) =>
        fundingFilterOption === StatusFilterOption.all
          ? fundings
          : fundings.filter(
              (funding) =>
                funding.status.toLowerCase() ===
                fundingFilterOption.toLowerCase(),
            )

    return defaultFilterFunction
  }, [
    activeTab,
    unitFilterOption,
    userFilterOption,
    fundingFilterOption,
    assetTypeFilterOption,
    defaultFilterFunction,
  ])
  return { filterComponent, filterFunction }
}
