import { useTranslation } from '@pasteltech/i18n-react'
import { useApiErrorHandle } from '../../../hooks'
import {
  useApproveBulkAssetRequestMutation,
  useWithdrawBulkAssetRequestMutation,
} from '@stewards-fas/queries'
import { useCallback } from 'react'
import { useOverlay } from '@pasteltech/overlay-provider'
import { AssetRequestsResponsePayload } from '@stewards-fas/schemas'

type RequestList = AssetRequestsResponsePayload[]

export function useHandleTransfer() {
  const { standardErrorHandler } = useApiErrorHandle()
  const { t } = useTranslation()
  const { showSnackbar, showSpinner, hideSpinner } = useOverlay()

  const { mutateAsync: approveAssetRequestBulkAsync, isLoading: approving } =
    useApproveBulkAssetRequestMutation()

  const { mutateAsync: withdrawAssetRequestBulkAsync } =
    useWithdrawBulkAssetRequestMutation()

  const handleWithdraw = useCallback(
    async (selectedRowsData: RequestList, withdrawReason: string) => {
      try {
        showSpinner()
        await withdrawAssetRequestBulkAsync(
          selectedRowsData.map((it) => ({
            id: it.id,
            rowVersion: it.rowVersion,
            reason: withdrawReason,
          })),
        )
        showSnackbar({ content: t('assetActions.withdrawTransit.success') })
      } catch (error) {
        standardErrorHandler(error)
      } finally {
        hideSpinner()
      }
    },
    [
      withdrawAssetRequestBulkAsync,
      showSnackbar,
      showSpinner,
      standardErrorHandler,
      hideSpinner,
      t,
    ],
  )

  const handleApprove = useCallback(
    async (selectedRowsData: RequestList) => {
      try {
        showSpinner()
        const approvalPayload = selectedRowsData.map((it) => ({
          id: it.id,
          rowVersion: it.rowVersion,
        }))
        await approveAssetRequestBulkAsync(approvalPayload)
        showSnackbar({ content: t('assetActions.transitApprove.success') })
      } catch (error) {
        standardErrorHandler(error)
      } finally {
        hideSpinner()
      }
    },
    [
      approveAssetRequestBulkAsync,
      showSnackbar,
      standardErrorHandler,
      showSpinner,
      hideSpinner,
      t,
    ],
  )

  return { handleApprove, approving, handleWithdraw }
}
