import { Button } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { memo } from 'react'
import styled from 'styled-components'
import { PreviewIcon, WithdrawIcon } from '../../../../icons'

type AssetManagementWriteOffActionButtonsPropType = {
  handleApprove?: () => void
  handleWithdraw: () => void
  handlePreview: () => void
  isDisableApprove?: boolean
  isDisableWithdraw: boolean
  isDisablePreview: boolean
}

const ApproveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  margin-bottom: 30px;
  column-gap: 20px;
`

const ActionButton = styled(Button)`
  display: flex;
  column-gap: 10px;
  font-weight: bold;
`
const WithdrawButton = styled(ActionButton)`
  background-color: ${({ theme }) => theme.colors.chestnut};
  &:hover {
    background-color: ${({ theme }) => theme.colors.chestnut};
  }
`
const PreviewButton = styled(ActionButton)`
  background-color: ${({ theme }) => theme.colors.berryBlue};
`

export const AssetManagementWriteOffActionButtons =
  memo<AssetManagementWriteOffActionButtonsPropType>(
    ({
      handleWithdraw,
      handlePreview,
      isDisableWithdraw,
      isDisablePreview,
    }) => {
      const { t } = useTranslation()
      return (
        <ApproveButtonContainer>
          <WithdrawButton
            variant="contained"
            onClick={handleWithdraw}
            disabled={isDisableWithdraw}
          >
            <WithdrawIcon />
            {t('assetManagement.writeOffSection.actions.withdraw')}
          </WithdrawButton>
          <PreviewButton
            variant="contained"
            onClick={handlePreview}
            disabled={isDisablePreview}
          >
            <PreviewIcon />
            {t('assetManagement.writeOffSection.actions.preview')}
          </PreviewButton>
        </ApproveButtonContainer>
      )
    },
  )
AssetManagementWriteOffActionButtons.displayName =
  'AssetManagementWriteOffActionButtons'
