import { Box, Button, Grid } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { useOverlay } from '@pasteltech/overlay-provider'
import { useAssetRequestList } from '@stewards-fas/queries'
import {
  AssetRequestStatus,
  AssetRequestsListRequestPayload,
} from '@stewards-fas/schemas'
import { memo, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  AdminConfirmTransferAssetButtonModal,
  AssetSearchBar,
  Dialog,
  FormCheckboxField,
  FormTextField,
  GenericTable,
  PageTitle,
  Spinner,
  TableList,
  TransferStatusDropdown,
} from '../../../components'
import { useCurrentUserUnits, useUserRole } from '../../../hooks'
import { WithdrawIcon } from '../../../icons'
import {
  SearchFormSchemaModel,
  Section,
  mapAssetTransferRequestListToTableRows,
} from '../../../models'
import { AppRoutes } from '../../../routers'
import {
  compareString,
  convertAllValueToNull,
  convertToEndOfDay,
  convertToStartOfDay,
  isAllEqual,
} from '../../../utilities'
import { useHandleTransfer } from './use-handle-transfer'

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
const WithdrawButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.chestnut};
  &:hover {
    background-color: ${({ theme }) => theme.colors.chestnut};
  }
  column-gap: 10px;
  font-weight: bold;
  width: 90px;
`
const ButtonBar = styled(Box)`
  display: flex;
  margin-left: auto;
  gap: 10px;
`
const WithDrawPopUpTable = styled(GenericTable)`
  max-height: 30vh;
  overflow: auto;
  margin-bottom: 10px;
  border: 1px solid ${({ theme }) => theme.colors.greys[5]};
`
export const AssetManagementTransferSection = memo(() => {
  // global hooks
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { showSnackbar } = useOverlay()
  const EMPTY_MSG = useMemo(() => t('common.selectItemFirst'), [t])

  // constants
  const headers = useMemo(
    () => [
      t('assetDetailsPage.transferTable.headers.moveOutDate'),
      t('assetDetailsPage.transferTable.headers.originalUnit'),
      t('assetDetailsPage.transferTable.headers.outOfState'),
      t('assetDetailsPage.transferTable.headers.receivingUnit'),
      t('assetDetailsPage.transferTable.headers.receivingStatus'),
      t('assetDetailsPage.transferTable.headers.receivingDate'),
      t('assetDetailsPage.transferTable.headers.newAssetNumber'),
      t('assetDetailsPage.transferTable.headers.name'),
      t('assetDetailsPage.transferTable.headers.brand'),
      t('assetDetailsPage.transferTable.headers.quantity'),
      t('assetDetailsPage.transferTable.headers.transferReason'),
    ],
    [t],
  )

  // Role permissions
  const { isAdmin, isSupervisor } = useUserRole()
  const isSupervisorOrAdmin = useMemo(
    () => isAdmin || isSupervisor,
    [isAdmin, isSupervisor],
  )

  // ui state
  const [isOpenWithdrawModal, setIsOpenWithdrawModal] = useState<boolean>(false)

  // input state
  const [searchParams, setSearchParams] =
    useState<AssetRequestsListRequestPayload>({})
  const [approveStatus, setApproveStatus] = useState<string | undefined>(
    undefined,
  )
  const [transitStatusChecked, setTransitStatusChecked] =
    useState<boolean>(true)
  const [receiveStatusChecked, setReceiveStatusChecked] =
    useState<boolean>(true)
  const [withdrawReason, setWithDrawReason] = useState<string>('')
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  // api data
  const { data: transferRequestRecords } = useAssetRequestList({
    ...searchParams,
    type: 'TRANSITION',
    expand: ['AssetRequestAssets.Asset', 'FromUnit', 'ToUnit'],
  })

  const { checkPermittedUnitId } = useCurrentUserUnits()
  const filteredList = useMemo(() => {
    const dataList = transferRequestRecords?.data ?? []
    const filteredData = dataList
      .filter((it) => {
        // withdrawn request are soft deleted
        const isWithdrawn = [it.transitStatus, it.receiveStatus].includes(
          AssetRequestStatus.withdrew,
        )
        return !isWithdrawn
      })
      .filter((it) => {
        // filter by status(input by user)
        if (approveStatus === undefined) return true
        if (isAllEqual(false, transitStatusChecked, receiveStatusChecked))
          return true
        const transitStatus = it.transitStatus ?? AssetRequestStatus.pending // transit Status is never null for transit request. Just for the parallelity
        const receiveStatus = it.receiveStatus ?? AssetRequestStatus.pending
        if (transitStatusChecked && transitStatus === approveStatus) return true
        if (receiveStatusChecked && receiveStatus === approveStatus) return true

        return false
      })
    const filteredByUnits = filteredData.filter((it) => {
      if (isAdmin) return true
      if (checkPermittedUnitId(it.fromUnitId)) return true
      if (checkPermittedUnitId(it.toUnitId)) return true
      return false
    })
    return filteredByUnits
  }, [
    transferRequestRecords?.data,
    approveStatus,
    transitStatusChecked,
    receiveStatusChecked,
    isAdmin,
    checkPermittedUnitId,
  ])

  const displayData = useMemo(() => {
    // sort data for display
    return filteredList.sort(
      (a, b) => -compareString(a.transitDateTime, b.transitDateTime),
    )
  }, [filteredList])

  const selectedRowsData = useMemo(
    () =>
      transferRequestRecords?.data?.filter((it) =>
        selectedRows.some((id) => id === it.id),
      ) ?? [],
    [transferRequestRecords?.data, selectedRows],
  )

  const { handleApprove, approving, handleWithdraw } = useHandleTransfer()

  const handleSearch = useCallback((formikValues: SearchFormSchemaModel) => {
    const fromDateTime =
      formikValues.startDate &&
      convertToStartOfDay(formikValues.startDate?.toISOString())
    const toDateTime =
      formikValues.endDate &&
      convertToEndOfDay(formikValues.endDate?.toISOString())
    setSearchParams({
      fromUnitId: convertAllValueToNull(formikValues.unit),
      toUnitId: convertAllValueToNull(formikValues.toUnit),
      transitFromDateTime: fromDateTime,
      transitToDateTime: toDateTime,
    })
  }, [])

  const approveTransfer = useCallback(async () => {
    await handleApprove(selectedRowsData)
    setSelectedRows([])
  }, [handleApprove, selectedRowsData])

  const handleWithdrawButtonOnClick = useCallback(() => {
    const isNoData = selectedRowsData.length === 0
    if (isNoData) {
      showSnackbar({ content: EMPTY_MSG, severity: 'error' })
      return
    }
    const isSomeAlreadyApproved = selectedRowsData.some((row) =>
      // if both status are approved, then data status is approved
      isAllEqual(
        row.receiveStatus,
        row.transitStatus,
        AssetRequestStatus.approved,
      ),
    )
    if (isSomeAlreadyApproved) {
      showSnackbar({
        content: t('assetActions.withdrawTransit.error'),
        severity: 'error',
      })
      return
    }
    setWithDrawReason('')
    setIsOpenWithdrawModal(true)
  }, [showSnackbar, t, setIsOpenWithdrawModal, selectedRowsData, EMPTY_MSG])

  const handleWithdrawModalSelected = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        await handleWithdraw(selectedRowsData, withdrawReason)
        setSelectedRows([])
        setIsOpenWithdrawModal(false)
        return
      }
      setIsOpenWithdrawModal(false)
    },
    [handleWithdraw, selectedRowsData, withdrawReason],
  )

  const withdrawPopUpTableColumn = useMemo(
    () => ({
      name: t('assetDetailsPage.transferTable.headers.name'),
      transitQuantity: t('assetDetailsPage.transferTable.headers.quantity'),
      transitDateTime: t('assetDetailsPage.transferTable.headers.moveOutDate'),
      fromUnit: t('assetDetailsPage.transferTable.headers.originalUnit'),
      toUnit: t('assetDetailsPage.transferTable.headers.receivingUnit'),
      reason: t('assetDetailsPage.transferTable.headers.transferReason'),
    }),
    [t],
  )
  return (
    <Box>
      <Box mb="20px">
        <PageTitle>{t('assetDetailsPage.transferTable.title')}</PageTitle>
      </Box>
      <AssetSearchBar
        onSearch={handleSearch}
        needState={false}
        tab={Section.transfer}
      />

      <Grid container mb={3}>
        <Grid item>
          <Grid container gap={3}>
            <Grid item width={130}>
              <TransferStatusDropdown
                onChange={(val) => {
                  setApproveStatus(val)
                  setSelectedRows([])
                }}
                notApprovedOnly={false}
              />
            </Grid>

            <Grid item gap={3}>
              <Grid container>
                <FormCheckboxField
                  label={t('assetDetailsPage.transferTable.headers.outOfState')}
                  name="transitStatus"
                  marginTop={0}
                  onChange={(val) => {
                    setTransitStatusChecked(val)
                    setSelectedRows([])
                  }}
                  checked={transitStatusChecked}
                />
                <FormCheckboxField
                  label={t(
                    'assetDetailsPage.transferTable.headers.receivingStatus',
                  )}
                  name="receiveStatus"
                  marginTop={0}
                  onChange={(val) => {
                    setReceiveStatusChecked(val)
                    setSelectedRows([])
                  }}
                  checked={receiveStatusChecked}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isSupervisorOrAdmin && (
          <ButtonBar>
            <WithdrawButton
              variant="contained"
              onClick={handleWithdrawButtonOnClick}
            >
              <WithdrawIcon />
              {t('assetManagement.writeOffSection.actions.withdraw')}
            </WithdrawButton>

            <AdminConfirmTransferAssetButtonModal
              withControlledButton
              handleApprove={approveTransfer}
              isDisableApprove={approving}
              selectedRowsData={displayData.filter((it) =>
                selectedRows.includes(it.id),
              )}
            />
          </ButtonBar>
        )}
      </Grid>
      <Dialog
        visible={isOpenWithdrawModal}
        onSelected={handleWithdrawModalSelected}
        title={t('assetManagement.transitSection.withdrawPopUp.title')}
      >
        <>
          <WithDrawPopUpTable
            rowData={mapAssetTransferRequestListToTableRows(selectedRowsData)}
            rowIdKey="id"
            tableColumns={withdrawPopUpTableColumn}
          />
          {t('assetManagement.transitSection.withdrawPopUp.confirmMessage')}
          <FormTextField
            templateColumns="1fr 7fr"
            value={withdrawReason}
            onChange={(val) => setWithDrawReason(val as string)}
            label={t('assetManagement.transitSection.withdrawPopUp.reason')}
          />
        </>
      </Dialog>

      {transferRequestRecords != null ? (
        <TableList
          headers={headers}
          rows={mapAssetTransferRequestListToTableRows(displayData)}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          onRowClick={(row) =>
            navigate(AppRoutes.assetTransferView.render(row.assetId))
          }
        />
      ) : (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
    </Box>
  )
})

AssetManagementTransferSection.displayName = 'AssetManagementTransferSection'
