import { TextField } from '@mui/material'
import { memo } from 'react'
import { FormikTextField } from './formik-text-field'

type FormikTextAreaFieldPropType = {
  name: string
  props?: React.ComponentProps<typeof TextField>
  disabled?: boolean
}
export const FormikTextAreaField = memo<FormikTextAreaFieldPropType>(
  ({ name, props }) => {
    return (
      <FormikTextField
        name={name}
        multiline
        maxRows={15}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    )
  },
)
FormikTextAreaField.displayName = 'FormikTextAreaField'
