import {
  Checkbox,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { AssetRequestStatus, AssetStatus } from '@stewards-fas/schemas'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Spinner } from '../spinner'

interface ApprovedStateProps {
  $approved: boolean
}

interface TableListProps {
  headers: string[]
  rows: any[]
  isLoading?: boolean
  onRowClick?(row: any): void
  selectedRows: string[]
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>
  isDisableSelection?: boolean
}

const Status = ['status', 'transitStatus', 'receiveStatus']

const omitProperty = ['id', 'assetId']

const StyledTableRow = styled(TableRow)<{
  $clickable?: boolean
  $isEven?: boolean
}>`
  ${({ $clickable: clickable }) => ({
    cursor: clickable ? 'pointer' : undefined,
  })}
  ${({ $isEven: isEven, theme }) => ({
    backgroundColor: isEven ? theme.colors.greys[6] : undefined,
  })}
`

const GradientTableRow = styled(TableRow)`
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.berryBlue},
    ${({ theme }) => theme.colors.brigBlue}
  );
`

const WhiteTextTableCell = styled(TableCell)`
  color: ${({ theme }) => theme.colors.white};
`

const StyledCheckbox = styled(Checkbox)`
  &.Mui-checked {
    color: ${({ theme }) => theme.colors.maroonOak};
  }
`

const ApprovedStateTableCell = styled.div<ApprovedStateProps>`
  padding: 16px;
  width: fit-content;
  white-space: nowrap;
  color: ${(props) =>
    props.$approved
      ? ({ theme }) => theme.colors.darkGreen
      : ({ theme }) => theme.colors.maroonOak};
  background-color: ${(props) =>
    props.$approved
      ? ({ theme }) => theme.colors.lightGreen
      : ({ theme }) => theme.colors.lightBrown};
`

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`

const PaginationContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const LeftAlignedContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
`
const Page = styled.div`
  margin-right: 15px;
`

export const TableList = memo(
  ({
    headers,
    rows,
    isLoading = false,
    selectedRows,
    setSelectedRows,
    onRowClick,
    isDisableSelection = false,
  }: TableListProps) => {
    const { t } = useTranslation()
    const [currentPage, setCurrentPage] = useState(1)
    const rowsPerPage = 24

    const handlePageChange = useCallback(
      (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page)
        setSelectedRows([])
      },
      [setSelectedRows],
    )

    const startIndex = useMemo(
      () => (currentPage - 1) * rowsPerPage,
      [currentPage],
    )

    const endIndex = useMemo(
      () => Math.min(startIndex + rowsPerPage, rows.length),
      [startIndex, rows.length],
    )

    const visibleRows = useMemo(
      () => rows.slice(startIndex, endIndex),
      [rows, startIndex, endIndex],
    )

    const selectedAll = useMemo(
      () =>
        selectedRows.length === visibleRows.length && visibleRows.length !== 0,
      [selectedRows, visibleRows],
    )

    const handleSelectAll = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRows(
          event.target.checked ? visibleRows.map((row) => row.id) : [],
        )
      },
      [visibleRows, setSelectedRows],
    )

    const handleSelectRow = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        event.stopPropagation()

        let newSelectedRows = []

        if (event.target.checked) {
          newSelectedRows = [...selectedRows, id]
        } else {
          newSelectedRows = selectedRows.filter((rowId) => rowId !== id)
        }

        setSelectedRows(newSelectedRows)
      },
      [selectedRows, setSelectedRows],
    )

    const isApprovedStatus = useCallback(
      (status: AssetStatus) =>
        [
          AssetStatus.assetApproved,
          AssetStatus.reimburseApproved,
          AssetStatus.transitApproved,
          AssetRequestStatus.approved,
        ].includes(status),
      [],
    )

    useEffect(() => {
      const totalPage = Math.ceil(rows.length / rowsPerPage)
      if (currentPage > totalPage) {
        setCurrentPage(1)
      }
    }, [rows, currentPage, rowsPerPage])

    return (
      <div>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <GradientTableRow>
                  {!isDisableSelection && (
                    <TableCell padding="checkbox">
                      <StyledCheckbox
                        checked={selectedAll}
                        onChange={handleSelectAll}
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        inputProps={{ 'aria-label': 'Select All Rows' }}
                      />
                    </TableCell>
                  )}
                  {headers.map((header) => (
                    <WhiteTextTableCell key={header}>
                      {header}
                    </WhiteTextTableCell>
                  ))}
                </GradientTableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell rowSpan={0}>
                      <Spinner />
                    </TableCell>
                  </TableRow>
                ) : (
                  visibleRows.map((row, index) => (
                    <StyledTableRow
                      key={row.id}
                      $clickable={onRowClick != null}
                      $isEven={(index + 1) % 2 == 0}
                    >
                      {!isDisableSelection && (
                        <TableCell padding="checkbox">
                          <StyledCheckbox
                            checked={selectedRows.includes(row.id)}
                            onChange={(event) => handleSelectRow(event, row.id)}
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            inputProps={{ 'aria-label': 'Select Row' }}
                          />
                        </TableCell>
                      )}
                      {Object.keys(row)
                        .filter((property) => !omitProperty.includes(property))
                        .map((property) => (
                          <TableCell
                            key={property}
                            onClick={() => onRowClick?.(row)}
                          >
                            {Status.includes(property) ? (
                              <ApprovedStateTableCell
                                $approved={isApprovedStatus(row[property])}
                              >
                                {isApprovedStatus(row[property])
                                  ? t('ApproveStatus.approved')
                                  : t('ApproveStatus.pending')}
                              </ApprovedStateTableCell>
                            ) : (
                              row[property]
                            )}
                          </TableCell>
                        ))}
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <PaginationContainer>
          <LeftAlignedContainer>
            <LabelContainer>
              <Page>
                {t('assetManagement.pagination.from')}
                {Math.min(startIndex + 1, endIndex)}
                {t('assetManagement.pagination.to')}
                {endIndex}
                {t('assetManagement.pagination.item')}
              </Page>
              ({rows.length}
              {t('assetManagement.pagination.item')})
            </LabelContainer>
          </LeftAlignedContainer>
          <Pagination
            count={Math.ceil(rows.length / rowsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            shape="rounded"
            variant="outlined"
          />
        </PaginationContainer>
      </div>
    )
  },
)

TableList.displayName = 'TableList'
