import { ApiGateway } from '@pasteltech/api-gateway'
import QueryString from 'qs'

type ApiConfig = {
  baseUrl: string
  timeout: number
  headers?: Record<string, string>
}

export function configureApi(apiConfig: ApiConfig) {
  ApiGateway.primary.setConfig({
    ...apiConfig,
    paramsSerializer: (params) =>
      QueryString.stringify(params, {
        arrayFormat: 'repeat', // ? dotnet 6 accept repeat by default
      }),
  })

  return ApiGateway.primary
}
