import {
  AssetTypeResponsePayload,
  FundingResponsePayload,
  UnitResponsePayload,
} from '@stewards-fas/schemas'
import { useMemo } from 'react'
import { compareString, removeInactive } from '../utilities'

export type Option = {
  value: string
  label: string
}

type Props = {
  unitList: UnitResponsePayload[]
  assetTypeList: AssetTypeResponsePayload[]
  fundingsList: FundingResponsePayload[]
}

function toActiveSortedOptions<
  T extends { status: string; code?: string; id: string },
>(list: T[]) {
  return removeInactive(list)
    .map((obj) => ({
      label: obj.code ?? '',
      value: obj.id,
    }))
    .sort((a, b) => compareString(a.label, b.label))
}

export function useDropdownOptions({
  unitList,
  assetTypeList,
  fundingsList,
}: Props) {
  const fundingOptions = useMemo(() => {
    return toActiveSortedOptions(fundingsList)
  }, [fundingsList])

  const unitOptions = useMemo(() => {
    return removeInactive(unitList)
      .map((unit) => ({
        label: `${unit.code} ${unit.nameCN}`,
        value: unit.id,
      }))
      .sort((a, b) => compareString(a.label, b.label))
  }, [unitList])

  const assetTypeOptions = useMemo(() => {
    return removeInactive(assetTypeList)
      .map((atype) => ({
        value: atype.id,
        label: `${atype.code} ${atype.typeCN}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [assetTypeList])

  const yearOptions: Option[] = useMemo(() => {
    const currentYear = new Date().getFullYear()
    const options: Option[] = []

    for (let year = currentYear - 4; year <= currentYear + 1; year++) {
      options.push({
        label: String(year),
        value: String(year),
      })
    }

    return options
  }, [])
  return { unitOptions, assetTypeOptions, yearOptions, fundingOptions }
}
