import { useTranslation } from '@pasteltech/i18n-react'
import { AssetTypeResponsePayload, Status } from '@stewards-fas/schemas'
import { useMemo } from 'react'
import {
  CRUDTableActions,
  FieldMapping,
  InputType,
} from '../../../../components'
import { ActiveStatus } from '../../ui-components'

export function useAssetTypeFields() {
  const { t } = useTranslation()
  const mappings = useMemo(() => {
    const map = {
      code: {
        key: 'code',
        display: (i) => i.code,
        value: (i) => i.code,
        inputType: InputType.text,
        label: t('referenceInformation.fields.shortName'),
        disableMode: [CRUDTableActions.edit],
      } as FieldMapping<AssetTypeResponsePayload>,
      chineseName: {
        key: 'typeCN',
        display: (i) => i.typeCN,
        value: (i) => i.typeCN,
        inputType: InputType.text,
        label: t('systemDataManagement.fields.assetType.typeCN'),
      } as FieldMapping<AssetTypeResponsePayload>,
      englishName: {
        key: 'typeEN',
        display: (i) => i.typeEN,
        value: (i) => i.typeEN,
        inputType: InputType.text,
        label: t('systemDataManagement.fields.assetType.typeEN'),
      } as FieldMapping<AssetTypeResponsePayload>,
      content: {
        key: 'contents',
        display: (i) => i.contents,
        value: (i) => i.contents,
        inputType: InputType.textArea,
        label: t('referenceInformation.fields.detail'),
      } as FieldMapping<AssetTypeResponsePayload>,
      status: {
        key: 'status',
        value: (it) => it.status ?? 'active',
        display: (it) => {
          const lower = it.status.toLowerCase()
          if (['active', 'inactive'].includes(lower)) {
            return (
              <ActiveStatus
                status={lower as Status}
                activeText={t('systemDataManagement.assetTypeStatus.active')}
                inactiveText={t(
                  'systemDataManagement.assetTypeStatus.inactive',
                )}
              />
            )
          }
          return 'undefined'
        },
        inputType: InputType.selectAssetTypeStatus,
        label: t('systemDataManagement.fields.assetTypeStatus'),
      } as FieldMapping<{ status: string }>,
    }
    return map
  }, [t])
  return { mappings }
}
