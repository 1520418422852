export const assetTransferRecordPage = {
  title: {
    transferRecord: '轉移記錄表',
  },
  previewForm: {
    titleLine1: '香港神託會',
    titleLine2: '轉移記錄表',
    unit: '服務單位',
    assetType: '資產類別',
    assetNo: '資產編號',
    transferer: '轉移者',
    transferDateTime: '轉移日期',
    purchasedDateTime: '購買日期',
    brand: '牌子',
    name: '名稱',
    model: '型號',
    serialNo: '序號',
    quantity: '數量',
    valuedAmount: '金額/估值',
    providerName: '供應商',
    invoiceNo: '發票號碼',
    warrantyProvider: '保養公司',
    warrantyPeriod: '保養期',
    atUnit: '放置地點',
    sourceOfFunds: '資金來源',
    remarks: '注意事項',
  },
  table: {
    transitDateTime: '轉移日期',
    transitQuantity: '轉移數量',
    fromUnit: '原本單位',
    toUnit: '接受單位',
    reason: '轉移原因',
  },
  button: {
    export: '匯出文件',
    print: '列印',
  },
}
