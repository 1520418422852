import {
  ApiFormattedError,
  getAssetRequest,
  getAssetRequestList,
  postApproveBulkAssetRequest,
  postReimburseRecordRequest,
  withdrawAssetRequest,
  withdrawBulkAssetRequest,
} from '@stewards-fas/apis'
import {
  AssetRequestApproveBulkPayload,
  AssetRequestBulkWithdrawPayload,
  AssetRequestListResponsePayload,
  AssetRequestReimburseRecordPayload,
  AssetRequestWithdrawPayload,
  AssetRequestsListRequestPayload,
  AssetRequestsResponsePayload,
  AssetResponsePayload,
} from '@stewards-fas/schemas'
import {
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import {
  QUERY_CACHE_KEY_ASSET_REQUESTS,
  useOptsOnSuccessInvalidate,
} from './helper'

const EMPTY_PAYLOAD = 'empty-payload'

export function useQueryAssetRequest(
  id: string,
  param?: AssetRequestsListRequestPayload,
  opts?: UseQueryOptions<unknown, unknown, AssetRequestsResponsePayload>,
) {
  return useQuery(
    [QUERY_CACHE_KEY_ASSET_REQUESTS, id, param] as QueryKey,
    () => getAssetRequest(id, param),
    opts,
  )
}

export function useAssetRequestList(
  param?: AssetRequestsListRequestPayload,
  opts?: UseQueryOptions<unknown, unknown, AssetRequestListResponsePayload>,
) {
  return useQuery(
    [QUERY_CACHE_KEY_ASSET_REQUESTS, param] as QueryKey,
    () => getAssetRequestList(param),
    opts,
  )
}

export function useApproveBulkAssetRequestMutation(
  opts?: UseMutationOptions<
    AssetRequestsResponsePayload[],
    ApiFormattedError,
    AssetRequestApproveBulkPayload
  >,
) {
  const optsWithInvalidate = useOptsOnSuccessInvalidate(
    [QUERY_CACHE_KEY_ASSET_REQUESTS],
    opts,
  )
  return useMutation(async (payload) => {
    const res = await postApproveBulkAssetRequest(payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, optsWithInvalidate)
}

export function useReimburseRecordRequestMutation(
  opts: UseMutationOptions<
    AssetResponsePayload[],
    ApiFormattedError,
    AssetRequestReimburseRecordPayload
  >,
) {
  const optsWithInvalidate = useOptsOnSuccessInvalidate(
    [QUERY_CACHE_KEY_ASSET_REQUESTS],
    opts,
  )
  return useMutation(async (payload) => {
    const res = await postReimburseRecordRequest(payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, optsWithInvalidate)
}

export function useWithdrawAssetRequestMutation(
  opts?: UseMutationOptions<
    AssetRequestsResponsePayload,
    ApiFormattedError,
    AssetRequestWithdrawPayload & { id: string }
  >,
) {
  const optsWithInvalidate = useOptsOnSuccessInvalidate(
    [QUERY_CACHE_KEY_ASSET_REQUESTS],
    opts,
  )
  return useMutation(async ({ id, ...payload }) => {
    const res = await withdrawAssetRequest(id, payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, optsWithInvalidate)
}

export function useWithdrawBulkAssetRequestMutation(
  opts?: UseMutationOptions<
    unknown,
    ApiFormattedError,
    AssetRequestBulkWithdrawPayload
  >,
) {
  const optsWithInvalidate = useOptsOnSuccessInvalidate(
    [QUERY_CACHE_KEY_ASSET_REQUESTS],
    opts,
  )
  return useMutation(async (payload) => {
    const res = await withdrawBulkAssetRequest(payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, optsWithInvalidate)
}
