import { useMsal } from '@azure/msal-react'
import { Button, Typography } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { memo, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { StewardsHeaderLogoLarge } from '../../icons'
import { AppRoutes } from '../../routers'
import { useLogin } from '../../services'
import { env } from '../../utilities'

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
`

const SystemNameChinese = styled(Typography)`
  font-weight: 800;
  font-size: 32px;
`
const SystemNameEnglish = styled(Typography)`
  font-weight: 700;
  font-size: ${({ theme }) => theme.sizes.fontSize.heading3}px;
`

const LogoContainer = styled.div``

const SystemNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ButtonContainer = styled.div``

const StyledButton = styled(Button)`
  width: 300px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.primaryContainer};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: ${({ theme }) => theme.sizes.fontSize.heading5}px;

  :hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`
const InfoContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 8px;
  left: 8px;
  flex-direction: column;
`
const InfoVersionContainer = styled.div``

const InfoVersion = styled(Typography)`
  font-weight: 100;
`
const InfoEnvContainer = styled.div``

const InfoEnv = styled(Typography)`
  font-weight: 100;
`

export const LandingPage = memo(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { instance } = useMsal()
  const login = useLogin()

  useEffect(() => {
    if (instance.getActiveAccount() != null) {
      navigate(AppRoutes.assetManagement.path)
    }
  }, [instance, navigate])

  const handleOnClick = useCallback(async () => {
    try {
      await login()
      navigate(AppRoutes.assetManagement.path)
    } catch (error) {
      console.error('Err-id:131736 ,Error when: Login Redirect', error)
    }
  }, [login, navigate])

  return (
    <Root>
      <LogoContainer>
        <StewardsHeaderLogoLarge />
      </LogoContainer>
      <SystemNameContainer>
        <SystemNameChinese>{t('common.systemName')}</SystemNameChinese>
        <SystemNameEnglish>
          {t('common.systemName', { lng: 'en' })}
        </SystemNameEnglish>
      </SystemNameContainer>
      <ButtonContainer>
        <StyledButton onClick={handleOnClick}>{t('common.login')}</StyledButton>
      </ButtonContainer>
      <InfoContainer>
        <InfoVersionContainer>
          <InfoVersion>
            {t('common.version', { lng: 'en', version: env.version })}
          </InfoVersion>
        </InfoVersionContainer>
        <InfoEnvContainer>
          <InfoEnv>
            {t('common.env', { lng: 'en' })}: {env.environment}
          </InfoEnv>
        </InfoEnvContainer>
      </InfoContainer>
    </Root>
  )
})

LandingPage.displayName = 'LandingPage'
