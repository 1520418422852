import { Box, Card } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { useOverlay } from '@pasteltech/overlay-provider'
import { useTheme } from '@pasteltech/theme-provider'
import {
  AssetResponsePayload,
  AssetStatus,
  PostAssetLinksPayload,
} from '@stewards-fas/schemas'
import { useFormikContext } from 'formik'
import { memo, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useCurrentUserUnits, useFormikErrorHelper } from '../../hooks'
import {
  ChainIcon,
  GuildelineIcon,
  LocationIcon,
  PurchaseIcon,
  SVGComponent,
  SubmitIcon,
  WriteOffIcon,
} from '../../icons'
import { AssetFormSchemaModel, CreateAssetFormSchemaModel } from '../../models'
import { useBlobStorage } from '../../services'
import { AssetLinksTable } from '../asset-links-table'
import { BaseButton } from '../buttons'
import { ImageUploader } from '../image-uploader'
import { Spinner } from '../spinner'
import { TableProps } from '../transfer-record-table'
import { useFormFields, useList, useResourceView } from './hooks'
import {
  BatchAssetModal,
  LinkAssetModal,
  UnlinkAssetModel,
  ViewImageModal,
} from './modals'
import {
  BasicInfoFormFields,
  FieldProps,
  FormAction,
  PurchaseRecordFormFields,
  TransferRecordFormFields,
} from './tabs-form-fields'

const ButtonWrapper = styled(Box)`
  display: flex;
  margin-bottom: 10px;
`

const ImageWrapper = styled(Box)`
  display: flex;
  height: 40vh;
`
const SmallImageWrapper = styled(Box)<{ $isMobileView?: boolean }>`
  margin-left: 20px;
  width: ${({ $isMobileView }) => ($isMobileView ? 'auto' : '40%')};
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: ${({ $isMobileView }) => ($isMobileView ? '1' : '0')};
`

const BigImage = styled(Box)<{ $isMobileView?: boolean }>`
  width: ${({ $isMobileView }) => ($isMobileView ? '80%' : '70%')};
  display: grid;
  place-items: center;
`

const Wrapper = styled(Card)<{ $isMobileView?: boolean }>`
  display: flex;
  overflow: visible;
  flex-direction: ${({ $isMobileView }) => ($isMobileView ? 'column' : 'row')};
`
const SmallImage = styled(Box)`
  height: 100%;
  display: grid;
  place-items: center;
`

const LeftWrapper = styled(Box)<{ $isMobileView?: boolean }>`
  width: ${({ $isMobileView }) => ($isMobileView ? '100%' : '50%')};
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const RightWrapper = styled(Box)`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`

const RowWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 3%;
  color: ${({ theme }) => theme.colors.primary};
`
const TabButton = styled(BaseButton)<{
  $selected?: boolean
}>`
  width: 100%;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid
    ${({ $selected, theme }) =>
      $selected ? theme.colors.secondary : theme.colors.greys[2]};
  color: ${({ $selected, theme }) =>
    $selected ? theme.colors.secondary : theme.colors.greys[2]};
  &:hover {
    border: none;
    border-bottom: 2px solid ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.black};
  }
`

const AddButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  margin-left: auto;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`

const StyledButton = styled(BaseButton)<{ $clickable?: boolean }>`
  background-color: ${({ theme }) => theme.colors.lightBlue};
  color: ${({ theme }) => theme.colors.black};
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightBlue};
    color: ${({ theme }) => theme.colors.black};
    cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};
  }
`

const TextWrapper = styled(Box)`
  display: grid;
  place-items: center;
  background-color: ${({ theme }) => theme.colors.greys[6]};
  padding: 1px 5px;
`

type Props = {
  action: FormAction
  transferRecord?: TableProps
  isMobileView?: boolean
  handleUnlink?: (unlinkAssetId: string) => void
  handleLink?: (
    parentId: string,
    asset: AssetResponsePayload,
    linkedQuantity: number,
  ) => void
  displayParentLinks: PostAssetLinksPayload[]
  displayChildLinks: AssetResponsePayload['childAssetLinks']
  cantLinkAssetIds: string[]
}

type AssetDetailsSectionTab = {
  name: string
  icon: SVGComponent
  element: FieldProps[]
  key: number
}
export enum AssetAction {
  batch = 'batch',
  link = 'link',
  unlink = 'unlink',
  viewImage = 'viewImage',
}

export const AssetDetailsSection = memo<Props>(
  ({
    action,
    transferRecord,
    isMobileView,
    handleLink,
    handleUnlink,
    displayParentLinks,
    displayChildLinks,
    cantLinkAssetIds,
  }) => {
    const [assetAction, setAssetAction] = useState<AssetAction | undefined>()
    const [unlinkAssetId, setUnlinkAssetId] = useState<string | undefined>()
    const { id } = useParams()
    const currentAssetId = id ?? ''
    const { handleSubmit, values, setFieldTouched, errors } =
      useFormikContext<AssetFormSchemaModel>()
    const { showSnackbar } = useOverlay()
    const { checkPermittedUnitId } = useCurrentUserUnits()

    const {
      unitList: rawUnitList,
      userList,
      assetTypeList,
      fundingsList,
      isLoading,
    } = useList()
    const unitList = rawUnitList.filter((u) => checkPermittedUnitId(u.id))

    const { t } = useTranslation()
    const theme = useTheme()
    const tabs = useMemo<AssetDetailsSectionTab[]>(
      () => [
        {
          name: t('assetDetailsPage.tabs.basicInfo'),
          icon: GuildelineIcon,
          element: BasicInfoFormFields,
          key: 1,
        },
        {
          name: t('assetDetailsPage.tabs.purchaseRecord'),
          icon: PurchaseIcon,
          element: PurchaseRecordFormFields,
          key: 2,
        },
        {
          name: t('assetDetailsPage.tabs.transferRecord'),
          icon: LocationIcon,
          element: TransferRecordFormFields,
          key: 3,
        },
      ],
      [t],
    )
    const [currentTab, setCurrentTab] = useState<AssetDetailsSectionTab>(
      tabs[0],
    )
    const tabNumber = action == FormAction.create ? 2 : 3

    const currentTabForm = useFormFields({
      fields: currentTab.element,
      action: action,
      unitList: unitList,
      userList: userList,
      assetTypeList: assetTypeList,
      fundingsList: fundingsList,
      transferRecord: transferRecord,
    })

    const imageReadOnly = useMemo(() => {
      return action === FormAction.view
    }, [action])

    const isBatchInput = useMemo(
      () => (values as CreateAssetFormSchemaModel)?.batchInput === true,
      [values],
    )

    const reimbursementStatus = useMemo(() => {
      if (values.status === AssetStatus.reimburseApproved)
        return t('assetDetailsPage.assetStatus.reimbursed')
      return t('assetDetailsPage.assetStatus.notReimbursed')
    }, [values.status, t])

    const handleLinkButtonOnClick = useCallback(
      () => handleLink !== undefined && setAssetAction(AssetAction.link),
      [setAssetAction, handleLink],
    )

    const { formikErrorExtractor, setAllFieldsTouched } = useFormikErrorHelper()

    const handleAddButtonOnClick = useCallback(() => {
      const errorMessages = formikErrorExtractor(errors, 5, ['assetBulk'])
      setAllFieldsTouched(values, setFieldTouched, ['assetBulk'])
      if (errorMessages) {
        showSnackbar({ content: errorMessages, severity: 'error' })
        return
      }
      if (isBatchInput) {
        setAssetAction(AssetAction.batch)
        return
      }
      handleSubmit()
    }, [
      isBatchInput,
      handleSubmit,
      showSnackbar,
      setAssetAction,
      errors,
      setAllFieldsTouched,
      formikErrorExtractor,
      setFieldTouched,
      values,
    ])

    const { handleUpload } = useBlobStorage()
    const {
      viewNextResource,
      viewPreviousResource,
      viewResourceByOrder,
      viewingResource,
      totalNumberOfResources,
      indexOfViewingResource,
    } = useResourceView({ resources: values.editResources ?? [] })
    const totalPieces = useMemo(
      () => Math.max(values.totalPieces ?? 0, 0),
      [values.totalPieces],
    )
    const PopUpModals = useMemo(
      () => ({
        batch: (
          <BatchAssetModal
            onClose={() => {
              setAssetAction(undefined)
            }}
            numberOfBulkAsset={totalPieces}
          />
        ),
        link: (
          <LinkAssetModal
            onClose={() => setAssetAction(undefined)}
            onSubmit={(asset, linkedQuantity) => {
              if (handleLink !== undefined) {
                handleLink(currentAssetId, asset, linkedQuantity)
              }
            }}
            cantLinkAssetIds={
              currentAssetId
                ? [...cantLinkAssetIds, currentAssetId]
                : cantLinkAssetIds
            }
          />
        ),
        unlink: (
          <UnlinkAssetModel
            onSubmit={() => {
              setAssetAction(undefined)
              if (unlinkAssetId && handleUnlink) {
                handleUnlink(unlinkAssetId)
              }
            }}
            onClose={() => {
              setAssetAction(undefined)
            }}
          />
        ),
        viewImage: (
          <ViewImageModal
            onClose={() => {
              setAssetAction(undefined)
            }}
            resource={viewingResource}
            nextButtonOnClick={viewNextResource}
            backButtonOnClick={viewPreviousResource}
            totalNumberOfResources={totalNumberOfResources}
            indexOfViewingResource={indexOfViewingResource}
          />
        ),
      }),
      [
        currentAssetId,
        unlinkAssetId,
        totalPieces,
        handleLink,
        handleUnlink,
        viewingResource,
        viewNextResource,
        viewPreviousResource,
        totalNumberOfResources,
        indexOfViewingResource,
        cantLinkAssetIds,
      ],
    )

    const assetImages = useMemo(
      () => (
        <ImageWrapper>
          <BigImage $isMobileView={isMobileView}>
            <ImageUploader
              name="image0"
              readonly={imageReadOnly}
              order={0}
              handleUpload={handleUpload}
              creating={action === FormAction.create}
              currentAssetId={currentAssetId}
              readonlyOnClick={() => {
                viewResourceByOrder(0)
                setAssetAction(AssetAction.viewImage)
              }}
            />
          </BigImage>
          <SmallImageWrapper $isMobileView={isMobileView}>
            {[1, 2, 3].map((order) => (
              <SmallImage key={order}>
                <ImageUploader
                  name={`image${order}`}
                  readonly={imageReadOnly}
                  order={order}
                  handleUpload={handleUpload}
                  creating={action === FormAction.create}
                  currentAssetId={currentAssetId}
                  readonlyOnClick={() => {
                    viewResourceByOrder(order)
                    setAssetAction(AssetAction.viewImage)
                  }}
                />
              </SmallImage>
            ))}
          </SmallImageWrapper>
        </ImageWrapper>
      ),
      [
        isMobileView,
        action,
        currentAssetId,
        handleUpload,
        imageReadOnly,
        viewResourceByOrder,
      ],
    )

    return (
      <Wrapper $isMobileView={isMobileView}>
        {isMobileView && assetImages}
        <LeftWrapper $isMobileView={isMobileView}>
          <ButtonWrapper>
            {tabs.slice(0, tabNumber).map((tab) => (
              <TabButton
                key={tab.key}
                text={tab.name}
                icon={tab.icon}
                onClick={() => {
                  setCurrentTab(tab)
                }}
                $selected={currentTab.key === tab.key}
              />
            ))}
          </ButtonWrapper>
          {isLoading ? <Spinner /> : currentTabForm}
        </LeftWrapper>

        {!isMobileView && (
          <RightWrapper>
            {assetImages}
            {action !== FormAction.create && (
              <RowWrapper>
                <StyledButton
                  text={t('assetDetailsPage.buttons.reimbursementStatus')}
                  paddingHorizontal={40}
                  paddingVertical={5}
                  icon={WriteOffIcon}
                />
                <TextWrapper>{reimbursementStatus}</TextWrapper>
              </RowWrapper>
            )}
            {action !== FormAction.create && (
              <>
                <RowWrapper>
                  <StyledButton
                    $clickable={handleLink !== undefined}
                    text={t('assetDetailsPage.buttons.linkAsset')}
                    paddingHorizontal={40}
                    paddingVertical={5}
                    icon={ChainIcon}
                    onClick={handleLinkButtonOnClick}
                  />
                  <TextWrapper>
                    {displayParentLinks.length + displayChildLinks.length}
                  </TextWrapper>
                  {t('assetDetailsPage.measurementUnit.asset')}
                </RowWrapper>

                <AssetLinksTable
                  canUnlink={action !== FormAction.view}
                  childLinks={displayChildLinks}
                  parentLinks={displayParentLinks}
                  onUnLink={(assetId) => {
                    setUnlinkAssetId(assetId)
                    setAssetAction(AssetAction.unlink)
                  }}
                />
              </>
            )}

            <RowWrapper>
              {action === FormAction.create && (
                <AddButton
                  text={
                    isBatchInput
                      ? t('assetDetailsPage.buttons.addMultiple', {
                          count: totalPieces,
                        })
                      : t('assetDetailsPage.buttons.add')
                  }
                  paddingHorizontal={60}
                  paddingVertical={15}
                  icon={SubmitIcon}
                  iconStrokeColor={theme.colors.white}
                  onClick={handleAddButtonOnClick}
                />
              )}
            </RowWrapper>
          </RightWrapper>
        )}

        {assetAction && PopUpModals[assetAction]}
      </Wrapper>
    )
  },
)

AssetDetailsSection.displayName = 'AssetDetailsSection'
