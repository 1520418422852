import { OverlayDialogOptions, useOverlay } from '@pasteltech/overlay-provider'
import {
  ApiError,
  ErrorDetailPayloadItem,
  FormattedApiError,
  extractApiErrorDetail,
} from '@stewards-fas/apis'

import { useTranslation } from '@pasteltech/i18n-react'
import { uniq } from 'lodash'
import { useCallback, useMemo } from 'react'
import { checkTemporary } from '@stewards-fas/apis/src/utils'

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  if (value === null || value === undefined) return false
  return true
}

type DialogOption = OverlayDialogOptions<null | 'retry' | string>

type ErrorHandle = {
  title?: string
  content: string
  canRetry?: boolean
  onDismiss?(): void
  onRetry?(): void
}

type Options = {
  defaultTitle?: string
  disallowRetry?: boolean
  retryActions?: DialogOption['actions']
  onUnFormattedErrorActions?: DialogOption['actions']
  errorMappings?: {
    [errorCode: string]:
      | ErrorHandle
      | ((error: ErrorDetailPayloadItem) => ErrorHandle)
  }
  errorFormatter?: {
    formatted?: (
      apiError: FormattedApiError,
      defaultOptions: Pick<DialogOption, 'title' | 'content'>,
      opts?: Options,
    ) => DialogOption & { onDismiss?(): void }
    unformatted?: (
      apiError: ApiError,
      defaultOptions: Pick<DialogOption, 'title' | 'content'>,
      opts?: Options,
    ) => DialogOption & { onDismiss?(): void }
  }
}

export type StandardErrorMapper = Omit<Options, 'disallowRetry'> & {
  onRetry?: () => void
  onDismiss?: () => void
  onExtraActions?: (action: string) => void
  retryActions?: DialogOption['actions']
  onUnFormattedErrorActions?: DialogOption['actions']
}

export function useApiErrorHandle() {
  const { t } = useTranslation()
  const { showDialog } = useOverlay()

  const retryActions = useMemo(
    (): DialogOption['actions'] => [
      { text: t('common.cancel'), value: null, type: 'outlined' },
      { text: t('error.action.retry'), value: 'retry' },
    ],
    [t],
  )

  const defaultActions = useMemo(
    (): DialogOption['actions'] => [
      {
        text: t('common.ok'),
        value: null,
        type: 'contained',
        color: 'secondary',
      },
    ],
    [t],
  )

  const defaultErrorMappings = useMemo((): { [K: string]: ErrorHandle } => {
    return {
      E1021: {
        content: t('error.conflict.content'),
      },
    }
  }, [t])

  const getFormattedError = useCallback(extractApiErrorDetail, [])

  const getFormattedErrorDetailMessage = useCallback(
    (apiError: FormattedApiError) => {
      const errorCodes = apiError.errors.map((it) => it.code)
      return t('error.errorDetail', {
        codes: errorCodes.length === 0 ? '---' : errorCodes.join(', '),
        traceId: apiError.traceId,
      })
    },
    [t],
  )

  const getFormattedErrorDialogOptions = useCallback(
    (
      apiError: FormattedApiError,
      defaultOptions: Pick<DialogOption, 'title' | 'content'>,
      opts?: Options,
    ) => {
      const mappedError = apiError.errors
        .map((err) => {
          const handle = opts?.errorMappings?.[err.code]
          if (typeof handle === 'function') {
            return handle(err)
          }
          return handle ?? defaultErrorMappings[err.code]
        })
        .filter(notEmpty)

      const canRetry =
        !opts?.disallowRetry && !mappedError.some((it) => !it?.canRetry)

      const mappedTitles = uniq(
        mappedError.map((it) => it?.title).filter(notEmpty),
      )

      const title =
        (mappedTitles.length === 1
          ? mappedTitles[0]
          : opts?.defaultTitle ?? mappedTitles[0]) ?? defaultOptions.title

      const content =
        mappedError.length > 0
          ? mappedError.map((it) => it.content).join('\n')
          : defaultOptions.content

      const onDismissHandlers = mappedError
        .map((it) => it.onDismiss)
        .filter(notEmpty)

      return {
        title: title,
        content: `${content} ${
          canRetry ? t('error.retryContent') : ''
        }\n\n${getFormattedErrorDetailMessage(apiError)}`.trim(),
        actions: canRetry ? retryActions : defaultActions,
        onDismiss:
          onDismissHandlers.length > 0
            ? () => {
                onDismissHandlers.forEach((it) => it())
              }
            : undefined,
      }
    },
    [
      defaultActions,
      defaultErrorMappings,
      getFormattedErrorDetailMessage,
      retryActions,
      t,
    ],
  )

  const getUnformattedErrorDialogOptions = useCallback(
    (
      apiError: ApiError,
      defaultOptions: Pick<DialogOption, 'title' | 'content'>,
      opts?: Options,
    ) => {
      return {
        title: defaultOptions.title,
        content: defaultOptions.content,
        actions: opts?.onUnFormattedErrorActions ?? defaultActions,
      }
    },
    [defaultActions],
  )

  const getErrorDialogOption = useCallback(
    (error: any, opts?: Options): DialogOption & { onDismiss?(): void } => {
      const apiError = getFormattedError(error)

      if (apiError == null) {
        return {
          title: t('error.default.title'),
          content: t('error.default.content', {
            error,
          }),
          actions: [
            {
              text: t('common.ok'),
              value: null,
              type: 'contained',
              color: 'secondary',
            },
          ],
        }
      }

      const isTemporary = checkTemporary(apiError.kind)

      const apiCanRetry = !opts?.disallowRetry && isTemporary

      switch (apiError.kind) {
        case 'timeout':
          return {
            title: t('error.timeout.title'),
            content: `${t('error.timeout.content')} ${
              apiCanRetry ? t('error.retryContent') : ''
            }`,
            actions: apiCanRetry
              ? opts?.retryActions ?? retryActions
              : defaultActions,
          }

        case 'cannot-connect':
          return {
            title: t('error.cannotConnect.title'),
            content: `${t('error.cannotConnect.content')} ${
              apiCanRetry ? t('error.retryContent') : ''
            }`,
            actions: apiCanRetry
              ? opts?.retryActions ?? retryActions
              : defaultActions,
          }
      }

      let defaultOptions: { title: string; content: string }

      switch (apiError.kind) {
        case 'forbidden':
          defaultOptions = {
            title: opts?.defaultTitle ?? t('error.forbidden.title'),
            content: t('error.forbidden.content'),
          }
          break
        case 'unauthorized':
          defaultOptions = {
            title: opts?.defaultTitle ?? t('error.unauthorized.title'),
            content: t('error.unauthorized.content'),
          }
          break
        case 'not-found':
          defaultOptions = {
            title: opts?.defaultTitle ?? t('error.notFound.title'),
            content: t('error.notFound.content'),
          }
          break
        case 'server':
          defaultOptions = {
            title: opts?.defaultTitle ?? t('error.serverError.title'),
            content: t('error.serverError.content', {
              error,
            }),
          }
          break
        case 'bad-request':
          defaultOptions = {
            title: opts?.defaultTitle ?? t('error.badRequest.title'),
            content: t('error.badRequest.content', {
              error,
            }),
          }
          break

        default:
          defaultOptions = {
            title: opts?.defaultTitle ?? t('error.default.title'),
            content: t('error.default.content'),
          }
      }

      if (apiError.formatted) {
        return (
          opts?.errorFormatter?.formatted ?? getFormattedErrorDialogOptions
        )(apiError, defaultOptions, opts)
      }
      return (
        opts?.errorFormatter?.unformatted ?? getUnformattedErrorDialogOptions
      )(apiError, defaultOptions, opts)
    },
    [
      defaultActions,
      getFormattedError,
      getFormattedErrorDialogOptions,
      getUnformattedErrorDialogOptions,
      retryActions,
      t,
    ],
  )

  const showErrorDialog = useCallback(
    async (error: any, opts?: Options) => {
      const dialogOptions = getErrorDialogOption(error, opts)
      const ret = await showDialog(dialogOptions)
      dialogOptions.onDismiss?.()
      return ret
    },
    [getErrorDialogOption, showDialog],
  )

  const standardErrorHandler = useCallback(
    async (error: any, opts?: StandardErrorMapper) => {
      const ret = await showErrorDialog(error, {
        ...opts,
        disallowRetry: opts?.onRetry == null,
      })

      if (ret == 'retry') {
        opts?.onRetry?.()
      } else if (ret != null && opts?.onExtraActions != null) {
        opts?.onExtraActions(ret)
      } else {
        opts?.onDismiss?.()
      }
    },
    [showErrorDialog],
  )

  return {
    retryActions,
    defaultActions,
    getFormattedError,
    getFormattedErrorDetailMessage,
    getErrorDialogOption,
    showErrorDialog,
    standardErrorHandler,
  }
}
