import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
} from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { ReactNode, memo, useMemo } from 'react'
import styled from 'styled-components'
import { ApprovedIcon, CloseIcon } from '../icons'

export type MuiColorProps =
  | 'error'
  | 'warning'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'inherit'
  | 'info'
  | 'blue'
  | 'brown'
  | 'lightRed'
  | 'sharpRed'
  | 'cascade'
  | 'darkGrey'
  | 'darkestGrey'
  | undefined

const Container = styled.div`
  padding: 40px;
`

const TitleContainer = styled.div`
  padding: 4px 8px;
  border-left: 4px solid ${({ theme }) => theme.colors.secondary};
`

const Title = styled(DialogTitle)`
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  padding: 0px;
  color: ${({ theme }) => theme.colors.secondary};
`

const StyledDialogContent = styled(DialogContent)`
  padding: 20px 0px;
`

const StyledDialogActions = styled(DialogActions)`
  padding: 0px;
  margin-top: 20px;
`

const StyledDialogContentText = styled(DialogContentText)`
  white-space: pre-line;
`

const StyledButton = styled(Button)`
  width: 160px;
  font-weight: 600;
`

export type DialogAction<T> = {
  text?: string
  type?: 'text' | 'outlined' | 'contained'
  value: T
  disabled?: boolean
  color?: MuiColorProps
  icon?: React.ReactNode
}

export type DialogProps<T = string | boolean> = Omit<MuiDialogProps, 'open'> & {
  title?: string
  content?: ReactNode
  children?: ReactNode
  width?: number
  actions?: DialogAction<T>[]
  text?: string
  visible?: boolean
} & (
    | {
        dismissable?: undefined
        onSelected?(action: T): void
      }
    | {
        dismissable: true
        onSelected?(action: T | undefined): void
      }
  )

export const Dialog = memo<DialogProps>(
  ({
    title,
    content,
    width,
    onSelected,
    dismissable,
    actions,
    children,
    visible,
    ...props
  }) => {
    const { t } = useTranslation()

    const dialogButtons = useMemo((): NonNullable<DialogProps['actions']> => {
      if (actions != null) {
        return actions
      }

      return [
        {
          text: t('common.cancel'),
          type: 'outlined',
          value: false,
          icon: <CloseIcon />,
        },
        {
          text: t('common.confirm'),
          color: 'secondary',
          value: true,
          type: 'contained',
          icon: <ApprovedIcon />,
        },
      ]
    }, [actions, t])

    return (
      <MuiDialog
        fullWidth
        maxWidth="md"
        disableEscapeKeyDown={!dismissable}
        {...props}
        onClose={() => {
          if (dismissable) {
            onSelected?.(undefined)
          }
        }}
        open={visible ?? false}
      >
        <Container>
          <TitleContainer>
            <Title>{title}</Title>
          </TitleContainer>
          <StyledDialogContent>
            <StyledDialogContentText>{content}</StyledDialogContentText>
            {children != null && children}
          </StyledDialogContent>
          <StyledDialogActions>
            {dialogButtons.map((it) => {
              return (
                <StyledButton
                  key={it.text}
                  variant={it.type}
                  onClick={() => onSelected?.(it.value)}
                  disabled={it.disabled}
                  startIcon={it.icon}
                >
                  {it.text}
                </StyledButton>
              )
            })}
          </StyledDialogActions>
        </Container>
      </MuiDialog>
    )
  },
)

Dialog.displayName = 'Dialog'

declare module '@pasteltech/overlay-provider' {
  interface OverlayDialogAction<T> extends DialogAction<T> {}
}
