import { AssetResponsePayload } from '@stewards-fas/schemas'
import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { Dialog, GenericColumns, GenericTable } from '../../../../components'
import { Box } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import dayjs from 'dayjs'
import { formats } from '../../../../theme'
import { useGetBlobDownloadUrlForResourcesList } from '@stewards-fas/queries'
import { toFirstCharLowerCase } from '../../../../utilities'
type Props = {
  title?: string
  visible: boolean
  onClose: () => void
  assetData: AssetResponsePayload
}
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`
const StyledTable = styled(GenericTable)`
  & .MuiTableCell-root {
    font-size: 15px;
  }
  & .Mui-selected * {
    color: ${({ theme }) => theme.colors.chestnut};
    background-color: ${({ theme }) => theme.colors.white};
  }
`
const EmptyMessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
`

const ChangeImg = styled.img`
  max-height: 50vh;
  max-width: 100%;
`

type AssetRecordType =
  | AssetResponsePayload
  | AssetResponsePayload['assetHistories'][0]

export const ViewAssetChangesModal = memo<Props>(
  ({ title, visible, onClose, assetData }) => {
    const { t } = useTranslation()
    const mapping = useMemo(() => {
      const emptyPlaceHolder = '- - -'
      return [
        {
          label: t('assetDetailsPage.form.fields.labels.assetNo'),
          display: (asset) => asset.assetNo,
        },
        {
          label: t('assetDetailsPage.form.fields.labels.name'),
          display: (asset) => asset.name,
        },
        {
          label: t('assetDetailsPage.form.fields.labels.brand'),
          display: (asset) => asset.brand,
        },
        {
          label: t('assetDetailsPage.form.fields.labels.model'),
          display: (asset) => asset.model,
        },
        {
          label: t('assetDetailsPage.form.fields.labels.quantity'),
          display: (asset) => asset.quantity,
        },
        {
          label: t('assetDetailsPage.form.fields.labels.serialNo'),
          display: (asset) => asset.serialNo,
        },
        {
          label: t('assetDetailsPage.form.fields.labels.valuedAmount'),
          display: (asset) => asset.valuedAmount,
        },
        {
          label: t('assetDetailsPage.form.fields.labels.atUnitNotes'),
          display: (asset) => asset.atUnitNotes ?? emptyPlaceHolder,
        },
        {
          label: t('assetDetailsPage.form.fields.labels.remarks'),
          display: (asset) => asset.remarks ?? emptyPlaceHolder,
        },
        {
          label: t('assetDetailsPage.form.fields.labels.supervisor'),
          display: (asset) =>
            asset.unit?.supervisorUser?.username ?? emptyPlaceHolder,
        },
        {
          label: t('assetDetailsPage.form.fields.labels.formFiller'),
          display: (asset) => asset.formFillUser?.username ?? emptyPlaceHolder,
        },
        {
          label: t('assetDetailsPage.form.fields.labels.purchasedDateTime'),
          display: (asset) =>
            dayjs(asset.purchasedDateTime).format(formats.date),
        },
        {
          label: t('assetDetailsPage.form.fields.labels.providerName'),
          display: (asset) => asset.providerName ?? emptyPlaceHolder,
        },
        {
          label: t('assetDetailsPage.form.fields.labels.invoiceNo'),
          display: (asset) => asset.invoiceNo ?? emptyPlaceHolder,
        },
        {
          label: t('assetDetailsPage.form.fields.labels.warrantyProvider'),
          display: (asset) => asset.warrantyProvider,
        },
        {
          label: t('assetDetailsPage.form.fields.labels.warrantyPeriod'),
          display: (asset) => asset.warrantyPeriod,
        },
      ] as { label: string; display: (a: AssetRecordType) => string }[]
    }, [t])

    const latestHistories = useMemo(
      () =>
        assetData.assetHistories.reduce(
          (acc: AssetResponsePayload['assetHistories'][0] | undefined, cur) => {
            if (acc === undefined) return cur
            return dayjs(cur.lastModifiedDateTime).isAfter(
              acc.lastModifiedDateTime,
            )
              ? cur
              : acc
          },
          undefined,
        ),
      [assetData],
    )
    const latestHistoriesResources: AssetResponsePayload['resources'] =
      useMemo(() => {
        if (!latestHistories?.resources) return []
        const resourceObject = JSON.parse(latestHistories.resources)
        const mapApiRawToObj = resourceObject.map((upperCaseResource: any) => {
          return Object.entries(upperCaseResource).reduce(
            (acc, [key, value]) => {
              return { ...acc, [toFirstCharLowerCase(key)]: value }
            },
            {},
          )
        })
        return mapApiRawToObj
      }, [latestHistories])

    const { data: currentAssetValidResources } =
      useGetBlobDownloadUrlForResourcesList(assetData?.resources ?? [], {
        enabled: assetData != null,
        refetchOnWindowFocus: false,
      })

    const { data: oldAssetValidResources } =
      useGetBlobDownloadUrlForResourcesList(latestHistoriesResources ?? [], {
        enabled: latestHistoriesResources != null,
        refetchOnWindowFocus: false,
      })

    const displayData = useMemo(() => {
      if (latestHistories) {
        const textFields = mapping.map((field) => ({
          label: field.label,
          old: field.display(latestHistories),
          new: field.display(assetData),
          selected: field.display(latestHistories) !== field.display(assetData),
        }))
        const resourcesFields = [1, 2, 3, 4].map((num) => {
          let oldAssetResourcesUrl: string | undefined = undefined
          let newAssetResourcesUrl: string | undefined = undefined
          if (oldAssetValidResources && num <= oldAssetValidResources?.length) {
            oldAssetResourcesUrl = oldAssetValidResources[num - 1].url
          }
          if (
            currentAssetValidResources &&
            num <= currentAssetValidResources?.length
          ) {
            newAssetResourcesUrl = currentAssetValidResources[num - 1].url
          }
          return {
            label: t('assetViewPage.viewChangeModal.photo', { num: num }),
            old: () => {
              if (!oldAssetResourcesUrl) return <></>
              return <ChangeImg src={oldAssetResourcesUrl} />
            },
            new: () => {
              if (!newAssetResourcesUrl) return <></>
              return <ChangeImg src={newAssetResourcesUrl} />
            },
          }
        })
        return [...textFields, ...resourcesFields]
      }
      return []
    }, [
      latestHistories,
      assetData,
      mapping,
      currentAssetValidResources,
      oldAssetValidResources,
      t,
    ])
    const displayColumns: GenericColumns<(typeof displayData)[0]> = useMemo(
      () => ({
        label: '',
        old: t('assetViewPage.viewChangeModal.beforeUpdate'),
        new: t('assetViewPage.viewChangeModal.afterUpdate'),
      }),
      [t],
    )
    return (
      <Dialog
        scroll="body"
        dismissable
        title={title}
        visible={visible}
        actions={[]}
        onSelected={onClose}
      >
        <Wrapper>
          {latestHistories ? (
            <StyledTable
              rowData={displayData}
              tableColumns={displayColumns}
              rowIdKey="label"
            />
          ) : (
            <EmptyMessageWrapper>
              {t('assetViewPage.errorMessage.noAssetHistory')}
            </EmptyMessageWrapper>
          )}
        </Wrapper>
      </Dialog>
    )
  },
)
ViewAssetChangesModal.displayName = 'ViewAssetChangesModal'
