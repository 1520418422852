import { memo, useRef, useMemo, useEffect, useCallback, useState } from 'react'
import styled from 'styled-components'
import {
  Html5QrcodeScanner,
  Html5QrcodeScanType,
  Html5QrcodeSupportedFormats,
} from 'html5-qrcode'
import { useTranslation } from '@pasteltech/i18n-react'
import { useOverlay } from '@pasteltech/overlay-provider'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
  width: 100%;
`

const ContentContainer = styled.div`
  flex-grow: 1;
  margin: -10px;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.colors.white};
`

const Scanner = styled.div`
  width: 100%;
  height: 100%;
`

const Footer = styled.div`
  margin-top: auto;
  text-align: center;
`

export const SearchAssetsQRPage = memo(() => {
  const { t } = useTranslation()
  const { showSnackbar } = useOverlay()

  const scannerRef = useRef(null)
  const [isScannerInit, setIsScannerInit] = useState(false)

  const onScanSuccess = useCallback(
    (decodedText: string) => {
      showSnackbar({
        content: t('qrScanner.redirectedTo', { message: decodedText }),
      })
      setTimeout(() => {
        window.location.replace(decodedText)
      }, 3000)
    },
    [showSnackbar, t],
  )

  const onScanFailure = useCallback(() => {}, [])

  const scanner = useMemo(() => {
    if (!scannerRef?.current) {
      return null
    }

    const html5QrcodeScanner = new Html5QrcodeScanner(
      'reader',
      {
        fps: 10,
        qrbox: { width: 250, height: 250 },
        supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
        formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
      },
      false,
    )

    return html5QrcodeScanner
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannerRef.current]) // To detect elm exist

  useEffect(() => {
    if (scanner && !isScannerInit) {
      scanner.render(onScanSuccess, onScanFailure)
      setIsScannerInit(true)
    }
  }, [scanner, onScanSuccess, onScanFailure, isScannerInit, setIsScannerInit])

  return (
    <Root>
      <ContentContainer>
        <Scanner id="reader" ref={scannerRef} />
      </ContentContainer>
      <Footer>
        {t('common.stewards')}
        {t('appbar.header')}
      </Footer>
    </Root>
  )
})

SearchAssetsQRPage.displayName = 'SearchAssetsQRPage'
