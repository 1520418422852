import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material'
import { memo, ReactNode } from 'react'
import { BaseFormLabel } from './base-form-label'

export type RadioGroupOption = {
  value: string
  label: ReactNode
}

type Props = RadioGroupProps & {
  label?: string
  options: RadioGroupOption[]
}

export const FormRadioGroupField = memo<Props>(
  ({ label, options, ...rest }) => {
    return (
      <FormControl>
        {label != null && <BaseFormLabel>{label}</BaseFormLabel>}
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <RadioGroup {...rest}>
          {options.map((it) => (
            <FormControlLabel
              key={it.value}
              value={it.value}
              control={<Radio />}
              label={it.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    )
  },
)

FormRadioGroupField.displayName = 'FormRadioGroupField'
