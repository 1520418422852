import { useTranslation } from '@pasteltech/i18n-react'
import dayjs from 'dayjs'
import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { StewardsHeaderLogoLarge } from '../../../../icons'

import {
  useQueryAssetRequest,
  useQueryUnit,
  useQueryUser,
  useQueryUserProfile,
} from '@stewards-fas/queries'
import { AssetRequestStatus } from '@stewards-fas/schemas'
import { SignatureBox } from '../../../../components/transfer-preview-form/signature-box'
import { formats } from '../../../../theme'
import { compareString } from '../../../../utilities'
import { useWriteOffContext } from '../context'
import { RecordListTable } from './record-list-table'

const RecordListContainer = styled.div`
  padding: 60px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  @media print {
    zoom: 0.666;
  }
`

const RecordListHeader = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 32px;
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
`
const Logo = styled(StewardsHeaderLogoLarge)`
  width: 90px;
  height: 90px;
  filter: blur(0);
`
const Title = styled.div`
  flex-grow: 1;
`

const RecordMeta = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.black};
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  row-gap: 8px;
`

type ReimburseFormPropType = {}

/**
 * A printable form to request write-off.
 * */
export const WriteOffForm = memo<ReimburseFormPropType>(() => {
  // context
  const { t } = useTranslation()
  const { writeOffList, writeOffDate, viewFormId } = useWriteOffContext()

  // data queries
  const isPreview = useMemo(() => {
    return !viewFormId?.length
  }, [viewFormId?.length])

  const { data: FormData, isLoading: isFormLoading } = useQueryAssetRequest(
    viewFormId,
    {
      expand: [
        'AssetRequestAssets',
        'AssetRequestAssets.Asset',
        'AssetRequestAssets.Asset.Unit',
      ],
    },
  )

  const { data: currentUserData } = useQueryUserProfile({
    expand: ['UserUnits', 'UserUnits.Unit'],
  })

  const applicantId = useMemo(() => {
    return isPreview ? currentUserData?.id : FormData?.createdByUserId
  }, [FormData?.createdByUserId, currentUserData?.id, isPreview])

  const { data: FormUserData } = useQueryUser(
    applicantId ?? '',
    {
      expand: ['UserUnits', 'UserUnits.Unit'],
    },
    { enabled: isPreview || !isFormLoading },
  )

  const userData = useMemo(() => {
    return isPreview ? currentUserData : FormUserData
  }, [FormUserData, currentUserData, isPreview])

  const formUnitId = useMemo(() => {
    return isPreview
      ? writeOffList[0].unitId
      : FormData?.assetRequestAssets[0].asset.unitId
  }, [isPreview, writeOffList, FormData?.assetRequestAssets])

  const { data: unitData } = useQueryUnit(
    formUnitId ?? '',
    { expand: ['SupervisorUser'] },
    { enabled: formUnitId != null },
  )
  const superVisorName = unitData?.supervisorUser?.username

  const formNo = useMemo(() => {
    return isPreview ? `${unitData?.code}-FAD-_ _ _ _` : FormData?.reimburseNo
  }, [FormData?.reimburseNo, unitData?.code, isPreview])

  const assetList = useMemo(() => {
    return isPreview
      ? writeOffList
      : FormData?.assetRequestAssets.map(({ asset }) => asset) ?? []
  }, [FormData?.assetRequestAssets, isPreview, writeOffList])

  const displayList = useMemo(() => {
    const sortedList = assetList.sort((a, b) =>
      compareString(a.assetNo, b.assetNo),
    )
    return sortedList
  }, [assetList])

  const approvalDate = useMemo(() => {
    if (isPreview || FormData?.reimburseStatus != AssetRequestStatus.approved) {
      return ''
    }
    const reimburseApprovalDate = FormData?.lastModifiedDateTime
    const formattedApprovalDate =
      reimburseApprovalDate && dayjs(reimburseApprovalDate).format(formats.date)
    return formattedApprovalDate
  }, [FormData, isPreview])

  const submissionDate = useMemo(() => {
    const source = isPreview ? writeOffDate : FormData?.submissionDateTime
    const day = dayjs(source)
    return day.isValid() ? day.format(formats.date) : ''
  }, [FormData?.submissionDateTime, isPreview, writeOffDate])

  return (
    <>
      <RecordListContainer>
        <RecordListHeader>
          <Logo />
          <Title>
            {t('assetManagement.writeOffSection.previewForm.titleLine1')}
            <br />
            {t('assetManagement.writeOffSection.previewForm.titleLine2')}
          </Title>
        </RecordListHeader>
        <RecordMeta>
          <div>
            {t('assetManagement.writeOffSection.previewForm.unit')}：
            {unitData?.nameCN}
          </div>
          <div>
            {t('assetManagement.writeOffSection.writeOffCode')}：{formNo}
          </div>
        </RecordMeta>
        <RecordListTable rowData={displayList} />
        <SignatureBox
          formFillerName={userData?.username}
          supervisorName={superVisorName}
          formFillDate={submissionDate}
          approvalDate={approvalDate}
        />
      </RecordListContainer>
    </>
  )
})
WriteOffForm.displayName = 'WriteOffForm'
