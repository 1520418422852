import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
type SimpleObject = Record<string, any>

export type GenericColumns<T extends SimpleObject> = Partial<{
  [key in keyof T]: string
}>

type GenericProps<T extends SimpleObject> = {
  rowData: (T & { selected?: boolean })[]
  rowIdKey: keyof T
  tableColumns: GenericColumns<T>
  className?: string
}

/**
 * A Generic Table to display Data[].
 * @param tableColumns: an Object of {key: Header}, ie each key:value pair is one column
 * Column Header is defined by Header, Column cell values is Data[key]
 * @param rowIdKey: Data[rowIdKey] must be unique. used as react node key.
 * @param className: add styled-component styles to the top level div
 * */
export const GenericTable = <T extends SimpleObject>({
  rowData,
  rowIdKey,
  tableColumns,
  className,
}: GenericProps<T>) => {
  return (
    <div className={className}>
      <TableContainer>
        <Table aria-label="preview write-off table">
          <TableHead>
            <TableRow>
              {Object.values(tableColumns).map((header) => (
                <TableCell key={`table-header-${header}`}>
                  {header as any}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.map((row) => (
              <TableRow
                selected={row.selected}
                key={`table-row-${row[rowIdKey]}`}
              >
                {Object.keys(tableColumns).map((column) => (
                  <TableCell key={`table-cell-${row[rowIdKey]}-${column}`}>
                    {typeof row[column as keyof T] === 'function'
                      ? row[column]()
                      : `${row[column as keyof T]}`}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
