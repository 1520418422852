import {
  useAssetRequestList,
  useGetAsset,
  useGetBlobDownloadUrlForResourcesList,
  useUnitList,
} from '@stewards-fas/queries'

import { useTranslation } from '@pasteltech/i18n-react'
import { memo, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Spinner } from '../../components'
import { mapAssetDataToFormik, mapTransferRecordToTable } from '../../models'
import { AssetViewPageAction, AssetViewPageContent } from './content'
import { AssetRequestStatus } from '@stewards-fas/schemas'

type AssetViewPageProps = {
  pageAction: AssetViewPageAction
}

export const AssetViewPage = memo<AssetViewPageProps>(({ pageAction }) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { data: assetTransferRequests } = useAssetRequestList({
    type: 'TRANSITION',
    expand: ['AssetRequestAssets.Asset', 'FromUnit', 'ToUnit'],
  })

  const transferRecord = assetTransferRequests?.data
    .filter((it) => it.assetRequestAssets[0].asset.id === id)
    .filter(
      (record) =>
        record.transitStatus != AssetRequestStatus.withdrew &&
        record.receiveStatus != AssetRequestStatus.withdrew,
    )

  const assetExpandKeys = useMemo(() => {
    const expandKeys = [
      'AssetType',
      'FormFillUser',
      'Unit',
      'ParentAssetLinks',
      'ParentAssetLinks.Child',
      'ChildAssetLinks',
      'ChildAssetLinks.Parent',
      'Resources',
      'AssetFundings',
      'AssetFundings.Funding',
    ]
    if (pageAction === AssetViewPageAction.approval)
      return [...expandKeys, 'AssetHistories']
    return expandKeys
  }, [pageAction])
  const { data: assetData, isLoading: assetIsLoading } = useGetAsset(id ?? '', {
    expand: assetExpandKeys,
  })
  const { data: units } = useUnitList()

  const {
    data: resourceWithValidUrl,
    isLoading: resourceWithValidUrlIsLoading,
  } = useGetBlobDownloadUrlForResourcesList(assetData?.resources ?? [], {
    enabled: assetData !== null,
  })

  const isLoading = useMemo(
    () => assetIsLoading || resourceWithValidUrlIsLoading,
    [assetIsLoading, resourceWithValidUrlIsLoading],
  )
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        assetData &&
        resourceWithValidUrl !== null && (
          <AssetViewPageContent
            formData={mapAssetDataToFormik({
              ...assetData,
              resources: resourceWithValidUrl ?? [],
            })}
            assetData={assetData}
            transferRecord={mapTransferRecordToTable(
              transferRecord ?? [],
              t(`transferRecordTable.pendingReceive`),
            )}
            units={units?.data}
            pageAction={pageAction}
          />
        )
      )}
    </>
  )
})

AssetViewPage.displayName = 'AssetViewPage'
