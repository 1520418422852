import { FormTextField } from '../../form-fields'
import { TransferRecordTable } from '../../transfer-record-table'
import { FieldProps, FormAction } from './tabs-form-fields'
export const TransferRecordFormFields: FieldProps[] = [
  {
    field: 'transferRecord',
    element: TransferRecordTable,
    actions: [FormAction.edit, FormAction.view],
  },
  {
    field: 'lastModifiedDateTime',
    props: {
      dir: 'vertical',
    },
    element: FormTextField,
    actions: [
      FormAction.create,
      FormAction.edit,
      FormAction.view,
      FormAction.approval,
    ],
    readOnlyActions: [FormAction.create, FormAction.edit, FormAction.approval],
  },
]
