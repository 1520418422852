import { MenuItem, Select, SelectProps } from '@mui/material'
import { memo } from 'react'
import styled from 'styled-components'
import { Option } from '../../hooks'
import { BaseFormDirection, BaseFormField } from './base-form-field'
import { FormHelperText } from './form-helper-text'

export type FormMultiSelectFieldProps = SelectProps & {
  options?: Option[]
  label?: string
  name?: string
  dir?: BaseFormDirection
  backgroundColor?: string
  templateColumns?: string
  onChange?: (val: string[]) => void
  value?: string[]
  error?: boolean
  helperText?: string
  required?: boolean
}

const StyledSelect = styled(Select)<{ $backgroundColor?: string }>`
  width: 100%;
  ${({ $backgroundColor }) => ({
    $backgroundColor,
  })}
`

export const FormMultiSelectField = memo<FormMultiSelectFieldProps>(
  ({
    label,
    options = [],
    name = '',
    dir,
    placeholder,
    backgroundColor,
    templateColumns,
    value,
    onChange,
    error = false,
    helperText = '',
    required = false,
    ...rest
  }) => {
    return (
      <BaseFormField
        label={label}
        dir={dir}
        templateColumns={templateColumns}
        required={required}
      >
        <StyledSelect
          // eslint-disable-next-line react/jsx-props-no-spreading
          multiple
          error={error}
          size="small"
          name={name}
          onChange={(e) => {
            onChange?.(e.target.value as string[])
          }}
          value={value ?? []}
          displayEmpty={true}
          $backgroundColor={backgroundColor}
          {...rest}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledSelect>
        <FormHelperText>{error && helperText}</FormHelperText>
      </BaseFormField>
    )
  },
)

FormMultiSelectField.displayName = 'FormMultiSelectField'
