import {
  AppManagementTabItem,
  AppManagementTabItemType,
} from '../../../components'
import {
  AddNewTabIcon,
  ApprovalStatusTabIcon,
  SearchTabIcon,
  TransferTabIcon,
  WriteOffTabIcon,
} from '../../../icons'
import { allPermittedRoles, Role } from '../../../models'
import { AssetManagementAddSection } from '../asset-management-add-section'
import { AssetManagementApprovalSection } from '../asset-management-approval-section'
import { AssetManagementSearchSection } from '../asset-management-search-section'
import { AssetManagementTransferSection } from '../asset-management-transfer-section'
import { AssetManagementWriteOffSection } from '../asset-management-write-off-section'

export const assetManagementTabItems: AppManagementTabItemType[] = [
  {
    value: AppManagementTabItem.search,
    titleKey: 'assetManagement.tab.search',
    icon: SearchTabIcon,
    content: AssetManagementSearchSection,
    allowedRoles: allPermittedRoles,
  },
  {
    value: AppManagementTabItem.add,
    titleKey: 'assetManagement.tab.add',
    icon: AddNewTabIcon,
    content: AssetManagementAddSection,
    allowedRoles: allPermittedRoles,
  },
  {
    value: AppManagementTabItem.writeOff,
    titleKey: 'assetManagement.tab.writeOff',
    icon: WriteOffTabIcon,
    content: AssetManagementWriteOffSection,
    allowedRoles: allPermittedRoles,
  },
  {
    value: AppManagementTabItem.transfer,
    titleKey: 'assetManagement.tab.transfer',
    icon: TransferTabIcon,
    content: AssetManagementTransferSection,
    allowedRoles: allPermittedRoles,
  },
  {
    value: AppManagementTabItem.approvalStatus,
    titleKey: 'assetManagement.tab.approvalStatus',
    icon: ApprovalStatusTabIcon,
    content: AssetManagementApprovalSection,
    allowedRoles: [Role.supervisor, Role.systemAdministrator],
  },
]
