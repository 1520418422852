import { useTranslation } from '@pasteltech/i18n-react'
import { Status } from '@stewards-fas/schemas'
import { memo, useMemo } from 'react'
import styled from 'styled-components'

const StatusDiv = styled.div<{ status: Status }>`
  background: ${(props) => {
    if (props.status === Status.active) return '#EEFAF0'
    if (props.status === Status.inactive) return props.theme.colors.greys[5]
    return undefined
  }};
  padding: 0 12px;
  display: inline-flex;
  justify-content: center;
  width: 100%;
  color: ${(props) => {
    if (props.status === Status.active) return '#469846'
    if (props.status === Status.inactive) return props.theme.colors.greys[2]
    return undefined
  }};
`

type UnitStatusPropType = {
  status: Status
  activeText?: string
  inactiveText?: string
}
export const ActiveStatus = memo<UnitStatusPropType>(
  ({ status, activeText, inactiveText }) => {
    const { t } = useTranslation()
    const text = useMemo(() => {
      if (status === Status.active) {
        return activeText ?? t('systemDataManagement.unitStatus.active')
      }
      if (status === Status.inactive) {
        return inactiveText ?? t('systemDataManagement.unitStatus.inactive')
      }
      return 'undefined'
    }, [activeText, inactiveText, status, t])

    return (
      <>
        <StatusDiv status={status}>{text}</StatusDiv>
      </>
    )
  },
)
ActiveStatus.displayName = 'UnitStatus'
