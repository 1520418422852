import { z } from 'zod'
import {
  makeGetPayloadSchema,
  makePatchPayloadSchema,
  makePostPayloadSchema,
} from '../base'

// Resource Schema
export const ResourcesSchema = z.object({
  id: z.string().uuid(),
  assetId: z.string().uuid(),
  mime: z.string(),
  filename: z.string(),
  url: z.string(),
  order: z.number().int(),
})

// Resource Response
export const ResourceResponseSchema = makeGetPayloadSchema(ResourcesSchema)
export type ResourceResponsePayload = z.infer<typeof ResourceResponseSchema>

// Resource Post
export const PostResourcesSchema = makePostPayloadSchema(ResourcesSchema)
export type PostResourcesPayload = z.infer<typeof PostResourcesSchema>

// Resource Patch
export const PatchResourcesSchema = makePatchPayloadSchema(
  ResourcesSchema,
).extend({ isDeleted: z.boolean() })
export type PatchResourcesPayload = z.infer<typeof PatchResourcesSchema>

// Blob create container
export const PostCreateBlobStorageContainerResponsePayloadSchema = z.object({
  endpoint: z.string(),
  containerName: z.string(),
  sasToken: z.string(),
})
export type PostCreateBlobStorageContainerResponsePayload = z.infer<
  typeof PostCreateBlobStorageContainerResponsePayloadSchema
>

// Blob Get File
export const PostGetBlobDownloadUrlResponsePayloadSchema = z.object({
  url: z.string(),
})
export type PostGetBlobDownloadUrlResponsePayload = z.infer<
  typeof PostGetBlobDownloadUrlResponsePayloadSchema
>
