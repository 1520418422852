import { useMsal } from '@azure/msal-react'
import { memo, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { BasePage } from '../components'
import { AppRoutes } from './paths'
import { RestoreSectionContainer } from './RestoreSectionContainer'
import { useUserRole } from '../hooks'
import { UnauthorizedPage } from '../pages'

type Props = {}

const Root = styled.div`
  background: ${({ theme }) => theme.colors.white};
  height: 100vh;
`

export const ProtectedRoute = memo<Props>(() => {
  const navigate = useNavigate()
  const { instance } = useMsal()

  useEffect(() => {
    if (instance.getActiveAccount() == null) {
      navigate(AppRoutes.login.path)
    }
  }, [instance, navigate])
  const { isDisabled } = useUserRole()

  return (
    <Root>
      {isDisabled ? (
        <UnauthorizedPage />
      ) : (
        <RestoreSectionContainer>
          <BasePage>
            <Outlet />
          </BasePage>
        </RestoreSectionContainer>
      )}
    </Root>
  )
})

ProtectedRoute.displayName = 'ProtectedRoute'
