import { useMemo } from 'react'
import { useDropdownAll } from '../utilities'
import { Status } from '@stewards-fas/schemas'
import { useQueryUserProfile, useUnitList } from '@stewards-fas/queries'
import { useUserRole } from './use-user-role'

type OptionDetail = {
  isHasOptionAll?: boolean
  isShowActive?: boolean
  isShowInactive?: boolean
  isDisregardPermission?: boolean
}

export function useUserUnitIds() {
  const { data: userProfile } = useQueryUserProfile({ expand: ['UserUnits'] })

  const userUnitIds = useMemo(
    () => userProfile?.userUnits.map((uu) => uu.unitId),
    [userProfile?.userUnits],
  )
  return userUnitIds
}

export function useUnitOptions({
  isHasOptionAll: isHasOptionAll = false,
  isShowActive = true,
  isShowInactive = false,
  isDisregardPermission = false,
}: OptionDetail) {
  const { allOption } = useDropdownAll()
  const { data: unitList } = useUnitList()
  const { isAdmin } = useUserRole()
  const { data: userProfile } = useQueryUserProfile({ expand: ['UserUnits'] })

  const userUnitIds = useMemo(
    () => userProfile?.userUnits.map((uu) => uu.unitId),
    [userProfile?.userUnits],
  )

  const unitOptions = useMemo(
    () =>
      (unitList?.data ?? [])
        .filter(
          (unit) =>
            (isShowActive && unit.status.toLowerCase() === Status.active) ||
            (isShowInactive && unit.status.toLowerCase() === Status.inactive),
        )
        .filter((unit) => {
          if (isDisregardPermission || isAdmin) return true
          return userUnitIds?.includes(unit.id)
        })
        .map((unit) => ({
          value: unit.id,
          label: `${unit.code} ${unit.nameCN}` ?? '',
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [
      unitList?.data,
      isShowActive,
      isShowInactive,
      isDisregardPermission,
      isAdmin,
      userUnitIds,
    ],
  )
  if (isHasOptionAll) {
    return [allOption].concat(unitOptions)
  }
  return unitOptions
}
