import { memo, PropsWithChildren } from 'react'
import styled from 'styled-components'

const TitleContainer = styled.div`
  padding: 4px 8px;
  border-left: 4px solid ${({ theme }) => theme.colors.secondary};
`

const Title = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  padding: 0px;
  color: ${({ theme }) => theme.colors.secondary};
`

export const PageTitle = memo(({ children }: PropsWithChildren) => {
  return (
    <TitleContainer>
      <Title>{children}</Title>
    </TitleContainer>
  )
})

PageTitle.displayName = 'PageTitle'
