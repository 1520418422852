import { ApiGateway } from '@pasteltech/api-gateway'
import {
  PatchUnitPayload,
  PostUnitPayload,
  UnitListRequestPayload,
  UnitListRequestSchema,
  UnitListResponseSchema,
} from '@stewards-fas/schemas'
import { z } from 'zod'

const PATH = '/units'

export function getUnit(id: string, param?: UnitListRequestPayload) {
  return ApiGateway.primary.get(
    `${PATH}/${id}`,
    {
      request: UnitListRequestSchema,
      response: UnitListResponseSchema,
    },
    {
      param,
    },
  )
}

export function postUnit(param: PostUnitPayload) {
  return ApiGateway.plain.post(
    `${PATH}`,
    { request: z.any(), response: z.any() },
    {
      body: param,
    },
  )
}

export function patchUnit(param: PatchUnitPayload) {
  const { id, ...content } = param
  return ApiGateway.primary.patch(
    `${PATH}/${id}`,
    { request: z.any(), response: z.any() },
    {
      body: content,
    },
  )
}

export function getUnitList(param?: UnitListRequestPayload) {
  return ApiGateway.primary.get(
    `${PATH}`,
    { request: z.any(), response: z.any() },
    {
      param,
      config: { timeout: 60000 },
    },
  )
}
