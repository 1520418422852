import { ApiError } from '@pasteltech/api-gateway'

type ApiErrorKind = ApiError['kind']

const temporaryErrorCodes: ApiErrorKind[] = [
  'timeout',
  'bad-gateway',
  'cannot-connect',
  'service-not-available',
]
export const checkTemporary = (errorKind: ApiErrorKind) =>
  temporaryErrorCodes.includes(errorKind)
