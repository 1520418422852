import {
  ApiFormattedError,
  getReferencePageList,
  patchReferencePage,
  postReferencePage,
} from '@stewards-fas/apis'
import {
  PatchReferencePagePayload,
  PostReferencePagePayload,
  ReferencePageListRequestPayload,
  ReferencePageListResponsePayload,
  ReferencePageResponsePayload,
} from '@stewards-fas/schemas'
import {
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import { callbackNonNullable, useOptsOnSuccessInvalidate } from './helper'

const QUERY_CACHE_KEY_ROOT = 'reference-pages'

function useOpts<R, E, P>(opts?: UseMutationOptions<R, E, P>) {
  return useOptsOnSuccessInvalidate([QUERY_CACHE_KEY_ROOT], opts)
}

export function useReferencePageList(
  param?: ReferencePageListRequestPayload,
  opts?: UseQueryOptions<unknown, unknown, ReferencePageListResponsePayload>,
) {
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => getReferencePageList(param),
    opts,
  )
}

export function usePostReferencePageMutation(
  opts?: UseMutationOptions<
    ReferencePageResponsePayload,
    ApiFormattedError,
    PostReferencePagePayload
  >,
) {
  const options = useOpts(opts)
  return useMutation(callbackNonNullable(postReferencePage), options)
}

export function usePatchReferencePageMutation(
  opts?: UseMutationOptions<
    ReferencePageResponsePayload,
    ApiFormattedError,
    PatchReferencePagePayload
  >,
) {
  const options = useOpts(opts)
  return useMutation(callbackNonNullable(patchReferencePage), options)
}
