import { Button } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { memo } from 'react'
import styled from 'styled-components'
import { SuspendIcon } from '../../icons'

const OutlinedButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  height: 40px;
`

type InactivateButtonPropType = { onClick: () => void }
export const InactivateButton = memo<InactivateButtonPropType>(
  ({ onClick }) => {
    const { t } = useTranslation()

    return (
      <>
        <OutlinedButton
          startIcon={<SuspendIcon />}
          variant="outlined"
          fullWidth
          onClick={onClick}
        >
          {t('common.disabled')}
        </OutlinedButton>
      </>
    )
  },
)
InactivateButton.displayName = 'InactivateButton'
