import { ApiGateway } from '@pasteltech/api-gateway'
import {
  AssetTypeListRequestPayload,
  PatchAssetTypePayload,
  PostAssetTypePayload,
} from '@stewards-fas/schemas'
import { z } from 'zod'

const PATH = '/asset-types'

export function getAssetTypeList(param?: AssetTypeListRequestPayload) {
  return ApiGateway.primary.get(
    `${PATH}`,
    { request: z.any(), response: z.any() },
    {
      param,
    },
  )
}

export function postAssetType(param: PostAssetTypePayload) {
  return ApiGateway.primary.post(
    `${PATH}`,
    { request: z.any(), response: z.any() },
    {
      body: param,
    },
  )
}

export function patchAssetType(param: PatchAssetTypePayload) {
  const { id, ...others } = param
  return ApiGateway.primary.patch(
    `${PATH}/${id}`,
    { request: z.any(), response: z.any() },
    {
      body: others,
    },
  )
}
