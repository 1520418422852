import {
  Alert,
  AlertProps as MuiAlertProps,
  Snackbar as MuiSnackbar,
} from '@mui/material'
import { memo } from 'react'
import styled from 'styled-components'

const StyledSnackbar = styled(MuiSnackbar)`
  top: 250px;

  .MuiPaper-root {
    min-width: 300px;
  }

  .MuiAlert-filledSuccess {
    background-color: ${({ theme }) => theme.colors.lightPrimary};
    border-radius: 5px;

    .MuiAlert-message {
      color: ${({ theme }) => theme.colors.black};
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }

    svg {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`

export type SnackbarProps = Pick<MuiAlertProps, 'severity'> & {
  visible: boolean
  content: string
  onDismiss(): void
}

export const Snackbar = memo<SnackbarProps>(
  ({ severity, content, onDismiss, visible }) => {
    return (
      <StyledSnackbar
        open={visible}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ whiteSpace: 'pre-line' }}
        onClose={onDismiss}
      >
        <Alert severity={severity} variant="filled">
          {content}
        </Alert>
      </StyledSnackbar>
    )
  },
)

Snackbar.displayName = 'Snackbar'

declare module '@pasteltech/overlay-provider' {
  interface OverlaySnackbarProps extends SnackbarProps {}
}
