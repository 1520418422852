import {
  ApiFormattedError,
  getBlobDownloadUrl,
  postCreateBlobStorageContainer,
  postResources,
} from '@stewards-fas/apis'
import {
  AssetResponsePayload,
  PostCreateBlobStorageContainerResponsePayload,
  PostGetBlobDownloadUrlResponsePayload,
  PostResourcesPayload,
} from '@stewards-fas/schemas'
import {
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'resources'
const EMPTY_PAYLOAD = 'empty-payload'
export function useCreateBlobStorageContainer(
  opts?: UseMutationOptions<
    PostCreateBlobStorageContainerResponsePayload | null,
    unknown
  >,
) {
  return useMutation(async () => {
    const res = await postCreateBlobStorageContainer()
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, opts)
}

export function useGetBlobDownloadUrl(
  opts?: UseMutationOptions<
    PostGetBlobDownloadUrlResponsePayload | null,
    unknown,
    string
  >,
) {
  return useMutation(async (id) => {
    const res = await getBlobDownloadUrl(id)
    if (res == null) throw new Error(EMPTY_PAYLOAD)
    return res
  }, opts)
}

export function usePostResources(
  opts?: UseMutationOptions<unknown, ApiFormattedError, PostResourcesPayload>,
) {
  return useMutation(async (payload) => {
    const res = await postResources(payload)
    if (res == null) throw new Error(EMPTY_PAYLOAD)

    return res
  }, opts)
}

export function useGetBlobDownloadUrlForResourcesList(
  resources: AssetResponsePayload['resources'],
  opts?: UseQueryOptions<
    AssetResponsePayload['resources'],
    unknown,
    AssetResponsePayload['resources']
  >,
) {
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, resources] as QueryKey,
    () =>
      Promise.all(
        resources.map(async (resource) => {
          const res = await getBlobDownloadUrl(resource.id)
          if (res == null) throw new Error(EMPTY_PAYLOAD)
          return {
            ...resource,
            url: res.url,
          }
        }),
      ),
    opts,
  )
}
