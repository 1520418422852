import { useCallback } from 'react'
import { RouteObject } from 'react-router-dom'
import { useUserRole } from '../hooks'
import { ForbiddenPage } from '../pages'
import { RouteItem, routes } from './routes'

export function useRoute(): RouteObject[] {
  const { role: currentRole } = useUserRole()

  const getRenderPage = useCallback(
    (routeItem: RouteItem) => {
      return routeItem.accessibleRoles == null ||
        routeItem.accessibleRoles.some((role) => role === currentRole) ? (
        routeItem.element
      ) : (
        <ForbiddenPage />
      )
    },
    [currentRole],
  )

  const renderRouteItemRecursively = useCallback(
    (routeItems: RouteItem[] | undefined): RouteObject[] => {
      if (routeItems == null) {
        return []
      }

      return routeItems.map((item) => ({
        ...item,
        element: getRenderPage(item),
        children: !item.index
          ? renderRouteItemRecursively(item.children)
          : undefined,
      })) as RouteObject[]
    },
    [getRenderPage],
  )

  return renderRouteItemRecursively(routes)
}
