import { memo, useCallback } from 'react'
import { Dialog } from '../dialog'

type Props = {
  title: string
  message: string
  onClose: () => void
  onConfirm: () => void
  visible: boolean
}
export const ImageDialog = memo<Props>(
  ({ title, message, onClose, onConfirm, visible }) => {
    const handleSelected = useCallback(
      (val: boolean | undefined) => {
        if (val === true) {
          onConfirm()
        }
        onClose()
      },
      [onClose, onConfirm],
    )
    return (
      <Dialog
        dismissable
        visible={visible}
        title={title}
        onSelected={handleSelected}
      >
        {message}
      </Dialog>
    )
  },
)

ImageDialog.displayName = 'ImageDialog'
