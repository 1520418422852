import { withEnvironment } from '@stewards-fas/model-core'
import JwtDecode from 'jwt-decode'
import { Instance, types } from 'mobx-state-tree'
import moment from 'moment'

export const UserSessionInfoModel = types
  .model('UserSessionInfo')
  .props({
    accessToken: types.string,
    tokenType: types.optional(types.string, 'Bearer'),
  })
  .views((self) => ({
    get expiryMoment() {
      if (!self?.accessToken) return undefined
      const { exp = 0 } = JwtDecode<any>(self.accessToken)
      return moment(exp * 1000)
    },
    get notBeforeMoment() {
      if (!self?.accessToken) return undefined
      const { nbf = 0 } = JwtDecode<any>(self.accessToken)
      return moment(nbf * 1000)
    },
    get authenticationHeader() {
      if (!self?.accessToken) return undefined
      return `${self.tokenType} ${self.accessToken}`
    },
    get decodedToken(): { [key: string]: string } | undefined {
      if (!self?.accessToken) return undefined
      return JwtDecode(self.accessToken)
    },
  }))
  .extend(withEnvironment)

export type UserSessionInfo = Instance<typeof UserSessionInfoModel>
