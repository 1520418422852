import { Grid } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { useOverlay } from '@pasteltech/overlay-provider'
import {
  useApproveAsset,
  useReimburseAsset,
  useRejectAsset,
  useTransitAsset,
} from '@stewards-fas/queries'
import {
  AssetResponsePayload,
  AssetStatus,
  TransitAssetRequestPayload,
  UnitResponsePayload,
} from '@stewards-fas/schemas'
import { FormikProvider, useFormik } from 'formik'
import { memo, useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  AssetDetailsSection,
  AssetTransferModal,
  Dialog,
  FormAction,
  FormTextField,
  PageTitle,
  TableProps,
  WriteOffModal,
} from '../../../components'
import { BackButton, TransitButton } from '../../../components/buttons'
import {
  useCurrentUserUnits,
  useApiErrorHandle,
  useAssetStatus,
} from '../../../hooks'
import {
  ApproveIcon,
  EditIcon,
  PreviewIcon,
  PrintIcon,
  TransferIcon,
  WithdrawIcon,
  WriteOffIcon,
} from '../../../icons'
import { EditAssetFormSchemaModel } from '../../../models'
import { AppRoutes } from '../../../routers'
import { ScreenSize, useView } from '../../../utilities'
import { useAssetLink } from '../../../components/asset-details-section/hooks'
import { ViewAssetChangesModal } from './modal'
import { ForbiddenPage } from '../../forbidden-page'

export enum AssetViewPageAction {
  approval = 'approval',
  transfer = 'transfer',
  view = 'view',
}

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const PrintButton = styled(TransitButton)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary};
  }
`

const EditButton = styled(TransitButton)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`

const TransferButton = styled(TransitButton)`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
  }
`

const WrtieOffButton = styled(TransitButton)`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
  }
`
const ApproveButton = styled(TransitButton)`
  background-color: ${({ theme }) => theme.colors.maroonOak};
  color: ${({ theme }) => theme.colors.white};
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
  }
`
const WithdrawButton = ApproveButton
const PreviewButton = styled(TransitButton)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`
type Props = {
  formData: EditAssetFormSchemaModel
  assetData: AssetResponsePayload
  transferRecord?: TableProps
  units?: UnitResponsePayload[]
  pageAction: AssetViewPageAction
}

export enum AssetAction {
  reimburse,
  transit,
}

export const AssetViewPageContent = memo<Props>(
  ({ formData, transferRecord, units, pageAction, assetData }) => {
    // global
    const { t } = useTranslation()
    const { showSnackbar, showSpinner, hideSpinner } = useOverlay()
    const view = useView()
    const isMobileView = useMemo(() => view === ScreenSize.mobile, [view])
    const navigate = useNavigate()
    const { id } = useParams()
    const { checkPermittedUnitId } = useCurrentUserUnits()

    const isMyUnitAssets = useMemo(() => {
      return checkPermittedUnitId(assetData.unitId)
    }, [assetData.unitId, checkPermittedUnitId])

    const BackButtonURL = useMemo(() => {
      if (pageAction === AssetViewPageAction.approval)
        return AppRoutes.assetManagement.approvalSection.path
      if (pageAction === AssetViewPageAction.transfer)
        return AppRoutes.assetManagement.transferSection.path
      // back to default page
      return AppRoutes.assetManagement.path
    }, [pageAction])

    // ui states
    const [isViewChange, setIsViewChange] = useState<boolean>(false)
    const [isRejectAsset, setIsRejectAsset] = useState<boolean>(false)
    const [rejectReason, setRejectReason] = useState<string>('')
    const currentAssetID = useMemo(() => id ?? '', [id])
    const [assetAction, setAssetAction] = useState<AssetAction | undefined>()
    const {
      validStatusForReimburse,
      validStatusForTransit,
      validStatusForUpdate,
    } = useAssetStatus(assetData.status as AssetStatus)
    const { standardErrorHandler } = useApiErrorHandle()

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: formData,
      onSubmit: () => {},
    })

    // ui handlers
    const { displayParentLinks, displayChildLinks, cantLinkAssetIds } =
      useAssetLink(
        FormAction.view,
        assetData.parentAssetLinks,
        assetData.childAssetLinks,
      )

    const handleButtonOnClickWithCheckAssetStatus = useCallback(
      (isStatusValid: boolean, errorMessage: string, callback?: () => void) => {
        if (callback && isStatusValid) {
          callback()
        } else {
          showSnackbar({
            content: errorMessage,
            severity: 'error',
          })
        }
      },
      [showSnackbar],
    )

    const { mutateAsync: approveAssetAsync } = useApproveAsset(currentAssetID)
    const handleApproval = useCallback(async () => {
      try {
        showSpinner()
        const res = await approveAssetAsync({
          rowVersion: formik.values.rowVersion,
        })
        formik.setFieldValue('status', res.status)
        showSnackbar({
          content: t('assetActions.approval.success'),
        })
      } catch (err) {
        standardErrorHandler(err)
      } finally {
        hideSpinner()
      }
    }, [
      approveAssetAsync,
      showSnackbar,
      formik,
      standardErrorHandler,
      t,
      showSpinner,
      hideSpinner,
    ])

    const { mutateAsync: reimburseAssetAsync } =
      useReimburseAsset(currentAssetID)
    const handleReimburse = useCallback(
      async (reason: string) => {
        try {
          showSpinner()
          const res = await reimburseAssetAsync({
            reason,
            rowVersion: formik.values.rowVersion,
          })
          formik.setFieldValue('status', res.status)
          showSnackbar({
            content: t('assetActions.reimburse.success'),
          })
        } catch (err) {
          standardErrorHandler(err)
        } finally {
          hideSpinner()
        }
      },
      [
        reimburseAssetAsync,
        showSnackbar,
        formik,
        standardErrorHandler,
        showSpinner,
        hideSpinner,
        t,
      ],
    )

    const { mutateAsync: transitAssetAsync } = useTransitAsset(currentAssetID)
    const handleTransit = useCallback(
      async (payload: Omit<TransitAssetRequestPayload, 'rowVersion'>) => {
        try {
          showSpinner()
          await transitAssetAsync({
            ...payload,
            rowVersion: formik.values.rowVersion,
          })
          showSnackbar({
            content: t('assetActions.transit.success'),
          })
        } catch (err) {
          standardErrorHandler(err)
        } finally {
          hideSpinner()
        }
      },
      [
        transitAssetAsync,
        showSnackbar,
        formik,
        standardErrorHandler,
        t,
        showSpinner,
        hideSpinner,
      ],
    )
    const { mutateAsync: rejectAssetAsync } = useRejectAsset()
    const handleRejectConfirm = useCallback(
      async (confirm: boolean) => {
        try {
          showSpinner()
          if (!confirm) return
          await rejectAssetAsync({
            id: assetData.id,
            rowVersion: assetData.rowVersion,
            reason: rejectReason,
          })
          showSnackbar({ content: t('assetViewPage.rejectModal.success') })
        } catch (err) {
          standardErrorHandler(err)
        } finally {
          setRejectReason('')
          setIsRejectAsset(false)
          hideSpinner()
        }
      },
      [
        assetData,
        hideSpinner,
        setRejectReason,
        setIsRejectAsset,
        showSpinner,
        rejectAssetAsync,
        showSnackbar,
        rejectReason,
        standardErrorHandler,
        t,
      ],
    )
    const ButtonsBar = useMemo(
      () => ({
        approval: (
          <>
            <PreviewButton
              icon={PreviewIcon}
              text={t('assetViewPage.buttons.previewChanges')}
              onClick={() => {
                setIsViewChange(true)
              }}
            />
            <WithdrawButton
              icon={WithdrawIcon}
              text={t('assetManagement.writeOffSection.actions.withdraw')}
              onClick={() => {
                setIsRejectAsset(true)
              }}
            />
            <ApproveButton
              icon={ApproveIcon}
              text={t('assetManagement.writeOffSection.actions.approve')}
              onClick={handleApproval}
              disabled={formik.values.status === AssetStatus.assetApproved}
            />
          </>
        ),
        transfer: (
          <PreviewButton
            icon={PreviewIcon}
            text={t('assetViewPage.buttons.preview')}
            url={AppRoutes.assetTransferPreview.render(currentAssetID)}
          />
        ),
        view: (
          <>
            <PrintButton
              icon={PrintIcon}
              text={t('assetViewPage.buttons.print')}
              onClick={() =>
                navigate(AppRoutes.assetPreview.render(currentAssetID))
              }
            />
            <EditButton
              icon={EditIcon}
              text={t('assetViewPage.buttons.edit')}
              onClick={() => {
                handleButtonOnClickWithCheckAssetStatus(
                  validStatusForUpdate,
                  t('assetViewPage.errorMessage.notPossibleStatusForUpdate'),
                  () => {
                    navigate(AppRoutes.assetEdit.render(currentAssetID))
                  },
                )
              }}
            />
            <TransferButton
              icon={TransferIcon}
              text={t('assetViewPage.buttons.transfer')}
              onClick={() => {
                handleButtonOnClickWithCheckAssetStatus(
                  validStatusForTransit,
                  t('assetViewPage.errorMessage.notPossibleStatusForTransit'),
                  () => {
                    setAssetAction(AssetAction.transit)
                  },
                )
              }}
            />
            <WrtieOffButton
              icon={WriteOffIcon}
              text={t('assetViewPage.buttons.writeOff')}
              onClick={() => {
                handleButtonOnClickWithCheckAssetStatus(
                  validStatusForReimburse,
                  t('assetViewPage.errorMessage.notPossibleStatusForReimburse'),
                  () => {
                    setAssetAction(AssetAction.reimburse)
                  },
                )
              }}
            />
          </>
        ),
      }),
      [
        t,
        currentAssetID,
        formik.values.status,
        handleApproval,
        handleButtonOnClickWithCheckAssetStatus,
        validStatusForTransit,
        validStatusForReimburse,
        validStatusForUpdate,
        navigate,
      ],
    )

    if (!isMyUnitAssets) {
      return <ForbiddenPage />
    }

    return (
      <Root>
        <FormikProvider value={formik}>
          <PageTitle>
            {pageAction === AssetViewPageAction.approval &&
            formData.status === 'CREATED'
              ? t('assetDetailsPage.form.title.create')
              : t('assetEditPage.title')}
          </PageTitle>
          <Grid container>
            <Grid item mr="auto">
              <BackButton url={BackButtonURL} />
            </Grid>
            <Grid item ml="auto">
              <Grid container gap={2}>
                {ButtonsBar[isMobileView ? 'approval' : pageAction]}
              </Grid>
            </Grid>
          </Grid>
          <AssetDetailsSection
            action={FormAction.view}
            transferRecord={transferRecord}
            isMobileView={isMobileView}
            displayParentLinks={displayParentLinks}
            displayChildLinks={displayChildLinks}
            cantLinkAssetIds={cantLinkAssetIds}
          />
          <WriteOffModal
            visible={assetAction === AssetAction.reimburse}
            onClose={() => setAssetAction(undefined)}
            assetName={formik.values.name}
            onSubmit={(reason: string) => {
              handleReimburse(reason)
              setAssetAction(undefined)
            }}
          />
          <AssetTransferModal
            visible={assetAction === AssetAction.transit}
            onClose={() => setAssetAction(undefined)}
            asset={{
              assetNo: formik.values.assetNo ?? '',
              name: formik.values.name,
              quantity: formik.values.quantity,
              assetLinks: [...displayParentLinks, ...displayChildLinks],
              atUnitNotes: formik.values.unit,
            }}
            units={units}
            multiple={formik.values.quantity > 1}
            onSubmit={(
              payload: Omit<TransitAssetRequestPayload, 'rowVersion'>,
            ) => {
              handleTransit(payload)
              setAssetAction(undefined)
            }}
          />
          <ViewAssetChangesModal
            visible={isViewChange}
            title={t('assetViewPage.buttons.previewChanges')}
            onClose={() => {
              setIsViewChange(false)
            }}
            assetData={assetData}
          />
          <Dialog
            visible={isRejectAsset}
            onSelected={handleRejectConfirm}
            title={t('assetViewPage.rejectModal.title')}
          >
            {t('common.confirm')}
            {t('assetViewPage.rejectModal.title')}?
            <FormTextField
              templateColumns="1fr 7fr"
              value={rejectReason}
              onChange={(val) => {
                setRejectReason(val as string)
              }}
              label={t('assetViewPage.rejectModal.rejectReason')}
            />
          </Dialog>
        </FormikProvider>
      </Root>
    )
  },
)
AssetViewPageContent.displayName = 'AssetViewPageContent'
