import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { TFuncKey, useTranslation } from '@pasteltech/i18n-react'
import {
  AssetTypeResponsePayload,
  FundingResponsePayload,
  Status,
  UnitResponsePayload,
} from '@stewards-fas/schemas'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Option } from '../../hooks'
import {
  ClearIcon,
  CollapseIcon,
  ExpandIcon,
  WhiteReportIcon,
} from '../../icons'
import {
  ReportGeneratorInitialValues,
  ReportGeneratorSchema,
  ReportGeneratorSchemaModel,
  ReportPageStateFieldValues,
  TransferToOrFromUnitFieldValues,
} from '../../models'
import { formats } from '../../theme'
import { convertAllValueToNull, useDropdownAll } from '../../utilities'

interface GeneratorProps {
  onGenerate: (formikInput: ReportGeneratorSchemaModel) => void
  defaultValue?: Partial<ReportGeneratorSchemaModel>
  needState: boolean
  assertTypeList: AssetTypeResponsePayload[]
  unitList: UnitResponsePayload[]
  fundingList: FundingResponsePayload[]
  setUnit?: React.Dispatch<React.SetStateAction<any>>
  setAssetType?: React.Dispatch<React.SetStateAction<any>>
  setTransferToOrFrom?: React.Dispatch<React.SetStateAction<any>>
}

const Generator = styled.div`
  margin-bottom: 30px;
  width: fit-content;
`

const ContentSelector = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.lightBlue2};
  flex-direction: row;
  box-shadow: 0px 0px 5px 0px ${({ theme }) => theme.colors.primary}cc;
  padding: 12px 20px;
`

const UnexpandedContentSelector = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.lightBlue2};
  flex-direction: column;
  box-shadow: 0px 0px 5px 0px ${({ theme }) => theme.colors.primary}cc;
  align-items: flex-end;
  padding: 10px;
`

const GeneratorBox = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
  flex-wrap: wrap;
`

const GeneratorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`

const ExpandedGeneratorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: solid;
  padding: 5px;
  border-width: 1px;
`

const ExpandedGeneratorBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-width: 144px;
  gap: 8px;
`

const LabelContainer = styled.div``

const NarrowTextField = styled(TextField)`
  width: 100px;
  background-color: ${({ theme }) => theme.colors.white};
`

const LabelContainerTo = styled.div`
  width: 70px;
  margin-left: 5px;
  margin-right: -60px;
`

const StyledSelect = styled(Select)`
  width: 130px;
  background-color: ${({ theme }) => theme.colors.white};
`
const ClearAllBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  background-color: ${({ theme }) => theme.colors.lightBlue2};
`

const ClearAllLabelContainer = styled.div`
  width: 70px;
  color: ${({ theme }) => theme.colors.chestnut};
  cursor: pointer;
`

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0 20px;
  margin: 20px 0;
  flex-wrap: wrap;
  justify-content: flex-end;
`

const SubmitButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.berryBlue};
  margin-right: auto;
  flex-shrink: 0;
  gap: 8px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.berryBlue};
  }
`

const ExpandedButtonBox = styled.div`
  display: flex;
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  align-items: flex-end;
`

const StyledDatePicker = styled(DatePicker)`
  background-color: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
`

export const ReportGenerator = memo(
  ({
    onGenerate,
    defaultValue,
    needState,
    assertTypeList,
    unitList,
    fundingList,
    setUnit,
    setAssetType,
    setTransferToOrFrom,
  }: GeneratorProps) => {
    const { t } = useTranslation()
    const formik = useFormik({
      initialValues: {
        ...ReportGeneratorInitialValues,
        ...(defaultValue ?? {}),
      },
      validationSchema: ReportGeneratorSchema,
      onSubmit: (values) => {
        onGenerate(values)
      },
    })
    const { allOption } = useDropdownAll()
    const unitFieldOptions = useMemo(
      () =>
        [allOption].concat(
          unitList
            .filter((unit) => unit.status.toLowerCase() !== Status.inactive)
            .map((unit) => ({
              value: unit.id,
              label: `${unit.code} ${unit.nameCN}` ?? '',
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        ),
      [unitList, allOption],
    )

    const assetTypesFieldOptions = useMemo(
      () =>
        [allOption].concat(
          assertTypeList
            .filter((atype) => atype.status.toLowerCase() !== Status.inactive)
            .map((atype) => ({
              value: atype.id,
              label: `${atype.code} ${atype.typeCN}`,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        ),
      [allOption, assertTypeList],
    )
    const fundingsFieldOption = useMemo(
      () =>
        [allOption].concat(
          fundingList
            .filter((atype) => atype.status.toLowerCase() !== Status.inactive)
            .map((atype) => ({ value: atype.id, label: atype.code }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        ),
      [fundingList, allOption],
    )
    const statusFieldOptions: Option[] = useMemo(
      () => [
        {
          value: ReportPageStateFieldValues.unreimbursed,
          label: t('reportPage.labels.unreimbursed'),
        },
        {
          value: ReportPageStateFieldValues.reimbursed,
          label: t('reportPage.labels.reimbursed'),
        },
      ],
      [t],
    )
    const transferToOrFromFieldOptions: Option[] = useMemo(
      () => [
        allOption,
        {
          value: TransferToOrFromUnitFieldValues.to,
          label: t('reportPage.content.transferToUnit'),
        },
        {
          value: TransferToOrFromUnitFieldValues.from,
          label: t('reportPage.content.transferFromUnit'),
        },
      ],
      [t, allOption],
    )
    const { touched, errors } = formik

    const [expanded, setExpanded] = useState(true)

    const handleExpand = useCallback(() => {
      setExpanded(!expanded)
    }, [expanded])

    const handleClearAll = useCallback(() => {
      formik.setValues(ReportGeneratorInitialValues)
      formik.setFieldValue('buyDate', false)
      formik.setFieldValue('name', false)
      formik.setFieldValue('brand', false)
      formik.setFieldValue('model', false)
      formik.setFieldValue('placement', false)
      formik.setFieldValue('quantity', false)
      formik.setFieldValue('amount', false)
      formik.setFieldValue('serialNumber', false)
      formik.setFieldValue('provider', false)
      formik.setFieldValue('invoiceNo', false)
      formik.setFieldValue('warrantyProvider', false)
      formik.setFieldValue('warrantyPeriod', false)
      formik.setFieldValue('sourceOfFundsContent', false)
      formik.setFieldValue('remarks', false)
      formik.setFieldValue('writeOffDate', false)
      formik.setFieldValue('writeOffReason', false)
      formik.setFieldValue('writeOffCode', false)
      formik.setFieldValue('transferDate', false)
      formik.setFieldValue('transferToUnit', false)
      formik.setFieldValue('transferReason', false)
      formik.setFieldValue('formFiller', false)
      formik.setFieldValue('sectionSuperviser', false)
      formik.setFieldValue('fillDate', false)
      formik.setFieldValue('approvalDate', false)
    }, [formik])
    useEffect(() => {
      setUnit?.(convertAllValueToNull(formik.values.unit))
      setAssetType?.(convertAllValueToNull(formik.values.assetType))
      setTransferToOrFrom?.(
        convertAllValueToNull(formik.values.transferToOrFrom),
      )
    }, [
      formik.values.unit,
      formik.values.assetType,
      formik.values.transferToOrFrom,
      setTransferToOrFrom,
      setUnit,
      setAssetType,
    ])

    return (
      <Generator>
        <form onSubmit={formik.handleSubmit}>
          <GeneratorBox>
            <GeneratorContainer>
              <LabelContainer>{t('reportPage.labels.buyDate')}</LabelContainer>
              <StyledDatePicker
                format={formats.date}
                onChange={(val: unknown) =>
                  formik.setFieldValue('buyStartDate', val as Date)
                }
                value={
                  formik.values.buyStartDate
                    ? dayjs(formik.values.buyStartDate)
                    : null
                }
              />

              <LabelContainerTo>-</LabelContainerTo>

              <StyledDatePicker
                format={formats.date}
                onChange={(val: unknown) =>
                  formik.setFieldValue('buyEndDate', val as Date)
                }
                value={
                  formik.values.buyEndDate
                    ? dayjs(formik.values.buyEndDate)
                    : null
                }
              />
            </GeneratorContainer>

            <GeneratorContainer>
              <LabelContainer>{t('reportPage.labels.unit')}</LabelContainer>
              <StyledSelect
                name="unit"
                onChange={formik.handleChange}
                value={formik.values.unit}
              >
                {unitFieldOptions.map((it) => (
                  <MenuItem key={it.value} value={it.value}>
                    {it.label}
                  </MenuItem>
                ))}
              </StyledSelect>
            </GeneratorContainer>

            <GeneratorContainer>
              <LabelContainer>
                {t('reportPage.labels.assetType')}
              </LabelContainer>
              <StyledSelect
                name="assetType"
                onChange={formik.handleChange}
                value={formik.values.assetType}
              >
                {assetTypesFieldOptions.map((it) => (
                  <MenuItem key={it.value} value={it.value}>
                    {it.label}
                  </MenuItem>
                ))}
              </StyledSelect>
            </GeneratorContainer>
          </GeneratorBox>

          <GeneratorBox>
            <GeneratorContainer>
              <LabelContainer>{t('reportPage.labels.amount')}</LabelContainer>
              <NarrowTextField
                variant="outlined"
                type="number"
                label={t('reportPage.labels.inputAmount')}
                name="amountStart"
                onChange={formik.handleChange}
                value={formik.values.amountStart ?? ''}
                error={touched.amountStart && Boolean(errors.amountStart)}
                helperText={
                  touched.amountStart && errors.amountStart
                    ? (t(errors.amountEnd as TFuncKey) as string)
                    : null
                }
              />
              <LabelContainerTo>-</LabelContainerTo>
              <NarrowTextField
                variant="outlined"
                type="number"
                label={t('reportPage.labels.inputAmount')}
                name="amountEnd"
                onChange={formik.handleChange}
                value={formik.values.amountEnd ?? ''}
                error={touched.amountEnd && Boolean(errors.amountEnd)}
                helperText={
                  touched.amountEnd && errors.amountEnd
                    ? (t(errors.amountEnd as TFuncKey) as string)
                    : null
                }
              />
            </GeneratorContainer>

            <GeneratorContainer>
              <LabelContainer>
                {t('reportPage.labels.sourceOfFunds')}
              </LabelContainer>

              <StyledSelect
                name="sourceOfFundsSearch"
                onChange={formik.handleChange}
                value={formik.values.sourceOfFundsSearch}
              >
                {fundingsFieldOption.map((it) => (
                  <MenuItem key={it.value} value={it.value}>
                    {it.label}
                  </MenuItem>
                ))}
              </StyledSelect>
            </GeneratorContainer>

            {needState && (
              <GeneratorContainer>
                <LabelContainer>{t('reportPage.labels.state')}</LabelContainer>
                <StyledSelect
                  name="state"
                  onChange={formik.handleChange}
                  value={formik.values.state}
                >
                  {statusFieldOptions.map((it) => (
                    <MenuItem key={it.value} value={it.value}>
                      {it.label}
                    </MenuItem>
                  ))}
                </StyledSelect>
                <LabelContainer>
                  {t('reportPage.labels.transferStatus')}
                </LabelContainer>
                <StyledSelect
                  name="transferToOrFrom"
                  onChange={formik.handleChange}
                  value={formik.values.transferToOrFrom}
                >
                  {transferToOrFromFieldOptions.map((it) => (
                    <MenuItem key={it.value} value={it.value}>
                      {it.label}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </GeneratorContainer>
            )}
          </GeneratorBox>

          {!expanded && (
            <UnexpandedContentSelector>
              <ExpandedButtonBox>
                <IconButton onClick={handleExpand}>
                  {expanded ? <CollapseIcon /> : <ExpandIcon />}
                </IconButton>
              </ExpandedButtonBox>
            </UnexpandedContentSelector>
          )}

          {expanded && (
            <ContentSelector>
              <CheckboxContainer>
                <ExpandedGeneratorContainer>
                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="buyDate"
                          checked={formik.values.buyDate}
                        />
                      }
                      label={t('reportPage.labels.buyDate')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="name"
                          checked={formik.values.name}
                        />
                      }
                      label={t('reportPage.labels.name')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="brand"
                          checked={formik.values.brand}
                        />
                      }
                      label={t('reportPage.labels.brand')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="model"
                          checked={formik.values.model}
                        />
                      }
                      label={t('reportPage.labels.model')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="placement"
                          checked={formik.values.placement}
                        />
                      }
                      label={t('reportPage.labels.placement')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="quantity"
                          checked={formik.values.quantity}
                        />
                      }
                      label={t('reportPage.labels.quantity')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="amount"
                          checked={formik.values.amount}
                        />
                      }
                      label={t('reportPage.labels.amount')}
                    />
                  </ExpandedGeneratorBox>
                </ExpandedGeneratorContainer>

                <ExpandedGeneratorContainer>
                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="serialNumber"
                          checked={formik.values.serialNumber}
                        />
                      }
                      label={t('reportPage.labels.serialNumber')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="provider"
                          checked={formik.values.provider}
                        />
                      }
                      label={t('reportPage.labels.provider')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="invoiceNo"
                          checked={formik.values.invoiceNo}
                        />
                      }
                      label={t('reportPage.labels.invoiceNo')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="warrantyProvider"
                          checked={formik.values.warrantyProvider}
                        />
                      }
                      label={t('reportPage.labels.warrantyProvider')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="warrantyPeriod"
                          checked={formik.values.warrantyPeriod}
                        />
                      }
                      label={t('reportPage.labels.warrantyPeriod')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="sourceOfFundsContent"
                          checked={formik.values.sourceOfFundsContent}
                        />
                      }
                      label={t('reportPage.labels.sourceOfFunds')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="remarks"
                          checked={formik.values.remarks}
                        />
                      }
                      label={t('reportPage.labels.remarks')}
                    />
                  </ExpandedGeneratorBox>
                </ExpandedGeneratorContainer>

                <ExpandedGeneratorContainer>
                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="writeOffDate"
                          checked={formik.values.writeOffDate}
                        />
                      }
                      label={t('reportPage.labels.writeOffDate')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="writeOffReason"
                          checked={formik.values.writeOffReason}
                        />
                      }
                      label={t('reportPage.labels.writeOffdReason')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="writeOffCode"
                          checked={formik.values.writeOffCode}
                        />
                      }
                      label={t('reportPage.labels.writeOffCode')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="transferDate"
                          checked={formik.values.transferDate}
                        />
                      }
                      label={t('reportPage.labels.transferDate')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="transferToUnit"
                          checked={formik.values.transferToUnit}
                        />
                      }
                      label={t('reportPage.labels.transferToUnit')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="transferFromUnit"
                          checked={formik.values.transferFromUnit}
                        />
                      }
                      label={t('reportPage.labels.transferFromUnit')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="transferReason"
                          checked={formik.values.transferReason}
                        />
                      }
                      label={t('reportPage.labels.transferReason')}
                    />
                  </ExpandedGeneratorBox>
                </ExpandedGeneratorContainer>

                <ExpandedGeneratorContainer>
                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="formFiller"
                          checked={formik.values.formFiller}
                        />
                      }
                      label={t('reportPage.labels.filler')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="sectionSuperviser"
                          checked={formik.values.sectionSuperviser}
                        />
                      }
                      label={t('reportPage.labels.sectionSuperviser')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="fillDate"
                          checked={formik.values.fillDate}
                        />
                      }
                      label={t('reportPage.labels.fillDate')}
                    />
                  </ExpandedGeneratorBox>

                  <ExpandedGeneratorBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={formik.handleChange}
                          name="approvalDate"
                          checked={formik.values.approvalDate}
                        />
                      }
                      label={t('reportPage.labels.approvalDate')}
                    />
                  </ExpandedGeneratorBox>
                </ExpandedGeneratorContainer>
              </CheckboxContainer>

              <ButtonContainer>
                <ExpandedButtonBox>
                  <IconButton onClick={handleExpand}>
                    {expanded ? <CollapseIcon /> : <ExpandIcon />}
                  </IconButton>
                </ExpandedButtonBox>

                <ClearAllBoxContainer>
                  <IconButton onClick={handleClearAll}>
                    <ClearIcon />
                  </IconButton>
                  <ClearAllLabelContainer onClick={handleClearAll}>
                    {t('reportPage.labels.clearAll')}
                  </ClearAllLabelContainer>
                </ClearAllBoxContainer>
              </ButtonContainer>
            </ContentSelector>
          )}
          <ButtonBox>
            <GeneratorContainer>
              <SubmitButton variant="contained" type="submit">
                <WhiteReportIcon />
                {t('reportPage.labels.generate')}
              </SubmitButton>
            </GeneratorContainer>
          </ButtonBox>
        </form>
      </Generator>
    )
  },
)

ReportGenerator.displayName = 'ReportGenerator'
