import { useEffect, useMemo, useState } from 'react'

const mobileWidthBreakPoint = 1024

export enum ScreenSize {
  desktop = 'desktop',
  mobile = 'mobile',
}

function getWindowInnerWidth() {
  return window.innerWidth
}

function getWindowInnerHeight() {
  return window.innerHeight
}

export const useView = (): ScreenSize => {
  const [width, setWidth] = useState(getWindowInnerWidth())
  const [height, setHeight] = useState(getWindowInnerHeight())

  useEffect(() => {
    function handleResize() {
      setWidth(getWindowInnerWidth())
      setHeight(getWindowInnerHeight())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return useMemo(
    () =>
      width <= mobileWidthBreakPoint && width < height
        ? ScreenSize.mobile
        : ScreenSize.desktop,
    [height, width],
  )
}
