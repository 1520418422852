import dayjs from 'dayjs'
import { WriteOffAssetData } from '../pages/asset-management-page/asset-management-write-off-section/context'
import { formats } from '../theme'

export type PreviewTableRows = {}

export const mapRowDataToPreviewRows = (rowData: WriteOffAssetData) => {
  return rowData.map((it) => ({
    ...it,
    purchasedDateTime: dayjs(it.purchasedDateTime).format(formats.date),
  }))
}
