import { Button } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { useOverlay } from '@pasteltech/overlay-provider'
import {
  useAssetList,
  useAssetTypeList,
  useDeleteAssetBulk,
} from '@stewards-fas/queries'
import {
  AssetResponsePayload,
  AssetStatus,
  Status,
} from '@stewards-fas/schemas'
import { toDataURL as toQrCodeSrc } from 'qrcode'
import { memo, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  AssetSearchBar,
  Dialog,
  PrintWindow,
  QrcodeAsset,
  Spinner,
  TableList,
} from '../../../components'
import { useApiErrorHandle, useUserRole, useUserUnitIds } from '../../../hooks'
import { ProduceLabelIcon, TrashIcon } from '../../../icons'
import {
  AssetReimburseStatus,
  SearchFormSchemaModel,
  Section,
  mapFormikToSearchParams,
} from '../../../models'
import {
  mapAssetSearchParamsToFormik,
  mapSearchAssetListToTableRows,
} from '../../../models/searchFormModel'
import { useSearchParamContext } from '../../../providers'
import { AppRoutes } from '../../../routers'
import { convertToEndOfDay, convertToStartOfDay } from '../../../utilities'

const Container = styled.div`
  max-height: 800px;
`

const AssetListLabelContainer = styled.div`
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.maroonOak};
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 40px;
`

const VerticalBar = styled.div`
  width: 5px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.maroonOak};
  margin-right: 8px;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
`

const ProductLabelButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  margin-left: 20px;
  margin-bottom: 30px;
  width: 120px;
  font-weight: bold;
`

const DeleteButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.chestnut};
  &:hover {
    background-color: ${({ theme }) => theme.colors.chestnut};
  }
  margin-left: 20px;
  margin-bottom: 30px;
  width: 120px;
  font-weight: bold;
`

const StyledProductLabelIcon = styled(ProduceLabelIcon)`
  margin-right: 10px;
`

const StyledTrashIcon = styled(TrashIcon)`
  margin-right: 10px;
`

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const HiddenQrcode = styled.div`
  display: none;
`

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

const LabelPrintPage = styled.div`
  @media print {
    @page {
      size: A4;
      margin: 11mm 3mm;
    }
  }
`

export const AssetManagementSearchSection = memo(() => {
  // global
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { showSnackbar } = useOverlay()

  // Role permissions
  const { isAdmin } = useUserRole()
  const {
    isAssetManagementSearchButtonClicked,
    setIsAssetManagementSearchButtonClicked,
    assetManagementSearchParam,
    setAssetManagementSearchParam: setSearchParams,
  } = useSearchParamContext()

  const [isOpenDeletingDialog, setIsOpenDeletingDialog] = useState(false)

  const isReimbursed = useMemo(() => {
    return assetManagementSearchParam.isReimbursed
  }, [assetManagementSearchParam])

  const searchParams = useMemo(() => {
    return {
      ...assetManagementSearchParam,
      isReimbursed: undefined,
      purchasedFromDateTime:
        assetManagementSearchParam.purchasedFromDateTime != null
          ? assetManagementSearchParam.purchasedFromDateTime &&
            convertToStartOfDay(
              assetManagementSearchParam.purchasedFromDateTime,
            )
          : undefined,
      purchasedToDateTime:
        assetManagementSearchParam.purchasedToDateTime != null
          ? assetManagementSearchParam.purchasedToDateTime &&
            convertToEndOfDay(assetManagementSearchParam.purchasedToDateTime)
          : undefined,
    }
  }, [assetManagementSearchParam])

  const wantedAssetStatusList = useMemo(() => {
    const WrittenOffStatusList = [
      AssetStatus.reimburseApproved,
      AssetStatus.reimbursePending,
      AssetStatus.reimburseRejected,
      AssetStatus.reimburseSubmitted,
    ]
    if (isReimbursed) {
      return WrittenOffStatusList
    }
    return Object.values(AssetStatus).filter(
      (status) => !WrittenOffStatusList.includes(status),
    )
  }, [isReimbursed])

  const { data: assetList, isFetching: isFetchingAssetList } = useAssetList(
    {
      ...searchParams,
      status: searchParams.status ?? wantedAssetStatusList,
      expand: ['AssetType', 'Unit'],
    },
    {
      enabled: isAssetManagementSearchButtonClicked,
      refetchOnWindowFocus: false,
    },
  )

  const { data: assetTypeList } = useAssetTypeList()

  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const defaultSearchFormikValue: SearchFormSchemaModel = useMemo(
    () => mapAssetSearchParamsToFormik(assetManagementSearchParam),
    [assetManagementSearchParam],
  )

  const handleSearch = useCallback(
    (formikValues: SearchFormSchemaModel) => {
      setIsAssetManagementSearchButtonClicked(true)
      setSearchParams({
        ...mapFormikToSearchParams(formikValues),
        isReimbursed: formikValues.state === AssetReimburseStatus.reimbursed,
      })
    },
    [setIsAssetManagementSearchButtonClicked, setSearchParams],
  )

  const [imgUrl, setImgUrl] = useState<string[]>([])

  const [isPrint, setIsPrint] = useState(false)

  const selectedRowsData: AssetResponsePayload[] = useMemo(() => {
    if (!assetList?.data) return []
    return assetList.data.filter((it) => selectedRows.includes(it.id))
  }, [assetList, selectedRows])

  const closePrint = useCallback(async () => {
    async function resetImgUrl() {
      setImgUrl([])
    }
    await resetImgUrl()
    setIsPrint(false)
  }, [])
  const startPrint = useCallback(async () => {
    async function setAllImgUrl() {
      selectedRowsData.forEach((data) =>
        toQrCodeSrc(
          window.location.protocol +
            window.location.host +
            AppRoutes.assetView.render(data.id),
          function (err, url) {
            setImgUrl((prevUrl) => [...prevUrl, url])
          },
        ),
      )
    }
    await setAllImgUrl()
    setIsPrint(true)
  }, [selectedRowsData])

  const searchHeaders = useMemo(
    () => [
      t('assetManagement.searchSection.TableHeaders.assetCode'),
      t('assetManagement.searchSection.TableHeaders.name'),
      t('assetManagement.searchSection.TableHeaders.brand'),
      t('assetManagement.searchSection.TableHeaders.model'),
      t('assetManagement.searchSection.TableHeaders.number'),
      t('assetManagement.searchSection.TableHeaders.amount'),
      t('assetManagement.searchSection.TableHeaders.buyDate'),
      t('assetManagement.searchSection.TableHeaders.placement'),
      t('assetManagement.searchSection.TableHeaders.approveState'),
    ],
    [t],
  )

  const EMPTY_MSG = useMemo(() => t('common.selectItemFirst'), [t])

  const handleProduceLabel = useCallback(() => {
    if (selectedRows.length > 0) {
      startPrint()
    } else {
      showSnackbar({ content: EMPTY_MSG })
    }
  }, [EMPTY_MSG, selectedRows, showSnackbar, startPrint])

  const { mutateAsync: deleteAssetBulkAsync } = useDeleteAssetBulk()
  const { standardErrorHandler } = useApiErrorHandle()
  const handleDelete = useCallback(async () => {
    if (selectedRows.length > 0) {
      setIsOpenDeletingDialog(true)
    } else {
      showSnackbar({ content: EMPTY_MSG })
    }
  }, [EMPTY_MSG, selectedRows.length, showSnackbar])

  const confirmDelete = useCallback(
    async (isConfirm: boolean) => {
      if (isConfirm) {
        try {
          // withdraw reimbursement
          await deleteAssetBulkAsync(
            assetList?.data
              .filter((it) => selectedRows.includes(it.id))
              .map((it) => ({
                id: it.id,
                rowVersion: it.rowVersion,
              })) ?? [],
          )
        } catch (error) {
          console.error('Err-id:967986 ,Error when: delete', error)
          standardErrorHandler(error)
        }
      }
      setIsOpenDeletingDialog(false)
    },
    [selectedRows, deleteAssetBulkAsync, assetList, standardErrorHandler],
  )

  const relatedUnitIds = useUserUnitIds()
  // users are only allowed to see data of their own unit. Admin can see all data
  const allowedData = useMemo(() => {
    const allAssetData = assetList?.data ?? []
    const validUnitData = allAssetData.filter(
      (a) => a.unit?.status !== Status.inactive,
    )
    if (isAdmin) {
      return validUnitData
    }
    return validUnitData.filter((a) => relatedUnitIds?.includes(a.unitId))
  }, [assetList?.data, isAdmin, relatedUnitIds])

  const tableData = useMemo(
    () => mapSearchAssetListToTableRows(allowedData),
    [allowedData],
  )

  return (
    <Container>
      <AssetSearchBar
        onSearch={handleSearch}
        tab={Section.search}
        needState={true}
        assetTypeList={assetTypeList?.data}
        defaultValue={defaultSearchFormikValue}
      />

      <AssetListLabelContainer>
        <VerticalBar />
        {t('assetManagement.searchSection.assetList')}
      </AssetListLabelContainer>
      {isAssetManagementSearchButtonClicked && (
        <ButtonContainer>
          <ProductLabelButton
            variant="contained"
            onClick={handleProduceLabel}
            disabled={selectedRows.length === 0}
          >
            <StyledProductLabelIcon />
            {t('assetManagement.searchSection.button.produceLabel')}
          </ProductLabelButton>
          {isAdmin && (
            <DeleteButton
              variant="contained"
              disabled={selectedRows.length === 0}
              onClick={handleDelete}
            >
              <StyledTrashIcon />
              {t('common.delete')}
            </DeleteButton>
          )}
        </ButtonContainer>
      )}
      {!isFetchingAssetList ? (
        isAssetManagementSearchButtonClicked && (
          <TableList
            headers={searchHeaders}
            rows={tableData}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            onRowClick={(row) => navigate(AppRoutes.assetView.render(row.id))}
          />
        )
      ) : (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
      <HiddenQrcode>
        {selectedRows.map((id, i) => {
          return <img key={id} src={imgUrl[i]} />
        })}
      </HiddenQrcode>
      {isPrint && (
        <div>
          <PrintWindow
            onClose={closePrint}
            title={t('assetManagement.searchSection.button.assetLabel')}
          >
            <LabelPrintPage>
              <LabelContainer>
                {selectedRowsData.map((data, i) => {
                  return (
                    <QrcodeAsset
                      key={data.id}
                      imageUrl={imgUrl[i]}
                      selectedRowsData={data}
                    />
                  )
                })}
              </LabelContainer>
            </LabelPrintPage>
          </PrintWindow>
        </div>
      )}
      <Dialog
        visible={isOpenDeletingDialog}
        title={t('common.delete')}
        onSelected={confirmDelete}
      >
        {t('common.ok')}
        {t('common.delete')} ?
      </Dialog>
    </Container>
  )
})

AssetManagementSearchSection.displayName = 'AssetManagementSearchSection'
