import { SomeZodObject, z } from 'zod'

export enum Sort {
  asc = 'asc',
  desc = 'desc',
}

export const SortSchema = z.nativeEnum(Sort)

export const BaseEntitySchema = z.object({
  createdByUserId: z.string(),
  lastModifiedByUserId: z.string(),
  createdDateTime: z.string(),
  lastModifiedDateTime: z.string(),
  rowVersion: z.string(),
})

export const BasePaginatedParameterSchema = z.object({
  offset: z.number().optional(),
  limit: z.number().optional(),
  sort: z.literal('-').optional(),
  expand: z.array(z.string()).optional(),
})

export const makeOptionalFieldNullable = <Schema extends z.AnyZodObject>(
  schema: Schema,
) => {
  const entries = Object.entries(schema.shape) as [
    keyof Schema['shape'],
    z.ZodTypeAny,
  ][]
  const newProps = entries.reduce(
    (acc, [key, value]) => {
      // eslint-disable-next-line no-param-reassign
      acc[key] =
        value instanceof z.ZodOptional ? value.unwrap().nullish() : value
      return acc
    },
    {} as {
      [key in keyof Schema['shape']]: Schema['shape'][key] extends z.ZodOptional<
        infer T
      >
        ? z.ZodOptional<z.ZodNullable<T>>
        : Schema['shape'][key]
    },
  )
  return z.object(newProps)
}

export const makePaginatedParameterSchema = <T extends z.ZodRawShape>(
  schema: z.ZodObject<T>,
) => schema.merge(BasePaginatedParameterSchema)

export const makePaginatedPayloadSchema = <T extends z.ZodRawShape>(
  schema: z.ZodObject<T>,
) =>
  z.object({
    offset: z.number(),
    limit: z.number(),
    total: z.number(),
    data: z.array(schema),
  })

export const makeGetPayloadSchema = <T extends z.ZodRawShape>(
  schema: z.ZodObject<T>,
) => schema.merge(BaseEntitySchema)

export const makeSchemaOptional = <T extends z.ZodRawShape>(
  schema: z.ZodObject<T>,
) => schema.partial()

export const makePostPayloadSchema = <T extends z.ZodRawShape>(
  schema: z.ZodObject<T>,
) =>
  makeOptionalFieldNullable(
    (schema as SomeZodObject).omit({ id: true, isObsolete: true }),
  ) as z.ZodObject<Omit<T, 'id' | 'isObsolete'>>

export const makePutPayloadSchema = makePostPayloadSchema

export const makePatchPayloadSchema = <T extends z.ZodRawShape>(
  schema: z.ZodObject<T>,
) =>
  (
    makeOptionalFieldNullable(
      (schema as SomeZodObject).omit({ id: true }),
    ) as z.ZodObject<Omit<T, 'id'>>
  ).merge(BaseEntitySchema.pick({ rowVersion: true }))
