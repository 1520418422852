import {
  ApiFormattedError,
  getFundingList,
  patchFunding,
  postFunding,
} from '@stewards-fas/apis'
import {
  FundingListRequestPayload,
  FundingListResponsePayload,
  FundingResponsePayload,
  PatchFundingPayload,
  PostFundingPayload,
} from '@stewards-fas/schemas'
import {
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import { callbackNonNullable, useOptsOnSuccessInvalidate } from './helper'

const QUERY_CACHE_KEY_ROOT = 'fundings'

function useOpts<R, E, P>(opts?: UseMutationOptions<R, E, P>) {
  return useOptsOnSuccessInvalidate([QUERY_CACHE_KEY_ROOT], opts)
}

export function useFundingList(
  param?: FundingListRequestPayload,
  opts?: UseQueryOptions<unknown, unknown, FundingListResponsePayload>,
) {
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => getFundingList(param),
    opts,
  )
}

export function usePostFundingMutation(
  opts?: UseMutationOptions<
    FundingResponsePayload,
    ApiFormattedError,
    PostFundingPayload
  >,
) {
  const options = useOpts(opts)
  return useMutation(callbackNonNullable(postFunding), options)
}

export function usePatchFundingMutation(
  opts?: UseMutationOptions<
    FundingResponsePayload,
    ApiFormattedError,
    PatchFundingPayload
  >,
) {
  const options = useOpts(opts)
  return useMutation(callbackNonNullable(patchFunding), options)
}
