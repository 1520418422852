import { Status } from '@stewards-fas/schemas'

type StatusObject = { status: string }

/**
 * Remove any object.status.toLowerCase === Status.inactive
 * @param objectList a list to filter. Object must have {status} field
 * @returns a filtered list.
 */
export function removeInactive<T extends StatusObject>(objectList: T[]) {
  return objectList.filter((o) => o.status.toLowerCase() !== Status.inactive)
}
