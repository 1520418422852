import { Grid } from '@mui/material'
import { useTranslation } from '@pasteltech/i18n-react'
import { memo } from 'react'
import styled from 'styled-components'

type Item = {
  originalUnit: string
  departureDate: string
  transferUnit: string
  receptionDate: string
}
type ItemKeys = keyof Item

const keys: ItemKeys[] = [
  'originalUnit',
  'departureDate',
  'transferUnit',
  'receptionDate',
]
const Header = styled.div`
  color: ${({ theme }) => theme.colors.greys[3]};
  margin: 10px 0;
`
const Cell = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  margin: 10px 0;
`
const COLUMN_WIDTH = 3 //12 a row

export type TableProps = Item[]
export const TransferRecordTable = memo<{ records: TableProps }>(
  ({ records }) => {
    const { t } = useTranslation()

    return records.length > 0 ? (
      <Grid container>
        {keys.map((key) => {
          return (
            <Grid
              item
              xs={COLUMN_WIDTH}
              key={`transferRecordTable.headers.${key}`}
            >
              <Header>{t(`transferRecordTable.headers.${key}`)}</Header>
            </Grid>
          )
        })}

        {records.map((record) => {
          return (
            <>
              {keys.map((key) => (
                <Grid
                  item
                  xs={COLUMN_WIDTH}
                  key={`transferRecordTable.cells.${key}`}
                >
                  <Cell>{record[key]}</Cell>
                </Grid>
              ))}
            </>
          )
        })}
      </Grid>
    ) : (
      <></>
    )
  },
)
TransferRecordTable.displayName = 'TransferRecordTable'
