import { useMsal } from '@azure/msal-react'
import { useQueryUserProfile } from '@stewards-fas/queries'
import { useMemo } from 'react'
import { Role } from '../models'
import { Status } from '@stewards-fas/schemas'

export const useUserRole = () => {
  const { instance } = useMsal()

  const {
    data: userProfile,
    isLoading,
    isInitialLoading,
  } = useQueryUserProfile(
    {
      expand: ['Role'],
    },
    {
      refetchOnWindowFocus: false,
      enabled: instance.getActiveAccount() != null,
    },
  )

  const isDisabled = userProfile?.status === Status.inactive

  const role = useMemo(() => {
    if (isLoading) {
      return Role.anonymous
    }
    if (userProfile?.role?.name === Role.doer) {
      return Role.doer
    }

    if (userProfile?.role?.name === Role.supervisor) {
      return Role.supervisor
    }

    if (userProfile?.role?.name === Role.systemAdministrator) {
      return Role.systemAdministrator
    }

    return Role.anonymous
  }, [isLoading, userProfile?.role?.name])

  const isSupervisor = useMemo(() => role === Role.supervisor, [role])
  const isAdmin = useMemo(() => role === Role.systemAdministrator, [role])
  const isDoer = useMemo(() => role === Role.doer, [role])

  return useMemo(
    () => ({
      isDisabled,
      isLoading,
      // expose isInitialLoading because isLoading is true even query has enabled: false
      // https://github.com/TanStack/query/issues/3584 (for v4 only, v5 no longer need this)
      isInitialLoading,
      role,
      isSupervisor,
      isAdmin,
      isDoer,
    }),
    [
      isDisabled,
      isLoading,
      isInitialLoading,
      role,
      isSupervisor,
      isAdmin,
      isDoer,
    ],
  )
}
