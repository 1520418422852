import { appbar } from './appbar'
import { ApproveStatus } from './approve-status'
import { assetActions } from './asset-actions'
import { assetCreatePage } from './asset-create-page'
import { assetDetailsPage } from './asset-details-page'
import { assetEditPage } from './asset-edit-page'
import { assetLinksTable } from './asset-links-table'
import { assetManagement } from './asset-management'
import { assetTransferRecordPage } from './asset-transfer-record-page'
import { assetViewPage } from './asset-view-page'
import { common } from './common'
import { error } from './error'
import { imageUploader } from './image-uploader'
import { qrScanner } from './qr-scanner'
import { referenceInformation } from './reference-information'
import { reportPage } from './report-page'
import { searchBar } from './search-bar'
import { sidebar } from './sidebar'
import { systemDataManagement } from './system-data-management'
import { transferRecordTable } from './transfer-record-table'
export const zhHant = {
  common,
  ApproveStatus,
  assetDetailsPage,
  sidebar,
  appbar,
  assetManagement,
  searchBar,
  systemDataManagement,
  assetEditPage,
  assetViewPage,
  transferRecordTable,
  referenceInformation,
  assetLinksTable,
  reportPage,
  imageUploader,
  assetTransferRecordPage,
  assetCreatePage,
  qrScanner,
  error,
  assetActions,
}
