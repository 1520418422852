import { memo } from 'react'
import styled from 'styled-components'
import { FieldContainer, FieldValue } from '../preview-form-fields'
import { useTranslation } from '@pasteltech/i18n-react'

const SignatureContainer = styled.div`
  margin-top: 5vh;
  display: flex;
  flex-wrap: wrap;
`

const SignatureFieldContainer = styled.div<{ $isBorderBottom?: boolean }>`
  display: flex;
  align-items: center;
  border-bottom: ${({ $isBorderBottom }) => ($isBorderBottom ? '2' : '0')}px
    solid ${({ theme }) => theme.colors.greys[4]};
  flex-basis: 50%;
`

type SignatureBoxPropType = {
  formFillerName: string | undefined
  supervisorName: string | undefined
  formFillDate: string | undefined
  approvalDate: string | undefined
}
export const SignatureBox = memo<SignatureBoxPropType>(
  ({ formFillerName, supervisorName, formFillDate, approvalDate }) => {
    const { t } = useTranslation()
    return (
      <>
        <SignatureContainer>
          <SignatureFieldContainer $isBorderBottom={true}>
            <FieldContainer>
              {t('assetViewPage.previewForm.formFillerSign')}
            </FieldContainer>
          </SignatureFieldContainer>
          <SignatureFieldContainer $isBorderBottom={true}>
            <FieldContainer>
              {t('assetViewPage.previewForm.supervisorSign')}
            </FieldContainer>
          </SignatureFieldContainer>
          <SignatureFieldContainer $isBorderBottom={true}>
            <FieldContainer>
              {t('assetViewPage.previewForm.formFillerName')}
            </FieldContainer>
            <FieldValue>{formFillerName}</FieldValue>
          </SignatureFieldContainer>
          <SignatureFieldContainer $isBorderBottom={true}>
            <FieldContainer>
              {t('assetViewPage.previewForm.supervisorName')}
            </FieldContainer>
            <FieldValue>{supervisorName}</FieldValue>
          </SignatureFieldContainer>
          <SignatureFieldContainer $isBorderBottom={true}>
            <FieldContainer>
              {t('assetViewPage.previewForm.date')}
            </FieldContainer>
            <FieldValue>{formFillDate}</FieldValue>
          </SignatureFieldContainer>
          <SignatureFieldContainer $isBorderBottom={true}>
            <FieldContainer>
              {t('assetViewPage.previewForm.date')}
            </FieldContainer>
            <FieldValue>{approvalDate}</FieldValue>
          </SignatureFieldContainer>
        </SignatureContainer>
      </>
    )
  },
)
SignatureBox.displayName = 'SignatureBox'
